module SugarCube {
    'use strict';

    export function bxSlider(
        $window: ng.IWindowService,
        $timeout: ng.ITimeoutService
    ): ng.IDirective {
        return {
            restrict: 'A',
            priority: 50,
            link: (scope: ng.IScope, element: any, attrs: any) => {
                element.bxSlider({
                    slideWidth: 110,
                    minSlides: 10,
                    maxSlides: 12,
                    moveSlides: 1,
                    auto: true,
                    autoDirection: 'prev',
                    slideMargin: 10,
                    controls: false,
                    pager: false
                });
            }
        }
    }

    bxSlider.$inject = [];
    angular.module('SugarCube').directive('bxSlider', bxSlider);
}