module SugarCube {
    'use strict';

    export class ctrlJourneySidebar {
        
    }

    export function dirJourneySidebar(): ng.IDirective {
        return {
            restrict: 'EA',
            controller: ctrlJourneySidebar,
            controllerAs: 'cSidebar',
            templateUrl: 'partials/journey/sidebar.html',
            link: {
                pre: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
					//
                },
                post: (scope: ng.IScope, element: JQuery, attrs: any) => {

                }
            }
        };
    }

    dirJourneySidebar.$inject = [];
    angular.module('SugarCube').directive('dirJourneySidebar', dirJourneySidebar);
}