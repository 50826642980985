/// <reference path="profile/interest.ts" />
/// <reference path="profile/education.ts" />

module SugarCube {
    'use strict';

    export interface Profile extends ng.resource.IResource<Profile> {
        experiences: any;
        educations: IEducation;
        interests: IInterest
        allskills: any;
        profilecompleteness: any;
        basicinfo: any;
        misc: any;
    }
}