module SugarCube {
    'use strict';

    export interface IEducationCourse {
        CourseId: number;
        CourseName: string;
        IsTempCourse: boolean;
    }

    export interface IEducationInstitute {
        InstituteId: number;
        Name: string;
        CountryId: number;
        GeodataId: number;
        InstituteTypeId: number;
        Email: string;
        PhoneNumber: string;
        Website: string;
        LogoURL: string;
        Population: number;
    }

    export interface IEducation extends ng.resource.IResource<IEducation> {
        ProfileEducationSubProfileId: number;
		ProfileEducationId: number;
        Institute: IEducationInstitute;
        Course: IEducationCourse;
        EducationalLevelId: number;
		EducationalLevelName: string;
        Description: string;
        Grade: string;
        StartDate: string;
        EndDate: string;

        IsCurrent: boolean;
        IsDistanceEducation: boolean;
        IsGraduate: boolean;
    }
}