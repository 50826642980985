module SugarCube {
    'use strict';

    export interface IReferee extends ng.resource.IResource<IReferee> {
        ProfileRefereeId: number;
        ContactId: number;
        FirstName: string;
        LastName: string;
        AvatarImagePath: string;
        Email: string;
        Phone: string;
		RefereeTypeId: number;
		RefereeTypeName: string;
        RoleId: number;
        RoleTitle: string;
        IsTempRole: boolean;
        OrganisationId: number;
        OrgCountryId: number;
        OrganisationName: string;
        KnownSince: string;
        ProfileExperienceId: number;
        ProfileEducationId: number;
        ProfileId: number;
        DisplayOption: number;
        OverallRating: number;
        TestimonialText: string;
        IsSendEmailRequest: boolean;
    }
}