module SugarCube {
    'use strict';

    export class ctrlTranslateExpDlg {
        public static $inject = ['exp', 'subprofile', 'srvProfile', '$resource'];

        public subprofiles: Array<ISubProfile>;
        public selectedsubprofileexp: IExperienceSubProfile

        constructor(
            public exp: IExperience,
            public subprofile: ISubProfile,
            private srvProfile: srvProfile,
            private $resource: ng.resource.IResourceService
        ) {

            this.subprofiles = srvProfile.subprofiles.query();

            this.selectedsubprofileexp = subprofile.expsubprofiles.find((v) => {
                return v.ProfileExperienceId == exp.ProfileExperienceId;
            });
        }

        changeSelectedSubProfile(subprofile: ISubProfile) {
            this.selectedsubprofileexp = subprofile.expsubprofiles.find((v) => {
                return v.SubProfileId == subprofile.id;
            });
        }

        save() {
            this.exp.Achievements = this.selectedsubprofileexp.Achievements;

            (<any>this.srvProfile.experiences).update(this.exp);
        }
    }

    angular.module('gControllers').controller('ctrlTranslateExpDlg', ctrlTranslateExpDlg);
}