module SugarCube {
    'use strict';

    export class srvI18N {

        protected cache: any = {};

        public static $inject = [
            '$q', '$resource', 'apiendpoints', 'srvAuth',
        ];

        constructor(private $q: ng.IQService, private $resource: ng.resource.IResourceService, private apiendpoints: any, private srvAuth: srvAuth) {
        }

        i18n(key: string): string {
            if (this.cache[key]) return this.cache[key];
            else return 'i18n[[' + key + ']]';
        }

        loadtranslations(area: Array<string>) {
            var def = this.$q.defer();

            var qareas: Array<string> = [];
            area.forEach((e) => {
                if (!this.cache[e]) qareas.push(e);
            });

            this.$resource(this.apiendpoints.auth + 'GetTranslations').get({
                containers: qareas.join(','),
                languageid: this.srvAuth.session.displaylanguageid
            }, (res) => {
                angular.extend(this.cache, res);

                def.resolve();
            });

            return def.promise;
        }
    }

    angular.module('gServices').service('srvI18N', srvI18N);
}