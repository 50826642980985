/// <reference path="../../models/sharelinks.ts" />

module SugarCube {
    "use strict";

    export class ctrlShareLinksDlg {
        public static $inject = ['links'];

        constructor(public links: ShareLinks) {
        }
    }

    angular.module('gControllers').controller('ctrlShareLinksDlg', ctrlShareLinksDlg);
}