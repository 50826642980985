module SugarCube {
    'use strict';

    export interface IRole extends ng.resource.IResource<IRole> {
        RoleId: number;
        RoleName: string;
        IsTempRole: boolean;
    }

    export interface IOrganisationLocation {
        OrgLocationId: number;
        Name: string;
        GeoData: IGeoData;
        IsSelected: boolean;
    }

    export interface IOrganisation extends ng.resource.IResource<IOrganisation> {
        OrganisationId: number;
        Name: string;
        LogoURL: string;
        AnnualTurnoverId: number;
        IndustryId: number;
        WebURL: string;
        NumberOfEmployeeId: number;
        CountryId: number;
        IsOperating: boolean;
        OrgLocationList: Array<IOrganisationLocation>;
    }

    export interface ITeamMgmt {
        hasRevenueResponsibility: boolean;
        RevenueResponsibilityId: number;
        hasExpenditureResponsibility: boolean;
        ExpenditureResponsibilityId: number;
        ReportedToTypeId: number;
        SectorId: number;
        hasManagedTeam: boolean;
        NumberOfStaffManaged: number;
        TeamTypeManaged: number;
        TeamLocationTypeManaged: number;
    }

    export interface IOrgCulture {
        CultureId: number;
        CultureName: string;
        IsTempCulture: boolean;
    }

    export interface IOrgReview {
        ProfileExperienceRatingId: number;
        RatingDetail: any;
        OverallRating: number;
        Pros: string;
        Cons: string;
        Summary: string;
        AdviceToMgmt: string;
        CEOName: string;
        CeoCapability: number;
        CultureList: Array<IOrgCulture>;
    }

    export interface IExperience extends ng.resource.IResource<IExperience> {
        ProfileExperienceSubProfileId: number;
        ProfileExperienceId: number;
        StartDate: string;
        EndDate: string;
        Achievements: string;
        SubCategories: Array<number>;
        IsCurrentlyEmployed: boolean;
        WorkType: number;
		WorkTypeName: string;
        MinSalary: number;
        MaxSalary: number;
        Organisation: IOrganisation;
        Role: IRole;
        Skills: Array<ISkill>;
        TeamManagement: ITeamMgmt;
        Review: IOrgReview;
        SalaryTypeId: number;
        validationcount: number;
        endorsers: Array<IContact>;
    }
}