module SugarCube {
    'use strict';

    export class ctrlJourney {
        public static $inject = ['$scope', 'srvMasterData'];
        public md: any;

        constructor(private $scope: ng.IScope, srvMasterData) {
            this.md = srvMasterData.md;
        }
        
    }

    export function dirJourney(): ng.IDirective {
        return {
            restrict: 'EA',
            controller: ctrlJourney,
            controllerAs: 'cJourney',
            link: {
                //
            }
        };
    }

    dirJourney.$inject = [];
    angular.module('SugarCube').directive('dirJourney', dirJourney);
}