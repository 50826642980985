module SugarCube {
    'use strict';

    declare var AddressPicker: any;

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirAutoSuggestLocation(apiendpoints: any, srvAuth: srvAuth, $resource: ng.resource.IResourceService, srvGooglePlaces): ng.IDirective {

        interface IASlocationScope extends ng.IScope {
            _location: any;
            location: IGeoData;
            googleAddresses: any;
            countryabbr: string;
            suggestiontypes: Array<string>;
            required: boolean;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/templates/autosuggestlocations.html',
            scope: {
                location: '=',
                countryabbr: '=',
                suggestiontypes: '=?',
                placeholder: '=?'
            },
            link: {
                pre: (scope: IASlocationScope, element, attrs: any) => {

                    scope.required = attrs.required != null;

                    if (!scope.suggestiontypes) scope.suggestiontypes = ['address'];

                    var firsttrigger = true;

                    if (scope.location) scope._location = scope.location.description;

                    var initGoogleAutosuggest = function (countrycode) {
                        var autosuggestGooglePlaces = new AddressPicker({
                            autocompleteService: {
                                componentRestrictions: { country: countrycode },
                                types: scope.suggestiontypes
                            }
                        });
                        autosuggestGooglePlaces.initialize();
                        scope.googleAddresses = {
                            displayKey: 'description',
                            source: autosuggestGooglePlaces.ttAdapter()
                        };
                    }
                    initGoogleAutosuggest(scope.countryabbr);

                    scope.$watch('_location', (newvalue: any) => {
                        if (firsttrigger) {
                            firsttrigger = false;
                            return;
                        }

                        if (newvalue && newvalue.place_id) {
                            srvGooglePlaces.getPlaceDetails(newvalue.place_id).then((res) => {
                                $resource(apiendpoints.common + 'UpdateMasterGeoData').save({ geodatastring: JSON.stringify(res) }, (geodata) => {
                                    if (!scope.location) scope.location = <IGeoData>{};

                                    scope.location.GeoDataId = geodata.geodataid;
                                    scope.location.description = res.formatted_address;
                                    scope.location.place_id = newvalue.place_id;
                                });
                            });
                        }
                    });

                    scope.$watch('location', (newvalue: any) => {
                        if (!newvalue || newvalue.GeoDataId == undefined) scope._location = null;
                        else {
                            scope.location = newvalue;
                            scope._location = scope.location.description;
                        }
                    });

                    scope.$watch('countryabbr', (newvalue: string) => {
                        initGoogleAutosuggest(scope.countryabbr);
                    });
                }
            }
        };
    }

    dirAutoSuggestLocation.$inject = ['apiendpoints', 'srvAuth', '$resource', 'srvGooglePlaces'];
    angular.module('SugarCube').directive('dirAutoSuggestLocation', dirAutoSuggestLocation);
}