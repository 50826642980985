module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditLanguages(srvProfile: srvProfile, srvAuth: any, $resource: ng.resource.IResourceService, apiendpoints: any): ng.IDirective {

        class ctrlEditLanguages extends EditDirBase {
            public languages: ng.resource.IResourceArray<ILanguage>;
            public newlang: ILanguage;
            public cachedlangs: ng.resource.IResourceArray<ILanguage>;
            public langnot: boolean = false;
      
            constructor(private $scope: ng.IScope) {
                super();
                $scope.$on("notfound", () => {
                    this.langnotfound();
                });
                $scope.$on("langfound", ()=> {
                    this.langfound();
                });
             
                this.languages = srvProfile.languages.query();
            }
            langfound() {
                this.langnot = false;              
            }
            langnotfound() {
                this.langnot = true;              
            }
            newEntry() {
                this.editing = true;

                this.newlang = new srvProfile.languages();

                this.cachedlangs = angular.copy(this.languages);
            }

            deleteEntry(index: number) {
                this.cachedlangs.splice(index, 1);
            }

            addNew() {
                if (this.isNewLangValid()) {
                    this.cachedlangs.push(this.newlang);

                    this.newlang = new srvProfile.languages();
                }
            }

            isNewLangValid(): boolean {

                if (!this.newlang) return false;     
               
                if (!this.newlang.LanguageId || this.newlang.LanguageId <= 0) return false;

                if (this.cachedlangs.filter((e) => {
                    return e.LanguageId == this.newlang.LanguageId
                }).length > 0) return false;

                if (!this.newlang.LanguageLevelId || this.newlang.LanguageLevelId <= 0) return false;

                return true;
            }

            commitLangs() {
                this.addNew();

                this.saving = true;
                this.editing = false;

                srvProfile.languages.save(this.cachedlangs, (res) => {
                    this.languages = res;

                    this.saving = false;
                });
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/languages.html',
            controller: ctrlEditLanguages,
            controllerAs: 'cEditLang'
        };
    }

    dirEditLanguages.$inject = ['srvProfile', 'srvAuth', '$resource', 'apiendpoints'];
    angular.module('SugarCube').directive('dirEditLanguages', dirEditLanguages);
}