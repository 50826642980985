/// <reference path="../../../controllers/careerjourney.ts" />

module SugarCube {
    'use strict';    

    export class ctrlTimelineHeader {
        public static minHeaderHeight: number = 89;
        public static maxHeaderHeight: number = 218;
        public static minPhotoHeight: number = 70;
        public static maxPhotoHeight: number = 136;

		public headerHeight: number;
		public photoHeight: number;

		static $inject = [];

		constructor() {
			//
		}

		public updateHeader(currentHeight): void {
			//
		}
	}

	export function dirTimelineHeader(): ng.IDirective {
        return {
            restrict: 'EA',
            require: '^^dirTimeline',
            templateUrl: 'partials/timeline/header.html',
			controller: ctrlTimelineHeader,
			controllerAs: 'timelineHeader',
            link: {
                pre: (scope: any, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
					scope.$on(TimelineEvents.evtCJPanChanged,
                        (evt, offset) => {
                            var pos = ctrl.viewport.position.posY;
                            if (pos > 0) {
                                if (pos > ctrl.viewport.dimensions.clampMaxY)
                                    pos = ctrl.viewport.dimensions.clampMaxY;
                            } else {
                                pos = 0;
                            }
                            var headerPercentage = pos / ctrl.viewport.dimensions.clampMaxY;
                            scope.timelineHeader.headerHeight = ctrlTimelineHeader.minHeaderHeight + Math.floor(headerPercentage * (ctrlTimelineHeader.maxHeaderHeight - ctrlTimelineHeader.minHeaderHeight));
                            scope.timelineHeader.photoHeight = ctrlTimelineHeader.minPhotoHeight + Math.floor(headerPercentage * (ctrlTimelineHeader.maxPhotoHeight - ctrlTimelineHeader.minPhotoHeight));
                        });
                },
                post: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
                    //
                }
            }
        };
    }

    dirTimelineHeader.$inject = [];
    angular.module('SugarCube').directive('dirTimelineHeader', dirTimelineHeader);
}