module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditSocials(srvProfile: srvProfile, srvAuth: any, srvMasterData: any, $resource: ng.resource.IResourceService): ng.IDirective {

        class ctrlEditSocials extends EditDirBase {
            public sociallinks: ng.resource.IResourceArray<ISocialMediaLink>;
            public cachedsm: ng.resource.IResourceArray<ISocialMediaLink>;

            public editingsm: ISocialMediaLink;

            constructor() {
                super();

                this.sociallinks = srvProfile.sociallinks.query();
            }

            newEntry() {
                super.newEntry();

                this.cachedsm = angular.copy(this.sociallinks);

                this.getNextSocialLinkToEdit();
            }

            getNextSocialLinkToEdit() {
                var unused = this.getUnusedMasterSocialMediaTypes();

                if (unused.length > 0) {
                    this.editingsm = new srvProfile.sociallinks();
                    this.editingsm.MediaLinkType = unused[0];
                }
                else this.editingsm = null;
            }

            getUrlMask() {
                return {
                    maskDefinitions: { '*': /[a-zA-Z0-9.\/]/ }
                };
            }

            getUnusedMasterSocialMediaTypes(): Array<IMasterSocialMediaLink> {
                var res = [];

                Object.keys(srvMasterData.md.socialmedialinks).forEach((e) => {
                    if (this.sociallinks.filter((v) => {
                        return v.MediaLinkType.MediaLinkTypeId == srvMasterData.md.socialmedialinks[e].MediaLinkTypeId;
                    }).length == 0 && (!this.editingsm || srvMasterData.md.socialmedialinks[e].MediaLinkTypeId != this.editingsm.MediaLinkType.MediaLinkTypeId)) {
                        res.push(srvMasterData.md.socialmedialinks[e]);
                    }
                });

                return res;
            }

            addEntry() {
                if (this.editingsm) {
                    this.sociallinks.push(this.editingsm);

                    this.getNextSocialLinkToEdit();
                }
            }

            commitAll() {
                this.saving = true;
                this.editing = false;

                srvProfile.sociallinks.save(this.sociallinks, (res) => {
                    this.sociallinks = res;

                    this.saving = false;
                });
            }

            deleteSM(index) {
                this.sociallinks.splice(index, 1);
            }

            cancelEdit() {
                this.sociallinks = angular.copy(this.cachedsm);
                this.sociallinks.$resolved = true;

                super.cancelEdit();
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/socials.html',
            controller: ctrlEditSocials,
            controllerAs: 'cEditSocials'
        };
    }

    dirEditSocials.$inject = ['srvProfile', 'srvAuth', 'srvMasterData', '$resource'];
    angular.module('SugarCube').directive('dirEditSocials', dirEditSocials);
}