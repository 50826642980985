/// <reference path="../models/profile/education.ts" />
/// <reference path="../config/config.ts" />

module SugarCube {
    'use strict';

    export class srvProfile {

        protected myresumes: any;

        public subprofiles: ng.resource.IResourceClass<ISubProfile>;
        public basicinfo: Cachable<IBasicInfo>;
        public miscdetails: ng.resource.IResourceClass<IProfileMiscDetails>;
        public basicdetails: ng.resource.IResourceClass<IBasicDetails>;
        public experiences: CachableQuery<IExperience>;
        public education: ng.resource.IResourceClass<IEducation>;
        public languages: ng.resource.IResourceClass<ILanguage>;
        public sociallinks: ng.resource.IResourceClass<ISocialMediaLink>;
        public storyboard: ng.resource.IResourceClass<IStoryBoard>;
        public interests: ng.resource.IResourceClass<IInterest>;
        public skills: ng.resource.IResourceClass<IAdditionalSkill>;
        public certifications: ng.resource.IResourceClass<ICertification>;
        public referees: ng.resource.IResourceClass<IReferee>;
        public privacysettings: ng.resource.IResourceClass<IPrivacySetting>;
        public purchases: ng.resource.IResourceClass<IPurchase>;
        public jobfinders: ng.resource.IResourceClass<any>;

        public static $inject = [
            '$resource', 'apiendpoints',
        ];

        constructor(private $resource: ng.resource.IResourceService, private apiendpoints: any) {

            this.purchases = $resource<IPurchase>(apiendpoints.account + 'GetPurchases');

            this.privacysettings = $resource<IPrivacySetting>(Config.CCApiBase + '/AccountAPI/GetPrivacySettings', {}, {
                'save': {
                    method: 'PUT',
                    url: Config.CCApiBase + '/AccountAPI/UpdatePrivacySettings',
                    isArray: true
                }
            });

            this.miscdetails = $resource<IProfileMiscDetails>(apiendpoints.profile + 'GetProfileDetails');

            this.subprofiles = $resource<ISubProfile>(Config.CCApiBase + '/ProfileAPI/GetSubProfiles', {}, {
                'save': {
                    method: 'POST',
                    url: Config.CCApiBase + '/ProfileAPI/UpdateSubProfiles',
                    isArray: true
                }
            });

            this.basicinfo = new Cachable($resource<IBasicInfo>(Config.CCApiBase + '/AccountAPI/GetBasicInfo', {}, {
                'update': { method: 'PUT' },
                'save': {
                    method: 'POST',
                    url: Config.CCApiBase + '/AccountAPI/UpdateBasicInfo'
                }
            }));

            this.basicdetails = $resource<IBasicDetails>(apiendpoints.profile + 'BasicDetail', {}, {
                'update': { method: 'PUT' },
                'save': { method: 'PUT' }
            });

            this.experiences = new CachableQuery<IExperience>($resource<IExperience>(apiendpoints.profile + 'Experience/:id', { id: '@ProfileExperienceSubProfileId' }, {
                'delete': { method: 'DELETE', url: apiendpoints.profile + 'DeleteExperience/:id' },
                'update': { method: 'PUT' },
                'query': {
                    cache: true,
                    isArray: true,
                    method: 'GET'
                }
            }));

            this.education = $resource<IEducation>(apiendpoints.profile + 'Education/:id', { id: '@ProfileEducationSubProfileId' }, {
                'delete': { method: 'DELETE', url: apiendpoints.profile + 'DeleteEducation/:id' },
                'update': { method: 'PUT' },
                'query': {
                    cache: true,
                    isArray: true,
                    method: 'GET'
                }
            });

            this.languages = $resource<ILanguage>(apiendpoints.profile + 'Language/:id', { id: '@ProfileLanguageId' }, {
                'save': {
                    url: apiendpoints.profile + 'SaveAllLanguages',
                    isArray: true,
                    method: 'POST'
                }
            });

            this.skills = $resource<IAdditionalSkill>(apiendpoints.profile + 'GeneralSkill/:id', { id: '@AdditionalSkillId' }, {
                'save': {
                    url: apiendpoints.profile + 'SaveAllGeneralSkills',
                    isArray: true,
                    method: 'POST'
                }
            });

            this.sociallinks = $resource<ISocialMediaLink>(apiendpoints.profile + 'SocialMediaLink/:id', { id: '@ProfileSocialMediaLinkId' }, {
                'save': {
                    url: apiendpoints.profile + 'SaveAllSocialMediaLinks',
                    isArray: true,
                    method: 'POST'
                }
            });

            this.interests = $resource<IInterest>(apiendpoints.profile + 'Interest/:id', { id: '@ProfileInterestId' }, {
                'save': {
                    url: apiendpoints.profile + 'SaveAllInterests',
                    isArray: true,
                    method: 'POST'
                }
            });

            this.storyboard = $resource<IStoryBoard>(apiendpoints.profile + 'Storyboard/:id', { id: '@ProfileStoryboardId' }, {
                'delete': { method: 'DELETE', url: apiendpoints.profile + 'DeleteStoryboard/:id' },
                'update': { method: 'PUT' }
            });

            this.certifications = $resource<ICertification>(apiendpoints.profile + 'Certification/:id', { id: '@ProfileCertificationId' }, {
                'delete': { method: 'DELETE', url: apiendpoints.profile + 'DeleteCertification/:id' },
                'update': { method: 'PUT' }
            });

            this.referees = $resource<IReferee>(apiendpoints.profile + 'Referee/:id', { id: '@ProfileRefereeId' }, {
                'save': {
                    url: apiendpoints.profile + 'SaveAllReferees',
                    isArray: true,
                    method: 'POST'
                }
            });

            this.jobfinders = $resource(Config.CCApiBase + '/repo/GetJobFinderPreferences');
        }

        updatePassword(newpassword: string) {
			this.$resource(Config.CCApiBase + '/ProfileAPI/UpdatePassword').save('"' + newpassword + '"').$promise;
        }

        cloneSubProfile(sourcesubprofileid: number): ISubProfile {
            return this.$resource<ISubProfile>(Config.CCApiBase + "/ProfileAPI/CloneSubProfile?sourcesubprofileid=:sourcesubprofileid", {
                sourcesubprofileid: sourcesubprofileid
            }).save();
        }

        archiveSubProfile(subprofileid: number): ISubProfile {
            return this.$resource<ISubProfile>(Config.CCApiBase + "/ProfileAPI/ArchiveSubProfile?subprofileid=:subprofileid", {
                subprofileid: subprofileid
            }).save();
        }

        unarchiveSubProfile(subprofileid: number): ISubProfile {
            return this.$resource<ISubProfile>(Config.CCApiBase + "/ProfileAPI/UnarchiveSubProfile?subprofileid=:subprofileid", {
                subprofileid: subprofileid
            }).save();
        }

        // deprecated functions below
        get(sections?: Array<string>): Profile {

            var qsections: Array<string> = [];

            if (sections) {
                sections.forEach((e: string) => {
                    if (!this.basicdetails[e]) qsections.push(e);
                });
            }

            if (qsections.length > 0) {
                return this.$resource<Profile>(this.apiendpoints.common + 'GetProfileDetails').get({
                    areas: qsections.join(',')
                }, (res) => {
                    angular.extend(this.basicdetails, res);
                    return this.basicdetails;
                });
            } else {
                return null;
            }
        }
    }

    angular.module('gServices').service('srvProfile', srvProfile);
}