module SugarCube {
    'use strict';

    export function fillHeight(
        $window: ng.IWindowService,
        $timeout: ng.ITimeoutService
    ): ng.IDirective {
        return {
            restrict: 'A',
            priority: 50,
            link: (scope: ng.IScope, element: JQuery, attrs: any) => {
                var padbtn = parseInt(attrs.paddingBottom, 10) || 0;

                var resize = () => {
                    var eTop = element.offset().top - $window.pageYOffset;

                    element.css('height', $window.innerHeight - eTop - padbtn);
                }

                angular.element($window).on('resize', (evt) => {
                    resize();
                });

                $timeout(() => {
                    resize();
                });
            }
        }
    }

    fillHeight.$inject = ['$window', '$timeout'];
    angular.module('SugarCube').directive('fillHeight', fillHeight);
}