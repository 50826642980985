module SugarCube {
    'use strict';

    export interface IInputCoord {
        lastX: number;
        lastY: number;
        posX: number;
        posY: number;
    }

    export interface IInput {
        dragmode: boolean;
        inputs: Array<IInputCoord>;
        blocked: boolean;
        lastmove: Date;
        mode: string;
    }
}