module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function skillsBucket(
        srvProfile: srvProfile
    ): ng.IDirective {

        class ctrlSkillsBucket {

            public exp: Array<IExperience>;
            public closed: boolean;
            public skills: Array<ISkill>;

            public static $inject = ['$scope'];

            constructor($scope: ng.IScope) {
                this.exp = srvProfile.experiences.query();

                this.closed = !this.skills || this.skills.length == 0;
            }

            addToExp(skill: ISkill, exp: IExperience) {
                exp.Skills.push(skill);

                srvProfile.experiences.update(exp);

                // remove skill from array
                var skillindex = this.skills.findIndex((v) => {
                    return v.SkillName == skill.SkillName;
                });

                this.skills.splice(skillindex, 1);
            }
        }

        return {
            restrict: 'EA',
            scope: {
                skills: '='
            },
            templateUrl: 'partials/profileedit/skillsbucket.html',
            controller: ctrlSkillsBucket,
            controllerAs: 'cSkillsBucket',
            bindToController: true
        };
    }

    skillsBucket.$inject = ['srvProfile'];
    angular.module('SugarCube').directive('skillsBucket', skillsBucket);
}