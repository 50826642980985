module SugarCube {
    'use strict';

    export function fullheight(srvMasterData: any): ng.IDirective {

        return {
            restrict: 'C',
            link: (scope: ng.IScope, element: JQuery, attrs: any) => {
                $(window).resize(function () {
                    element.height($(window).height() - $('nav').outerHeight());
                    //NOTE Excluded the top nav bar from the full height calculations to fix CJ, I don't believe it is used anywhere else. - MJ
                });

                $(window).trigger('resize');
            }
        };
    }

    fullheight.$inject = ['srvMasterData'];
    angular.module('SugarCube').directive('fullheight', fullheight);
}