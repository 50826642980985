module SugarCube {
    'use strict';

    // these classes are used to cache a resource
    // it provides functionality which waits for a previous request to finish and reuse the results instead of initiating a new request

    export class Cachable<T extends ng.resource.IResource<T>> {

        private cache: T | ng.resource.IResourceArray<T>;

        constructor(private resource: ng.resource.IResourceClass<T>) {
        }

        get(): T {
            if (this.cache) return <T>this.cache;

            this.cache = this.resource.get();

            return <T>this.cache;
        }

        query(): ng.resource.IResourceArray<T> {
            if (this.cache) return <ng.resource.IResourceArray<T>>this.cache;

            this.cache = this.resource.query();

            return <ng.resource.IResourceArray<T>>this.cache;
        }

        refresh() {
            this.cache = null;
        }
    }

    export class CachableQuery<T> {

        private cache: ng.resource.IResourceArray<T>;

        constructor(private resource: ng.resource.IResourceClass<T>) {
        }

        query(params?: Object): ng.resource.IResourceArray<T> {
            if (this.cache) return this.cache;

            this.cache = this.resource.query(params);

            return this.cache;
        }

        update(params?: Object): T {
            return (<any>this.resource).update(params);
        }

        save(params?: Object): T {
            return this.resource.save(params);
        }

        delete(params?: Object): T {
            return this.resource.delete(params);
        }

        clear() {
            this.cache = null;
        }
    }
}