module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditStoryboard(srvProfile: srvProfile, srvAuth: any, apiendpoints: any, ngDialog: ng.dialog.IDialogService): ng.IDirective {

        class ctrlEditStoryboard extends EditDirBase {
            public storyboard: ng.resource.IResourceArray<IStoryBoard>;
            public dzcfgUpload: any;
            public dzevtUpload: any;
            public dropzone: any;

            public editingobj: IStoryBoard;

            constructor() {
                super();

                var _self = this;

                this.storyboard = srvProfile.storyboard.query();

                this.dzcfgUpload = {
                    maxFileSize: 2,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                    url: apiendpoints.common + 'UploadTempFile',
                    autoProcessQueue: true,
                    headers: {
                        "Authorization": "Bearer " + srvAuth.session.accesstoken
                    },
                    thumbnailWidth: null,
                    thumbnailHeight: null
                }

                this.dzevtUpload = {
                    removedfile: function (file) {
                    },
                    addedfile: function (file) {
                    },
                    success: function (file, res) {
                        if (_self.editingobj) _self.editingobj.Path = res;
                    },
                    error: function (file, res, xhr) {
                    },
                    complete: function () {
                    }
                }
            }

            editEntry(sb: IStoryBoard) {
                this.editingobj = angular.copy(sb);

                this.editing = true;
                if (this.dropzone) this.dropzone.removeAll();
            }

            setStartMonth(newMonth) {
                if (arguments.length) {
                    this.editingobj.StoryDate = moment(this.editingobj.StoryDate).month(newMonth).format();
                } else {
                    return moment(this.editingobj.StoryDate).month();
                }
            }

            setStartYear(newYear) {
                if (arguments.length) {
                    this.editingobj.StoryDate = moment(this.editingobj.StoryDate).year(newYear).format();
                } else {
                    return moment(this.editingobj.StoryDate).year();
                }
            }

            commitEdit() {
                this.saving = true;
                this.editing = false;

                if (this.editingobj.ProfileStoryboardId > 0) {
                    (<any>srvProfile.storyboard).update(this.editingobj, (res) => {
                        this.storyboard.forEach((e, i, a) => {
                            if (e.ProfileStoryboardId == this.editingobj.ProfileStoryboardId) {
                                a[i] = res;
                                return false;
                            }
                        });

                        this.editingobj = null;
                        this.saving = false;
                    });
                } else {
                    srvProfile.storyboard.save(this.editingobj, (res) => {
                        this.storyboard.push(res);

                        this.editingobj = null;
                        this.saving = false;
                    });
                }
            }

            deleteEditingSB() {
                ngDialog.openConfirm({
                    plain: true,
                    template: '<p>Are you sure you wish to delete this entry?</p><p class="text-align-right"><button class="btn-small btn-cancel" ng-click="closeThisDialog()">No</button><button class="btn-small" ng-click="confirm()">Yes</button></p>'
                }).then((res) => {
                    this.saving = true;
                    this.editing = false;

                    srvProfile.storyboard.delete({ id: this.editingobj.ProfileStoryboardId }, (res) => {
                        this.storyboard.forEach((e, i, a) => {
                            if (e.ProfileStoryboardId == this.editingobj.ProfileStoryboardId) {
                                a.splice(i, 1);
                                return false;
                            }
                        });

                        this.saving = false;
                        this.editingobj = null;
                    });
                });

                
            }

            newEntry() {
                super.newEntry();

                this.editingobj = new srvProfile.storyboard();
                this.editingobj.StoryDate = moment().format();
                if (this.dropzone) this.dropzone.removeAll();
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/storyboard.html',
            controller: ctrlEditStoryboard,
            controllerAs: 'cEditSB'
        };
    }

    dirEditStoryboard.$inject = ['srvProfile', 'srvAuth', 'apiendpoints', 'ngDialog'];
    angular.module('SugarCube').directive('dirEditStoryboard', dirEditStoryboard);
}