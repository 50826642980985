module SugarCube {
    "use strict";

    class BasicInfoForUpdate {
        newpassword: string;
        confirmpassword: string;
        userid: any;
        sourceid: number;
    }


    export class ctrlConfirmUpdateDetailsDlg {
        public profile: IBasicDetails;
        public md: any;
        public googleAddresses: any = (new utilGoogleAddressPicker('US')).getTypeAheadDataSet();
        public newpassword: string;
        public confirmpassword: string;
        public userid: any;
        public static $inject = ['srvProfile', 'srvMasterData', '$resource', 'srvGooglePlaces', '$scope', '$q', '$state'];

        constructor(
            srvProfile: srvProfile,
            srvMasterData: any,
            private $resource: ng.resource.IResourceService,
            private srvGooglePlaces: any,
            private $scope: ng.dialog.IDialogScope,
            private $q: ng.IQService,
            private $state: ng.ui.IStateService
        ) {
            //this.userid = $scope.$parent.cConfirmUpdate.userid;
            //this.profile = $scope.$parent.cConfirmUpdate.profile;
            this.userid = $scope.userid;
            this.profile = $scope.profile;
            this.md = srvMasterData.md;
        }

        submit() {

            if (this.profile.isGuest
                && (this.newpassword != this.confirmpassword
                    || typeof (this.newpassword) == undefined
                    || typeof (this.confirmpassword) == undefined)) return;

            var geodataid: number = 0;

            var updatedetailpromise = this.$resource(Config.CCApiBase + '/ClassicProfileAPI/BasicDetail').save(this.profile).$promise;

            var updatepasswordpromise = null;


            if (this.profile.isGuest && this.newpassword) {
                var updatePassword = new BasicInfoForUpdate();
                updatePassword.userid = this.userid;
                updatePassword.newpassword = this.newpassword;
                updatePassword.confirmpassword = this.confirmpassword;
                updatepasswordpromise = this.$resource(Config.CCApiBase + '/ClassicProfileAPI/UpdatePassword' ).save(updatePassword).$promise;
            }
            else {
                updatepasswordpromise = this.$q.when('');
            }

            var insertToLog = new BasicInfoForUpdate();
            insertToLog.userid = this.userid;
            insertToLog.sourceid = 2; // 2 means submit information
            var updatehistorylog = this.$resource(Config.CCApiBase + '/ClassicProfileAPI/InsertRequestAutoEmialLog').save(insertToLog).$promise;

            this.$q.all([updatedetailpromise, updatepasswordpromise, updatehistorylog]).then(() => {
                this.$scope.closeThisDialog();

                if (this.$scope.fromwhere == 'requestautoemail')
                    this.$state.go('home');

                //when fix auto login with password,and then replace the code
                //this.$state.go('culture.profileedit');
            });
        }
    }

    angular.module('gControllers').controller('ctrlConfirmUpdateDetailsDlg', ctrlConfirmUpdateDetailsDlg);
}