module SugarCube {
    'use strict';

    export function convertToNumber(): ng.IDirective {


        return {
            require: 'ngModel',
            link: (scope, element, attrs, ngModel: ng.INgModelController) => {
                ngModel.$parsers.push((val) => {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push((val) => {
                    return '' + val;
                });
            }
        };
    }

    convertToNumber.$inject = [];
    angular.module('SugarCube').directive('convertToNumber', convertToNumber);
}