module SugarCube {
    "use strict";

    export class ctrlProfileEdit {
        public md: any;
        public basicdetails: IBasicDetails;
        public miscdetails: IProfileMiscDetails;
        public editingbasicdetails: boolean;
        public editingprofilephoto: boolean;
        public editingpositiontitle: boolean;
        public uploadingvideo: boolean;
        public rawyoutubelink: string;
        public currencysymbol: string;

        public suggestedcareersummarytexts: Array<any>;

        public static $inject = ['srvAuth', 'srvMasterData', '$scope', 'srvProfile', '$rootScope', 'apiendpoints', '$resource', 'ngDialog', 'subprofileid', 'skillsbucket', 'access', '$state', '$stateParams'];

        constructor(
            private srvAuth: srvAuth,
            srvMasterData: any,
            $scope: ng.IScope,
            private srvProfile: srvProfile,
            $rootScope: ng.IRootScopeService,
            private apiendpoints: any,
            private $resource: ng.resource.IResourceService,
            private ngDialog: ng.dialog.IDialogService,
            public subprofileid: number,
            public skillsbucket: Array<any>,
            public access: string,
            private $state: ng.ui.IStateService,
            private $stateParams: any
        ) {
            if ($stateParams && $stateParams.accesstoken) {
                srvAuth.setSession({
                    accesstoken: $stateParams.accesstoken
                });
            }

            if (access == null || access == '' || access == undefined) {
                if (this.skillsbucket) {
                    // first, we need to transform the skills bucket into an array of ISkills
                    this.skillsbucket = this.skillsbucket.map((v) => {
                        return <ISkill>{
                            SkillName: v,
                            IsTempSkill: true
                        };
                    });
                }

                this.md = srvMasterData.md;
                this.currencysymbol = srvAuth.session.currencySymbol;

                $scope.$on(Events.evtScrollToExp, () => {
                    $('html, body').animate({
                        scrollTop: $("#pe-exp").offset().top - 70
                    }, 'slow');
                });

                $scope.$on(Events.evtScrollToEdu, () => {
                    $('html, body').animate({
                        scrollTop: $("#pe-edu").offset().top - 70
                    }, 'slow');
                });

                this.miscdetails = srvProfile.miscdetails.get();
                this.basicdetails = srvProfile.basicdetails.get({
                    subprofileid: this.subprofileid
                });

                this.basicdetails.FirstName = srvAuth.session.firstname;
                this.basicdetails.LastName = srvAuth.session.lastname;

                this.suggestedcareersummarytexts = $resource(apiendpoints.profile + 'GetSuggestedCareerSummaryTexts').query();
                this.rawyoutubelink = this.basicdetails.VideoIntroPath || "";
            } else {
                var decodedString = atob(access);
                var arr = decodedString.split("xzcvr");

                var subprofileid = this.subprofileid;
                srvAuth.clearTokensInLocalStorage();

                srvAuth.getSession("en-US").then(() => {
                    $resource(apiendpoints.account + 'SignInUser').save({
                        username: arr[0],
                        password: arr[1],
                        rememberme: false
                    }, function (res) {
                        srvAuth.setSession(res);
                        $state.go('culture.profileedit');
                    }, function (res) {
                        $scope.loading = false;
                        $scope.errormsg = res.data.Message;
                    });
                }, function (error) {
                    console.error(error);
                });
            }
        }


        getDisplayDuration(startdate: string, enddate: string): string {
            var mstart = moment(startdate);
            var mend = moment(enddate);
            var dur = moment.duration(mend.diff(mstart)).add(1, 'month');

            var res = dur.years() + 'y';
            if (dur.months() > 0) res += ' ' + dur.months() + 'm';

            return res;
        }

        editBasicDetails() {
            this.editingbasicdetails = true;
        }

        cancelEditBasicDetails() {
            this.editingbasicdetails = false;
        }

        editProfilePhoto() {
            this.editingprofilephoto = true;
        }

        cancelEditProfilePhoto() {
            this.editingprofilephoto = false;
        }

        cancel() {
            this.editingpositiontitle = false;
            this.$state.reload();
        }

        commitBasicDetails() {
            this.editingprofilephoto = false;
            this.editingbasicdetails = false;
            this.editingprofilephoto = false;
            this.editingpositiontitle = false;

            this.basicdetails.$save({
                subprofileid: this.subprofileid
            }, (res: IBasicDetails) => {
                this.srvAuth.session.avatarurl = this.basicdetails.ProfileImagePath;
                if (this.srvAuth.session.displaylanguageid != res.CurrentSPLanguageId) {
                    var paths = window.location.pathname.split('/');
                    paths[1] = this.md.languages[res.CurrentSPLanguageId].CultureCode;
                    window.location.href = paths.join('/');
                }
            });
        }

        removeEditProfilePhoto() {
            this.basicdetails.ProfileImagePath = null;

            this.commitBasicDetails();
        }

        editPositionTitle() {
            this.editingpositiontitle = true;
        }

        setBirthDay(newDay) {
            if (arguments.length) {
                this.basicdetails.DOB = moment(this.basicdetails.DOB).date(newDay).format();
            } else {
                return moment(this.basicdetails.DOB).date();
            }
        }

        setBirthMonth(newMonth) {
            if (arguments.length) {
                this.basicdetails.DOB = moment(this.basicdetails.DOB).month(newMonth).format();
            } else {
                return moment(this.basicdetails.DOB).month();
            }
        }

        setBirthYear(newYear) {
            if (arguments.length) {
                this.basicdetails.DOB = moment(this.basicdetails.DOB).year(newYear).format();
            } else {
                return moment(this.basicdetails.DOB).year();
            }
        }

        getDays(month: number, year: number) {
            var de = [];
            for (var i = 1; i <= 31; i++) de.push(i);

            if (month == undefined || year == undefined) return de;

            var days = [];

            for (var i = 1; i <= moment().year(year).month(month).endOf('month').date(); i++)
                days.push(i);

            return days;
        }

        changeBasicDetailsCountry() {
            this.basicdetails.GeoData = null;
        }

        getDOBMonth() {
            return moment(this.basicdetails.DOB).month();
        }

        getDOBYear() {
            return moment(this.basicdetails.DOB).year();
        }

        checkBit(bit: number, shift: number): boolean {
            return ((bit >> shift) & 1) > 0;
        }

        setRefBit(shift: number, show: boolean) {
            if (show) this.miscdetails.refereesectiondisplayoption |= 1 << shift;
            else this.miscdetails.refereesectiondisplayoption &= ~(1 << shift);
        }

        saveRefDisplay() {
            this.$resource(this.apiendpoints.profile + 'UpdateRefereeSectionDisplayOption/:id').get({
                id: this.miscdetails.refereesectiondisplayoption
            });

        }

        addSuggestedCareerSummary(suggestedtext, index) {
            this.basicdetails.CareerSummary += '<p>' + suggestedtext.text + '</p>';
            this.$resource(this.apiendpoints.profile + 'UpdateUsageCountForCareerSummaryTexts').save([suggestedtext.id]);
            this.suggestedcareersummarytexts.splice(index, 1);
        }

        editCoverImg() {
            this.ngDialog.openConfirm({
                template: '<dir-edit-coverimg coverimg="coverimg"></dir-edit-coverimg><div class="text-align-right">\
                                <button class="btn-white btn-small" ng-click="closeThisDialog()">Cancel</button>\
                                <button class="btn-small" ng-click="!uploading && confirm({coverimg: coverimg})" ng-class{disabled: uploading}>Save</button>\
                            </div>',
                className: 'ngdialog-theme-default dlg-addcoverimg',
                plain: true,
                controller: ['$scope', ($scope) => {
                    $scope.coverimg = this.basicdetails.CoverImage;

                    $scope.uploading = true;
                    var first = true;
                    $scope.$watch('coverimg', () => {
                        if (first) {
                            first = false; return;
                        }
                        $scope.uploading = false;
                    });
                }]
            }).then((data) => {
                this.basicdetails.CoverImage = data.coverimg;

                this.basicdetails.$save({
                    subprofileid: this.subprofileid
                });
            }, () => {
            });
        }

        getYouTubeID(): string {
            var matches = this.rawyoutubelink.match(/watch\?v=([a-zA-Z0-9\-_]+)/);

            if (matches) return matches[1];
            else return null;
        }

        isValidYouTubeLink(): boolean {
            return !!this.getYouTubeID();
        }

        isVideoPathYoutube(): boolean {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = this.basicdetails.VideoIntroPath.match(regExp);
            return !!match;
        }

        addYouTubeVideo() {
            if (this.isValidYouTubeLink()) {
                this.basicdetails.VideoIntroPath = 'https://www.youtube.com/embed/' + this.getYouTubeID();
            }
        }

        deleteVideo() {
            this.basicdetails.VideoIntroPath = null;
            this.basicdetails.$save({
                subprofileid: this.subprofileid
            });
        }

        saveVideo() {
            this.basicdetails.$save({
                subprofileid: this.subprofileid
            });
            this.uploadingvideo = false;
        }
    }

    angular.module('gControllers').controller('ctrlProfileEdit', ctrlProfileEdit);
}