module SugarCube {
    "use strict";
    export class ctrlSignUpWithResume {
        public currentFile: any;
        public loginModel: ISignUpWithResumeModel;
        public md: any;
        public loading: boolean;
        public errormsg: any;

        public static $inject = ['$resource',
            '$scope', 'ngDialog', '$state', 'apiendpoints',
            'srvAuth', 'srvAnalytics', '$timeout', '$rootScope', 'srvI18N', 'srvMasterData'];

        constructor(
            private $resource: ng.resource.IResourceService,
            private $scope: ng.dialog.IDialogScope,
            private ngDialog: ng.dialog.IDialogService,
            private $state: ng.ui.IStateService,
            private apiendpoints: any,
            private srvAuth: srvAuth,
            private srvAnalytics: any,
            private $timeout: ng.ITimeoutService,
            private $rootScope: ng.IRootScopeService,
            private srvI18N: srvI18N,
            srvMasterData: any,
        ) {
            
            this.md = srvMasterData.md;
            this.loading = false;

            $scope.dzcfgRegister = {
                maxFileSize: 2,
                acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                maxFiles: 1,
                url: apiendpoints.account + 'RegisterUserWithResume',
                autoProcessQueue: false,
                headers: {
                    "Authorization": "Bearer " + srvAuth.session.accesstoken
                },
                clickable: '.upload-container',
                previewsContainer: '.file-preview',

            }

            $scope.dzevtRegister = {
                addedfile: function (file) {
                    if (this.currentFile) {
                        this.removeFile(this.currentFile);
                    }
                    this.currentFile = file;
                },
                success: function (file, res) {
                    srvAuth.setSession(res);
                },
                error: function (file, res, xhr) {
                    switch (xhr.status) {
                        case 412:
                            ngDialog.open({
                                template: 'partials/dialogs/emailexists.html',
                                className: 'ngdialog-theme-default dlg-msg',
                                scope: $scope
                            });
                            break;

                        case 400:
                            ngDialog.open({
                                template: '<p>Email was not found in the resume you provided. Please try a different resume.</p>',
                                className: 'ngdialog-theme-default dlg-msg',
                                plain: true
                            });
                            break;
                    }

                    this.removeFile(file);
                },
                complete: function () {
                    $scope.$apply();
                },

            }
        }

        /* test function
        popupbox()
        {
            this.ngDialog.open({
                template: '<home-job-finder class="jobfinder-include"></home-job-finder>',
                className: 'ngdialog-theme-default p-home dlg-signupwithresume',
                //html code
                plain: true
            });
        }
        */

        i18n(key) {
            return this.srvI18N.i18n(key);
        }

        submit() {

            if (this.$scope.registerform.$valid && !this.$scope.registerform.$pending) {
                this.loading = true;
                this.$resource(this.apiendpoints.account + 'RegisterUser').save({
                    FirstName: this.loginModel.firstname,
                    LastName: this.loginModel.lastname,
                    Email: this.loginModel.email,
                    Password: this.loginModel.password,
                    Address: this.loginModel.geodata.description,
                    GeoDataId: this.loginModel.geodata.GeoDataId,
                    AddressUnit: this.loginModel.addressunit,
                    PersonalBackupEmail: this.loginModel.personalbackupemail,
                    PhoneNumber: this.loginModel.phone,
                    CountryId: this.loginModel.countryid,
                    CountryName: this.md.country[this.loginModel.countryid].name,
                    IsSendBothEmail: false
                }, (res) => {

                    this.srvAuth.setSession(res);
                    this.srvAnalytics.registerevent();

                }).$promise.then((res) => {
                    this.$resource<any>(Config.CCApiBase + '/Auth/ActivateUser/', {
                        userid: res.userid
                    }).get();

                }).then((res) => {
                    if (this.$scope.dropzone.files.length > 0) {
                        // this function will call API (RegisterUserWithResume)
                        this.$scope.dropzone.processQueue();
                    }
                    this.$state.go('culture.jobalert');

                }).catch((res) => {
                    this.loading = false;
                    this.errormsg = res.data.Message;
                }
                    );

            }
        }

    }

    angular.module('gControllers').controller('ctrlSignUpWithResume', ctrlSignUpWithResume);
}