module SugarCube {
    'use strict';

    export function displayduration() {
        return function (startdate: string, enddate: string) {
            var mstart = moment(startdate);
            var mend = moment(enddate);
            var dur = moment.duration(mend.diff(mstart)).add(1, 'month');

            var res = dur.years() + 'y';
            if (dur.months() > 0) res += ' ' + dur.months() + 'm';

            return res;
        }
    }

    cdnurl.$inject = [];
    angular.module('SugarCube').filter('displayduration', displayduration);
}