module SugarCube {
    'use strict';

    export interface IPurchase extends ng.resource.IResource<IPurchase> {
        productid: number;
        previewurl: string;
        name: string;
        datepurchased: string;
        price: number;
        link: string;
    }
}