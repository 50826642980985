module SugarCube {
    'use strict';

    export function internationalphone() {
        return function (phone: string) {

            if (!phone) return phone;
            if (phone.length == 0) return phone;

            var firstdigit = phone.substr(0, 1);

            if (firstdigit == '0') return phone.substr(1);
            return phone;
        }
    }

    internationalphone.$inject = [];
    angular.module('SugarCube').filter('internationalphone', internationalphone);
}