module SugarCube {
    'use strict';

    export interface IViewportConfig {
        minTileSize: number;
        enforceMaximum: boolean;
        tileMargin: number;
        minYearGranularity: number;
        defaultYearGranularity: number;
        granularityIncrement: number;
    }

	export class ViewportDimensions {
		width: number;
		displayedYearRangeStart: number;
        displayedYearRangeEnd: number;
        displayedYearRangeSpan: number;
		streamWidth: number;                       // Full size of a stream in pixels
		visibleStartDate: string;
        visibleEndDate: string;
        clampMinY: number;
        clampMaxY: number;
	}

	export class ViewportPosition {
		posX1: number;
        posX2: number;
        posY: number;
	}

	export class ViewportScaling {
		percentageYearsVisible: number;
		maxWidth: number;
		yearWidth: number;                         // Current year width for the current zoom level; used for scaling tiles
        minYearWidth: number;                     // When zoomed out all the way
		factor: number;
		yearsVisible: number;
	}

    export class Viewport {
		scaling: ViewportScaling;
		position: ViewportPosition;
		dimensions: ViewportDimensions;
		config: IViewportConfig;

		constructor(config: IViewportConfig) {
			this.scaling = new ViewportScaling();
			this.position = new ViewportPosition();
			this.dimensions = new ViewportDimensions();
			this.config = config;
		}

		public ConvertPixelsToDate(px: number): moment.Moment {
            var year = Math.floor(px / this.scaling.yearWidth) + this.dimensions.displayedYearRangeStart;
            var remainder = Math.floor(px % this.scaling.yearWidth);
            var startYear = moment(new Date(year, 0, 1));
            var endYear = moment(new Date(year + 1, 0, 1));
			var daysInYear = moment.duration(startYear.diff(endYear)).asDays();
			var percentage = remainder / this.scaling.yearWidth;
			var daysIntoYear = percentage * daysInYear;

            var finalDate = startYear.add(daysIntoYear, "days");
            return finalDate;
        }

	    public setWidth(newWidth: number) {
		    if (newWidth) {
			    this.dimensions.width = newWidth;
		    }
		}

		public setModel(model: JourneyModel) {
			if (model && this.dimensions.width) {
				this.dimensions.displayedYearRangeStart = model.startDate.year() - (model.startDate.year() % 5);
				var modelBegin = moment(new Date(this.dimensions.displayedYearRangeStart, 0, 1));
				var minYearWidth = this.dimensions.width / (model.endDate.year() + 1 - model.startDate.year()) * this.config.minYearGranularity;
				model.updateLastDrawn(modelBegin, minYearWidth);
				var maxYears = (model.lastDrawnTile.meta.posX1 + model.lastDrawnTile.getTileSize()) / minYearWidth;

				this.dimensions.displayedYearRangeSpan = maxYears - maxYears % 5 + 5;
				this.dimensions.displayedYearRangeEnd = this.dimensions.displayedYearRangeStart + this.dimensions.displayedYearRangeSpan - 1;

				this.scaling.minYearWidth = this.dimensions.width / this.dimensions.displayedYearRangeSpan;
                this.scaling.maxWidth = this.dimensions.width * this.dimensions.displayedYearRangeSpan / this.config.minYearGranularity;
			}
		}

	    public pan(x: number, y: number) {
            var tempwidth = this.dimensions.width * this.scaling.factor;
		    this.position.posX1 = x;
		    this.position.posX2 = this.position.posX1 + tempwidth;

		    if (this.position.posX2 > this.scaling.maxWidth) {
			    var diff = this.position.posX2 - this.scaling.maxWidth;
			    /*if (diff > tempwidth) {
			        diff = tempwidth;
			    }*/
			    this.position.posX1 -= diff;
			    this.position.posX2 -= diff;

		    }
		    if (this.position.posX1 < 0) {
			    this.position.posX1 = 0;
			    this.position.posX2 = this.position.posX1 + tempwidth;
		    }

            this.position.posY = y;

            if (this.position.posY > this.dimensions.clampMaxY)
                this.position.posY = this.dimensions.clampMaxY;

            if (this.position.posY < this.dimensions.clampMinY)
                this.position.posY = this.dimensions.clampMinY;
		}

		public zoom(visible): boolean {
            var oldWidth = this.scaling.yearWidth;

            var yearsVisible = visible / this.scaling.maxWidth * this.dimensions.displayedYearRangeSpan;
            if (yearsVisible > this.dimensions.displayedYearRangeSpan) {
                yearsVisible = this.dimensions.displayedYearRangeSpan;
            }
            if (yearsVisible < 1) {
                yearsVisible = 1;
            }
			this.scaling.yearsVisible = yearsVisible;
            this.scaling.yearWidth = Math.round(this.dimensions.width / yearsVisible);
		    
            if (this.scaling.yearWidth !== oldWidth) {
                this.dimensions.streamWidth = this.scaling.yearWidth * this.dimensions.displayedYearRangeSpan;
                this.scaling.factor = this.scaling.maxWidth / this.dimensions.streamWidth;
                return true;
            }
            return false;
        }

		public zoomDefaultViewport() {
            var yearsVisible = 0;
            if (this.dimensions.displayedYearRangeSpan > this.config.defaultYearGranularity)
                yearsVisible = this.config.defaultYearGranularity;
            else
                yearsVisible = this.dimensions.displayedYearRangeSpan;
            var visible = yearsVisible / this.dimensions.displayedYearRangeSpan * this.scaling.maxWidth;
            this.zoom(visible);
        }
    }
}