module SugarCube {
    'use strict';

	export class ctrlTimelineYears {
		public years: {};
		public width: number;
		public streamWidth: number;
		public posX: number;
		public yearsVisible: string;

		static $inject = [];

		constructor() {
			//
		}

		public updateYears(startYear, endYear, width): void {
			this.width = width;
			this.years = {};
			for (var i = startYear; i <= endYear; i++) {
				this.years[i] = { key: i};
			}
		}
	}

	export function dirTimelineYears(): ng.IDirective {
        return {
            restrict: 'EA',
            require: '^^dirTimeline',
            templateUrl: 'partials/timeline/years.html',
			controller: ctrlTimelineYears,
			controllerAs: 'timelineYears',
            link: {
                pre: (scope: any, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
	                scope.$on(TimelineEvents.evtCJZoomChanged,
						(evt, offset) => {
							scope.timelineYears.updateYears(ctrl.viewport.dimensions.displayedYearRangeStart, ctrl.viewport.dimensions.displayedYearRangeEnd, ctrl.viewport.scaling.yearWidth);
							scope.timelineYears.yearsVisible = ctrl.viewport.scaling.yearsVisible;
							scope.timelineYears.streamWidth = ctrl.viewport.scaling.maxWidth;
						});
					scope.$on(TimelineEvents.evtCJPanChanged,
						(evt, offset) => {
                            scope.timelineYears.posX = -(ctrl.viewport.position.posX1 / ctrl.viewport.scaling.factor);
						});
                },
                post: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
                    //
                }
            }
        };
    }

    dirTimelineYears.$inject = [];
    angular.module('SugarCube').directive('dirTimelineYears', dirTimelineYears);
}