/// <reference path="../../models/profile/skill.ts" />

module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditSkills(srvProfile: srvProfile, srvAuth: any, srvMasterData: any, ngDialog: ng.dialog.IDialogService): ng.IDirective {

        class ctrlEditSkills extends EditDirBase {
            public skills: ng.resource.IResourceArray<IAdditionalSkill>;
            public newskill: IAdditionalSkill;
            public cached: ng.resource.IResourceArray<IAdditionalSkill>;
            public subprofileid: number;
            public md: any;

            constructor() {
                super();
                this.md = srvMasterData.md;
                this.skills = srvProfile.skills.query({
                    subprofileid: this.subprofileid
                });
            }

            newEntry() {
                this.editing = true;
                this.newskill = new srvProfile.skills();
                this.cached = angular.copy(this.skills);
            }

            edit(skill: IAdditionalSkill, index: number) {               
                this.newskill = skill;
                this.editdeleteEntry(index);
            }
            editdeleteEntry(index: number) {
                this.cached.splice(index, 1);
            }

            deleteEntry(index: number) {
                this.deleteskill(index);                
            }

            deleteskill(index: number) {
                ngDialog.openConfirm({
                    plain: true,
                    template: '<p>Are you sure you wish to delete this entry</p><p class="text-align-right"><button class="btn-small btn-cancel" ng-click="closeThisDialog()">No</button><button class="btn-small" ng-click="confirm()">Yes</button></p>'
                }).then((res) => {                    
                    this.cached.splice(index, 1);                    
                });
            }

            addNew() {
                $('.modal').removeClass('in').css('display', 'none');
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');
                if (this.isNewSkillValid()) {
                    this.cached.push(<any>this.newskill);
                    this.newskill = new srvProfile.skills();
                }
            }

            animateup(index: number) {
                if (index === 0) return;
                var temp = this.cached[index - 1];
                this.cached[index - 1] = this.cached[index];
                this.cached[index] = temp;


            }
            animatedown(index: number) {
                if (index === this.cached.length - 1) return;
                var temp = this.cached[index + 1];
                this.cached[index + 1] = this.cached[index];
                this.cached[index] = temp;
            }

            isNewSkillValid(): boolean {
                if (!this.newskill) return false;

                if (this.cached.filter((e) => {
                    return e.SkillName == this.newskill.SkillName
                }).length > 0) return false;

                if (!this.newskill.SkillId && !this.newskill.SkillName) return false;
                if (this.newskill.SkillLevelId <= 0) return false;
                if (this.newskill.Description == '') return false;
                return true;
            }

            spellcheckactive() {
                $("#txtarea").attr("spellcheck", "true");
            }

            commitSkills() {
                this.addNew();
                this.cached.forEach((skill, idx) => {
                    skill.Order = idx;
                });
                this.saving = true;
                this.editing = false;

                srvProfile.skills.save(this.cached, (res) => {
                    this.skills = res;
                    this.saving = false;
                });
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/skills.html',
            controller: ctrlEditSkills,
            controllerAs: 'cEditSkills',
            scope: {
                subprofileid: '=?'
            },
            bindToController: true
        };
    }

    dirEditSkills.$inject = ['srvProfile', 'srvAuth', 'srvMasterData', 'ngDialog'];
    angular.module('SugarCube').directive('dirEditSkills', dirEditSkills);
}