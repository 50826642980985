module SugarCube {
    'use strict';

    export function dirUploadAvatar(apiendpoints: any, srvAuth: srvAuth, $timeout: ng.ITimeoutService): ng.IDirective {
    
        class ctrlUploadAvatar {
            public avatarurl: string;
            public uploading: boolean;
            public hasfiles: boolean;
            public cropdim: any = {};
            public dzcfgUpload: any;
            public dropzone: any;
            public dzevtUpload: any;

            public static $inject = ['$scope'];

            constructor($scope: ng.IScope) {
                var _ctrl = this;

                this.dzcfgUpload = {
                    maxFileSize: 2,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                    url: apiendpoints.common + 'UploadImageForCropping',
                    autoProcessQueue: false,
                    headers: {
                        "Authorization": "Bearer " + srvAuth.session.accesstoken
                    },
                    thumbnailWidth: null,
                    thumbnailHeight: null
                }

                this.dzevtUpload = {
                    removedfile: function (file) {
                        _ctrl.hasfiles = false;
                    },
                    addedfile: function (file) {
                        var _dz = this;

                        $scope.$apply(() => {
                            _ctrl.hasfiles = true;
                        });

                        $timeout(() => {
                            (<any>$(file.previewElement).find('[data-dz-thumbnail]')).cropper({
                                aspectRatio: 1,
                                crop: function (e) {
                                    $scope.$apply(() => {
                                        _ctrl.cropdim = e;
                                    });
                                }
                            });

                            
                        }, 500);

                        $(file.previewElement).find('.dz-error-mark').click(function () {
                            _dz.removeFile(file);
                        });

                        $(file.previewElement).find('.dz-success-mark').click(function () {
                            _dz.processQueue();
                        });
                    },
                    success: function (file, res) {
                        $scope.$apply(() => {
                            _ctrl.avatarurl = res;
                        });
                        
                        this.removeFile(file);
                    },
                    error: function (file, res, xhr) {
                        $scope.$apply(() => {
                            _ctrl.hasfiles = false;
                        });

                        this.removeFile(file);
                    },
                    complete: function () {
                        $scope.$apply(() => {
                            _ctrl.uploading = false;
                            _ctrl.hasfiles = false;
                        });
                    }
                }
            }
        }

        return {
            restrict: 'EA',
            scope: {
                avatarurl: '='
            },
            controller: ctrlUploadAvatar,
            controllerAs: 'cUploadAvatar',
            bindToController: true,
            templateUrl: 'partials/templates/uploadavatar.html',
            link: {
                pre: (scope: ng.IScope, element: JQuery, attrs: any) => {
                }
            }
        };
    }

    dirUploadAvatar.$inject = ['apiendpoints', 'srvAuth', '$timeout'];
    angular.module('SugarCube').directive('dirUploadAvatar', dirUploadAvatar);
}