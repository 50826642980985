/// <reference path="../models/resume.ts" />
/// <reference path="../utilities/util.ts" />

module SugarCube {
    'use strict';


    export function dirCvCurtain(
        srvAuth: srvAuth,
        srvMasterData: any,
        $timeout: ng.ITimeoutService,
        $sce: ng.ISCEService,
        cfgApp: any,
        ngDialog: ng.dialog.IDialogService,
        srvProfile: srvProfile,
        $stateParams: any): ng.IDirective {

        var cfgCvWidth: number = 330;
        var cfgCvMargin: number = 20;

        class ctrlCvCurtain {
            public slotsperrow: number = 4;
            public cvs: Array<any> = [];
            public culturecode: string;
            public fullcv: Resume;
            public accesstoken: string;
            public apidomain; string;
            public rand: number;
            public cvid: number;

            public basicinfo: IBasicInfo;

            public showgoogleskyscraper: boolean = false;
            public showrrb: boolean = false;

            protected cachedarrcv: Array<Resume> = [];
            protected counter: number = 0;

            constructor() {
                if ($stateParams && $stateParams.accesstoken) {
                    srvAuth.setSession({
                        accesstoken: $stateParams.accesstoken
                    });
                }

                this.cachedarrcv = srvMasterData.toArray('resumes');
                console.table(this.cachedarrcv);
                this.culturecode = srvAuth.session.displayculturecode || 'en-US';
                this.accesstoken = srvAuth.session.accesstoken;
                this.apidomain = cfgApp.apidomain;
                this.rand = Date.now();

                if (srvAuth.isLoggedIn())
                    srvProfile.basicinfo.get().$promise.then((res) => {
                        this.basicinfo = res;
                    });
            };

            purchaseSuccess() {
                ngDialog.open({
                    className: 'ngdialog-theme-default dlg-jobguarantee',
                    template: 'partials/dialogs/jobguaranteepurchased.html'
                });
            }

            isLoggedIn(): boolean {
                return !!(srvAuth.session && srvAuth.session.profileid);
            }

            loadMoreCvs() {
                for (var i = 0; i < this.slotsperrow * 3; i++) {
                    this.cvs.push(this.cachedarrcv[this.counter % this.cachedarrcv.length]);
                    this.counter++;
                }

                console.log('HERE...');
                console.table(this.cvs);
                // only replace slots with Google Ads when
                //      - there are more than 2 slots per row
                //      - we have shown less than 5 sets of Google Ads
                // if (this.slotsperrow > 2 && this.counter < (this.slotsperrow * 3 - 2) * 5) {
                //     if (this.showgoogleskyscraper) this.cvs.splice(this.cvs.length - this.slotsperrow - 1, 1, { adtype: 'long' });
                //     this.cvs.splice(this.cvs.length - 1, 1, { adtype: 'short' });
                //     this.counter -= 1;
                // }

                // place rapid resume builder ad only if we are in the 3rd slot
                // if (this.showrrb && this.counter < this.slotsperrow * 4) {
                //     this.cvs.splice(3, 1, { adtype: 'rrb' });
                // }
            }

            getCvContainerStyle(): any {
                return { width: this.slotsperrow * cfgCvWidth, 'margin-left': 'auto' };

                /*if (this.slotsperrow > 1) {
                    return { width: this.slotsperrow * cfgCvWidth, 'margin-left': 'auto' };
                } else {
                    var increment = 1 / this.cvs.length;

                    return {
                        width: this.cvs.length * cfgCvWidth,
                        //'margin-left': -(this.currcv * cfgCvWidth) + cfgCvMargin + 'px'
                    };
                }*/
            }

            getColourExt(i): string {
                var r = i / this.cachedarrcv.length;

                if (r > 1) return '.c1';
                else if (r > 2) return '.c2';
                else return '';
            }

            view(cv: Resume) {
                this.fullcv = cv;

                Util.scrollToTop();
            }

            viewGallery() {
                this.fullcv = null;
            }

            trustAsResource(url) {
                return $sce.trustAsResourceUrl(url);
            }
        }

        interface ICvCurtainScope extends ng.IScope {
            cCvCurtain: ctrlCvCurtain;
            view: string;
            cvid: number;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/cvcurtain.html',
            scope: {
                view: '=',
                cvid: '=?'
            },
            controller: ctrlCvCurtain,
            controllerAs: 'cCvCurtain',
            link: {
                pre: (scope: ICvCurtainScope, element: JQuery, attrs: any) => {
                    if (!scope.view) {
                        scope.view = 'dummy';
                    }
                    // scope.cCvCurtain.showgoogleskyscraper = attrs['googleSkyscraper'] != null;

                    var relayout = function () {
                        var width = element.find('.cv-outer-container').width();
                        scope.cCvCurtain.slotsperrow = Math.min(Math.floor(width / cfgCvWidth), 4);
                    }

                    scope.$watch('cCvCurtain.fullcv', (newvalue: any) => {
                        if (newvalue) {
                            $timeout(() => {
                                relayout()
                            }, 600);
                        } else {
                            scope.cCvCurtain.slotsperrow = 4;

                            $timeout(() => {
                                relayout()
                            }, 600);
                        }
                    });
                },
                post: (scope: ICvCurtainScope, element: JQuery, attrs: any) => {
                    scope.cCvCurtain.loadMoreCvs();

                    if (scope.cvid) {
                        scope.cCvCurtain.view(srvMasterData.md.resumes[scope.cvid]);
                    } else {
                        scope.cCvCurtain.view(srvMasterData.toArray('resumes')[0]);
                    }
                }
            }
        }
    };

    dirCvCurtain.$inject = ['srvAuth', 'srvMasterData', '$timeout', '$sce', 'cfgApp', 'ngDialog', 'srvProfile', '$stateParams'];
    angular.module('SugarCube').directive('dirCvCurtain', dirCvCurtain);
}