module SugarCube {
    'use strict';

    export class ctrlPurchaseSettings {

        public purchases: ng.resource.IResourceArray<IPurchase>;

        public static $inject = ['srvProfile', 'ngDialog', '$resource'];

        constructor(
            private srvProfile: srvProfile,
            private ngDialog: ng.dialog.IDialogService,
            private $resource: ng.resource.IResourceService
        ) {
            this.purchases = srvProfile.purchases.query();
        }

        refund(purchase: IPurchase) {
            this.ngDialog.open({
                className: 'ngdialog-theme-default dlg-refund',
                template: 'partials/dialogs/refund.html',
                controller: ['$scope', ($scope) => {
                    $scope.refund = () => {
                        this.$resource(Config.CCApiBase + "/PaymentAPI/Refund?productid=19").save(() => {
                        
                            this.purchases = this.srvProfile.purchases.query();

                            this.srvProfile.basicinfo.refresh();

                            $scope.closeThisDialog();
                        });
                    }
                }]
            });
        }

        isRefundAvailable(purchase: IPurchase): boolean {
            var mdp = moment(purchase.datepurchased);
            var fourmonthslater = mdp.clone().add(4, 'months');
            var sixmonthslater = mdp.clone().add(6, 'months');

            return mdp.isAfter(fourmonthslater) && mdp.isBefore(sixmonthslater);
        }
    }

    angular.module('gControllers').controller('ctrlPurchaseSettings', ctrlPurchaseSettings);
}