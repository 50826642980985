module SugarCube {
    'use strict';

    export class EditDirBase {
        public loading: boolean = true;
        public editing: boolean = false;
        public saving: boolean = false;
        public addbtnshow: boolean = false;

        hasEntries(): boolean {
            return false;
        }

        newEntry() {
            this.editing = true;
            this.addbtnshow = true;               
        }

        cancelEdit() {
            this.editing = false;
        }
    }
}