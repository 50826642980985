module SugarCube {
    'use strict';

    export function filterSubCats() {
        return function (items, cid) {
            var newitems = {};

            angular.forEach(items, (value, key) => {
                if (value.cid == cid) newitems[key] = value;
            });

            return newitems;
        }
    }

    filterSubCats.$inject = [];
    angular.module('SugarCube').filter('filterSubCats', filterSubCats);
}