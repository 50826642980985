module SugarCube {
    'use strict';

    export function infoQualifications(
    ): ng.IDirective {
        return {
            restrict: 'EA',
            templateUrl: 'partials/infographics/qualifications.html',
            link: (scope: ng.IScope, element: any, attrs: any) => {

            }
        }
    }

    infoQualifications.$inject = [];
    angular.module('SugarCube').directive('infoQualifications', infoQualifications);
}