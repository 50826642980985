module SugarCube {
    'use strict';

    declare var FB: any;

    export function promoPanels(
        $interval: ng.IIntervalService,
        ngDialog: ng.dialog.IDialogService,
        srvProfile: srvProfile,
        $resource: ng.resource.IResourceService,
        srvAnalytics: any,
        srvAuth: srvAuth
    ): ng.IDirective {

        class ctrlDlgPromoPanels {

            public basicinfo: IBasicInfo;
            public hpopupinterval: any;
            public countdown: number;
            public percentagecomplete: number;

            protected popupinterval: number = 5 * 60;  // five minutes

            tickingtime: number = 0;        // a time ticker counter in seconds

            constructor() {
                var popupfuncs = [
                    this.showResumePreview,
                    this.showJobGuaranteePopup,
                    this.showHirerPopup,
                    this.showFreeTrialPopup,
                    this.showEmpowerNoEndorsementsPopup
                ];

                var order = [0, 3, 1, 2, 3, 0, 1, 2, 3, 0, 1, 2, 3];
                this.countdown = this.popupinterval;
                if (srvAuth.isLoggedIn()) {
                    $resource(Config.CCApiBase + '/ProfileAPI/GetPercentageComplete').get((res) => {
                        this.percentagecomplete = res.percentagecomplete;
                    });                   
                    //srvProfile.basicinfo.get().$promise.then((res) => {
                    //    this.basicinfo = res;                       

                    //    //if (!this.basicinfo.ispremium) {
                    //    //    var counter = 0;

                    //    //    this.hpopupinterval = $interval(() => {
                    //    //        this.countdown--;

                    //    //        if (this.countdown <= 0) {

                    //    //            popupfuncs[order[counter]]();

                    //    //            counter++;
                    //    //            counter = counter % order.length;

                    //    //            this.countdown = this.popupinterval;
                    //    //        }

                    //    //        this.tickingtime++;
                    //    //    }, 1000);
                    //    //}

                    //    //return res;
                    //});
                }
            }

            public isLoggedIn() {
                return srvAuth.isLoggedIn();
            }

            public getTrialEndCountdownText() {
                if (!this.basicinfo.ispremium || !this.basicinfo.trialenddate) return '';

                var enddate = moment.utc(this.basicinfo.trialenddate);
                var today = moment.utc();

                var seconds = enddate.diff(today, 'seconds') - this.tickingtime;

                return Math.floor(seconds / 60 / 60) + ":" + this.paddSeconds((Math.floor(seconds / 60) % 60));
            }

            public shareFB() {
                FB.ui({
                    method: 'share',
                    href: 'https://careercontroller.com/',
                }, (response) => {
                    $resource(Config.CCApiBase + '/ProfileAPI/SharedSuccessfully').save();

                    srvAnalytics.shareevent();

                    srvProfile.basicinfo.refresh();

                    this.basicinfo.ccshareddate = moment.utc().format();
                    this.basicinfo.ispremium = true;
                    this.basicinfo.trialenddate = moment().add(1, 'day').format();
                });
            }

            public showFreeTrialPopup() {
                if (this.isLoggedIn()) {
                    ngDialog.open({
                        className: 'ngdialog-theme-default dlg-freetrial',
                        template: 'partials/dialogs/freetrial.html',
                        controller: ['$scope', ($scope) => {
                            $scope.shareFB = this.shareFB;
                            $scope.shareTW = this.shareFB;
                        }]
                    });
                } else {
                    srvAuth.signin(true);
                }
            }

            public showEmpowerNoEndorsementsPopup() {
                if (this.isLoggedIn()) {
                    ngDialog.open({
                        className: 'ngdialog-theme-default dlg-empowerpopup',
                        template: 'partials/dialogs/empowernoendorsements.html'
                    });
                } else {
                    srvAuth.signin(true);
                }
            }

            public showJobGuaranteePopup() {
                if (this.isLoggedIn()) {
                    ngDialog.open({
                        className: 'ngdialog-theme-default dlg-jobguarantee',
                        template: 'partials/dialogs/jobguarantee.html',
                        controller: ctrlNewJobGuaranteeDlg,
                        controllerAs: 'cNewJobGuaranteeDlg'
                    });
                } else {
                    srvAuth.signin(true);
                }
            }

            public showHirerPopup() {
                if (this.isLoggedIn()) {
                    ngDialog.open({
                        className: 'ngdialog-theme-default dlg-registerwithhirers',
                        template: 'partials/dialogs/registerwithhirers.html',
                        controller: ['$scope', ($scope) => {
                            $scope.register = () => {
                                $resource(Config.CCApiBase + '/AccountAPI/RegisterWithAllHirers').save(true, (res) => {
                                    if (this.basicinfo) this.basicinfo.registeredwithhirers = true;

                                    $scope.closeThisDialog();
                                });
                            }

                            $scope.onbuysuccessful = () => {
                                $resource(Config.CCApiBase + '/AccountAPI/RegisterWithAllHirers').save(true, (res) => {
                                    if (this.basicinfo) this.basicinfo.registeredwithhirers = true;

                                    $scope.closeThisDialog();
                                });
                            }
                        }]
                    });
                } else {
                    srvAuth.signin(true); 
                }
            }

            public isInTrial() {
                if (!this.basicinfo || !this.basicinfo.trialenddate) return false;

                return moment.utc().isBefore(moment.utc(this.basicinfo.trialenddate));
            }

            public showResumePreview() {
                if (this.isLoggedIn()) {
                    ngDialog.open({
                        className: 'ngdialog-theme-default dlg-resumepreview',
                        template: 'partials/dialogs/resumepriview.html'
                    });
                } else {
                    srvAuth.signin(true);
                }
            }

            public getMinutes() {
                return Math.floor(this.countdown / 60);
            }

            protected paddSeconds(sec: number) {
                var str = "" + sec;
                var pad = "00";
                var ans = pad.substring(0, pad.length - str.length) + str;

                return ans;
            }

            public getSeconds() {
                var str = "" + this.countdown % 60;
                var pad = "00";
                var ans = pad.substring(0, pad.length - str.length) + str;

                return ans;
            }

            public delayPreview() {
                this.countdown = this.popupinterval;
            }
        }

        return {
            restrict: 'EA',
            templateUrl: 'partials/templates/promopanels.html',
            controller: ctrlDlgPromoPanels,
            controllerAs: 'cDlgPromoPanels',
            link: (scope: any, element: JQuery, attrs: any) => {
                // slider options
                scope.curpos = 0;
                
                scope.goto = function (i) {
                    scope.curpos = i;                   
                }
                
                // profile completeness chart
                    scope.chartlabels = [
                    'Profile completeness',
                    'More to go'
                ];

                scope.chartcolours = [
                    '#CCCCCC', '#FFFFFF'
                ];

                scope.chartoptions = {
                    percentageInnerCutout: 85,
                    responsive: true,
                    maintainAspectRatio: true
                }

                $interval(function () {
                    scope.curpos++;
                    if (scope.curpos > 3) scope.curpos = 0;
                }, 7000);
            }
        }
    }

    promoPanels.$inject = ['$interval', 'ngDialog', 'srvProfile', '$resource', 'srvAnalytics', 'srvAuth'];
    angular.module('SugarCube').directive('promoPanels', promoPanels);
}