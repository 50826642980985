module SugarCube {
    'use strict';

    export class ctrlProfileVersions {

        public subprofiles: ng.resource.IResourceArray<ISubProfile>;
        public sourcesubprofileid: number;

        public static $inject = ['srvProfile', 'ngDialog', 'srvMasterData', '$resource', '$scope', '$timeout'];

        constructor(
            private srvProfile: srvProfile,
            private ngDialog: ng.dialog.IDialogService,
            private srvMasterData,
            private $resource: ng.resource.IResourceService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService
        ) {
            this.subprofiles = srvProfile.subprofiles.query((res: Array<ISubProfile>) => {
                if (res.length > 0)
                    this.sourcesubprofileid = res[0].id;

                return res;
            });
        }

        cloneSubProfile() {
            if (!this.sourcesubprofileid) return;

            this.srvProfile.cloneSubProfile(this.sourcesubprofileid).$promise.then((res: ISubProfile) => {
                this.subprofiles.push(res);
            }, (err) => {
                if (err.status == 402) {
                    this.ngDialog.open({
                        template: 'partials/dialogs/purchasesubprofile.html',
                        className: 'ngdialog-theme-default dlg-purchasesubprofile',
                        controller: ['$scope', ($scope) => {
                            $scope.paymentsuccess = () => {
                                $scope.closeThisDialog();

                                this.cloneSubProfile();
                            }
                        }]
                    });
                }
            });
        }

        isAtSubprofileLimit() {
            return this.subprofiles.filter((v) => {
                return !v.isarchived;
            }).length >= 6;
        }

        translateSubProfile(subprofile: ISubProfile) {
            this.ngDialog.open({
                template: 'partials/dialogs/translatesubprofile.html',
                className: 'ngdialog-theme-default dlg-translatesubprofile',
                resolve: {
                    languages: () => {
                        return this.srvMasterData.md['masterlanguages'];
                    }
                },
                controller: ['$scope', 'languages', ($scope, languages) => {
                    $scope.languages = [];
                    angular.forEach(languages, (value, key) => {
                        $scope.languages.push(value);
                    });


                    $scope.translate = () => {
                        if (!$scope.tolanguageid) return;

                        $scope.loading = true;

                        this.$resource(Config.CCApiBase + '/ProfileAPI/TranslateSubProfile', {
                            sourcesubprofileid: subprofile.id,
                            tolanguageid: $scope.tolanguageid
                        }).save((res: ISubProfile) => {
                            $scope.loading = false;

                            this.subprofiles.push(res);

                            $scope.closeThisDialog();
                        }, (err) => {
                            if (err.status == 402) {
                                this.ngDialog.open({
                                    template: 'partials/dialogs/purchasesubprofile.html',
                                    className: 'ngdialog-theme-default dlg-purchasesubprofile',
                                    controller: ['$scope', ($scope) => {
                                        $scope.paymentsuccess = () => {
                                            $scope.closeThisDialog();

                                            this.translateSubProfile(subprofile);
                                        }
                                    }]
                                });
                            }
                        });
                    }
                }]
            });
        }

        deleteSubProfile(subprofile: ISubProfile) {
            var index = this.subprofiles.findIndex((v) => {
                return v.id == subprofile.id;
            });

            this.subprofiles.splice(index, 1);

            this.$timeout(() => {
                this.$scope.$apply();
            }, 2000);

            this.$resource(Config.CCApiBase + '/ProfileAPI/DeleteSubProfile', {
                subprofileid: subprofile.id
            }).save();
        }

        archiveSubProfile(subprofile: ISubProfile) {
            // find the index of the subprofile we wish to archive
            var index = this.subprofiles.findIndex((v) => {
                return v.id == subprofile.id;
            });

            this.srvProfile.archiveSubProfile(subprofile.id).$promise.then((res) => {
                this.subprofiles[index] = res;
            });
        }

        unarchiveSubProfile(subprofile: ISubProfile) {
            // find the index of the subprofile we wish to archive
            var index = this.subprofiles.findIndex((v) => {
                return v.id == subprofile.id;
            });

            this.srvProfile.unarchiveSubProfile(subprofile.id).$promise.then((res) => {
                this.subprofiles[index] = res;
            });
        }

        editSubProfile(subprofile: ISubProfile) {
            this.ngDialog.open({
                template: 'partials/dialogs/editsubprofiledetails.html',
                controller: ctrlEditSubprofileDetails,
                controllerAs: 'cEditSubprofileDetails',
                className: 'ngdialog-theme-default dlg-editsubprofiledetails',
                resolve: {
                    subprofile: () => {
                        return subprofile;
                    }
                }
            }).closePromise.then((res: ng.dialog.IDialogClosePromise) => {
                subprofile.name = res.value.name;
                subprofile.CareerSummary = res.value.CareerSummary;
                subprofile.CareerSummaryHeading = res.value.CareerSummaryHeading;
            });
        }
    }

    angular.module('gControllers').controller('ctrlProfileVersions', ctrlProfileVersions);
}