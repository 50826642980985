module SugarCube {
    'use strict';

    declare var google: any;
    declare var $: any;
    declare var machina: any;
    declare var CKEDITOR: any;
    declare var CanvasJS: any;

    export function jobsManagement(
        srvAuth: srvAuth,
        $resource: ng.resource.IResourceService
    ): ng.IDirective {

        interface IJobsManagementScope extends ng.IScope {
            careerSummarySuggestions: Array<any>;
            summaryText: any;
            summaryTextTemplate: any;
            addCareerSummaryText: Function;
            coverLetterSuggestions: any;
            coverLetter: string;
            coverLetterTemplate: string;
            addCoverLetterText: Function;
        }

        class ctrlJobsManagement {
            public dzcfgJobDescUpload: any;
            public dzevtJobDescUpload: any;
            public dzcfgOrglogo: any;
            public dzevtOrglogo: any;
            public dzcfgMOrglogo: any;
            public dzevtMOrglogo: any;
            public dzcfgContactpic;
            public dzevtContactpic;

            public App: any;

            public static $inject = ['$scope'];

            constructor($scope: any) {

                var ctrl = this;

                var appTranslations = [];
                ctrl.App = {
                    Helper: {
                        randIntBetweenInterval: function (min, max) {
                            return Math.floor(Math.random() * (max - min + 1) + min);
                        },
                        GetDisplayDate: function (date) {
                            var datedisplay = '';
                            var created = moment.utc(date).local();

                            if (created.format() == 'Invalid date') return 'N/A';

                            var now = moment();

                            if (now.diff(created, 'days') > 6) {
                                datedisplay = created.format('llll');
                            } else {
                                datedisplay = created.fromNow();
                            }

                            return datedisplay;
                        },
                        Confirm: function (msg, callback) {
                            $('#modal-confirm').find('.modal-content p').html(msg);

                            $('#modal-confirm').data('callback', callback).modal();
                        },
                        Alert: function (status, msg) {
                            if (this.alerttimeout) clearTimeout(this.alerttimeout);

                            $('#o-alert p').html(msg);
                            $('#o-alert').removeClass().addClass('alert in alert-' + status);

                            this.alerttimeout = setTimeout(function () {
                                $('#o-alert').removeClass();
                            }, 3000);
                        }
                    },

                    // config variables
                    cCardWidth: 304,
                    cCardHeight: 165,
                    cCardMargin: 15,
                    cPanelPadding: 20,
                    cBatchTimeout: 1000,

                    // member variables
                    fsmApp: null,
                    fsmCard: null,
                    fsmPanel: null,
                    fsmModals: <any>{},
                    CardGrid: [],
                    MasterData: <any>{
                        c: null,
                        sc: null,
                        worktype: null,
                        csc: null
                    },

                    // ckeditors
                    ckCareerSummary: null,
                    ckCoverLetter: null,
                    ckEmail: null,

                    // miscellaneous variables (event tracking, click tracking, and other hacks here)
                    misc: {
                        justclickedresize: false
                    },

                    AjaxCall: function (url, data, success?, error?) {
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + url,
                            type: "POST",
                            data: data,
                            success: success,
                            error: function (jqXHR, textStatus, errorThrown) {
                                if (error) error(jqXHR, textStatus, errorThrown);
                                else {
                                    console.error(textStatus + " " + errorThrown);
                                    ctrl.App.fsmApp.transition('unauthorised');
                                }
                            }
                        });
                    },

                    AjaxCallGet: function (url, success) {
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + url,
                            type: "GET",
                            success: success,
                            error: function (jqXHR, textStatus, errorThrown) {
                                console.error(textStatus + " " + errorThrown);
                                ctrl.App.fsmApp.transition('unauthorised');
                            }
                        });
                    },

                    FillMatchedJobContainer: function (template, jvm) {
                        template.data('jvm', jvm);
                        template.find('.t-role').html(jvm.JobDetail.RoleName);
                        template.find('.t-orgname').html(jvm.JobDetail.OrganisationName);
                        template.find('.t-orglogo').attr('src', jvm.JobDetail.OrganisationLogoURL);
                        template.find('.t-location').html(jvm.JobDetail.JobLocationGeoDataVicinity + ', ' + jvm.JobDetail.JobLocationGeoDataCountry);
                        template.find('.t-worktype').html(jvm.JobDetail.WorkTypeName);
                        template.find('.t-cat').html(jvm.JobDetail.MostSuitableCategoryName);
                        template.find('.t-subcat').html(jvm.JobDetail.MostSuitableSubCategoryName);
                        template.find('.t-salary').html(jvm.JobDetail.SalaryCurrencySymbol + jvm.JobDetail.SalaryMin + '-' + jvm.JobDetail.SalaryMax);
                        template.find('.t-hours').html(jvm.JobDetail.RoleName);
                        template.find('.t-startdate').html(jvm.JobDetail.JobStartDate);
                        template.find('.t-contactname').html(jvm.JobDetail.ContactName);
                        template.find('.t-contactrole').html(jvm.JobDetail.ContactJobTitle);
                        template.find('.t-contactphone').html(jvm.JobDetail.ContactPhone);
                        template.find('.t-contactmobile').html(jvm.JobDetail.ContactMobile);
                        template.find('.t-contactemail').html(jvm.JobDetail.ContactEmail);
                        template.find('.t-contactimg').html(jvm.JobDetail.ContactJobTitle);
                    },

                    FillJobTile: function (template, jvm) {
                        template.data('jvm', jvm);
                        template.find('.t-rolename').html(jvm.JobDetail.RoleName);
                        template.find('.t-orgname').html(jvm.JobDetail.OrganisationName);
                        template.find('.t-orglogo').attr('src', jvm.JobDetail.OrganisationLogoURL);
                        if (!jvm.JobDetail.OrganisationLogoURL) template.find('.t-orglogo').attr('src', 'https://api.careercontroller.com/Content/Images/company-icon-default.png');

                        if (!jvm.JobDetail.JobLocationGeoDataGoogleJSON || jvm.JobDetail.JobLocationGeoDataGoogleJSON == '')
                            template.find('.t-location').html(appTranslations['Location not supplied']);
                        else
                            template.find('.t-location').html(jvm.JobDetail.JobLocationGeoDataVicinity + ', ' + jvm.JobDetail.JobLocationGeoDataCountry);

                        template.find('.t-worktype').html(jvm.JobDetail.WorkTypeName);

                        template.find('.t-salary').html(jvm.JobDetail.SalaryCurrencySymbol + jvm.JobDetail.SalaryMin);
                        if (jvm.JobDetail.SalaryMin == 0) template.find('.t-salary').html('N/A');

                        template.find('.t-hours').html(jvm.JobDetail.RoleName);
                        template.find('.t-startdate').html(jvm.JobDetail.JobStartDate);

                        template.find('.demo-2').attr('data-percent', jvm.JobDetail.TalentMatchingPercentage).empty().percentcircle({
                            animate: true,
                            diameter: 55,
                            guage: 3,
                            coverBg: '#fff',
                            bgColor: '#efefef',
                            fillColor: '#ed674e',
                            percentSize: '50px',
                            percentWeight: 'normal'
                        });

                        template.find('.demo-2-small').attr('data-percent', jvm.JobDetail.TalentMatchingPercentage).empty().percentcircle({
                            animate: true,
                            diameter: 35,
                            guage: 3,
                            coverBg: '#fff',
                            bgColor: '#efefef',
                            fillColor: '#ed674e',
                            percentSize: '50px',
                            percentWeight: 'normal'
                        });

                        if (!jvm.JobDetail.TalentJobStageId) jvm.JobDetail.TalentJobStageId = 1;
                        template.find('.progress-container li').removeClass('active');
                        for (var i = 1; i <= jvm.JobDetail.TalentJobStageId; i++)
                            template.find('.progress-container li:nth-child(' + i + ')').addClass('active');

                        if (jvm.JobDetail.CSCVM != null && jvm.JobDetail.CSCVM.length > 0) {
                            template.find('.t-cat').html(jvm.JobDetail.CSCVM[0].CategoryName);
                            template.find('.t-subcat').html(jvm.JobDetail.CSCVM[0].SubCategoryName);
                        }
                        else {
                            template.find('.t-cat').html('N/A');
                            template.find('.t-subcat').html('N/A');
                        }

                        template.find('.to-do').empty();
                        jvm.JobDetail.TalentToDoList.forEach(function (e, i, a) {
                            var td;

                            switch (e.MasterJobToDoId) {
                                case 1:
                                    td = $('<li><i class="icon-icon_Tick tick"></i><span data-link="keyword">' + e.ToDoDescription + '</span></li>');
                                    break;

                                case 2:
                                    td = $('<li><i class="icon-icon_Tick tick"></i><span data-link="cv">' + e.ToDoDescription + '</span></li>');
                                    break;

                                case 3:
                                    td = $('<li><i class="icon-icon_Tick tick"></i><span data-link="customiseresume">' + e.ToDoDescription + '</span></li>');
                                    break;

                                case 4:
                                    td = $('<li><i class="icon-icon_Tick tick"></i><span data-link="contacts">' + e.ToDoDescription + '</span></li>');
                                    break;

                                case 5:
                                    td = $('<li><i class="icon-icon_Tick tick"></i><span data-link="customisecoverletter">' + e.ToDoDescription + '</span></li>');
                                    break;

                                case 6:
                                    if (jvm.JobDetail.JobContact) {
                                        td = $('<li><i class="icon-icon_Tick tick"></i><span data-link="output">' + appTranslations['Apply'] + '</span></li>');
                                    } else {
                                        td = $('<li><i class="icon-icon_Tick tick"></i><span data-link="output">' + e.ToDoDescription + '</span></li>');
                                    }

                                    break;

                                default:
                                    td = $('<li><i class="icon-icon_Tick tick"></i><span>' + e.ToDoDescription + '</span></li>');
                                    break;
                            }

                            if (e.isCompleted) td.addClass('completed');

                            template.find('.to-do').append(td);
                        });

                        if (jvm.JobDetail.TalentToDoList.length == 0) {
                            var td = $('<li><i class="icon-icon_Tick tick"></i><span>' + ctrl.App.MasterData['Add a to-do task'] + '!</span></li>');

                            template.find('.to-do').append(td);
                        }

                        template.find('.t-startdate').html(ctrl.App.Helper.GetDisplayDate(jvm.JobDetail.JobPostedDate));
                        template.find('.t-created').html(ctrl.App.Helper.GetDisplayDate(jvm.JobDetail.JobApplicationCloseDate));

                        template.find('.act-preview').attr('href', 'https://api.careercontroller.com/PDFHandler2.ashx?AccessToken=' + srvAuth.getAccessToken() + '&jobDetailId=' + jvm.JobDetail.JobDetailId);

                        template.find('.contact-container').hide();
                        if (jvm.JobDetail.DetectedEmailAddress && jvm.JobDetail.DetectedEmailAddress.length > 1) {
                            template.find('.createcontact-container').show();
                        } else if (jvm.JobDetail.JobContact && jvm.JobDetail.JobContact.ContactFirstName && jvm.JobDetail.JobContact.ContactLastName) {
                            template.find('.t-contactname').html(jvm.JobDetail.JobContact.ContactFirstName + ' ' + jvm.JobDetail.JobContact.ContactLastName);
                            template.find('.t-contactrole').html(jvm.JobDetail.JobContact.ContactRoleName);
                            template.find('.t-contactphone span').html(jvm.JobDetail.JobContact.ContactPhone);
                            template.find('.t-contactmobile span').html(jvm.JobDetail.JobContact.ContactMobile);
                            template.find('.t-contactemail').html(jvm.JobDetail.JobContact.ContactEmail);
                            template.find('.t-contactimg').attr('src', jvm.JobDetail.JobContact.ContactAvatarImage);

                            if (!jvm.JobDetail.JobContact.ContactMobile) template.find('.t-contactmobile').hide();
                            else template.find('.t-contactmobile').show();

                            if (!jvm.JobDetail.JobContact.ContactRoleName) template.find('.t-contactrole').hide();
                            else template.find('.t-contactrole').show();

                            if (!jvm.JobDetail.JobContact.ContactPhone) template.find('.t-contactphone').hide();
                            else template.find('.t-contactphone').show();

                            if (!jvm.JobDetail.JobContact.ContactAvatarImage)
                                template.find('.t-contactimg').attr('src', 'https://api.careercontroller.com/Content/Images/ProfileDefault.png');

                            template.find('.detailcontact-container').show();
                        } else {
                            template.find('.nocontact-container').show();
                        }

                        if (jvm.JobDetail.isApplied) template.find('.apply-container button').addClass('disabled').html(appTranslations['Applied']);
                        else template.find('.apply-container button').removeClass('disabled').html(appTranslations['Apply']);

                        if (jvm.JobDetail.JobAttachmentPath) template.find('.act-attachjobdescription').html(appTranslations['Download position description']);
                        else template.find('.act-attachjobdescription').html(appTranslations['Attach position description']);

                        template.find('.rating-container i').removeClass('active');
                        if (jvm.JobDetail.JobRating)
                            template.find('.rating-container i:nth-child(' + (6 - jvm.JobDetail.JobRating) + ')').addClass('active');
                    },

                    FillTodo: function (template, todo) {
                        template.data('id', todo.Id);
                        template.find('.t-desc').html(todo.ToDoDescription);

                        if (todo.isCompleted) template.addClass('completed');
                    },

                    FillNote: function (template, note) {
                        template.data('note', note);

                        template.find('.t-created').html(ctrl.App.Helper.GetDisplayDate(note.created));
                        template.find('.t-note').html(note.notes);
                    },

                    getPlaceDetails: function (placeId, callback) {
                        if (placeId == null) return;
                        var request = {
                            placeId: placeId
                        };
                        var elem = document.createElement('div');
                        if (google) {
                            var service = new google.maps.places.PlacesService(elem);
                            if (service)
                                service.getDetails(request, callback);
                        }
                    },

                    getDistance: function (origin, destination) {
                        var service = new google.maps.DistanceMatrixService;
                        service.getDistanceMatrix({
                            origins: [origin],
                            destinations: [destination],
                            travelMode: google.maps.TravelMode.DRIVING,
                            unitSystem: google.maps.UnitSystem.METRIC,
                            avoidHighways: false,
                            avoidTolls: false
                        }, function (response, status) {
                            if (status !== google.maps.DistanceMatrixStatus.OK) {
                                alert('Error was: ' + status);
                                return 0;
                            } else {
                                if (response.rows[0].elements[0].distance) {
                                    console.error(response.rows[0].elements[0].distance);
                                    return response.rows[0].elements[0].distance;
                                }
                            }
                        });
                    },

                    getPanelHeight: function () {
                        return Math.max($('.panel-content').height(), $('.card.long .front').first().height()) + ctrl.App.cPanelPadding * 2 + ctrl.App.cCardMargin * 2;
                    },

                    CreateCSCSelect: function (selgroup, languageid) {
                        var jqc = selgroup.find('select[name=c]');
                        jqc.empty();
                        jqc.append('<option value="" disabled selected>' + appTranslations['Please select a category'] + '</option>');
                        ctrl.App.MasterData.csc.c.forEach(function (e, i, a) {
                            jqc.append('<option value="' + e.ClassificationID + '">' + e.ClassificationName + '</option>');
                        });

                        var jqsc = selgroup.find('select[name=sc]');
                        jqsc.empty();
                        jqsc.append('<option value="" disabled selected>' + appTranslations['Please select a subcategory'] + '</option>');
                        ctrl.App.MasterData.csc.sc.forEach(function (e, i, a) {
                            jqsc.append($('<option data-lid="' + e.ClassificationID + '" value="' + e.SubClassificationID + '">' + e.Description + '</option>').hide());
                        });
                    },

                    Init: function () {
                        // initialise state machines

                        // modal dialogs
                        var bfsmModal = machina.BehavioralFsm.extend({

                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            namespace: "app-modal",

                            initialState: "uninitialized",

                            clear: function (client) {
                                var jqclient = $(client);

                                jqclient.find('input').val('');
                                jqclient.find('textarea').val('');
                            },

                            reset: function (client) {
                                this.transition(client, "uninitialized");
                            },

                            ok: function (client, data) {
                                if (client.callback) client.callback(data, client.learnmore);

                                $(client).modal('hide');
                            },

                            states: {
                                uninitialized: {
                                    _onEnter: function (client) {
                                        this.clear(client);
                                        $(client).find('.modal-content').removeClass('loading');
                                        $(client).find('.page').hide();
                                    },
                                    "*": function (client, event, callback) {
                                        this.clear(client);
                                        if (callback) client.callback = callback;

                                        var jqclient = $(client);
                                        jqclient.modal({ backdrop: 'static' });

                                        this.deferUntilTransition(client);
                                        this.transition(client, "page1");
                                    }
                                },

                                // all modals must have at least 1 page (and override this page too while you're at it)
                                page1: {
                                    _onEnter: function (client) {
                                        $(client).find('.page:first-child').show();
                                    }
                                }
                            }
                        });

                        ctrl.App.fsmModals.newjob = new bfsmModal({
                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            states: {
                                page1: {
                                    _onEnter: function (client) {
                                        $(client).find('.page:first-child').slideDown();
                                        var jqclient = $(client);
                                        var sel = jqclient.find('select[name=subProfileId]');
                                        var selectedId = sel.val();
                                        if (selectedId == null) {
                                            sel.empty();
                                            ctrl.App.MasterData.subprofiles.forEach(function (e, i, a) {
                                                sel.append('<option value="' + e.SubProfileId + '">' + e.SubProfileName + '</option>');
                                            });
                                            sel.val(sel.find('option').first().attr('value'));
                                        }
                                        else {
                                            sel.empty();
                                            ctrl.App.MasterData.subprofiles.forEach(function (e, i, a) {
                                                if (e.SubProfileId.toString() === selectedId.toString()) {
                                                    sel.append('<option value="' + e.SubProfileId + '"selected>' + e.SubProfileName + '</option>');
                                                } else {
                                                    sel.append('<option value="' + e.SubProfileId + '">' + e.SubProfileName + '</option>');
                                                }
                                            });
                                        }
                                    },
                                    _onExit: function (client) {
                                        $(client).find('.page:first-child').slideUp();
                                    },
                                    ok: function (client) {
                                        var jqclient = $(client);

                                        var ad = jqclient.find('textarea[name=content]').val();
                                        if (!ad || ad == '') {
                                            $('#v-pastejobad').show();
                                            jqclient.find('textarea[name=content]').focus();
                                            return;
                                        }

                                        var _sm = this;
                                        jqclient.find('.modal-content').addClass('loading');

                                        if (ad.length > 4000) ad.length = 3000;

                                        ctrl.App.AjaxCall('/repo/CheckExistingJobs', {
                                            content: ad
                                        }, function (data) {
                                            jqclient.find('.matchedjobs-container').empty();

                                            client.jobid = data.JobId;

                                            if (data.enableNewJobCreation) jqclient.find('.notamatch').show();
                                            else jqclient.find('.notamatch').hide();

                                            if (data.jvm == null || data.jvm.length == 0) {
                                                _sm.handle(client, "didnotfindmatches");
                                            } else {
                                                data.jvm.forEach(function (e, i, a) {
                                                    var template = $('.t.matchedjob').clone().removeClass('t').data('obj', e);

                                                    ctrl.App.FillMatchedJobContainer(template, e);

                                                    jqclient.find('.matchedjobs-container').append(template);
                                                });

                                                _sm.handle(client, "foundmatches");
                                            }
                                        });
                                    },
                                    foundmatches: "page2",
                                    didnotfindmatches: function (client) {
                                        this.deferUntilTransition(client);
                                        this.transition(client, "page2");
                                    }
                                },
                                page2: {
                                    _onEnter: function (client) {
                                        $(client).find('.modal-content').removeClass('loading');
                                        $(client).find('.page:nth-child(2)').slideDown();
                                    },
                                    ok: function (client) {
                                        var _sm = this;
                                        var jqclient = $(client);

                                        var matchedjob = jqclient.find('.matchedjobs-container .matchedjob.active');
                                        if (matchedjob.length > 0) {
                                            var jvm = matchedjob.first().data('jvm');

                                            // saves the job ad
                                            var params = <any>{
                                                roleid: jvm.JobDetail.RoleId,
                                                rolename: jvm.JobDetail.RoleName,
                                                workTypeId: jvm.JobDetail.WorkTypeId,
                                                currencyId: jvm.JobDetail.SalaryCurrencyId,
                                                organisationid: jvm.JobDetail.OrganisationId,
                                                organisationname: jvm.JobDetail.OrganisationName,
                                                subCategoriesCSV: jvm.JobDetail.MostSuitableSubCategoryId + '',
                                                JobId: jvm.JobDetail.JobId,
                                                subProfileId: jqclient.find('select[name=subProfileId]').val()
                                            };

                                            try {
                                                var gobj = JSON.parse(jvm.JobDetail.JobLocationGeoDataGoogleJSON);
                                                params.geodata = gobj;
                                            } catch (e) { /* not valid json, treat as no geodata */ }

                                            ctrl.App.AjaxCall('/repo/SaveJobDetailsAsync', params, function (data) {
                                                _sm.ok(client, data);
                                            });
                                        } else {
                                            $('#v-selectmatchedjob').show();
                                        }
                                    },
                                    next: function (client) {
                                        this.handle(client, "didnotfindmatches");
                                    },
                                    back: "page1",
                                    didnotfindmatches: function (client) {
                                        var _sm = this;
                                        $(client).find('.modal-content').addClass('loading');

                                        var jqclient = $(client);

                                        ctrl.App.AjaxCall('/repo/ParseJobAd', {
                                            jobId: client.jobid,
                                            subProfileId: jqclient.find('select[name=subProfileId]').val()
                                        }, function (data) {
                                            client.jobid = data.JobId;

                                            // add new csc to master data
                                            var langid = 0;
                                            $.each(data.MasterCSCVM, function (index, value) {
                                                if (index == '$id') return true;

                                                langid = index;
                                                ctrl.App.MasterData.csc[index] = value;
                                            });

                                            // update role title
                                            if (data.role) jqclient.find('input[name=role]').data('id', data.role.RoleId).val(data.role.RoleName);
                                            else jqclient.find('input[name=role]').data('id', 0).val('');

                                            // update classifications
                                            jqclient.find('.csc-sel-group-container .sel-group:not(:first-child)').remove();
                                            ctrl.App.CreateCSCSelect(jqclient.find('.csc-sel-group-container .sel-group:last-child'), langid);

                                            var counter = 1;
                                            data.csc.forEach(function (e, i, a) {
                                                jqclient.find('.csc-sel-group-container .sel-group:last-child select[name=c]').val(e.ClassificationID);
                                                jqclient.find('.csc-sel-group-container .sel-group:last-child select[name=sc]').val(e.SubClassificationID);

                                                if (counter < data.csc.length) {
                                                    jqclient.find('.csc-sel-group-container').append(jqclient.find('.csc-sel-group-container .sel-group:first-child').clone());
                                                }
                                                counter++;
                                            });

                                            // update work types
                                            var jqwt = jqclient.find('div[name=worktype]');
                                            jqwt.empty();
                                            ctrl.App.MasterData.worktype.forEach(function (e, i, a) {
                                                jqwt.append('<div data-id="' + e.WorkStatusID + '" class="toggle">' + e.WorkStatus + '</div>');
                                            });

                                            if (data.workType) {
                                                jqwt.find('div[data-id="' + data.workType.WorkTypeId + '"]').addClass('active');
                                            }

                                            // update currency
                                            var jqc = jqclient.find('select[name=currency]');
                                            jqc.empty();
                                            ctrl.App.MasterData.currency.forEach(function (e, i, a) {
                                                jqc.append('<option value="' + e.CurrencyId + '">' + e.Code + '</option>');
                                            });

                                            // update organisation
                                            $('#in-organisation').data('id', data.detectedOrgId).val(data.detectedOrgName);

                                            // update percentage
                                            jqclient.find('.t-percentagematch').attr('data-percent', data.matching.toFixed(0)).empty().percentcircle({
                                                animate: true,
                                                diameter: 55,
                                                guage: 3,
                                                coverBg: '#fff',
                                                bgColor: '#efefef',
                                                fillColor: '#ed674e',
                                                percentSize: '50px',
                                                percentWeight: 'normal'
                                            });

                                            _sm.handle(client, "finishedparsing");
                                        });
                                    },
                                    finishedparsing: "page3",
                                    _onExit: function (client) {
                                        $(client).find('.page:nth-child(2)').slideUp();
                                    }
                                },
                                page3: {
                                    _onEnter: function (client) {
                                        var jqclient = $(client);

                                        $(client).find('.modal-content').removeClass('loading');
                                        jqclient.find('.page:nth-child(3)').slideDown();

                                        $('#in-organisation').data('subprofileid', jqclient.find('select[name=subProfileId]').val());
                                        $('#in-role').data('subprofileid', jqclient.find('select[name=subProfileId]').val());
                                    },
                                    back: "page1",
                                    ok: function (client) {
                                        var jqclient = $(client);
                                        var _sm = this;

                                        var sc = [];
                                        jqclient.find('select[name="sc"]').each(function () {
                                            if ($(this).val() == null || $(this).val() == '') {
                                                sc = null;
                                                return false;
                                            }
                                            sc.push($(this).val());
                                        });

                                        if (sc == null) {
                                            jqclient.find('select[name="sc"]').first().focus();
                                            $('#v-selectsubcat').show();
                                            return;
                                        }

                                        if (sc != null) {
                                            $('#v-selectsubcat').hide();
                                        }

                                        if ($('#in-role').data('id') == null || $('#in-role').val() == '') {
                                            $('#v-selectrole').show();
                                            $('#in-role').focus();
                                            return;
                                        }

                                        if ($('#in-organisation').data('id') == null || $('#in-organisation').val() == '') {
                                            $('#v-selectorg').show();
                                            $('#in-organisation').focus();
                                            return;
                                        }

                                        // parse salary
                                        var salary = jqclient.find('input[name=salary]').val();
                                        if (salary.length > 8) {
                                            alert("Please enter exactly 8 digits.");
                                        }
                                        else {
                                            salary = salary.split('-');

                                            var minsalary = 0, maxsalary = 0;

                                            try {
                                                minsalary = parseInt(salary[0], 10);
                                            } catch (e) {
                                                minsalary = 0;
                                            }

                                            try {
                                                maxsalary = parseInt(salary[1], 10);
                                            } catch (e) {
                                                maxsalary = minsalary;
                                            }

                                            if (isNaN(minsalary)) minsalary = 0;
                                            if (isNaN(maxsalary)) maxsalary = minsalary;

                                            jqclient.find('.modal-content').addClass('loading');

                                            // saves the job ad
                                            var params = <any>{
                                                roleid: $('#in-role').data('id'),
                                                rolename: $('#in-role').val(),
                                                workTypeId: jqclient.find('div[name="worktype"] .toggle.active').first().attr('data-id'),
                                                currencyId: jqclient.find('select[name="currency"]').val(),
                                                organisationid: $('#in-organisation').data('id'),
                                                organisationname: $('#in-organisation').val(),
                                                subCategoriesCSV: sc.join(','),
                                                JobId: client.jobid,
                                                subProfileId: jqclient.find('select[name=subProfileId]').val(),
                                                minSalary: minsalary,
                                                maxSalary: maxsalary,
                                                isJobPublic: jqclient.find('.isJobPublic span:first-child').hasClass('active')
                                            };

                                            var gobj = $('#in-location').data('obj');
                                            if (gobj) {
                                                var placeid = gobj.predictionobj.place_id;
                                                ctrl.App.getPlaceDetails(placeid, function (place, status) {
                                                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                                                        var l = place.geometry.location;
                                                        place.geometry = {
                                                            location: {
                                                                G: l.G || l.H,
                                                                K: l.K || l.L
                                                            }
                                                        };

                                                        // we don't need the photoes here
                                                        place.photos = null;

                                                        // saves the job ad
                                                        params.geodata = place;

                                                        ctrl.App.AjaxCall('/repo/SaveJobDetailsAsync', params, function (data) {
                                                            _sm.ok(client, data);
                                                        });
                                                    } else {
                                                        setTimeout(function () {
                                                            _sm.handle(client, "ok");
                                                        }, 1000);
                                                    }
                                                });
                                            } else {
                                                ctrl.App.AjaxCall('/repo/SaveJobDetailsAsync', params, function (data) {
                                                    _sm.ok(client, data);
                                                });
                                            }
                                        }
                                    },
                                    learnmore: function (client) {
                                        client.learnmore = true;
                                        this.handle(client, 'ok');
                                    },
                                    _onExit: function (client) {
                                        $(client).find('.page:nth-child(3)').slideUp();
                                    }
                                }
                            }
                        });

                        ctrl.App.fsmModals.editcontact = new bfsmModal({
                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            m: null,

                            create: function (client, m, callback) {
                                this.m = m;
                                this.transition(client, "uninitialized");
                                this.handle(client, 'create', callback);
                            },

                            states: {
                                page1: {
                                    _onEnter: function (client) {
                                        var jqclient = $(client);

                                        jqclient.find('.t-name').html(this.m.ContactFirstName + ' ' + this.m.ContactLastName);
                                        jqclient.find('.t-role').html(this.m.ContactRoleName);

                                        if (this.m.ContactOrg) {
                                            jqclient.find('.t-role').html(this.m.ContactOrg.Name);
                                        }

                                        if (this.m.ContactAvatarImage) {
                                            jqclient.find('.t-avatar').attr('src', this.m.ContactAvatarImage);
                                        } else {
                                            jqclient.find('.t-avatar').attr('src', 'https://api.careercontroller.com/Content/Images/ProfileDefault.png');
                                        }

                                        if (!this.m.ContactRoleName || !this.m.ContactOrg) jqclient.find('.t-details').hide();
                                        else jqclient.find('.t-details').show();

                                        jqclient.find('input[name=firstname]').val(this.m.ContactFirstName);
                                        jqclient.find('input[name=lastname]').val(this.m.ContactLastName);
                                        jqclient.find('input[name=email]').val(this.m.ContactEmail);
                                        jqclient.find('input[name=phone]').val(this.m.ContactPhone);
                                        jqclient.find('input[name=mobile]').val(this.m.ContactMobile);
                                    }
                                }
                            }
                        });

                        ctrl.App.fsmModals.editorg = new bfsmModal({
                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            states: {
                                page1: {
                                }
                            }
                        });

                        ctrl.App.fsmModals.previewresume = new bfsmModal({
                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            states: {
                                uninitialized: {
                                    "*": function (client, event, callback) {
                                        this.clear(client);
                                        if (callback) client.callback = callback;

                                        var jqclient = $(client);
                                        jqclient.modal();

                                        this.deferUntilTransition(client);
                                        this.transition(client, "page1");
                                    }
                                },

                                page1: {
                                }
                            }
                        });

                        ctrl.App.fsmModals.editjob = new bfsmModal({
                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            create: function (client, jvm, callback) {
                                this.jvm = jvm;
                                this.transition(client, "uninitialized");
                                this.handle(client, 'create', callback);
                            },

                            states: {
                                page1: {
                                    _onEnter: function (client) {
                                        var jqclient = $(client);

                                        $(client).find('.modal-content').removeClass('loading');
                                        jqclient.find('.page').slideDown();

                                        $('#in-role-edit').data('subprofileid', this.jvm.JobDetail.SubProfileId);
                                        $('#in-organisation-edit').data('subprofileid', this.jvm.JobDetail.SubProfileId);

                                        // update role title
                                        jqclient.find('input[name=role]').data('id', this.jvm.JobDetail.RoleId).val(this.jvm.JobDetail.RoleName);

                                        // update classifications
                                        jqclient.find('.csc-sel-group-container .sel-group:not(:first-child)').remove();
                                        ctrl.App.CreateCSCSelect(jqclient.find('.csc-sel-group-container .sel-group:last-child'), this.jvm.JobDetail.SubProfileLanguageId);

                                        var counter = 1;
                                        this.jvm.JobDetail.CSCVM.forEach(function (e, i, a) {
                                            jqclient.find('.csc-sel-group-container .sel-group:last-child select[name=c]').val(e.CategoryId);
                                            jqclient.find('.csc-sel-group-container .sel-group:last-child select[name=sc]').val(e.SubCategoryId);

                                            if (counter < this.jvm.JobDetail.CSCVM.length) {
                                                jqclient.find('.csc-sel-group-container').append(jqclient.find('.csc-sel-group-container .sel-group:first-child').clone());
                                            }
                                            counter++;
                                        }.bind(this));

                                        // update work types
                                        var jqwt = jqclient.find('div[name=worktype]');
                                        jqwt.empty();
                                        ctrl.App.MasterData.worktype.forEach(function (e, i, a) {
                                            jqwt.append('<div data-id="' + e.WorkStatusID + '" class="toggle">' + e.WorkStatus + '</div>');
                                        });

                                        jqwt.find('div[data-id="' + this.jvm.JobDetail.WorkTypeId + '"]').addClass('active');

                                        // update currency
                                        var jqc = jqclient.find('select[name=currency]');
                                        jqc.empty();
                                        ctrl.App.MasterData.currency.forEach(function (e, i, a) {
                                            jqc.append('<option value="' + e.CurrencyId + '">' + e.Code + '</option>');
                                        });

                                        // update organisation
                                        $('#in-organisation-edit').data('id', this.jvm.JobDetail.OrganisationId).val(this.jvm.JobDetail.OrganisationName);

                                        var jobpostdate = moment.utc(this.jvm.JobDetail.JobPostedDate).local().format('YYYY-MM-DD');
                                        if (jobpostdate == 'Invalid date') $('#in-jobposteddate-edit').val('');
                                        else $('#in-jobposteddate-edit').val(jobpostdate);

                                        var jobclosedate = moment.utc(this.jvm.JobDetail.JobApplicationCloseDate).local().format('YYYY-MM-DD');
                                        if (jobclosedate == 'Invalid date') $('#in-jobclosedate-edit').val('');
                                        else $('#in-jobclosedate-edit').val(jobclosedate);

                                        // update location
                                        try {
                                            var gobj = JSON.parse(this.jvm.JobDetail.JobLocationGeoDataGoogleJSON);
                                            $('#in-location-edit').data('obj', gobj).val(this.jvm.JobDetail.JobLocationGeoDataFullAddress);
                                        } catch (e) {
                                            $('#in-location-edit').data('obj', null).val('');
                                        }
                                    },
                                    ok: function (client) {
                                        var jqclient = $(client);
                                        var _sm = this;

                                        var sc = [];
                                        jqclient.find('select[name="sc"]').each(function () {
                                            sc.push($(this).val());
                                        });

                                        if ($('#in-role-edit').data('id') == null || $('#in-role-edit').val() == '') {
                                            $('#v-selectrole-edit').show();
                                            $('#in-role-edit').focus();
                                            return;
                                        }

                                        if ($('#in-organisation-edit').data('id') == null || $('#in-organisation-edit').val() == '') {
                                            $('#v-selectorg-edit').show();
                                            $('#in-organisation-edit').focus();
                                            return;
                                        }

                                        // parse salary
                                        var salary = jqclient.find('input[name=salary]').val();
                                        salary = salary.split('-');

                                        var minsalary = 0, maxsalary = 0;

                                        try {
                                            minsalary = parseInt(salary[0], 10);
                                        } catch (e) {
                                            minsalary = 0;
                                        }

                                        try {
                                            maxsalary = parseInt(salary[1], 10);
                                        } catch (e) {
                                            maxsalary = minsalary;
                                        }

                                        if (isNaN(minsalary)) minsalary = 0;
                                        if (isNaN(maxsalary)) maxsalary = minsalary;

                                        jqclient.find('.modal-content').addClass('loading');

                                        // saves the job ad
                                        var params = <any>{
                                            jobDetailId: this.jvm.JobDetail.JobDetailId,
                                            roleid: $('#in-role-edit').data('id'),
                                            rolename: $('#in-role-edit').val(),
                                            workTypeId: jqclient.find('div[name="worktype"] .toggle.active').first().attr('data-id'),
                                            currencyId: jqclient.find('select[name="currency"]').val(),
                                            organisationid: $('#in-organisation-edit').data('id'),
                                            organisationname: $('#in-organisation-edit').val(),
                                            subCategoriesCSV: sc.join(','),
                                            JobId: client.jobid,
                                            subProfileId: jqclient.find('select[name=subProfileId]').val()
                                        };

                                        var jobposteddate = moment($('#in-jobposteddate-edit').val()).utc().format();
                                        if (jobposteddate != 'Invalid date') params.jobPostedDate = jobposteddate;

                                        var jobclosedate = moment($('#in-jobclosedate-edit').val()).utc().format();
                                        if (jobclosedate != 'Invalid date') params.JobApplicationCloseDate = jobclosedate;

                                        var gobj = $('#in-location-edit').data('obj');
                                        if (gobj) {
                                            var placeid = '';

                                            if (gobj.predictionobj) placeid = gobj.predictionobj.place_id;
                                            else placeid = gobj.place_id;

                                            ctrl.App.getPlaceDetails(placeid, function (place, status) {
                                                if (status == google.maps.places.PlacesServiceStatus.OK) {
                                                    var l = place.geometry.location;
                                                    place.geometry.location = {
                                                        G: l.G,
                                                        K: l.K
                                                    };

                                                    // saves the job ad
                                                    params.geodata = place;

                                                    ctrl.App.AjaxCall('/repo/EditTalentJobDetails', params, function (data) {
                                                        _sm.ok(client, data);
                                                    });
                                                } else {
                                                    setTimeout(function () {
                                                        _sm.handle(client, "ok");
                                                    }, 1000);
                                                }
                                            });
                                        } else {
                                            ctrl.App.AjaxCall('/repo/EditTalentJobDetails', params, function (data) {
                                                _sm.ok(client, data);
                                            });
                                        }
                                    },
                                    _onExit: function (client) {
                                        $(client).find('.page').slideUp();
                                    }
                                }
                            }
                        });

                        // app
                        ctrl.App.fsmApp = new machina.Fsm({

                            initialize: function (options) {
                                // your setup code goes here...
                                this.panelheight = 0;
                            },

                            namespace: "app",

                            initialState: "uninitialized",

                            states: {
                                uninitialized: {
                                    "create": function () {
                                        var startapp = function () {
                                            $('#o-loading').remove();

                                            this.deferUntilTransition();
                                            this.transition("relayouting");

                                            if ($('#post-jobad').val() != null && $('#post-jobad').val() != '') {
                                                ctrl.App.fsmApp.handle('newcard');
                                            }
                                        }.bind(this);

                                        // get translations
                                        $.ajax({
                                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                                            dataType: "json",
                                            url: Config.CCApiBase + "/repo/GetTranslations",
                                            type: "GET",
                                            success: function (data) {
                                                appTranslations = data;
                                            }
                                        });

                                        // get init data bundle
                                        $.ajax({
                                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                                            dataType: "json",
                                            url: Config.CCApiBase + "/repo/GetInitDataBundle",
                                            type: "GET",
                                            success: function (data) {
                                                ctrl.App.MasterData = data.md;

                                                $('#filterjobstage ul').empty();
                                                $('.progress-container').empty();

                                                $('#filterjobstage ul').append('<li data-id="0"><a href="javascript:void(0);">' + appTranslations['All'] + '</a></li>');

                                                ctrl.App.MasterData.masterJobStagesForTalent.forEach(function (e, i, a) {
                                                    $('#filterjobstage ul').append('<li data-id="' + e.StageId + '"><a href="javascript:void(0);">' + e.StageName + '</a></li>');
                                                    $('.progress-container').append('<li data-id="' + e.StageId + '"><a href="javascript:void(0);">' + e.StageName + '</a></li>');
                                                });

                                                var counter = 0;
                                                data.card.forEach(function (e, i, a) {
                                                    var template = $('.t.card:not(.avert)').clone().removeClass('t');

                                                    ctrl.App.FillJobTile(template, e);

                                                    $('.card-container').append(template);

                                                    ctrl.App.fsmCard.handle(template[0], 'create');

                                                    switch (e.JobDetail.size) {
                                                        case 3: ctrl.App.fsmCard.handle(template[0], "resizeup");

                                                        case 2: ctrl.App.fsmCard.handle(template[0], "resizeup");
                                                    }

                                                    if (counter % 5 == 0) {
                                                        var template = $('.t.card.avert').clone().removeClass('t');

                                                        template.find('.avert-container').html('<script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>\
                                        <!-- Jobs Management Tile -->\
                                        <ins class="adsbygoogle"\
                                        style="display:inline-block;width:300px;height:250px"\
                                        data-ad-client="ca-pub-9363224871438359"\
                                        data-ad-slot="4150458625"></ins>\
                                   <script>\
                                   (adsbygoogle = window.adsbygoogle || []).push({});\
                                        </script>');

                                                        ctrl.App.fsmCard.handle(template[0], 'create');
                                                        ctrl.App.fsmCard.handle(template[0], "resizeup");

                                                        $('.card-container').append(template);
                                                    }

                                                    counter++;
                                                });

                                                startapp();
                                            },
                                            error: function (jqXHR, textStatus, errorThrown) {
                                                console.error(textStatus + " " + errorThrown);
                                                ctrl.App.fsmApp.transition('unauthorised');
                                            }
                                        });
                                    }
                                },
                                idle: {
                                    relayout: "relayouting",
                                    openpanel: function (card, panel) {
                                        $('.card-container .card').each(function () {
                                            ctrl.App.fsmCard.handle(this, "unanchor");
                                        });

                                        ctrl.App.fsmPanel.reset($(card).data('jvm'));

                                        ctrl.App.fsmCard.handle(card, "resizelong");

                                        $('.panel .page').hide();

                                        if (!panel) panel = 'jobad';
                                        ctrl.App.fsmPanel.transition(panel);

                                        $('.card-container .card:not(.long)').css('top', 1000);

                                        // scroll document to top
                                        $("html, body").animate({ scrollTop: 0 }, 1000);

                                        $('.card-container .card .header .resize:not(.larger)').addClass('hidden');

                                        this.transition('panel-opened');
                                    },
                                    readjust: function () {
                                        this.deferUntilTransition();
                                    },
                                    newcard: function () {
                                        var modal = $('#modal-newjob');

                                        ctrl.App.fsmModals.newjob.handle(modal[0], 'create', function (job, learnmore) {
                                            var template = $('.t.card:not(.avert)').clone().removeClass('t');

                                            ctrl.App.FillJobTile(template, job);

                                            $('.card-container').prepend(template);

                                            ctrl.App.fsmCard.handle(template[0], 'create');
                                            ctrl.App.fsmCard.handle(template[0], 'resizeup');
                                            ctrl.App.fsmCard.handle(template[0], 'resizeup');

                                            if (learnmore) ctrl.App.fsmApp.handle('openpanel', template[0], 'keyword');

                                            this.handle("relayout");
                                        }.bind(this));
                                    },
                                    editcard: function (card) { ctrl.App.fsmCard.handle(card, "edit"); },
                                    resizecard: function (card, dir) {
                                        if (dir) ctrl.App.fsmCard.handle(card, "resizeup");
                                        else ctrl.App.fsmCard.handle(card, "resizedown");
                                    },
                                    startmovecard: function (card) { ctrl.App.fsmCard.handle(card, "startmove"); },
                                    movingcard: function (card) { ctrl.App.fsmCard.handle(card, "moving"); },
                                    stopmovecard: function (card) { ctrl.App.fsmCard.handle(card, "stopmove"); }
                                },
                                relayouting: {
                                    _onEnter: function () {
                                        // relayout the cards
                                        var width = $('.card-outer-container').width();
                                        var cardouterwidth = ctrl.App.cCardWidth + ctrl.App.cCardMargin * 2;
                                        var cardouterheight = ctrl.App.cCardHeight + ctrl.App.cCardMargin * 2;
                                        var hadjust = this.panelheight;

                                        ctrl.App.CardGrid = [];
                                        var rowcount = 0;
                                        var slotcount = 0;
                                        var slotsperrow = Math.floor(width / cardouterwidth);
                                        var collapse = function (x, y) { return x + y * slotsperrow; }
                                        var expand = function (i) { return { x: i % slotsperrow, y: Math.floor(i / slotsperrow) } };

                                        $('.auto-width-container').css('width', slotsperrow * cardouterwidth);
                                        $('.panel').css('width', Math.max(slotsperrow - 1, 2) * cardouterwidth - ctrl.App.cCardMargin * 2);

                                        ctrl.App.CardGrid = [];

                                        var cardstates = [];
                                        var counter = 0;

                                        $('.card-container .card').each(function () {
                                            var card = $(this);

                                            if (card.hasClass('moving')) return true;
                                            if (card.hasClass('long')) return true;
                                            if (card.hasClass('hidden')) return true;

                                            if (card.data('jvm') && !card.hasClass('avert')) {
                                                var cardsize = 1;
                                                if (card.hasClass('large')) cardsize = 3;
                                                else if (card.hasClass('normal')) cardsize = 2;

                                                cardstates.push({
                                                    jobdetailid: card.data('jvm').JobDetail.JobDetailId,
                                                    size: cardsize,
                                                    order: counter
                                                });
                                                counter++;
                                            }

                                            if (card.hasClass('hidden')) return true;

                                            var scalew = $(this).hasClass('large') ? 2 : 1;
                                            var scaleh = $(this).hasClass('large') ? 4 : $(this).hasClass('normal') ? 2 : 1;

                                            var placed = false;
                                            for (var i = 0; i < ctrl.App.CardGrid.length; i++) {
                                                var coord = expand(i);
                                                var filled = false;

                                                if (coord.x + scalew > slotsperrow) filled = true;
                                                for (var j = 0; j < scalew; j++) {
                                                    for (var k = 0; k < scaleh; k++) {
                                                        if (ctrl.App.CardGrid[collapse(coord.x + j, coord.y + k)] != null) {
                                                            filled = true;
                                                            break;
                                                        }
                                                    }
                                                }

                                                if (!filled) {
                                                    var top = coord.y * cardouterheight;
                                                    var left = coord.x * cardouterwidth;

                                                    card.css('top', top + hadjust);
                                                    card.css('left', left);

                                                    for (var j = 0; j < scalew; j++) {
                                                        for (var k = 0; k < scaleh; k++) {
                                                            ctrl.App.CardGrid[collapse(coord.x + j, coord.y + k)] = card;
                                                        }
                                                    }

                                                    placed = true;
                                                    break;
                                                }
                                            }

                                            var coord = expand(ctrl.App.CardGrid.length);

                                            if (!placed) {
                                                if (coord.x + scalew > slotsperrow) {
                                                    coord.x = 0;
                                                    coord.y++;
                                                }

                                                var top = coord.y * cardouterheight;
                                                var left = coord.x * cardouterwidth;

                                                card.css('top', top + hadjust);
                                                card.css('left', left);

                                                for (var j = 0; j < scalew; j++) {
                                                    for (var k = 0; k < scaleh; k++) {
                                                        ctrl.App.CardGrid[collapse(coord.x + j, coord.y + k)] = card;
                                                    }
                                                }
                                            }
                                        });

                                        if (cardstates.length > 0) {
                                            // save current state for user (rolling timeout, 1s)
                                            if (this.savestatetimer) clearTimeout(this.savestatetimer);
                                            this.savestatetimer = setTimeout(function () {
                                                ctrl.App.AjaxCall('/repo/SaveTileStates', { states: cardstates }, function () { /* do nothing */ });
                                            }, 1000);
                                        }

                                        // automatically transition back to idle after 1 second
                                        this.timer = setTimeout(function () {
                                            this.handle("finishrelayout");
                                        }.bind(this), 1000);
                                    },
                                    finishrelayout: "idle",
                                    relayout: function () {
                                        clearTimeout(this.timer);

                                        this.handle("_onEnter");
                                    },
                                    readjust: function () {
                                        this.transition("panel-opened");
                                    },
                                    _onExit: function () {
                                        clearTimeout(this.timer);
                                    },
                                    "*": function () {
                                        this.deferUntilTransition();
                                        this.transition("idle");
                                    }
                                },
                                "panel-opened": {
                                    _onEnter: function () {
                                        $('.panel').slideDown(500).delay(1000).queue(function (n) { $(this).css('overflow', 'visible'); n(); });
                                    },
                                    newcard: function () {
                                        this.deferUntilTransition();
                                        this.handle('closepanel');
                                    },
                                    readjust: function () {
                                        this.panelheight = ctrl.App.getPanelHeight();
                                        $('.panel').css('max-height', this.panelheight);

                                        this.deferUntilTransition();
                                        this.transition("relayouting");
                                    },
                                    openpanel: function (card, panel) {
                                        this.deferUntilTransition();
                                        this.transition('idle');
                                    },
                                    resizecard: function (card, dir) {
                                        this.handle('closepanel');
                                    },
                                    closepanel: function (jvm) {
                                        this.panelheight = 0;

                                        $('.panel').clearQueue().css('overflow', 'hidden').css('max-height', 0).slideUp(500);

                                        $('.card-container .card').each(function () {
                                            ctrl.App.fsmCard.handle(this, "unanchor");
                                        });

                                        $('.card-container .card .header .resize').removeClass('hidden');

                                        // update the card
                                        var card = $('.card-container .card').filter(function () {
                                            return $(this).data('jvm') && $(this).data('jvm').JobDetail.JobDetailId == ctrl.App.fsmPanel.jvm.JobDetail.JobDetailId;
                                        });
                                        if (card.length > 0) ctrl.App.fsmCard.update(card[0], ctrl.App.fsmPanel.jvm);

                                        this.deferUntilTransition();
                                        this.transition('relayouting');
                                    },
                                    editcard: function (card) { ctrl.App.fsmCard.handle(card, "edit"); },
                                    startmovecard: function (card) {
                                        if (!$(card).hasClass('long')) {
                                            ctrl.App.fsmPanel.reset($(card).data('jvm'));
                                            ctrl.App.fsmApp.handle("openpanel", card);
                                        }
                                    }
                                },

                                // this state will force the app to reload, this state does not have an exit mode
                                unauthorised: {
                                    _onEnter: function () {
                                        $('#o-unauth').show();
                                        $('body').addClass('nooverflow');
                                    },
                                    _onExit: function () {
                                        this.transition('unauthorised');
                                    }
                                }
                            },

                            reset: function () {
                                this.handle("_reset");
                            },

                            create: function () {
                                this.handle("create");
                            }
                        });

                        // card
                        ctrl.App.fsmCardFunctions = {
                            states: {
                                idle: {
                                    _onEnter: function (client) { },
                                    _reset: function (client) { },
                                    resizeup: function (client) {
                                        var jqclient = $(client);

                                        if (jqclient.hasClass('large')) {
                                            // do nothing
                                        } else if (jqclient.hasClass('normal')) {
                                            jqclient.removeClass('normal').addClass('large');
                                        } else {
                                            jqclient.addClass('normal');
                                        }

                                        ctrl.App.fsmApp.handle("relayout");
                                    },
                                    resizedown: function (client) {
                                        var jqclient = $(client);

                                        if (jqclient.hasClass('large')) {
                                            jqclient.removeClass('large').addClass('normal');
                                        } else if (jqclient.hasClass('normal')) {
                                            jqclient.removeClass('normal');
                                        } else {
                                            // do nothing
                                        }

                                        ctrl.App.fsmApp.handle("relayout");
                                    },
                                    startmove: function (client) {
                                        client.moving = true;

                                        var jqclient = $(client);
                                        jqclient.addClass('moving');

                                        var ph = jqclient.clone().empty().addClass('ph').removeClass('moving');
                                        ph.insertBefore(jqclient);
                                    },
                                    moving: function (client) {
                                        if (client.moving) {
                                            var jqclient = $(client);

                                            // drag the card around
                                            var top = client.initialoffset.y + (client.movecoord.y - client.initialcoord.y);
                                            var left = client.initialoffset.x + (client.movecoord.x - client.initialcoord.x);
                                            jqclient.css('top', top);
                                            jqclient.css('left', left);

                                            // create placeholders
                                            var ph = jqclient.clone().empty().addClass('ph').removeClass('moving');

                                            var width = $('.card-outer-container').width();
                                            var cardouterwidth = ctrl.App.cCardWidth + ctrl.App.cCardMargin * 2;
                                            var cardouterheight = ctrl.App.cCardHeight + ctrl.App.cCardMargin * 2;

                                            var slotsperrow = Math.floor(width / cardouterwidth);
                                            var collapse = function (x, y) { return x + y * slotsperrow; }
                                            var expand = function (i) { return { x: i % slotsperrow, y: Math.floor(i / slotsperrow) } };

                                            var index = collapse(Math.floor(left / cardouterwidth), Math.floor(top / cardouterheight));
                                            var existingcard = ctrl.App.CardGrid[index];

                                            if (index == 0) {
                                                $('.card-container .card.ph').remove();

                                                $('.card-container').prepend(ph);

                                                ctrl.App.fsmApp.handle("relayout");
                                            } else if (existingcard != null && !existingcard.hasClass('ph')) {
                                                $('.card-container .card.ph').remove();

                                                ph.insertAfter(existingcard);

                                                ctrl.App.fsmApp.handle("relayout");
                                            }
                                        }
                                    },
                                    stopmove: function (client) {
                                        client.moving = false;

                                        var jqclient = $(client);
                                        jqclient.removeClass('moving');

                                        var fph = $('.card-container .card.ph').first();
                                        if (fph.length > 0) {
                                            fph.before(jqclient.detach());
                                            $('.card-container .card.ph').remove();
                                        }

                                        ctrl.App.fsmApp.handle("relayout");
                                    },
                                    resizelong: function (client) {
                                        var jqclient = $(client);

                                        jqclient.addClass('long');

                                        this.transition(client, "anchored");
                                    },
                                    edit: function (client) {
                                        var modal = $('#modal-editjob');
                                        var jqclient = $(client);

                                        ctrl.App.fsmModals.editjob.create(modal[0], jqclient.data('jvm'), function (data) {
                                            var jvm = jqclient.data('jvm');
                                            jvm.JobDetail = data;

                                            ctrl.App.fsmCard.update(client, jvm);
                                        }.bind(this));
                                    },
                                }
                            }
                        };

                        ctrl.App.fsmCard = new machina.BehavioralFsm({

                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            namespace: "card",

                            initialState: "uninitialised",

                            update: function (client, jvm) {
                                var jqclient = $(client);
                                ctrl.App.FillJobTile(jqclient, jvm);
                            },

                            states: {
                                uninitialised: {
                                    "*": function (client) {
                                        this.deferUntilTransition(client);
                                        this.transition(client, "main");
                                    }
                                },
                                main: {
                                    _onEnter: function (client) {
                                        client.timer = setInterval(function () {
                                            this.handle(client, "timeout-main");
                                        }.bind(this), ctrl.App.Helper.randIntBetweenInterval(15000, 30000));
                                    },
                                    'timeout-main': function (client) {
                                        if (!$(client).hasClass('large')) {
                                            var cards = $('.card-container .card');
                                            var flipped = $('.card-container .card.flipped');
                                            if (flipped.length * 2 <= cards.length) {
                                                this.handle(client, "flip");
                                            }
                                        }
                                    },
                                    flip: "flipping",

                                    // common inputs
                                    resizeup: ctrl.App.fsmCardFunctions.states.idle.resizeup,
                                    resizedown: ctrl.App.fsmCardFunctions.states.idle.resizedown,
                                    startmove: ctrl.App.fsmCardFunctions.states.idle.startmove,
                                    moving: ctrl.App.fsmCardFunctions.states.idle.moving,
                                    stopmove: ctrl.App.fsmCardFunctions.states.idle.stopmove,
                                    resizelong: ctrl.App.fsmCardFunctions.states.idle.resizelong,
                                    edit: ctrl.App.fsmCardFunctions.states.idle.edit,
                                    updateprogress: function (client, id) {
                                        if (this.updateprogresstimer) clearTimeout(this.updateprogresstimer);
                                        var jvm = $(client).data('jvm');

                                        jvm.JobDetail.TalentJobStageId = id;

                                        this.update(client, jvm);

                                        this.updateprogresstimer = setTimeout(function () {
                                            ctrl.App.AjaxCall('/repo/EditTalentJobStage', {
                                                jobDetailId: jvm.JobDetail.JobDetailId,
                                                stageId: id
                                            }, function () {
                                                ctrl.App.Helper.Alert('success', appTranslations['Updated job stage'] + '!');
                                            });
                                        }.bind(this), 1000);
                                    },
                                    ratejob: function (client, index) {
                                        if (this.ratejobtimer) clearTimeout(this.ratejobtimer);
                                        var jvm = $(client).data('jvm');

                                        index = 5 - index;
                                        jvm.JobDetail.JobRating = index;

                                        this.update(client, jvm);

                                        this.ratejobtimer = setTimeout(function () {
                                            ctrl.App.AjaxCall('/repo/RateJob', {
                                                jobDetailId: jvm.JobDetail.JobDetailId,
                                                rating: index
                                            }, function () {
                                                ctrl.App.Helper.Alert('success', appTranslations['Updated job rating'] + '!');
                                            });
                                        }.bind(this), 1000);
                                    },
                                    editcontacts: function (client) {
                                        var modal = $('#modal-editcontact');

                                        ctrl.App.fsmModals.editcontact.create(modal[0], $(client).data('jvm').JobDetail.JobContact, function () {
                                        }.bind(this));
                                    },

                                    _onExit: function (client) {
                                        clearInterval(client.timer);
                                    }
                                },
                                flipped: {
                                    _onEnter: function (client) {
                                        client.timer = setTimeout(function () {
                                            this.handle(client, "timeout-flipped");
                                        }.bind(this), ctrl.App.Helper.randIntBetweenInterval(15000, 30000));
                                    },
                                    'timeout-flipped': "flipback",
                                    resizelong: function (client) {
                                        this.deferUntilTransition(client);
                                        this.transition(client, 'flipback');
                                    },
                                    flip: "flipback",

                                    // common inputs
                                    resizeup: ctrl.App.fsmCardFunctions.states.idle.resizeup,
                                    resizedown: ctrl.App.fsmCardFunctions.states.idle.resizedown,
                                    startmove: ctrl.App.fsmCardFunctions.states.idle.startmove,
                                    moving: ctrl.App.fsmCardFunctions.states.idle.moving,
                                    stopmove: ctrl.App.fsmCardFunctions.states.idle.stopmove,
                                    edit: ctrl.App.fsmCardFunctions.states.idle.edit,

                                    _onExit: function (client) {
                                        clearTimeout(client.timer);
                                    }
                                },
                                flipping: {
                                    _onEnter: function (client) {
                                        var sm = this;
                                        var jqclient = $(client);

                                        jqclient.addClass('flipping-pullback')
                                            .delay(200).queue(function (next) {
                                                $(this).removeClass("flipping-pullback").addClass('flipping-overshoot');
                                                next();
                                            }).delay(300).queue(function (next) {
                                                $(this).removeClass("flipping-overshoot").addClass('flipped');
                                                next();
                                            }).delay(200).queue(function (next) {
                                                sm.handle(client, "timeout-flipping");
                                                next();
                                            });
                                    },
                                    'timeout-flipping': "flipped",
                                    'resizelong': function (client) {
                                        this.deferUntilTransition(client);
                                        this.transition(client, "flipped");
                                    }
                                },
                                flipback: {
                                    _onEnter: function (client) {
                                        var sm = this;
                                        var jqclient = $(client);

                                        jqclient.removeClass('flipped').addClass('flipping-overshoot')
                                            .delay(200).queue(function (next) {
                                                $(this).removeClass("flipping-overshoot").addClass('flipping-pullback');
                                                next();
                                            }).delay(300).queue(function (next) {
                                                $(this).removeClass("flipping-pullback");
                                                next();
                                            }).delay(200).queue(function (next) {
                                                sm.handle(client, "timeout-flipback");
                                                next();
                                            });
                                    },
                                    'timeout-flipback': "main",
                                    'resizelong': function (client) {
                                        this.deferUntilTransition(client);
                                        this.transition(client, "main");
                                    }
                                },
                                anchored: {
                                    unanchor: function (client) {
                                        this.transition(client, 'main');
                                    },
                                    resizelong: function (client) {
                                        this.deferUntilTransition();
                                        this.handle(client, 'unanchor');
                                    },
                                    edit: ctrl.App.fsmCardFunctions.states.idle.edit,
                                    _onExit: function (client) {
                                        $(client).removeClass('long');
                                    }
                                }
                            },

                            reset: function (client) {
                                this.handle(client, "_reset");
                            },

                            create: function (client) {
                                this.handle(client, "create");
                            }
                        });

                        // panel
                        ctrl.App.fsmPanel = new machina.Fsm({

                            initialize: function (options) {
                                // your setup code goes here...
                            },

                            namespace: "card-page",

                            initialState: "uninitialized",

                            jvm: null,

                            completeMasterTodo: function (id) {
                                var todo = null;
                                this.jvm.JobDetail.TalentToDoList.forEach(function (e, i, a) {
                                    if (e.MasterJobToDoId == id) {
                                        todo = e;
                                        return false;
                                    }
                                });

                                if (todo) {
                                    ctrl.App.AjaxCall('/repo/MarkToDoAsComplete', {
                                        jobDetailId: this.jvm.JobDetail.JobDetailId,
                                        toDoId: todo.Id
                                    }, function () { /* do nothing */ });

                                    todo.isCompleted = true;

                                    // update the card
                                    var card = $('.card-container .card').filter(function () {
                                        return $(this).data('jvm') && $(this).data('jvm').JobDetail.JobDetailId == ctrl.App.fsmPanel.jvm.JobDetail.JobDetailId;
                                    });
                                    if (card.length > 0) ctrl.App.fsmCard.update(card[0], ctrl.App.fsmPanel.jvm);
                                }
                            },

                            updateBreadcrumbs: function (show) {
                                var pp = $('.panel .panel-progress');

                                if (show) {
                                    $('.panel .heading .btn-primary').show();
                                    pp.show();

                                    pp.find('i').removeClass();

                                    this.jvm.JobDetail.TalentToDoList.forEach(function (e, i, a) {
                                        if (e.isCompleted) {
                                            pp.find('i[data-todoid="' + e.MasterJobToDoId + '"]').addClass('completed');
                                        }
                                    });
                                } else {
                                    $('.panel .heading .btn-primary').hide();
                                    pp.hide();
                                }
                            },

                            states: {
                                uninitialized: {
                                },
                                jobad: {
                                    _onEnter: function () {
                                        $('#page-jobad').show();
                                        $('#page-jobad p.job-content').html(this.jvm.Job.JobAd.replace(/\n/gi, '<br/>'));
                                        $('.panel .heading .h').html(appTranslations['Job Details']);

                                        this.updateBreadcrumbs(false);

                                        $('#page-jobad .t-link').attr('href', this.jvm.JobDetail.JobExternalURL);

                                        $('#in-role-edit').data('subprofileid', this.jvm.JobDetail.SubProfileId);
                                        $('#in-organisation-edit').data('subprofileid', this.jvm.JobDetail.SubProfileId);

                                        // update role title
                                        $('#page-jobad input[name=role]').data('id', this.jvm.JobDetail.RoleId).val(this.jvm.JobDetail.RoleName);

                                        // update classifications
                                        $('#page-jobad .csc-sel-group-container .sel-group:not(:first-child)').remove();
                                        ctrl.App.CreateCSCSelect($('#page-jobad .csc-sel-group-container .sel-group:last-child'), this.jvm.JobDetail.SubProfileLanguageId);

                                        var counter = 1;
                                        this.jvm.JobDetail.CSCVM.forEach(function (e, i, a) {
                                            var c = $('#page-jobad .csc-sel-group-container .sel-group:last-child select[name=c]');
                                            var sc = $('#page-jobad .csc-sel-group-container .sel-group:last-child select[name=sc]');

                                            c.val(e.CategoryId);

                                            setTimeout(function () {
                                                c.trigger('filter-csc');
                                                sc.val(e.SubCategoryId);
                                            }, 700);

                                            if (counter < this.jvm.JobDetail.CSCVM.length) {
                                                $('#page-jobad .csc-sel-group-container').append($('#page-jobad .csc-sel-group-container .sel-group:first-child').clone());
                                            }
                                            counter++;
                                        }.bind(this));

                                        // update work types
                                        var jqwt = $('#page-jobad div[name=worktype]');
                                        jqwt.empty();
                                        ctrl.App.MasterData.worktype.forEach(function (e, i, a) {
                                            jqwt.append('<div data-id="' + e.WorkStatusID + '" class="toggle">' + e.WorkStatus + '</div>');
                                        });

                                        jqwt.find('div[data-id="' + this.jvm.JobDetail.WorkTypeId + '"]').addClass('active');

                                        // update salary
                                        $('#page-jobad input[name=salary]').val(this.jvm.JobDetail.SalaryMin);

                                        // update currency
                                        var jqc = $('#page-jobad select[name=currency]');
                                        jqc.empty();
                                        ctrl.App.MasterData.currency.forEach(function (e, i, a) {
                                            jqc.append('<option value="' + e.CurrencyId + '">' + e.Code + '</option>');
                                        });
                                        jqc.val(this.jvm.JobDetail.SalaryCurrencyId);

                                        // update organisation
                                        $('#in-organisation-edit').data('id', this.jvm.JobDetail.OrganisationId).val(this.jvm.JobDetail.OrganisationName);

                                        var jobpostdate = moment.utc(this.jvm.JobDetail.JobPostedDate).local().format('LL');
                                        if (jobpostdate == 'Invalid date') $('#page-jobad input[name=posteddate]').val('');
                                        else $('#page-jobad input[name=posteddate]').val(jobpostdate);

                                        var jobclosedate = moment.utc(this.jvm.JobDetail.JobApplicationCloseDate).local().format('LL');
                                        if (jobclosedate == 'Invalid date') $('#page-jobad input[name=closedate]').val('');
                                        else $('#page-jobad input[name=closedate]').val(jobclosedate);

                                        // update location
                                        try {
                                            var gobj = JSON.parse(this.jvm.JobDetail.JobLocationGeoDataGoogleJSON);
                                            $('#in-location-edit').data('obj', gobj).val(this.jvm.JobDetail.JobLocationGeoDataFullAddress);
                                        } catch (e) {
                                            $('#in-location-edit').data('obj', null).val('');
                                        }

                                        // misc
                                        $('#page-jobad input[name=referenceno]').val(this.jvm.JobDetail.JobReferenceNumber);
                                        $('#page-jobad input[name=adlink]').val(this.jvm.JobDetail.JobExternalURL);

                                        $('.panel').removeClass('loading');

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    save: function () {
                                        var _sm = this;

                                        var sc = [];
                                        $('#page-jobad select[name="sc"]').each(function () {
                                            sc.push($(this).val());
                                        });

                                        if ($('#in-role-edit').data('id') == null || $('#in-role-edit').val() == '') {
                                            $('#v-selectrole-edit').show();
                                            $('#in-role-edit').focus();
                                            return;
                                        }

                                        if ($('#in-organisation-edit').data('id') == null || $('#in-organisation-edit').val() == '') {
                                            $('#v-selectorg-edit').show();
                                            $('#in-organisation-edit').focus();
                                            return;
                                        }

                                        // parse salary
                                        var salary = $('#page-jobad input[name=salary]').val();

                                        var minsalary = 0, maxsalary = 0;

                                        try {
                                            minsalary = parseInt(salary, 10);
                                        } catch (e) {
                                            minsalary = 0;
                                        }

                                        try {
                                            maxsalary = parseInt(salary, 10);
                                        } catch (e) {
                                            maxsalary = minsalary;
                                        }

                                        if (isNaN(minsalary)) minsalary = 0;
                                        if (isNaN(maxsalary)) maxsalary = minsalary;

                                        // saves the job ad
                                        var params = <any>{
                                            jobDetailId: this.jvm.JobDetail.JobDetailId,
                                            roleid: $('#in-role-edit').data('id'),
                                            rolename: $('#in-role-edit').val(),
                                            workTypeId: $('#page-jobad div[name="worktype"] .toggle.active').first().attr('data-id'),
                                            currencyId: $('#page-jobad select[name="currency"]').val(),
                                            organisationid: $('#in-organisation-edit').data('id'),
                                            organisationname: $('#in-organisation-edit').val(),
                                            subCategoriesCSV: sc.join(','),
                                            JobId: this.jvm.Job.JobId,
                                            subProfileId: $('#page-jobad select[name=subProfileId]').val(),
                                            jobReferenceNumber: $('#page-jobad input[name=referenceno]').val(),
                                            jobExternalURL: $('#page-jobad input[name=adlink]').val(),
                                            minSalary: minsalary,
                                            maxSalary: maxsalary
                                        };

                                        var jpd = moment($('#page-jobad input[name=posteddate]').val()).utc().format('LL');
                                        if (jpd != 'Invalid date') params.jobPostedDate = jpd;

                                        var jacd = moment($('#page-jobad input[name=closedate]').val()).utc().format('LL');
                                        if (jacd != 'Invalid date') params.JobApplicationCloseDate = jacd;

                                        $('.panel').addClass('loading');

                                        var gobj = $('#in-location-edit').data('obj');
                                        if (gobj) {
                                            var placeid = '';

                                            if (gobj.predictionobj) placeid = gobj.predictionobj.place_id;
                                            else placeid = gobj.place_id;

                                            ctrl.App.getPlaceDetails(placeid, function (place, status) {
                                                if (status == google.maps.places.PlacesServiceStatus.OK) {
                                                    var l = place.geometry.location;
                                                    place.geometry = {
                                                        location: {
                                                            G: l.G || l.H || l.lat(),
                                                            K: l.K || l.L || l.lng()
                                                        }
                                                    };

                                                    // saves the job ad
                                                    params.geodata = JSON.parse(JSON.stringify(place));

                                                    ctrl.App.AjaxCall('/repo/EditTalentJobDetails', params, function (data) {
                                                        _sm.jvm.JobDetail = data;
                                                        _sm.handle('_onEnter');
                                                    });
                                                } else {
                                                    setTimeout(function () {
                                                        _sm.handle('save');
                                                    }, 1000);
                                                }
                                            });
                                        } else {
                                            ctrl.App.AjaxCall('/repo/EditTalentJobDetails', params, function (data) {
                                                _sm.jvm.JobDetail = data;
                                                _sm.handle('_onEnter');
                                            });
                                        }
                                    },
                                    learnmore: function () {
                                        this.handle('save');
                                        this.transition('keyword');
                                    },
                                    _onExit: function () {
                                        $('#page-jobad').hide();
                                    }
                                },
                                location: {
                                    _onEnter: function () {
                                        $('.panel').addClass('loading');
                                        $('#page-location').show();
                                        $('.panel .heading .h').html(appTranslations['Location']);

                                        this.updateBreadcrumbs(false);

                                        $('#map-gmap').show();

                                        $('#page-location .t-orgname').html(this.jvm.JobDetail.OrganisationName);

                                        if (this.jvm.JobDetail.JobLocationGeoDataFullAddress)
                                            $('#page-location .t-workaddr').html(this.jvm.JobDetail.JobLocationGeoDataFullAddress.replace(/,/g, '<br/>'));
                                        else $('#page-location .t-workaddr').html(appTranslations['Location not supplied']);

                                        if (this.jvm.JobDetail.TalentGeoDataFullAddress) {
                                            $('#page-location .t-myaddr').html(this.jvm.JobDetail.TalentGeoDataFullAddress.replace(/,/g, '<br/>'));
                                        } else {
                                            $('#page-location .t-myaddr').html(appTranslations['Home address not supplied.']);
                                        }

                                        var _sm = this;

                                        var gjsonstring = this.jvm.JobDetail.JobLocationGeoDataGoogleJSON;

                                        try {
                                            var gjson = JSON.parse(gjsonstring);

                                            var constructMap = function (centrepoint, _unused?: any) {
                                                this.gmap = new google.maps.Map(document.getElementById('map-gmap'), {
                                                    center: { lat: parseFloat(centrepoint.G || centrepoint.H), lng: parseFloat(centrepoint.K || centrepoint.L) },
                                                    scrollwheel: true,
                                                    zoom: 15,
                                                    panControl: false,
                                                    zoomControl: true,
                                                    zoomControlOptions: {
                                                        style: google.maps.ZoomControlStyle.SMALL
                                                    },
                                                    scaleControl: false,
                                                    streetViewControl: false
                                                });

                                                var workmarker = new google.maps.Marker({
                                                    position: { lat: parseFloat(centrepoint.G || centrepoint.H), lng: parseFloat(centrepoint.K || centrepoint.L) },
                                                    map: this.gmap,
                                                    title: _sm.jvm.JobDetail.OrganisationName
                                                });

                                                //ctrl.App.getDistance(centrepoint, mypoint);

                                                $('.panel').removeClass('loading');
                                            };

                                            if (gjson.geometry) {
                                                constructMap(gjson.geometry.location, { lat: -37.827125, lng: 145.051984 });
                                            } else {
                                                ctrl.App.getPlaceDetails(gjson.place_id, function (data) {
                                                    $('#page-location .t-workaddr').html(data.formatted_address.replace(/,/g, '<br/>'));
                                                    constructMap(data.geometry.location, { lat: -37.827125, lng: 145.051984 });
                                                });
                                            }
                                        } catch (e) {
                                            $('#v-gmaperror').show();
                                            $('#map-gmap').hide();
                                            $('.panel').removeClass('loading');
                                        }

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    _onExit: function () {
                                        $('#page-location').hide();
                                    }
                                },
                                keyword: {
                                    _onEnter: function () {
                                        var _sm = this;

                                        $('#page-keyword').show();
                                        $('.panel').addClass('loading');
                                        $('#page-keyword p.job-content').html(this.jvm.Job.JobAd.replace(/\n/gi, '<br/>'));
                                        $('.panel .heading .h').html('1. ' + appTranslations['Keyword Checker']);

                                        this.updateBreadcrumbs(true);
                                        $('.panel .panel-progress i[data-todoid=1]').addClass('active');

                                        $('#page-keyword .bucket-container').empty();
                                        $('#page-keyword .suggested-container').empty();
                                        $('#page-keyword .act-recheck').addClass('disabled');
                                        $('#page-keyword .act-recheckandadd').addClass('disabled');

                                        ctrl.App.AjaxCall('/repo/GetDataForKeywordMatching', {
                                            jobDetailId: this.jvm.JobDetail.JobDetailId
                                        }, function (data) {
                                            $('.panel').removeClass('loading');

                                            data.SuggestedSkillsNotInProfile.forEach(function (e, i, a) {
                                                $('#page-keyword .suggested-container').append($('<p><span data-id="' + e.SkillId + '" class="highlight-big">' + e.SkillName + '</span></p>'));
                                            });

                                            var ad = $('#page-keyword .job-content').html();

                                            var puntuations = ',.:';

                                            data.DetectedSkillsNotInProfile.forEach(function (e, i, a) {
                                                var regEx = new RegExp('(^|\\s|[a-z0-9' + puntuations + '])(' + e.SkillName + ')($|\\s|[a-z0-9' + puntuations + '])', "ig");
                                                ad = ad.replace(regEx, ' <span data-id="' + e.SkillId + '" class="highlight-big">$2</span> ');
                                            });

                                            data.ProfileSkills.forEach(function (e, i, a) {
                                                var regEx = new RegExp('(^|\\s|[a-z0-9' + puntuations + '])(' + e.SkillName + ')($|\\s|[a-z0-9' + puntuations + '])', "ig");
                                                var count = ad.match(regEx);

                                                if (count) ad = ad.replace(regEx, ' <span class="highlight-small" data-count="' + count.length + '">$2</span> ');
                                            });

                                            data.BucketList.forEach(function (e, i, a) {
                                                var regEx = new RegExp('(^|\\s|[a-z0-9' + puntuations + '])(' + e.SkillName + ')($|\\s|[a-z0-9' + puntuations + '])', "ig");
                                                var count = ad.match(regEx);

                                                if (count) ad = ad.replace(regEx, ' <span class="highlight-small" data-count="' + count.length + '">$2</span> ');
                                            });

                                            $('#page-keyword .highlight-menu li.i').remove();
                                                data.ExperienceInSubProfile.forEach(function (e, i, a) {
                                                
                                                $('#page-keyword .highlight-menu li:first-child').after($('<li class="i" data-expid="' + e.ProfileExperienceId + '">' + e.RoleName + '<br /><small>' + e.OrgName + '</small></li>'));
                                            });
                                            if (data.ExperienceInSubProfile.length == 0)
                                                $('#page-keyword .highlight-menu li:first-child').after($('<li class="inactive"><small>You have no experience</small></li>'));

                                            $('#page-keyword .job-content').html(ad);

                                            $('#page-keyword .t-subprofilename').html(_sm.jvm.JobDetail.SubProfileName);
                                            $('#page-keyword .t-percentagematch').html(data.PercentageMatch);

                                            $('#page-keyword .ig').empty().append($('<div></div>').attr('data-percent', data.PercentageMatch));

                                            $('#page-keyword .ig div').percentcircle({
                                                animate: true,
                                                diameter: 55,
                                                guage: 3,
                                                coverBg: '#fff',
                                                bgColor: '#efefef',
                                                fillColor: '#ed674e',
                                                percentSize: '50px',
                                                percentWeight: 'normal'
                                            })

                                            ctrl.App.fsmApp.handle("readjust");
                                        });

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    addkeyword: function (expid, skillid) {
                                        // initialise array if not initialised
                                        if (!this.keywordpayload) this.keywordpayload = [];
                                        this.keywordpayload.push({
                                            ExpId: expid, SkillId: skillid
                                        });

                                        // abort last save if exists
                                        if (this.timerKeyword) clearTimeout(this.timerKeyword);

                                        var _sm = this;
                                        this.timerKeyword = setTimeout(function () {
                                            ctrl.App.AjaxCall('/repo/UpdateKeywordMatching', {
                                                jobDetailId: this.jvm.JobDetail.JobDetailId,
                                                payload: this.keywordpayload
                                            }, function (data) {
                                                _sm.keywordpayload = null;
                                            });
                                        }.bind(this), ctrl.App.cBatchTimeout);
                                    },
                                    recheck: function () {
                                        $('.panel').addClass('loading');

                                        var payload = [];
                                        $('#page-keyword .bucket-container p span').each(function () {
                                            payload.push({
                                                skillId: $(this).attr('data-id'),
                                                skillName: $(this).html()
                                            });
                                        });

                                        var _sm = this;
                                        ctrl.App.AjaxCall('/repo/AddToBucketList', {
                                            jobDetailId: this.jvm.JobDetail.JobDetailId,
                                            payload: payload
                                        }, function (data) {
                                            _sm.handle('_onEnter');
                                        });
                                    },
                                    recheckandadd: function () {
                                        $('.panel').addClass('loading');

                                        var payload = [];
                                        $('#page-keyword .bucket-container p span').each(function () {
                                            payload.push({
                                                skillId: $(this).attr('data-id'),
                                                skillName: $(this).html()
                                            });
                                        });

                                        var _sm = this;
                                        ctrl.App.AjaxCall('/repo/AddToBucketList', {
                                            jobDetailId: this.jvm.JobDetail.JobDetailId,
                                            payload: payload
                                        }, function (data) {
                                            //window.location.href = '/Career/Account/Profile/?jobdetailid=' + _sm.jvm.JobDetail.JobDetailId;
                                        });
                                    },
                                    next: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('cv');
                                    },
                                    _onExit: function () {
                                        $('#page-keyword').hide();

                                        this.completeMasterTodo(1);
                                    }
                                },
                                cv: {
                                    _onEnter: function () {
                                        var _sm = this;

                                        $('#page-cv').show();
                                        $('.panel .heading .h').html('2. ' + appTranslations['Choose Resume']);

                                        this.updateBreadcrumbs(true);
                                        $('.panel .panel-progress i[data-todoid=2]').addClass('active');

                                        $('#page-cv .tab').hide();
                                        $('#page-cv .tab').first().show();
                                        $('#page-cv .filters strong').removeClass('active');
                                        $('#page-cv .filters strong').first().addClass('active');
                                        $('#page-cv .act-loadmore').show();

                                        // fill cv list
                                        $('#page-cv .myresumes .cvs-container').empty();
                                        ctrl.App.MasterData.masterCVList.forEach(function (e, i, a) {
                                            if (e.isPurchased || e.isSelected) {
                                                var t = $('.t.mycv-container-col').clone().removeClass('t');
                                                t.find('.cv-container').data('cv', e);
                                                t.find('img').attr('src', 'https://api.careercontroller.com/EmailTemplates/Common/images/ResumePreviews/Large/' + e.EnglishName + '.' + (_sm.jvm.JobDetail.SubProfileLanguageCultureCode || 'en-US') + '.png');

                                                if (e.CVId == _sm.jvm.JobDetail.SelectedCVId) t.find('.cv-container-outer').addClass('active');

                                                $('#page-cv .myresumes .cvs-container').append(t);
                                            } else {
                                                var t = $('.t.cv').clone().removeClass('t').data('cv', e);

                                                t.find('.t-name').html(e.EnglishName);

                                                t.find('.t-currency').html(ctrl.App.MasterData.currentCurrencySymbol);
                                                t.find('.t-price').html(Math.ceil(e.price).toFixed(2));

                                                t.find('.img-container-large img').attr('src', 'https://api.careercontroller.com/EmailTemplates/Common/images/ResumePreviews/Large/' + e.EnglishName + '.' + (_sm.jvm.JobDetail.SubProfileLanguageCultureCode || 'en-US') + '.png');
                                                t.find('.img-container-small').first().find('img').attr('src', 'https://api.careercontroller.com/EmailTemplates/Common/images/ResumePreviews/Large/' + e.EnglishName + '.c1.' + (_sm.jvm.JobDetail.SubProfileLanguageCultureCode || 'en-US') + '.png');
                                                t.find('.img-container-small').last().find('img').attr('src', 'https://api.careercontroller.com/EmailTemplates/Common/images/ResumePreviews/Large/' + e.EnglishName + '.c2.' + (_sm.jvm.JobDetail.SubProfileLanguageCultureCode || 'en-US') + '.png');
                                                t.find('.btn-primary a').attr('href', $('#current-paymenturl').val() + '/Career/Template/TemplatePayment?templateId=' + e.CVId + '&origin=' + $('#current-domain').val() + '&sessionid=' + $('#current-sessionid').val());

                                                if (e.price == 0) {
                                                    t.find('.t-currency').html('');
                                                    t.find('.t-price').html('Free');
                                                    t.find('.btn-primary a').click(function (evt) {
                                                        evt.preventDefault();

                                                        var t = $('.t.mycv-container-col').clone().removeClass('t');
                                                        t.find('.cv-container').data('cv', e);
                                                        t.find('img').attr('src', 'https://api.careercontroller.com/EmailTemplates/Common/images/ResumePreviews/Large/' + e.EnglishName + '.' + (_sm.jvm.JobDetail.SubProfileLanguageCultureCode || 'en-US') + '.png');
                                                        t.find('.cv-container-outer').addClass('active');

                                                        $('#page-cv .myresumes .cvs-container').append(t);

                                                        $('#page-cv .tab').hide()
                                                        $('#page-cv .tab').first().show();
                                                        $('#page-cv .filters strong').show();
                                                        $('#page-cv .filters strong').removeClass('active');
                                                        $('#page-cv .filters strong').first().addClass('active');
                                                    });
                                                }

                                                $('#page-cv .allresumes .cvs-container').append(t);
                                            }

                                            t.find('img').load(function () {
                                                ctrl.App.fsmApp.handle('readjust');
                                            });
                                        });

                                        if ($('#page-cv .myresumes .cvs-container .mycv-container-col').length <= 0) {
                                            $('#page-cv .tab').show();
                                            $('#page-cv .tab').first().hide();
                                            $('#page-cv .filters strong').addClass('active');
                                            $('#page-cv .filters strong').first().hide();
                                        }

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    updatecv: function (id) {
                                        if (this.updatecvtimer) clearTimeout(this.updatecvtimer);

                                        this.updatecvtimer = setTimeout(function () {
                                            ctrl.App.AjaxCall('/repo/EditSelectedCVForJob', {
                                                jobDetailId: this.jvm.JobDetail.JobDetailId,
                                                cvId: id
                                            }, function () {
                                                this.jvm.JobDetail.SelectedCVId = id;
                                                ctrl.App.Helper.Alert('success', appTranslations['Selected new resume'] + '!');
                                            }.bind(this));
                                        }.bind(this), 1000);
                                    },
                                    back: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('keyword');
                                    },
                                    next: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('customiseresume');
                                    },
                                    _onExit: function () {
                                        $('#page-cv').hide();

                                        this.completeMasterTodo(2);
                                    }
                                },
                                customiseresume: {
                                    _onEnter: function () {
                                        var _sm = this;
                                        $('#page-customiseresume').show();
                                        $('.panel .heading .h').html('3. ' + appTranslations['Customise Career Summary']);

                                        this.updateBreadcrumbs(true);
                                        $('.panel .panel-progress i[data-todoid=3]').addClass('active');

                                        $('#page-customiseresume p.job-content').html(this.jvm.Job.JobAd.replace(/\n/gi, '<br/>'));

                                        $('.panel').addClass('loading');

                                        ctrl.App.ckCareerSummary.setData(this.jvm.JobDetail.JobCareerSummary || '');

                                        // Job Career Summary editor and suggestions
                                        ctrl.App.AjaxCall('/repo/GetJobCareerSummaryTexts', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId
                                        }, function (data) {
                                            $('.panel').removeClass('loading');

                                            // angular update
                                            $scope.$apply(function () {
                                                var initialCareerSummary = '';
                                                // template
                                                data.careerSummarySuggestions.slice(0, 5).forEach(function (e, i, a) {
                                                    initialCareerSummary += a[i].Text;
                                                });
                                                if (!_sm.jvm.JobDetail.JobCareerSummary || _sm.jvm.JobDetail.JobCareerSummary == '') {
                                                    $('#ck-careersummarytemplate').data('t', initialCareerSummary);
                                                }

                                                // merge data by js
                                                initialCareerSummary = '';
                                                $.each(data.careerSummaryValues, function (key, value) {
                                                    data.careerSummarySuggestions.forEach(function (e, i, a) {
                                                        a[i].Text = e.Text.replace(new RegExp("{{" + key + "}}", 'g'), value);
                                                    });
                                                });

                                                data.careerSummarySuggestions.slice(0, 5).forEach(function (e, i, a) {
                                                    initialCareerSummary += a[i].Text;
                                                });
                                                if (!_sm.jvm.JobDetail.JobCareerSummary || _sm.jvm.JobDetail.JobCareerSummary == '') {
                                                    CKEDITOR.instances['ck-careersummary'].setData(initialCareerSummary);
                                                }

                                                var counter = 0;
                                                data.careerSummarySuggestions.forEach(function (e, i, a) {
                                                    if (counter < 5) e.InitialDisplay = true;
                                                    else e.InitialDisplay = false;

                                                    counter++;
                                                });

                                                // display by angular
                                                $scope.careerSummarySuggestions = data.careerSummarySuggestions;
                                                if ($scope.careerSummarySuggestions.length <= 5) {
                                                    $('#page-customiseresume .act-loadmore').hide();
                                                }
                                            });

                                            ctrl.App.fsmApp.handle("readjust");
                                        });

                                        // get recent career summary texts
                                        ctrl.App.AjaxCall('/repo/GetRecentJobCareerSummaries', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId
                                        }, function (data) {
                                            $('#page-customiseresume select[name=recentcareersummaries]').empty();

                                            data.forEach(function (e, i, a) {
                                                if (!e.content) return true;

                                                var displaydate = '';
                                                if (e.date) displaydate = ' - ' + ctrl.App.Helper.GetDisplayDate(e.date);
                                                $('#page-customiseresume select[name=recentcareersummaries]').append('<option value="' + e.content + '">' + e.title + displaydate + '</option>');
                                            });
                                        });

                                        $('.cke_toolbox #cke_26').css('float', 'right');
                                    },
                                    save: function () {
                                        // save summary
                                        // save the data
                                        ctrl.App.AjaxCall('/repo/SaveJobCareerSummary', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId,
                                            careerSummaryText: CKEDITOR.instances['ck-careersummary'].getData(),
                                            careerSummaryTextTemplate: $('#ck-careersummarytemplate').data('t'),
                                            usageCount: $.map($scope.careerSummarySuggestions, function (e, i) {
                                                return {
                                                    id: e.id,
                                                    UsageCount: e.UsageCount
                                                }
                                            })
                                        }, function (data) {
                                            ctrl.App.Helper.Alert('success', 'Career summary saved!');
                                        });
                                    },
                                    back: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('cv');
                                    },
                                    next: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('contacts');
                                    },
                                    _onExit: function () {
                                        $('#page-customiseresume').hide();

                                        this.completeMasterTodo(3);
                                    }
                                },
                                contacts: {
                                    _onEnter: function () {
                                        $('#page-contacts').show();
                                        $('.panel .heading .h').html('4. ' + appTranslations['Job Contact Details']);

                                        this.updateBreadcrumbs(true);
                                        $('.panel .panel-progress i[data-todoid=4]').addClass('active');

                                        $('#page-contacts p.job-content').html(this.jvm.Job.JobAd.replace(/\n/gi, '<br/>'));
                                        $('#page-contacts input[name=role]').data('subprofileid', this.jvm.JobDetail.SubProfileId);
                                        $('#page-contacts input[name=org]').data('subprofileid', this.jvm.JobDetail.SubProfileId);
                                        $('#page-contacts input[name=jobDetailId]').val(this.jvm.JobDetail.JobDetailId);

                                        $('#page-contacts select[name=country]').empty();
                                        ctrl.App.MasterData.masterCountries.forEach(function (e, i, a) {
                                            $('#page-contacts select[name=country]').append('<option value="' + e.CountryId + '" data-isdcode="' + e.ISDCode + '">' + e.CountryName + '</option>');
                                        });

                                        if (!this.jvm.JobDetail.JobOrg.IsTempOrg) {
                                            $('#page-contacts .organisation-box').show();

                                            $('#page-contacts .t-joborglogo').attr('src', this.jvm.JobDetail.JobOrg.LogoURLPath);
                                            if (!this.jvm.JobDetail.JobOrg.LogoURLPath) $('#page-contacts .t-joborglogo').attr('src', 'https://api.careercontroller.com/Content/Images/company-icon-default.png');

                                            $('#page-contacts .t-joborgname').html(this.jvm.JobDetail.JobOrg.Name);
                                            $('#page-contacts .t-joborgurl').html(this.jvm.JobDetail.JobOrg.WebURL);
                                            $('#page-contacts .t-joborgstaff').html(this.jvm.JobDetail.JobOrg.NumberOfEmployeeDisplayValue || 'N/A');
                                            $('#page-contacts .t-joborgturnover').html(this.jvm.JobDetail.JobOrg.AnnualTurnoverDisplayValue || 'N/A');
                                        } else {
                                            $('#page-contacts .organisation-box').hide();
                                        }


                                        this.handle('updatedisplaycontactdetails');
                                        this.handle('showhidetiles');

                                        // cache the state of contacts upon enter in case user exists without saving
                                        this.contactcache = this.jvm.JobDetail.JobContact;

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    editorg: function () {
                                        var modal = $('#modal-editorg');

                                        ctrl.App.fsmModals.editorg.handle(modal[0], 'create', function (data) {

                                        }.bind(this));
                                    },
                                    search: function () {
                                        var email = $('#in-searchcontact').val();
                                        if (email == '') {
                                            $('#in-searchcontact').focus();
                                            return;
                                        }

                                        ctrl.App.AjaxCall('/repo/GetContactInfo', {
                                            EmailAddress: email
                                        }, function (data) {
                                            if (data.ContactEmail) {
                                                this.jvm.JobDetail.JobContact = data;
                                                this.handle('updatedisplaycontactdetails');
                                            } else {
                                                ctrl.App.Helper.Alert('info', appTranslations['Sorry! No results were found, try picking up the phone and finding out the contact details yourself!']);
                                            }
                                        }.bind(this));
                                    },
                                    next: function () {
                                        if (this.jvm.JobDetail.JobContact.ContactEmail && this.jvm.JobDetail.JobContact.ContactEmail != '') {
                                            $('.panel').addClass('loading');

                                            ctrl.App.AjaxCall('/repo/SaveContactToJob', {
                                                jobDetailId: this.jvm.JobDetail.JobDetailId,
                                                contact: this.jvm.JobDetail.JobContact
                                            }, function (data) {
                                                this.jvm.JobDetail.JobContact = data;
                                                this.contactcache = null;

                                                $("html, body").animate({ scrollTop: 0 }, 1000);
                                                this.transition('customisecoverletter');
                                            }.bind(this));
                                        } else {
                                            $("html, body").animate({ scrollTop: 0 }, 1000);
                                            this.transition('customisecoverletter');
                                        }
                                    },
                                    back: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('customiseresume');
                                    },
                                    showhidetiles: function () {
                                        var contact = this.jvm.JobDetail.JobContact;
                                        if (contact && contact.ContactFirstName && contact.ContactLastName && contact.ContactRoleName && contact.ContactAvatarImage) {
                                            $('#page-contacts .contactavatar-container form').hide();
                                            $('#page-contacts .contactavatar-container section').show();
                                        } else {
                                            $('#page-contacts .contactavatar-container form').show();
                                            $('#page-contacts .contactavatar-container section').hide();
                                        }

                                        var contactorg = this.jvm.JobDetail.JobContact.ContactOrg;
                                        if (contactorg && contactorg.Name && contactorg.LogoURLPath) {
                                            $('#page-contacts .contactorg-container form').hide();
                                            $('#page-contacts .contactorg-container section').show();
                                        } else {
                                            $('#page-contacts .contactorg-container form').show();
                                            $('#page-contacts .contactorg-container section').hide();
                                        }

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    uploadedcontactpic: function (url) {
                                        this.jvm.JobDetail.JobContact.ContactAvatarImage = url;

                                        $('#page-contacts .contactavatar-container form').addClass('hidden');
                                        $('#page-contacts .contactavatar-container aside').show();
                                        $('#page-contacts .contactavatar-container aside img').cropper('replace', url);

                                        this.handle('updatedisplaycontactdetails');
                                        this.handle('showhidetiles');
                                    },
                                    uploadedcontactorglogo: function (url) {
                                        this.jvm.JobDetail.JobContact.ContactOrg.LogoURLPath = url;

                                        $('#page-contacts .contactorg-container form').addClass('hidden');
                                        $('#page-contacts .contactorg-container aside').show();
                                        $('#page-contacts .contactorg-container aside img').cropper('replace', url);

                                        this.handle('updatedisplaycontactdetails');
                                        this.handle('showhidetiles');
                                    },
                                    updatedisplaycontactdetails: function () {
                                        $('#page-contacts input[name=email]').val(this.jvm.JobDetail.JobContact.ContactEmail);
                                        $('#page-contacts input[name=firstname]').val(this.jvm.JobDetail.JobContact.ContactFirstName);
                                        $('#page-contacts input[name=lastname]').val(this.jvm.JobDetail.JobContact.ContactLastName);
                                        $('#page-contacts input[name=role]').data('id', this.jvm.JobDetail.JobContact.ContactRoleId).val(this.jvm.JobDetail.JobContact.ContactRoleName);
                                        $('#page-contacts input[name=org]').data('id', this.jvm.JobDetail.JobContact.ContactOrg.OrganisationId).val(this.jvm.JobDetail.JobContact.ContactOrg.Name);
                                        $('#page-contacts input[name=phone]').val(this.jvm.JobDetail.JobContact.ContactPhone);

                                        if (this.jvm.JobDetail.JobContact.ContactAvatarImage) $('#page-contacts .t-avatar').attr('src', this.jvm.JobDetail.JobContact.ContactAvatarImage);
                                        else $('#page-contacts .t-avatar').attr('src', 'https://api.careercontroller.com/Content/Images/ProfileDefault.png');

                                        $('#page-contacts .t-email').html(this.jvm.JobDetail.JobContact.ContactEmail);
                                        $('#page-contacts .t-firstname').html(this.jvm.JobDetail.JobContact.ContactFirstName);
                                        $('#page-contacts .t-lastname').html(this.jvm.JobDetail.JobContact.ContactLastName);
                                        $('#page-contacts .t-role').html(this.jvm.JobDetail.JobContact.ContactRoleName);
                                        $('#page-contacts .t-org').html(this.jvm.JobDetail.JobContact.ContactOrg.Name);

                                        if (this.jvm.JobDetail.JobContact.ContactOrg.LogoURLPath) $('#page-contacts .t-orglogo').attr('src', this.jvm.JobDetail.JobContact.ContactOrg.LogoURLPath);
                                        else $('#page-contacts .t-orglogo').attr('src', 'https://api.careercontroller.com/Content/Images/CompanyDefault.png');
                                    },
                                    updatecontactdetails: function () {
                                        this.jvm.JobDetail.JobContact.ContactEmail = $('#page-contacts input[name=email]').val();
                                        this.jvm.JobDetail.JobContact.ContactFirstName = $('#page-contacts input[name=firstname]').val();
                                        this.jvm.JobDetail.JobContact.ContactLastName = $('#page-contacts input[name=lastname]').val();
                                        this.jvm.JobDetail.JobContact.ContactRoleId = $('#page-contacts input[name=role]').data('id');
                                        this.jvm.JobDetail.JobContact.ContactRoleName = $('#page-contacts input[name=role]').val();
                                        this.jvm.JobDetail.JobContact.ContactPhone = $('#page-contacts input[name=phone]').val();

                                        this.jvm.JobDetail.JobContact.ContactOrg.OrganisationId = $('#page-contacts input[name=org]').data('id');
                                        this.jvm.JobDetail.JobContact.ContactOrg.Name = $('#page-contacts input[name=org]').val();
                                        this.jvm.JobDetail.JobContact.ContactOrg.IsAgency = !$('#page-contacts .toggle-container button:first-child').hasClass('active');
                                        this.jvm.JobDetail.JobContact.IsConsultant = this.jvm.JobDetail.JobContact.ContactOrg.IsAgency;

                                        this.handle('updatedisplaycontactdetails');
                                        this.handle('showhidetiles');
                                    },
                                    unlinkcontact: function () {
                                        this.jvm.JobDetail.JobContact.ContactAvatarImage = null;
                                        this.handle('updatedisplaycontactdetails');
                                        this.handle('showhidetiles');
                                    },
                                    unlinkcontactorg: function () {
                                        this.jvm.JobDetail.JobContact.ContactOrg.LogoURLPath = null;
                                        this.handle('updatedisplaycontactdetails');
                                        this.handle('showhidetiles');
                                    },
                                    _onExit: function () {
                                        $('#page-contacts').hide();

                                        this.completeMasterTodo(4);

                                        // restore contact object to entry state if user has not saved
                                        if (this.contactcache) this.jvm.JobDetail.JobContact = this.contactcache;
                                    }
                                },
                                customisecoverletter: {
                                    _onEnter: function () {
                                        var _sm = this;
                                        $('.panel').addClass('loading');

                                        $('#page-customisecoverletter').show();
                                        $('.panel .heading .h').html('5. ' + appTranslations['Cover Letter Creator']);

                                        this.updateBreadcrumbs(true);
                                        $('.panel .panel-progress i[data-todoid=5]').addClass('active');

                                        ctrl.App.ckCoverLetter.setData(this.jvm.JobDetail.JobCoverLetter || '');

                                        // Job Cover Letter editor and suggestions
                                        ctrl.App.AjaxCall('/repo/GetJobCoverLetterTexts', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId
                                        }, function (data) {
                                            $('.panel').removeClass('loading');

                                            // angular update
                                            $scope.$apply(function () {
                                                var initialCoverLetter = '';
                                                // template
                                                data.coverLetterSuggestions.forEach(function (e, i, a) {
                                                    if (e.InitialDisplay) {
                                                        initialCoverLetter += a[i].Text;
                                                    }
                                                });
                                                if (!_sm.jvm.JobDetail.JobCoverLetter || _sm.jvm.JobDetail.JobCoverLetter == '') {
                                                    $('#ck-coverlettertemplate').data('t', initialCoverLetter);
                                                }

                                                // merge data by js
                                                initialCoverLetter = '';

                                                // merge data by js
                                                $.each(data.coverLetterValues, function (key, value) {
                                                    data.coverLetterSuggestions.forEach(function (e, i, a) {
                                                        a[i].Text = e.Text.replace(new RegExp("{{" + key + "}}", 'g'), value);
                                                    });
                                                });

                                                data.coverLetterSuggestions.forEach(function (e, i, a) {
                                                    if (e.InitialDisplay) {
                                                        initialCoverLetter += a[i].Text;
                                                    }
                                                });
                                                if (!_sm.jvm.JobDetail.JobCoverLetter || _sm.jvm.JobDetail.JobCoverLetter == '') {
                                                    CKEDITOR.instances['ck-coverletter'].setData(initialCoverLetter);
                                                }

                                                // display by angular
                                                $scope.coverLetterSuggestions = data.coverLetterSuggestions;

                                                if ($scope.coverLetterSuggestions.length <= 5) {
                                                    $('#page-customisecoverletter .act-loadmore').hide();
                                                }
                                            });

                                            ctrl.App.fsmApp.handle("readjust");
                                        });

                                        // get recent career summary texts
                                        ctrl.App.AjaxCall('/repo/GetRecentJobCoverLetters', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId
                                        }, function (data) {
                                            $('#page-customisecoverletter select[name=recentcoverletters]').empty();

                                            data.forEach(function (e, i, a) {
                                                if (!e.content) return true;

                                                var displaydate = '';
                                                if (e.date) displaydate = ' - ' + ctrl.App.Helper.GetDisplayDate(e.date);
                                                $('#page-customisecoverletter select[name=recentcoverletters]').append('<option value="' + e.content + '">' + e.title + displaydate + '</option>');
                                            });
                                        });

                                        $('.cke_toolbox #cke_26').css('float', 'right');
                                    },
                                    save: function () {
                                        // save summary
                                        this.jvm.JobDetail.JobCoverLetter = CKEDITOR.instances['ck-coverletter'].getData();

                                        // save the data
                                        ctrl.App.AjaxCall('/repo/SaveJobCoverLetter', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId,
                                            coverLetterText: CKEDITOR.instances['ck-coverletter'].getData(),
                                            coverLetterTextTemplate: $('#ck-coverlettertemplate').data('t'),
                                            usageCount: $.map($scope.coverLetterSuggestions, function (e, i) {
                                                return {
                                                    id: e.id,
                                                    UsageCount: e.UsageCount
                                                }
                                            })
                                        }, function (data) {
                                            ctrl.App.Helper.Alert('success', appTranslations['Cover letter saved!']);
                                        });
                                    },
                                    back: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('contacts');
                                    },
                                    next: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('output');
                                    },
                                    _onExit: function () {
                                        $('#page-customisecoverletter').hide();

                                        this.completeMasterTodo(5);
                                    },
                                },
                                output: {
                                    _onEnter: function () {
                                        var _sm = this;
                                        $('#page-output').show();
                                        $('.panel .heading .h').html('6. ' + appTranslations['Output CV Cover Letter']);
                                        var rolename = this.jvm.JobDetail.RoleName || '';

                                        if (this.jvm.JobDetail.JobContact.ContactEmail) {
                                            $('.panel .heading .h').html('5. ' + appTranslations['Apply']);
                                            $('#page-output input[name=email]').val(this.jvm.JobDetail.JobContact.ContactEmail);

                                            $('#page-output .t-contact').show();
                                            $('#page-output .row-applyto').show();

                                            if (this.jvm.JobDetail.JobContact.ContactAvatarImage) $('#page-output .t-avatar').attr('src', this.jvm.JobDetail.JobContact.ContactAvatarImage);
                                            else $('#page-output .t-avatar').attr('src', 'https://api.careercontroller.com/Content/Images/ProfileDefault.png');

                                            $('#page-output .t-name').html(this.jvm.JobDetail.JobContact.ContactFirstName + ' ' + this.jvm.JobDetail.JobContact.ContactLastName);
                                            $('#page-output .t-role').html(this.jvm.JobDetail.JobContact.ContactRoleName);
                                        } else {
                                            $('#page-output .t-contact').hide();
                                            $('#page-output .row-applyto').hide();
                                        }

                                        this.updateBreadcrumbs(true);
                                        $('.panel .panel-progress i[data-todoid=6]').addClass('active');

                                        $('.panel .heading .btn-primary').hide();

                                        if (this.jvm.JobDetail.isApplied) $('#page-output .alreadyapplied').show();
                                        else $('#page-output .alreadyapplied').hide();

                                        $('.panel').removeClass('loading');

                                        $('#page-output button a').attr('href', 'https://api.careercontroller.com/PDFHandler2.ashx?AccessToken=' + srvAuth.getAccessToken() + '&jobDetailId=' + this.jvm.JobDetail.JobDetailId);

                                        var subject = appTranslations["Career Controller Application for"] + ' ' + rolename;
                                        if (this.jvm.JobDetail.JobReferenceNumber && this.jvm.JobDetail.JobReferenceNumber != '') {
                                            subject += " (ref:" + this.jvm.JobDetail.JobReferenceNumber + ")";
                                        }

                                        $('#page-output input[name=subject]').val(subject);

                                        $('#page-output .t-orgname').html(this.jvm.JobDetail.OrganisationName);

                                        var emailContent = this.jvm.JobDetail.JobCoverLetter;
                                        CKEDITOR.instances['ck-email'].setData(emailContent);
                                        if (!this.jvm.JobDetail.JobCoverLetter || this.jvm.JobDetail.JobCoverLetter == '') {
                                            // no cover letter

                                            ctrl.App.AjaxCall('/repo/GetJobEmailTemplateTexts', {
                                                jobdetailid: this.jvm.JobDetail.JobDetailId
                                            }, function (data) {
                                                $('.panel').removeClass('loading');
                                                emailContent = '';
                                                // merge data by js
                                                $.each(data.emailTemplateValues, function (key, value) {
                                                    data.emailTemplateSuggestions.forEach(function (e, i, a) {
                                                        a[i].Text = e.Text.replace(new RegExp("{{" + key + "}}", 'g'), value);
                                                    });
                                                });

                                                data.emailTemplateSuggestions.forEach(function (e, i, a) {
                                                    emailContent += a[i].Text;
                                                });
                                                CKEDITOR.instances['ck-email'].setData(emailContent);

                                            });
                                        }
                                        $('.cke_toolbox #cke_26').css('float', 'right');
                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    send: function () {
                                        var _sm = this;

                                        if ($('#page-output input[name=email]').val() == '') {
                                            $('#page-output input[name=email]').focus();
                                            return;
                                        }

                                        $('.panel').addClass('loading');

                                        console.info('sending ...');
                                        console.info(this.jvm.JobDetail.JobDetailId);
                                        console.info('email:' + $('#page-output input[name=email]').val());
                                        console.info('subject:' + $('#page-output input[name=subject]').val());
                                        console.info('content:' + ctrl.App.ckEmail.getData());
                                        console.info('organisationlogourl: ' + this.jvm.JobDetail.OrganisationLogoURL);
                                        console.info('cvid: ' + this.jvm.JobDetail.SelectedCVId);
                                        console.info('checked: ' + $('#chbx-publicprofile').is(':checked'));
                                        console.info('preapply: ' + $('#chbx-preapply').is(':checked'));
                                        ctrl.App.AjaxCall('/repo/SendJobEmail', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId,
                                            emailfrom: 'noreply@careercontroller.com',
                                            emailfromname: 'CareerController',
                                            emailto: $('#page-output input[name=email]').val(),
                                            subject: $('#page-output input[name=subject]').val(),
                                            content: ctrl.App.ckEmail.getData(),
                                            organisationlogourl: this.jvm.JobDetail.OrganisationLogoURL || '',
                                            cvid: this.jvm.JobDetail.SelectedCVId,
                                            tickone: $('#chbx-publicprofile').is(':checked'),
                                            ticktwo: $('#chbx-preapply').is(':checked'),
                                            tickthree: $('#chbx-empower').is(':checked')
                                        }, function (data) {
                                            console.info(data);

                                            this.jvm.JobDetail.isApplied = true;
                                            this.jvm.JobDetail.TalentJobStageId = 2;

                                            ctrl.App.Helper.Alert('success', appTranslations['Your application has been sent successfully! Best of luck!']);

                                            this.completeMasterTodo(6);

                                            this.handle('_onEnter');
                                        }.bind(this));
                                    },
                                    back: function () {
                                        $("html, body").animate({ scrollTop: 0 }, 1000);
                                        this.transition('customisecoverletter');
                                    },
                                    _onExit: function () {
                                        $('#page-output').hide();
                                        $('.panel .heading .btn-primary').show();
                                    }
                                },
                                todo: {
                                    _onEnter: function () {
                                        $('#page-todo').show();
                                        $('.panel .heading .h').html('To-dos');

                                        this.updateBreadcrumbs(false);

                                        $('#page-todo .todo-container:not(.new)').empty();

                                        this.jvm.JobDetail.TalentToDoList.forEach(function (e, i, a) {
                                            var template = $('.t.li-todo').clone().removeClass('t');

                                            ctrl.App.FillTodo(template, e);

                                            $('#page-todo .todo-container:not(.new)').append(template);
                                        });

                                        $('#page-todo .todo-container .dtp').datetimepicker();

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    save: function () {
                                        if (this.savetodotimer) clearTimeout(this.savetodotimer);

                                        var _sm = this;

                                        var payload = [];
                                        var order = 0;
                                        $('#ul-sortabletodo li').each(function () {
                                            payload.push({
                                                toDoId: $(this).data('id'),
                                                toDoDescription: $(this).find('.t-desc').html(),
                                                isCompleted: $(this).hasClass('completed'),
                                                sortOrder: order,
                                                toDelete: $(this).hasClass('deleted')
                                            });

                                            order++;
                                        });

                                        this.savetodotimer = setTimeout(function () {
                                            ctrl.App.AjaxCall('/repo/UpdateTalentToDoList', {
                                                jobDetailId: this.jvm.JobDetail.JobDetailId,
                                                payload: payload
                                            }, function (data) {
                                                ctrl.App.Helper.Alert('success', appTranslations['To-do list saved!']);

                                                _sm.jvm.JobDetail.TalentToDoList = data;
                                            });
                                        }.bind(this), 1000);
                                    },
                                    addtodo: function () {
                                        if ($('#in-newtodo').val() == '') {
                                            $('#in-newtodo').focus();
                                            return;
                                        }

                                        var template = $('.t.li-todo').clone().removeClass('t');

                                        ctrl.App.FillTodo(template, {
                                            Id: 0,
                                            ToDoDescription: $('#in-newtodo').val(),
                                            isCompleted: false
                                        });

                                        $('#page-todo .todo-container:not(.new)').append(template);

                                        $('#in-newtodo').val('');

                                        this.handle('save');
                                    },
                                    _onExit: function () {
                                        $('#page-todo').hide();
                                    }
                                },
                                notes: {
                                    _onEnter: function () {
                                        $('#page-notes').show();
                                        $('.panel .heading .h').html('Notes');

                                        this.updateBreadcrumbs(false);

                                        $('#page-notes textarea[name=note]').val('');

                                        $('.panel').addClass('loading');

                                        $('#page-notes .notes-container').empty();

                                        ctrl.App.AjaxCallGet('/repo/GetNotes?jobdetailid=' + this.jvm.JobDetail.JobDetailId, function (data) {
                                            $('.panel').removeClass('loading');

                                            data.forEach(function (e, i, a) {
                                                var template = $('.t.note').clone().removeClass('t');

                                                ctrl.App.FillNote(template, e);

                                                $('#page-notes .notes-container').append(template);
                                            });

                                            ctrl.App.fsmApp.handle("readjust");
                                        });

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    savenote: function () {
                                        var note = $('#page-notes textarea[name=note]').val();

                                        if (!note || note == '') {
                                            $('#page-notes textarea[name=note]').focus();
                                            $('#v-addnote').show();
                                            return;
                                        }

                                        $('.panel').addClass('loading');

                                        var _sm = this;

                                        var noteid = $('#page-notes textarea[name=note]').data('id');
                                        if (!noteid) noteid = 0;

                                        ctrl.App.AjaxCall('/repo/SaveNotes', {
                                            jobdetailid: this.jvm.JobDetail.JobDetailId,
                                            noteid: noteid,
                                            note: note
                                        }, function (notes) {
                                            $('.panel').removeClass('loading');

                                            $('#page-notes .notes-container').empty();

                                            notes.forEach(function (e, i, a) {
                                                var template = $('.t.note').clone().removeClass('t');

                                                ctrl.App.FillNote(template, e);

                                                $('#page-notes .notes-container').append(template);
                                            });

                                            $('#page-notes textarea[name=note]').data('id', null).val('');

                                            ctrl.App.fsmApp.handle("readjust");
                                        });
                                    },
                                    _onExit: function () {
                                        $('#page-notes').hide();
                                    }
                                },
                                jobdesc: {
                                    _onEnter: function () {
                                        $('#page-jobdesc').show();
                                        $('.panel .heading .h').html(appTranslations['Attach Job Description']);

                                        this.updateBreadcrumbs(false);

                                        $('#in-jobdetailid').val(this.jvm.JobDetail.JobDetailId);

                                        if (this.jvm.JobDetail.JobAttachmentPath) {
                                            $('#page-jobdesc .upload').hide();
                                            $('#page-jobdesc .download').show();

                                            $('#page-jobdesc .act-download').attr('href', this.jvm.JobDetail.JobAttachmentPath);
                                        } else {
                                            $('#page-jobdesc .upload').show();
                                            $('#page-jobdesc .download').hide();
                                        }

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    uploadedjobdesc: function (url) {
                                        $('.panel').removeClass('loading');

                                        this.jvm.JobDetail.JobAttachmentPath = url;

                                        this.handle('_onEnter');
                                    },
                                    "delete": function () {
                                        var _sm = this;

                                        ctrl.App.Helper.Confirm(appTranslations['Are you sure you want to delete the attachment for this job?'], function () {
                                            $('.panel').addClass('loading');

                                            ctrl.App.AjaxCall('/repo/DeleteAttachment', {
                                                jobDetailId: _sm.jvm.JobDetail.JobDetailId
                                            }, function () {
                                                $('.panel').removeClass('loading');

                                                _sm.jvm.JobDetail.JobAttachmentPath = null;
                                                _sm.handle('_onEnter');
                                            });
                                        });
                                    },
                                    _onExit: function () {
                                        $('#page-jobdesc').hide();
                                    }
                                },
                                endorse: {
                                    _onEnter: function () {
                                        var _sm = this;

                                        $('#page-endorse').show();
                                        $('.panel .heading .h').html('Invite Endorsements');

                                        this.updateBreadcrumbs(false);

                                        this.step = 2;

                                        this.updatesteps = function () {
                                            $('#section-endorse-step2').hide();
                                            $('#section-endorse-step3').hide();
                                            $('#section-endorse-step4').hide();
                                            $('#section-endorse-step5').hide();
                                            $('#section-endorse-step' + this.step).show();

                                            if (this.step == 5) {
                                                $('#page-endorse .head, #page-endorse .panel-footer').hide();
                                            } else {
                                                $('#page-endorse .head, #page-endorse .panel-footer').show();
                                            }
                                        }.bind(this);

                                        this.updatesteps();

                                        $('#section-endorse-step2 .endorse-types').show();
                                        $('#section-endorse-step2 .endorse-skills').hide();
                                        $('#section-endorse-step2 .endorse-written').hide();

                                        this.endorse = {
                                            "area": "jobs",
                                            "message": "As one of a small group of people whom I value opinions from, I would appreciate your insights on my career. It should not take more than 5 minutes and would very much be appreciated.",
                                            "payload": {},
                                            "etc": 1,
                                            "boxes": [
                                                { "type": "simple", "title": "Overall Capability", "description": "Invite people to rate your overall capability out of 5", "etc": 1, "recommended": true, "contenttype": "overall-capability-job", "selected": false },
                                                { "type": "skills", "title": "Skills", "description": "Invite people to rate your skills in this job", "etc": 5, "payload": [{ "$id": "6", "id": 12, "name": "Time Management", "istemp": false, "level": 2, "selected": false }, { "$id": "7", "id": 6, "name": "Communication Skills", "istemp": false, "level": 3, "selected": false }, { "$id": "8", "id": 168, "name": "Microsoft Office", "istemp": false, "level": 4, "selected": false }], "contenttype": "skills", "left": 820, "top": 15, "cache": null, "scale": "small" },
                                                { "type": "writtentestimonial", "title": "Written Testimonial", "description": "Invite people to write a testimonial or provide them with one to start", "etc": 10, "recommended": true, "contenttype": "written-testimonial-job", "left": 0, "top": 265, "selected": false },
                                                { "type": "simple", "title": "Be a Referee", "description": "Invite someone to be a referee for you", "etc": 10, "recommended": true, "contenttype": "beareferee", "left": 820, "top": 265, "selected": false }
                                            ],
                                            jobid: this.jvm.JobDetail.JobDetailId
                                        };

                                        ctrl.App.AjaxCall('/repo/GetDataForKeywordMatching', {
                                            jobDetailId: this.jvm.JobDetail.JobDetailId,
                                            onlymatchskills: true
                                        }, function (data) {
                                            console.info(data);
                                        });

                                        ctrl.App.AjaxCallGet('/CommonAPI/GetContacts', function (res) {
                                            res.forEach(function (e) {
                                                var t = $('.t.t-empower-contact').clone().removeClass('t');

                                                t.data('obj', e);
                                                t.find('.t-name').html(e.ContactFirstName + '<br/>' + e.ContactLastName);
                                                t.find('.t-role').html(e.ContactRoleName);

                                                $('#page-endorse .contacts-container').append(t);
                                            });

                                            ctrl.App.fsmApp.handle("readjust");
                                        });

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    updateendorsementselect: function (elem, index, requesttype) {
                                        var __this = this;

                                        __this.endorse.boxes[index].selected = !__this.endorse.boxes[index].selected;
                                        __this.endorse.boxes[index].requesttype = requesttype;

                                        if (index != 1) {
                                            if (__this.endorse.boxes[index].selected) {
                                                elem.parents('.recommend-box').addClass('active');
                                                elem.addClass('active');

                                                elem.html('Selected');
                                            } else {
                                                elem.parents('.recommend-box').removeClass('active');
                                                elem.removeClass('active');

                                                elem.html('Select');
                                            }
                                        } else {
                                            if (elem.hasClass('active')) {
                                                __this.endorse.boxes[index].selected = false;
                                                elem.parents('.recommend-box').removeClass('active');

                                                elem.parents('.btnWrap').find('.btn').removeClass('active');
                                            } else {
                                                __this.endorse.boxes[index].selected = true;
                                                elem.parents('.recommend-box').addClass('active');

                                                elem.parents('.btnWrap').find('.btn').removeClass('active');
                                                elem.addClass('active');
                                            }

                                        }

                                    },
                                    chooseskills: function () {
                                        console.info(this.jvm);

                                        $('#section-endorse-step2 .endorse-types').hide();
                                        $('#section-endorse-step2 .endorse-skills').show();
                                    },
                                    writetestimonial: function () {
                                        console.info(this.jvm);

                                        $('#section-endorse-step2 .endorse-types').hide();
                                        $('#section-endorse-step2 .endorse-written').show();
                                    },
                                    newjobempowerpack: function () {
                                        ctrl.App.AjaxCall('/mgmt/saveEmpowerPack', { jobid: this.jvm.JobDetail.JobDetailId }, function (res) {
                                            window.location.href = '/#/empower/editor/' + res.id;
                                        }, function (res) {
                                            window.location.href = $('#current-paymenturl').val() + '/Career/Template/TemplatePayment?templateId=10&origin=' + $('#current-domain').val() + '&sessionid=' + $('current-sessionid').val();
                                        });
                                    },
                                    next: function () {
                                        this.step++;
                                        if (this.step > 2) $('#page-endorse .act-back').show();

                                        if (this.step == 4) $('#page-endorse .act-next').html('Send');
                                        else $('#page-endorse .act-next').html('Next');

                                        if (this.step > 4) {
                                            var contacts = [];
                                            $('#page-endorse .contacts-container .t-empower-contact').each(function (e) {
                                                if ($(this).data('obj').selected) {
                                                    contacts.push($(this).data('obj'));
                                                }
                                            });

                                            this.endorse.contacts = contacts;
                                            this.endorse.payload = { id: 0 };

                                            $resource(Config.CCApiBase + '/mgmt/CreateEndorsementInvite').save(this.endorse, (res) => {
                                                this.updatesteps();
                                            });

                                            return;
                                        }

                                        this.updatesteps();

                                        if (this.step == 4) {
                                            // update display of step 4
                                        }

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    back: function () {
                                        this.step--;
                                        if (this.step == 2) $('#page-endorse .act-back').hide();

                                        this.updatesteps();

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    _onExit: function () {
                                        $('#page-endorse').hide();

                                        $('.act-endorse-select').off('click');
                                    }
                                },
                                comingsoon: {
                                    _onEnter: function () {
                                        $('#page-comingsoon').show();
                                        $('.panel .heading .h').html(appTranslations['Coming Soon']);

                                        this.updateBreadcrumbs(false);

                                        ctrl.App.fsmApp.handle("readjust");
                                    },
                                    _onExit: function () {
                                        $('#page-comingsoon').hide();
                                    }
                                }
                            },

                            reset: function (jvm) {
                                this.jvm = jvm;

                                $('#in-suggestskills').data('subprofileid', jvm.JobDetail.SubProfileId);

                                this.transition("uninitialized");
                            },

                            create: function () {
                                this.handle("create");
                            }
                        });

                        ctrl.App.fsmApp.handle('create');
                    },

                    FilterCards: function (name) {
                        $('.card-container .card').removeClass('hidden');

                        if (name != '') {
                            $('.card-container .card').filter(function () {
                                var html = $(this).find('.t-rolename').first().html();
                                if (html) return html.match(new RegExp(name, 'gi')) == null;
                                else return false;
                            }).addClass('hidden');

                            ctrl.App.fsmApp.handle('relayout');
                            setTimeout(function () {
                                ctrl.App.fsmApp.handle('relayout');
                            }, 2000);
                        }
                    }
                }

                this.dzcfgJobDescUpload = {
                    maxFilesize: 2,     // MB
                    maxFiles: 1,
                    headers: {
                        "AccessToken": srvAuth.getAccessToken()
                    },
                    url: Config.CCApiBase + '/repo/UploadJobDescription'
                };

                this.dzevtJobDescUpload = {
                    uploadprogress: function (file, progress) {
                        if (progress == 100) $('.panel').addClass('loading');
                    },

                    success: function (file, response) {
                        switch (response) {
                            case 'FileSize Error':
                                ctrl.App.Helper.Alert('danger', appTranslations['File size too big!']);
                                $('.panel').removeClass('loading');
                                break;

                            case 'FileType Error':
                                ctrl.App.Helper.Alert('danger', appTranslations['Please upload a PDF, DOC, or DOCX file!']);
                                $('.panel').removeClass('loading');
                                break;

                            default: ctrl.App.fsmPanel.handle('uploadedjobdesc', response);
                        }

                        this.removeAllFiles();
                    },

                    error: function (file, errorMessage) {
                        if (file.size > 2097152) {
                            ctrl.App.Helper.Alert('danger', appTranslations['File size too big!']);
                        }

                        this.removeAllFiles();
                    }
                };

                this.dzcfgOrglogo = {
                    maxFilesize: 2,     // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: {
                        "AccessToken": srvAuth.getAccessToken()
                    },
                    url: Config.CCApiBase + '/repo/UploadTempFile'
                };

                this.dzevtOrglogo = {
                    addedfile: function (file) {
                        $('#dz-orglogo .dz-message').hide();
                    },

                    reset: function () {
                        $('#dz-orglogo .dz-message').show();
                    },

                    success: function (file, response) {
                        switch (response) {
                            case 'FileSize Error':
                                ctrl.App.Helper.Alert('danger', 'File size too big!');
                                break;

                            case 'FileType Error':
                                ctrl.App.Helper.Alert('danger', 'Please upload a valid image file!');
                                break;

                            default: ctrl.App.fsmPanel.handle('uploadedcontactorglogo', response);
                        }

                        this.removeAllFiles();
                    },

                    error: function (file, errorMessage) {
                        if (file.size > 2097152) {
                            ctrl.App.Helper.Alert('danger', 'File size too big!');
                        }

                        this.removeAllFiles();
                    }
                };

                this.dzcfgContactpic = {
                    maxFilesize: 2,     // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: {
                        "AccessToken": srvAuth.getAccessToken()
                    },
                    url: Config.CCApiBase + '/repo/UploadTempFile'
                };

                this.dzevtContactpic = {
                    addedfile: function (file) {
                        $('#dz-contactpic .dz-message').hide();
                    },

                    reset: function () {
                        $('#dz-contactpic .dz-message').show();
                    },

                    success: function (file, response) {
                        switch (response) {
                            case 'FileSize Error':
                                ctrl.App.Helper.Alert('danger', 'File size too big!');
                                break;

                            case 'FileType Error':
                                ctrl.App.Helper.Alert('danger', 'Please upload a valid image file!');
                                break;

                            default: ctrl.App.fsmPanel.handle('uploadedcontactpic', response);
                        }
                    },

                    error: function (file, errorMessage) {
                        if (file.size > 2097152) {
                            ctrl.App.Helper.Alert('danger', 'File size too big!');
                        }

                        this.removeAllFiles();
                    }
                };

                this.dzcfgMOrglogo = {
                    maxFilesize: 2,     // MB
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: {
                        "AccessToken": srvAuth.getAccessToken()
                    },
                    url: Config.CCApiBase + '/repo/UploadTempFile'
                };

                this.dzevtMOrglogo = {
                    addedfile: function (file) {
                        $('#dz-orglogo .dz-message').hide();
                    },

                    reset: function () {
                        $('#dz-orglogo .dz-message').show();
                    },

                    success: function (file, response) {
                        switch (response) {
                            case 'FileSize Error':
                                ctrl.App.Helper.Alert('danger', 'File size too big!');
                                break;

                            case 'FileType Error':
                                ctrl.App.Helper.Alert('danger', 'Please upload a valid image file!');
                                break;

                            default: ctrl.App.fsmPanel.handle('uploadedcontactorglogo', response);
                        }

                        this.removeAllFiles();
                    },

                    error: function (file, errorMessage) {
                        if (file.size > 2097152) {
                            ctrl.App.Helper.Alert('danger', 'File size too big!');
                        }

                        this.removeAllFiles();
                    }
                };
            }
        }


        return {
            restrict: 'E',
            templateUrl: 'partials/templates/jobsmanagement.html',
            controller: ctrlJobsManagement,
            controllerAs: 'cJobsManagement',
            link: (scope: IJobsManagementScope, element: any, attr: any, ctrl: any) => {
                var App = ctrl.App;

                // cover letter
                scope.coverLetter = '';
                scope.coverLetterTemplate = '';
                scope.addCoverLetterText = function (suggestion, $event) {
                    $($event.target).parents('li').hide();

                    suggestion.UsageCount++;

                    var range = CKEDITOR.instances['ck-coverletter'].createRange();
                    range.moveToElementEditablePosition(CKEDITOR.instances['ck-coverletter'].editable(), true); // bar.^</p>
                    CKEDITOR.instances['ck-coverletter'].getSelection().selectRanges([range]);

                    var originalText = CKEDITOR.instances['ck-coverletter'].getData();
                    var useList = false; // $('.fa-list-ul').hasClass('active')
                    if (useList) {
                        scope.coverLetter = CKEDITOR.instances['ck-coverletter'].getData() + '<ul><li>' + suggestion.Text + '</li></ul>';
                        scope.coverLetterTemplate += + '<ul><li>' + suggestion.Tex.t + '</li></ul>';
                    } else {
                        if (originalText == '') {
                            scope.coverLetter = suggestion.Text;
                            scope.coverLetterTemplate = suggestion.Text;
                        } else {
                            scope.coverLetter = CKEDITOR.instances['ck-coverletter'].getData() + suggestion.Text;
                            scope.coverLetterTemplate += suggestion.Text;
                        }
                    }

                    CKEDITOR.instances['ck-coverletter'].setData(scope.coverLetter);
                    $('#ck-coverlettertemplate').data('t', scope.coverLetterTemplate);

                    range = CKEDITOR.instances['ck-coverletter'].createRange();
                    range.moveToElementEditablePosition(CKEDITOR.instances['ck-coverletter'].editable(), true); // bar.^</p>
                    CKEDITOR.instances['ck-coverletter'].getSelection().selectRanges([range]);
                }

                // career summary
                scope.summaryText = '';
                scope.summaryTextTemplate = '';
                scope.addCareerSummaryText = function (suggestion, $event) {
                    $($event.target).parents('li').hide();

                    suggestion.UsageCount++;


                    var originalText = CKEDITOR.instances['ck-careersummary'].getData();
                    var useList = false; // $('.fa-list-ul').hasClass('active')
                    if (useList) {
                        scope.summaryText = CKEDITOR.instances['ck-careersummary'].getData() + '<ul><li>' + suggestion.Text + '</li></ul>';
                        scope.summaryTextTemplate += '<ul><li>' + suggestion.Text + '</li></ul>';
                    } else {
                        if (originalText == '') {
                            scope.summaryText = suggestion.Text;
                            scope.summaryTextTemplate = suggestion.Text;
                        } else {
                            var text = $('<div>' + CKEDITOR.instances['ck-careersummary'].getData() + '</div>');
                            text.find('p').last().append($('<span> ' + suggestion.Text + '</span>'));
                            scope.summaryText = text.html();
                            scope.summaryTextTemplate = scope.summaryTextTemplate + suggestion.Text;
                        }
                    }

                    CKEDITOR.instances['ck-careersummary'].setData(scope.summaryText);
                    $('#ck-careersummarytemplate').data('t', scope.summaryTextTemplate);
                }

                var gservice = new google.maps.places.AutocompleteService();

                App.Init();

                $('[data-toggle="tooltip"]').tooltip();

                // careersummary
                App.ckCareerSummary = CKEDITOR.replace('ck-careersummary');
                if (App.ckCareerSummary != null) {
                    App.ckCareerSummary.addCommand("ClearAll", {
                        exec: function (edt) {
                            App.ckCareerSummary.setData('');

                            var scope = <any>angular.element($('#page-customiseresume div[ng-controller="ngAppController"]')).scope();
                            scope.$apply(function () {
                                scope.careerSummarySuggestions.forEach(function (e, i, a) {
                                    e.InitialDisplay = false;
                                });

                            });
                        }
                    });
                    App.ckCareerSummary.ui.addButton('Clear', {
                        // add new button and bind our command
                        label: "Clear All",
                        command: 'ClearAll',
                        toolbar: 'insert',
                        icon: 'https://api.careercontroller.com/Content/Images/clear-icon.png'
                    });

                    App.ckCareerSummary.on('instanceReady', function () {
                        $('.cke_toolbox > span:nth-child(4)').css('float', 'right');
                        $('#cke_ck-careersummary').addClass('noloading');
                    });

                    App.ckCareerSummary.on('change', function (e) {
                        if (App.ckCareerSummary.changetimeout) clearTimeout(App.ckCareerSummary.changetimeout);

                        App.ckCareerSummary.changetimeout = setTimeout(function () {
                            App.fsmPanel.handle('save');
                        }, 1000);
                    });
                }


                // coverletter
                App.ckCoverLetter = CKEDITOR.replace('ck-coverletter');
                if (App.ckCoverLetter != null) {
                    App.ckCoverLetter.addCommand("ClearAll", {
                        exec: function (edt) {
                            App.ckCoverLetter.setData('');

                            var scope = <any>angular.element($('#page-customisecoverletter div[ng-controller="ngAppController"]')).scope();
                            scope.$apply(function () {
                                scope.coverLetterSuggestions.forEach(function (e, i, a) {
                                    e.InitialDisplay = false;
                                });

                            });
                        }
                    });
                    App.ckCoverLetter.ui.addButton('Clear', {
                        // add new button and bind our command
                        label: "Clear All",
                        command: 'ClearAll',
                        toolbar: 'insert',
                        icon: 'https://api.careercontroller.com/Content/Images/clear-icon.png'
                    });
                    App.ckCoverLetter.on('instanceReady', function () {
                        $('.cke_toolbox > span:nth-child(4)').css('float', 'right');
                        $('#cke_ck-coverletter').addClass('noloading');
                    });

                    App.ckCoverLetter.on('change', function (e) {
                        if (App.ckCoverLetter.changetimeout) clearTimeout(App.ckCoverLetter.changetimeout);

                        App.ckCoverLetter.changetimeout = setTimeout(function () {
                            App.fsmPanel.handle('save');
                        }, 1000);
                    });
                }

                // email
                App.ckEmail = CKEDITOR.replace('ck-email');
                if (App.ckEmail != null) {
                    App.ckEmail.addCommand("ClearAll", {
                        exec: function (edt) {
                            App.ckEmail.setData('');
                        }
                    });
                    App.ckEmail.ui.addButton('Clear', {
                        // add new button and bind our command
                        label: "Clear All",
                        command: 'ClearAll',
                        toolbar: 'insert',
                        icon: 'https://api.careercontroller.com/Content/Images/clear-icon.png'
                    });
                    App.ckEmail.on('instanceReady', function () {
                        $('.cke_toolbox > span:nth-child(4)').css('float', 'right');
                        $('#cke_ck-email').addClass('noloading');
                    });
                }

                $('.act-new').click(function () {
                    App.fsmApp.handle('newcard');
                });

                $('#in-search').keyup(function () {
                    var input = $(this).val();
                    App.FilterCards(input);
                });

                //  disable some default behavior of browsers
                $(document).on('dragstart', 'img', function (event) { event.preventDefault(); });

                // card events
                $('.card-container').on('mousedown', 'button,a', function (e) {
                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .resize', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("resizecard", card[0], $(this).hasClass('larger'));

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-delete', function (e) {
                    var card = $(this).parents('.card');
                    var jvm = card.data('jvm');

                    if (jvm) {
                        App.Helper.Confirm('Are you sure you wish to delete this job?', function () {
                            App.fsmApp.handle('closepanel');

                            App.AjaxCall('/repo/DeleteJob', { jobDetailId: jvm.JobDetail.JobDetailId }, function (data) { /* do nothing */ });

                            card.remove();

                            App.fsmApp.handle('relayout');
                        });
                    }

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-editcontact', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmCard.handle(card[0], 'editcontacts');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-preview', function (e) {
                    var card = $(this).parents('.card');
                    var jvm = $(this).parents('.card').data('jvm');

                    if (jvm) {
                        var modal = $('#modal-previewresume');
                        $('#modal-previewresume iframe').attr('src', 'https://www.careercontroller.com/en-US/ResumePreview/' + srvAuth.getAccessToken() + '/' + jvm.JobDetail.SelectedCVOriginalName + '/');

                        // 'https://api.careercontroller.com/Resumes/' + jvm.JobDetail.SelectedCVOriginalName + '/Preview/?ProfileGuid=' + App.MasterData.profileGuid + '&Culture=' + jvm.JobDetail.SubProfileLanguageCultureCode + '&subprofileid=' + jvm.JobDetail.SubProfileId + '&talentjobdetailid=' + jvm.JobDetail.JobDetailId);

                        App.fsmModals.previewresume.handle(modal[0], 'create', function () {

                        }.bind(this));
                    }

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-viewdetails', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0]);

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-viewmap', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0]);
                    App.fsmPanel.transition('location');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-contact', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0]);
                    App.fsmPanel.transition('contacts');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-todo', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0], 'todo');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-keyword', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0], 'keyword');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-endorse', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0], 'endorse');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-comingsoon', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0], 'comingsoon');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-apply:not(.disabled)', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0]);
                    App.fsmPanel.transition('output');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-addnotes', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0], 'notes');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-attachjobdescription', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0], 'jobdesc');

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .to-do span[data-link]', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmApp.handle("openpanel", card[0], $(this).attr('data-link'));

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .progress-container li a', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmCard.handle(card[0], "updateprogress", $(this).parents('li').attr('data-id'));

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card .act-rate i', function (e) {
                    var card = $(this).parents('.card');

                    App.fsmCard.handle(card[0], "ratejob", $(this).index());

                    e.stopPropagation();
                });

                $('.card-container').on('mousedown', '.card:not(.avert)', function (e) {
                    this.initialcoord = {
                        x: e.pageX,
                        y: e.pageY
                    };
                    this.initialoffset = {
                        x: parseInt($(this).css('left'), 10),
                        y: parseInt($(this).css('top'), 10)
                    }

                    this.lastmousedowntime = Date.now();

                    App.fsmApp.handle("startmovecard", this);
                }).on('mouseup', '.card:not(.avert)', function (e) {
                    App.fsmApp.handle("stopmovecard", this);

                    if (this.lastmousedowntime && Date.now() - this.lastmousedowntime < 100) {
                        var card = $(this);
                        App.fsmCard.handle(card[0], 'flip');
                    }
                }).on('mousemove', '.card:not(.avert)', function (e) {
                    this.movecoord = {
                        x: e.pageX,
                        y: e.pageY
                    };

                    App.fsmApp.handle("movingcard", this);
                });

                // modal dialog events
                $('.modal').on('hide.bs.modal', function (e) {
                    var modal = this;

                    $.each(App.fsmModals, function (key, value) {
                        value.reset(modal);
                    });
                });

                $('.modal .btn-primary').click(function () {
                    var jqmodal = $(this).parents('.modal');

                    if (App.fsmModals[jqmodal.attr('state-machine')]) App.fsmModals[jqmodal.attr('state-machine')].handle(jqmodal[0], "ok");
                    else {
                        var callback = jqmodal.data('callback');
                        if (callback) callback();
                        jqmodal.modal('hide');
                    }
                });

                $('.modal .act-learnmore').click(function () {
                    var jqmodal = $(this).parents('.modal');

                    if (App.fsmModals[jqmodal.attr('state-machine')]) App.fsmModals[jqmodal.attr('state-machine')].handle(jqmodal[0], "learnmore");
                });

                $('.modal .act-back').click(function () {
                    var jqmodal = $(this).parents('.modal');
                    App.fsmModals[jqmodal.attr('state-machine')].handle(jqmodal[0], "back");
                });

                $('.modal .act-next').click(function () {
                    var jqmodal = $(this).parents('.modal');
                    App.fsmModals[jqmodal.attr('state-machine')].handle(jqmodal[0], "next");
                });

                $('.modal .modal-header h1 span.pull-right span').click(function () {
                    var parent = $(this).parents('span');
                    parent.find('span').removeClass('active');
                    $(this).addClass('active');
                });

                $('.modal .matchedjobs-container').on('click', '.matchedjob', function () {
                    $('#v-selectmatchedjob').hide();
                    $('.modal .matchedjobs-container .matchedjob').removeClass('active');
                    $(this).addClass('active');
                });

                $('.modal, .panel').on('filter-csc', '.csc-sel-group-container .sel-group', function () {
                    var cid = $(this).find('select[name=c]').val();

                    $(this).find('select[name=sc] option').show();
                    $(this).find('select[name=sc] option').filter(function () {
                        return $(this).attr('data-lid') != cid;
                    }).hide();

                    $(this).find('select[name=sc]').val($(this).find('select[name=sc] option:first-child').attr('value'));
                });

                $('.modal, .panel').on('change', '.csc-sel-group-container .sel-group select[name=c]', function () {
                    $(this).parents('.sel-group').trigger('filter-csc');
                });

                $('.modal .act-addc').click(function () {
                    var m = $(this).parents('.modal');
                    var count = m.find('.csc-sel-group-container .sel-group').length;

                    if (count < 5) {
                        var n = m.find('.csc-sel-group-container .sel-group:first-child').clone(true);
                        n.trigger('filter-csc');
                        m.find('.csc-sel-group-container').append(n);

                        if (count == 4) $(this).hide();
                    } else {
                        $(this).hide();
                    }
                });

                $('#page-jobad .act-addc').click(function () {
                    var m = $('#page-jobad');
                    var count = m.find('.csc-sel-group-container .sel-group').length;

                    if (count < 5) {
                        var n = m.find('.csc-sel-group-container .sel-group:first-child').clone(true);
                        n.trigger('filter-csc');
                        m.find('.csc-sel-group-container').append(n);

                        if (count == 4) $(this).hide();
                    } else {
                        $(this).hide();
                    }
                });

                $('.csc-sel-group-container').on('click', '.act-delete', function () {
                    var m = $(this).parents('.csc-sel-group-container');
                    var selgroup = $(this).parents('.sel-group');
                    var count = m.find('.sel-group').length;

                    if (count > 1) {
                        selgroup.remove();
                        m.parents('div').find('.act-addc').show();
                    } else {
                        selgroup.find('select').val('');
                    }
                });

                // validations
                $('textarea[data-validate], input[data-validate]').keyup(function () {
                    var id = $(this).attr('data-validate');
                    $('#v-' + id).hide();
                });

                $('.panel .heading .dropdown-menu li').click(function () {
                    App.fsmPanel.transition($(this).attr('data-panel'));
                });

                $('.panel .heading .panel-progress i').click(function () {
                    App.fsmPanel.transition($(this).attr('data-panel'));
                });

                $('.panel .act-next').click(function () {
                    App.fsmPanel.handle('next');
                });

                $('.panel .act-back').click(function () {
                    App.fsmPanel.handle('back');
                });

                $('.suggestion-container .act-loadmore').click(function () {
                    var parent = $(this).parents('.suggestion-container');
                    var lis = parent.find('li').filter(function () {
                        return $(this).css('display') == 'none';
                    });

                    for (var i = 0; i < 5; i++)
                        lis.eq(i).show();

                    if (lis.length <= 5) parent.find('.act-loadmore').hide();

                    App.fsmApp.handle('readjust');
                });

                $('#act-closepanel, .act-closepanel').click(function () {
                    App.fsmApp.handle('closepanel');
                });

                $('#page-jobad .act-save').click(function () {
                    App.fsmPanel.handle('save');
                });

                $('#page-jobad .act-learnmore').click(function () {
                    App.fsmPanel.handle('learnmore');
                });

                $('#page-keyword').click(function () {
                    $('#page-keyword .highlight-menu').hide();
                });

                $('#page-keyword').on('click', '.highlight-big:not(.added)', function (e) {                    
                    var thisOffset = $(this).offset();
                    var parentOffset = $('#page-keyword').offset();
                    
                        // show tooltip
                        var relX = e.pageX - parentOffset.left;
                        var relY = e.pageY - parentOffset.top;

                        $('#page-keyword .highlight-menu').data('skillid', $(this).attr('data-id'));

                        $('#page-keyword .highlight-menu').show().css('top', relY).css('left', relX);

                        var hw = $('#page-keyword .highlight-menu').width();
                        var hh = $('#page-keyword .highlight-menu').height();

                        if (relX + hw > $('#page-keyword').width()) {
                            $('#page-keyword .highlight-menu').css('left', relX - hw);
                        }

                        if (relY + hh > $('#page-keyword').height()) {
                            $('#page-keyword .highlight-menu').css('top', relY - hh);
                        }

                        e.stopPropagation();
                                          
                        var id = $(this).attr('data-id');
                        var name = $(this).html();

                        $('#page-keyword .highlight-big[data-id="' + id + '"]').addClass('added');

                        setTimeout(function () {
                            $('#page-keyword .bucket-container').append($('<p><span data-id="' + id + '">' + name + '</span> <i class="icon-icon_x act-delete"></i></p>'));
                            $('#page-keyword .act-recheck').removeClass('disabled');
                            $('#page-keyword .act-recheckandadd').removeClass('disabled');
                            App.fsmApp.handle('readjust');
                        }, 300);

                        // start animation (this runs independently and has no effect on rest of the system)
                        var clone = $(this).clone().appendTo('#page-keyword .job-content');
                        clone.css('position', 'absolute').css('top', thisOffset.top - parentOffset.top).css('left', thisOffset.left - parentOffset.left).css('z-index', 10);
                        setTimeout(function () {
                            clone.css('top', '100%').css('left', '20%').css('opacity', 0).delay(1000).queue(function (n) { $(this).remove(); n(); });
                        }, 0);


                });

                $('#page-keyword .highlight-menu').on('click', 'li.i', function () {                 
                    var skillid = $(this).parents('.highlight-menu').data('skillid');
                    var expid = $(this).attr('data-expid');

                    $('#page-keyword .highlight-big[data-id="' + skillid + '"]').addClass('added');

                    App.fsmPanel.handle('addkeyword', expid, skillid);

                    $('#page-keyword .act-recheck').removeClass('disabled');
                    $('#page-keyword .act-recheckandadd').removeClass('disabled');
                });

                $('#page-keyword .highlight-menu').on('click', 'li.act-disregard', function () {                  
                    var skillid = $(this).parents('.highlight-menu').data('skillid');

                    var element = $('#page-keyword .highlight-big[data-id="' + skillid + '"]');
                    element.each(function () {
                        var text = $(this).html();
                        $(this).replaceWith(text);
                    });

                    App.fsmPanel.handle('addkeyword', 0, skillid);
                });

                $('#page-keyword .act-moresuggestedskills').click(function () {
                    var hiddens = $('#page-keyword .suggested-container p').filter(function () {
                        return $(this).css('display') == 'none';
                    });

                    for (var i = 0; i < hiddens.length && i < 9; i++)
                        hiddens.eq(i).css('display', 'inline-block');

                    if (hiddens.length <= 9) $(this).hide();

                    App.fsmApp.handle('readjust');
                });

                $('#page-keyword .act-addnewskilltobucket').click(function () {
                    if ($('#in-suggestskills').val() != '') {
                        $('#page-keyword .bucket-container').append($('<p><span data-id="' + $('#in-suggestskills').data('id') + '">' + $('#in-suggestskills').val() + '</span> <i class="icon-icon_x act-delete"></i></p>'));
                        $('#in-suggestskills').data('id', 0).val('');
                        $('#page-keyword .act-recheck').removeClass('disabled');
                        $('#page-keyword .act-recheckandadd').removeClass('disabled');
                        App.fsmApp.handle('readjust');
                    } else {
                        $('#in-suggestskills').focus();
                    }
                });

                $('#page-keyword .act-recheck').click(function () {
                    if (!$(this).hasClass('disabled')) App.fsmPanel.handle('recheck');
                });

                $('#page-keyword .act-recheckandadd').click(function () {
                    if (!$(this).hasClass('disabled')) App.fsmPanel.handle('recheckandadd');
                });

                $('#page-keyword .bucket-container').on('click', '.act-delete', function () {                
                    var parent = $(this).parents('p')
                    var skillid = parent.find('span').attr('data-id');
                    $('#page-keyword .highlight-big[data-id="' + skillid + '"').removeClass('added');

                    parent.remove();
                    App.fsmApp.handle('readjust');

                    if ($('#page-keyword .bucket-container p').length <= 0) {
                        $('#page-keyword .act-recheck').addClass('disabled');
                        $('#page-keyword .act-recheckandadd').addClass('disabled');
                    }
                });

                $('#page-keyword .act-viewdetails').click(function () {
                    $("html, body").animate({ scrollTop: 0 }, 1000);
                    App.fsmPanel.transition('jobad');
                });

                $('#page-cv .filters strong').click(function () {
                    $('#page-cv .tab').hide();
                    $('#page-cv .filters strong').removeClass('active');
                    $(this).addClass('active');

                    if ($(this).attr('data-filter') == 'my') {
                        $('#page-cv .tab').first().show();
                    } else {
                        $('#page-cv .tab').last().show();
                    }

                    App.fsmApp.handle("readjust");
                });

                $('#page-cv .myresumes .cvs-container').on('click', '.cv-container', function () {
                    var cv = $(this).data('cv');
                    $('#page-cv .myresumes .cvs-container .cv-container-outer').removeClass('active');
                    $(this).parents('.cv-container-outer').addClass('active');

                    App.fsmPanel.handle('updatecv', cv.CVId);
                });

                $('#page-notes .act-addnote').click(function () {
                    App.fsmPanel.handle('savenote');
                });

                $('#page-contacts .act-search').click(function () {
                    App.fsmPanel.handle('search');
                });

                $('#page-contacts input').change(function () {
                    App.fsmPanel.handle('updatecontactdetails');
                });

                $('#page-notes').on('click', '.note .act-delete', function () {
                    var note = $(this).parents('.note');

                    App.Helper.Confirm('Are you sure you want to delete this note?', function () {
                        App.AjaxCallGet('/repo/DeleteNote?noteid=' + note.data('note').id, function () { /* do nothing */ });

                        note.remove();
                        App.fsmApp.handle('readjust');
                    })
                });

                $('#page-notes').on('click', '.note .act-edit', function () {
                    var parent = $(this).parents('.note');

                    $('#page-notes textarea[name=note]').data('id', parent.data('note').id).val(parent.find('.t-note').html());

                    $("html, body").animate({ scrollTop: 0 }, 1000);
                });

                $('#page-customiseresume select[name=recentcareersummaries]').change(function () {
                    App.ckCareerSummary.setData($(this).val());
                });

                $('#page-customisecoverletter select[name=recentcoverletters]').change(function () {
                    App.ckCoverLetter.setData($(this).val());
                });

                $('#ul-sortabletodo').sortable({
                    handle: ".act-reorder",
                    stop: function () {
                        App.fsmPanel.handle('save');
                    }
                });

                $('#ul-sortabletodo').on('click', '.act-complete', function () {
                    $(this).parents('li').toggleClass('completed');
                    App.fsmPanel.handle('save');
                });

                $('#ul-sortabletodo').on('click', '.act-delete', function () {
                    $(this).parents('li').addClass('deleted');
                    App.fsmPanel.handle('save');
                });

                $('#page-jobdesc .act-delete').click(function () {
                    App.fsmPanel.handle('delete');
                });

                $('#page-todo .act-addtodo').click(function () {
                    App.fsmPanel.handle('addtodo');
                });

                $('#page-todo .act-addtodo').click(function () {
                    App.fsmPanel.handle('addtodo');
                });

                $('#page-todo .todo-container.new .dropdown-menu').on('click', 'li a', function () {
                    $('#in-newtodo').val($(this).html());
                });

                $('#page-output .act-send').click(function () {
                    App.fsmPanel.handle('send');
                });

                $('#page-contacts .toggle-container button').click(function () {
                    var parent = $(this).parents('.toggle-container');
                    parent.find('button').removeClass('active');
                    $(this).addClass('active');
                });

                $('#page-contacts select[name=country]').change(function () {
                    var option = $(this).find('option[value="' + $(this).val() + '"]');
                    $('#page-contacts .isdcode').html('+' + option.attr('data-isdcode'));
                });

                $('#page-contacts .act-unlinkcontact').click(function () {
                    App.fsmPanel.handle('unlinkcontact');
                });

                $('#page-contacts .act-unlinkcontactorg').click(function () {
                    App.fsmPanel.handle('unlinkcontactorg');
                });

                $('#filterjobstage').on('click', 'li a', function () {
                    var li = $(this).parents('li');
                    var stageid = li.attr('data-id');
                    if (stageid > 0) {
                        $('.card-container .card').addClass('hidden');
                        $('.card-container .card').filter(function () {
                            return $(this).data('jvm') && $(this).data('jvm').JobDetail.TalentJobStageId == stageid;
                        }).removeClass('hidden');
                    } else {
                        $('.card-container .card').removeClass('hidden');
                    }
                    $('#filterjobstage button span').first().html($(this).html());
                    App.fsmApp.handle('relayout');
                });

                $('#filter-sort').on('click', 'li a', function () {
                    var li = $(this).parents('li');
                    var sortid = li.attr('data-id');

                    switch (sortid) {
                        case "1":
                            $('.card-container .card').sort((a, b): any => {
                                if (!$(a).data('jvm') || !$(b).data('jvm')) return 0;

                                return $(a).data('jvm').JobDetail.TalentMatchingPercentage < $(b).data('jvm').JobDetail.TalentMatchingPercentage;
                            }).appendTo('.card-container');
                            break;
                    }

                    App.fsmApp.handle('relayout');
                });

                $('#page-jobad input[name=posteddate]').datetimepicker({ format: "LL" });
                $('#page-jobad input[name=closedate]').datetimepicker({ format: "LL" });

                $('#page-contacts aside img').cropper({
                    aspectRatio: 1,
                    crop: function (e) {
                        // Output the result data for cropping image.
                        console.info(e.x);
                        console.info(e.y);
                        console.info(e.width);
                        console.info(e.height);
                        console.info(e.rotate);
                        console.info(e.scaleX);
                        console.info(e.scaleY);
                    }
                });


                $('#in-role').autocomplete({
                    appendTo: '#div-acc-rolename',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        // retrieve Master Roles from server
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + "/repo/SearchMasterRoles?term=" + request.term + '&subprofileid=' + $('#in-role').data('subprofileid'),
                            type: "GET",
                            success: function (rsp) {
                                // return the results
                                response(rsp);
                            }
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {

                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#in-role').data('id', ui.item.RoleId);
                        $('#in-role').val(ui.item.Name);
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { },
                    close: function (event, ui) { }
                }).keyup(function () {
                    $('#in-role').data('id', 0);
                    $('#v-selectrole').hide();
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li></li>").html(item.Name).appendTo(ul);
                };

                $('#in-organisation').autocomplete({
                    appendTo: '#div-acc-organisation',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        // retrieve Master Roles from server
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + "/repo/SearchMasterOrganisations?term=" + request.term + '&subprofileid=' + $('#in-organisation').data('subprofileid'),
                            type: "GET",
                            success: function (rsp) {
                                // return the results
                                response(rsp);
                            }
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {
                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#in-organisation').data('id', ui.item.Id);
                        $('#in-organisation').val(ui.item.Name);

                        var template = $('.organisation-autcomplete-result');

                        var item = ui.item;
                        template.find('.t-name').html(item.Name);

                        if (item.LogoUrl) template.find('.logo').attr('src', 'https://api.careercontroller.com' + item.LogoUrl);
                        else template.find('.logo').attr('src', 'https://api.careercontroller.com/Content/Images/company-icon-default.png');

                        if (item.WebURL) template.find('.t-website').html(item.WebURL);
                        else template.find('.t-website').hide();

                        if (item.Industry) template.find('.t-industry').html(item.Industry);
                        else template.find('.t-industry').hide();

                        if (item.NumberOfEmployees) template.find('.t-staff').html('<strong>' + 'Staff' + ': </strong> ' + item.NumberOfEmployees);
                        else template.find('.t-staff').hide();

                        if (item.AnnualTurnover) template.find('.t-turnover').html('<strong>' + 'Annual Turnover' + ': </strong> ' + item.AnnualTurnover);
                        else template.find('.t-turnover').hide();

                        template.show();

                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { },
                    close: function (event, ui) { }
                }).keyup(function () {
                    $('#in-organisation').data('id', 0);
                    $('#v-selectorg').hide();

                    $('.organisation-autcomplete-result').hide();
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    var template = $('.organisation-autcomplete-container.t').clone().removeClass('t');

                    template.find('.t-name').html(item.Name);

                    if (item.LogoUrl) template.find('.logo').attr('src', 'https://api.careercontroller.com' + item.LogoUrl);
                    else template.find('.logo').attr('src', 'https://api.careercontroller.com/Content/Images/company-icon-default.png');

                    if (item.WebURL) template.find('.t-website').html(item.WebURL);
                    else template.find('.t-website').hide();

                    if (item.Industry) template.find('.t-industry').html(item.Industry);
                    else template.find('.t-industry').hide();

                    if (item.NumberOfEmployees) template.find('.t-staff').html('<strong>' + 'Staff' + ': </strong> ' + item.NumberOfEmployees);
                    else template.find('.t-staff').hide();

                    if (item.AnnualTurnover) template.find('.t-turnover').html('<strong>' + 'Annual Turnover' + ': </strong> ' + item.AnnualTurnover);
                    else template.find('.t-turnover').hide();

                    return template.appendTo(ul);
                };

                $('#in-suggestskills').autocomplete({
                    appendTo: '#div-acc-suggestskills',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        // retrieve Master Roles from server
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + "/repo/SearchMasterSkills?term=" + request.term + '&subprofileid=' + $('#in-suggestskills').data('subprofileid'),
                            type: "GET",
                            success: function (rsp) {
                                // return the results
                                response(rsp);
                            }
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {

                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#in-suggestskills').data('id', ui.item.SkillID);
                        $('#in-suggestskills').val(ui.item.Name);
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { },
                    close: function (event, ui) { }
                }).keyup(function () {
                    $('#in-suggestskills').data('id', 0);
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li></li>").html(item.Name).appendTo(ul);
                };

                $('#in-location').autocomplete({
                    appendTo: '#div-acc-location',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        var results = [];
                        var thisElement = $("#in-location");

                        var googleOptions = {
                            input: thisElement.val(),
                            types: ['geocode']
                            //componentRestrictions: { country: 'AUS' }
                        };

                        gservice.getPlacePredictions(googleOptions, function (predictions, status) {
                            // reply from google
                            // reset result list because google can reply anytime
                            var googleResults = [];

                            if (status != google.maps.places.PlacesServiceStatus.OK) {
                                // something is wrong. google is not ok
                                return;
                            }

                            predictions.forEach(function (object) {
                                googleResults.push({
                                    id: object.id,
                                    predictionobj: object,
                                    name: object.description,
                                    meta: {
                                        google: true
                                    }
                                });
                            });

                            results = results.concat(googleResults);
                            response(results);
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {
                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#in-location').data('obj', ui.item);
                        $('#in-location').val(ui.item.name);
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { },
                    close: function (event, ui) { }
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li></li>").html(item.name).appendTo(ul);
                };


                $('#in-role-edit').autocomplete({
                    appendTo: '#div-acc-rolename-edit',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        // retrieve Master Roles from server
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + "/repo/SearchMasterRoles?term=" + request.term + '&subprofileid=' + $('#in-role-edit').data('subprofileid'),
                            type: "GET",
                            success: function (rsp) {
                                // return the results
                                response(rsp);
                            }
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {

                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#in-role-edit').data('id', ui.item.RoleId);
                        $('#in-role-edit').val(ui.item.Name);
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { App.fsmApp.handle('readjust'); },
                    close: function (event, ui) { App.fsmApp.handle('readjust'); }
                }).keyup(function () {
                    $('#in-role-edit').data('id', 0);
                    $('#v-selectrole-edit').hide();
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li></li>").html(item.Name).appendTo(ul);
                };

                $('#in-organisation-edit').autocomplete({
                    appendTo: '#div-acc-organisation-edit',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        // retrieve Master Roles from server
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + "/repo/SearchMasterOrganisations?term=" + request.term + '&subprofileid=' + $('#in-organisation-edit').data('subprofileid'),
                            type: "GET",
                            success: function (rsp) {
                                // return the results
                                response(rsp);
                            }
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {

                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#in-organisation-edit').data('id', ui.item.Id);
                        $('#in-organisation-edit').val(ui.item.Name);
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { App.fsmApp.handle('readjust'); },
                    close: function (event, ui) { App.fsmApp.handle('readjust'); }
                }).keyup(function () {
                    $('#in-organisation-edit').data('id', 0);
                    $('#v-selectorg-edit').hide();
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    var template = $('.organisation-autcomplete-container.t').clone().removeClass('t');

                    template.find('.t-name').html(item.Name);

                    if (item.LogoUrl) template.find('.logo').attr('src', 'https://api.careercontroller.com' + item.LogoUrl);
                    else template.find('.logo').attr('src', 'https://api.careercontroller.com/Content/Images/company-icon-default.png');

                    if (item.WebURL) template.find('.t-website').html(item.WebURL);
                    else template.find('.t-website').hide();

                    if (item.Industry) template.find('.t-industry').html(item.Industry);
                    else template.find('.t-industry').hide();

                    if (item.NumberOfEmployees) template.find('.t-staff').html('<strong>' + 'Staff' + ': </strong> ' + item.NumberOfEmployees);
                    else template.find('.t-staff').hide();

                    if (item.AnnualTurnover) template.find('.t-turnover').html('<strong>' + 'Annual Turnover' + ': </strong> ' + item.AnnualTurnover);
                    else template.find('.t-turnover').hide();

                    return template.appendTo(ul);
                };

                $('#in-location-edit').autocomplete({
                    appendTo: '#div-acc-location-edit',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        var results = [];
                        var thisElement = $("#in-location-edit");

                        var googleOptions = {
                            input: thisElement.val(),
                            types: ['geocode']
                            //componentRestrictions: { country: 'AUS' }
                        };

                        gservice.getPlacePredictions(googleOptions, function (predictions, status) {
                            // reply from google
                            // reset result list because google can reply anytime
                            var googleResults = [];

                            if (status != google.maps.places.PlacesServiceStatus.OK) {
                                // something is wrong. google is not ok
                                return;
                            }

                            predictions.forEach(function (object) {
                                googleResults.push({
                                    id: object.id,
                                    predictionobj: object,
                                    name: object.description,
                                    meta: {
                                        google: true
                                    }
                                });
                            });

                            results = results.concat(googleResults);
                            response(results);
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {
                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#in-location-edit').data('obj', ui.item);
                        $('#in-location-edit').val(ui.item.name);
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { App.fsmApp.handle('readjust'); },
                    close: function (event, ui) { App.fsmApp.handle('readjust'); }
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li></li>").html(item.name).appendTo(ul);
                };

                $('#page-contacts input[name=org]').autocomplete({
                    appendTo: '#div-acc-page-contacts-organisation',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        // retrieve Master Roles from server
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + "/repo/SearchMasterOrganisations?term=" + request.term + '&subprofileid=' + $('#page-contacts input[name=org]').data('subprofileid'),
                            type: "GET",
                            success: function (rsp) {
                                // return the results
                                response(rsp);
                            }
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {

                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#page-contacts input[name=org]').data('id', ui.item.Id);
                        $('#page-contacts input[name=org]').val(ui.item.Name);
                        App.fsmPanel.jvm.JobDetail.JobContact.ContactOrg.IsTempOrg = false;
                        App.fsmPanel.handle('updatecontactdetails');
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { App.fsmApp.handle('readjust'); },
                    close: function (event, ui) { App.fsmApp.handle('readjust'); }
                }).keyup(function () {
                    $('#page-contacts input[name=org]').data('id', 0);
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    var template = $('.organisation-autcomplete-container.t').clone().removeClass('t');

                    template.find('.t-name').html(item.Name);

                    if (item.LogoUrl) template.find('.logo').attr('src', 'https://api.careercontroller.com' + item.LogoUrl);
                    else template.find('.logo').attr('src', 'https://api.careercontroller.com/Content/Images/company-icon-default.png');

                    if (item.WebURL) template.find('.t-website').html(item.WebURL);
                    else template.find('.t-website').hide();

                    if (item.Industry) template.find('.t-industry').html(item.Industry);
                    else template.find('.t-industry').hide();

                    if (item.NumberOfEmployees) template.find('.t-staff').html('<strong>' + 'Staff' + ': </strong> ' + item.NumberOfEmployees);
                    else template.find('.t-staff').hide();

                    if (item.AnnualTurnover) template.find('.t-turnover').html('<strong>' + 'Annual Turnover' + ': </strong> ' + item.AnnualTurnover);
                    else template.find('.t-turnover').hide();

                    return template.appendTo(ul);
                };

                $('#page-contacts input[name=role]').autocomplete({
                    appendTo: '#div-acc-page-contacts-role',
                    delay: 300,
                    minLength: 1,
                    autoFocus: true,
                    source: function (request, response) {
                        // retrieve Master Roles from server
                        $.ajax({
                            headers: { "Authorization": "Bearer " + srvAuth.getAccessToken() },
                            dataType: "json",
                            url: Config.CCApiBase + "/repo/SearchMasterRoles?term=" + request.term + '&subprofileid=' + $('#page-contacts input[name=role]').data('subprofileid'),
                            type: "GET",
                            success: function (rsp) {
                                // return the results
                                response(rsp);
                            }
                        });
                    },
                    focus: function (event, ui) {
                    },
                    change: function (event, ui) {

                    },
                    search: function (event, ui) { },
                    select: function (event, ui) {
                        $('#page-contacts input[name=role]').data('id', ui.item.RoleId);
                        $('#page-contacts input[name=role]').val(ui.item.Name);
                        App.fsmPanel.jvm.JobDetail.JobContact.isTempRole = false;
                        App.fsmPanel.handle('updatecontactdetails');
                        return false;
                    },
                    response: function (event, ui) { },
                    open: function (event, ui) { App.fsmApp.handle('readjust'); },
                    close: function (event, ui) { App.fsmApp.handle('readjust'); }
                }).keyup(function () {
                    $('#page-contacts input[name=role]').data('id', 0);
                    App.fsmPanel.jvm.JobDetail.JobContact.isTempRole = true;
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $("<li></li>").html(item.Name).appendTo(ul);
                };

                $('.toggle-group').on('click', '.toggle', function () {
                    var parent = $(this).parents('.toggle-group');
                    parent.find('.toggle').removeClass('active');
                    $(this).addClass('active');
                });

                $('.demo-2').percentcircle({
                    animate: true,
                    diameter: 55,
                    guage: 3,
                    coverBg: '#fff',
                    bgColor: '#efefef',
                    fillColor: '#ed674e',
                    percentSize: '50px',
                    percentWeight: 'normal'
                });

                $('.demo-3').percentcircle({
                    animate: true,
                    diameter: 55,
                    guage: 3,
                    coverBg: '#fff',
                    bgColor: '#efefef',
                    fillColor: '#ed674e',
                    percentSize: '50px',
                    percentWeight: 'normal'
                });

                var chart = new CanvasJS.Chart("chartContainer",
                    {

                        animationEnabled: true,
                        axisY: {
                            gridThickness: 15
                        },

                        data: [
                            {
                                type: "column", //change type to bar, line, area, pie, etc
                                color: "#cccccc",
                                dataPoints: [
                                    { x: 10, y: 40 },
                                    { x: 20, y: 50 },
                                    { x: 30, y: 60 },
                                    { x: 40, y: 80, color: "#ed674e" },
                                ]
                            }
                        ]
                    });

                chart.render();

                $('#page-endorse').on('click', '.act-endorse-select', function () {
                    var index = $(this).attr('data-id');
                    var requesttype = $(this).attr('data-requesttype');

                    App.fsmPanel.handle('updateendorsementselect', $(this), index, requesttype);
                });

                $('#page-endorse').on('click', '.act-selectcontact', function () {
                    var parent = $(this).parents('.t-empower-contact');
                    var contact = parent.data('obj');
                    contact.selected = !contact.selected;
                    parent.data('obj', contact);

                    if (contact.selected) {
                        parent.addClass('active');
                        parent.find('.btn-text').html('Selected');
                    } else {
                        parent.removeClass('active');
                        parent.find('.btn-text').html('Select');
                    }
                });

                $('#page-endorse').on('click', '.act-chooseskills', function () {
                    App.fsmPanel.handle('chooseskills');
                });

                $('#page-endorse').on('click', '.act-writetestimonial', function () {
                    App.fsmPanel.handle('writetestimonial');
                });

                $('#page-endorse .act-newjobempowerpack').click(function () {
                    App.fsmPanel.handle('newjobempowerpack');
                });

                $(window).resize(function () {
                    App.fsmApp.handle("relayout");
                });

                $(window).load(function () {

                });
            }
        }
    }

    jobsManagement.$inject = ['srvAuth', '$resource'];
    angular.module('SugarCube').directive('jobsManagement', jobsManagement);
}