module SugarCube {
    "use strict";

    export class ctrlEmpowerList {
        public uniqueendorsers: Array<any>;
        public content: Array<any>;
        public basicdetails: IBasicDetails;
        public agencydetails: any;

        public static $inject = ['$resource', '$stateParams', 'apiendpoints', 'srvEmpowerPack', 'srvAuth'];

        constructor(
            private $resource: ng.resource.IResourceService,
            private $stateParams: any,
            apiendpoints: any,
            srvEmpowerPack: any,
            srvAuth: srvAuth
        ) {
            if ($stateParams && $stateParams.accesstoken) {
                srvAuth.setSession({
                    accesstoken: $stateParams.accesstoken
                });
            }

            srvEmpowerPack.getuniqueendorsers().then((uniqueendorsersres) => {
                srvEmpowerPack.getcontent().then((contentres) => {
                    this.uniqueendorsers = uniqueendorsersres;
                });
            });

            this.basicdetails = $resource<IBasicDetails>(apiendpoints.profile + 'BasicDetail').get();
            this.agencydetails = $resource(apiendpoints.account + 'GetAgencyDetails').get({
                hirerprofileid: 79951
            });
        }

        isReferee(endorser: any): boolean {
            return this.uniqueendorsers.find((v) => {
                return v.type = 'endorser' && v.firstname == endorser.firstname && v.lastname == endorser.lastname;
            }) != null;
        }

        getEndorsementCount(endorser: any): number {
            return 1;
        }
    }

    angular.module('gControllers').controller('ctrlEmpowerList', ctrlEmpowerList);
}