module SugarCube {
	export class Config {

		// dev api endpoints
		// SSS
		//public static SSSApiBase: string = 'http://localhost:5000/api/v1';

		// dev frontend endpoints
		// public static RSApiBase: string = 'http://localhost:5020/api';
		// public static CCApiBase: string = 'http://localhost:5020/api';
		// public static CCBase: string = 'http://localhost:3000/';


		// live api endpoints
		public static RSApiBase: string = 'https://api.careercontroller.com/api';
		public static CCApiBase: string = 'https://api.careercontroller.com/api';
		public static CCBase: string = 'https://www.careercontroller.com';
		// live frontend endpoints


		// google
		public static googlemapsapi: string = 'https://maps.googleapis.com/maps/api/js';
		public static googlegeocodeapi: string = 'https://maps.googleapis.com/maps/api/geocode/json';
		public static googledetailsapi: string = 'https://maps.googleapis.com/maps/api/place/details/json';
		public static googleapikey: string = 'AIzaSyAHmkMmLBu3mnEVSN-_OAJfsqCjVoZPtLg';
		public static googleclientid: string = '805785933195-dk639vmovlmvhnajb3kf6f8u3sv5pc1q.apps.googleusercontent.com';

		// SSS
		public static SSSApiBase: string = 'https://api.recruitsolutions.com/api/v1';

		// misc
		public static cacheduration: number = 1800;         // cache duration in the front-end in seconds
		public static cdnbase: string = 'https://dtr2ia9o7j1dl.cloudfront.net';

		constructor() { }
	}
}