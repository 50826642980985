module SugarCube {
    'use strict';

    export function srvHttpInterceptor($q: ng.IQService, $rootScope: ng.IRootScopeService) {
        return {
            'request': function (config) {
                return config;
            },

            'response': function (response) {
                
                // do something on success
                return response || $q.when(response);
            },
            'responseError': function (rejection) {
                
                // broadcast this error
                $rootScope.$broadcast(Events.evtHttpError, rejection);

                return $q.reject(rejection);
            }
        };
    }

    srvHttpInterceptor.$inject = ['$q', '$rootScope'];
}