/// <reference path="timeline.ts" />
/// <reference path="../models/viewport.ts" />

module SugarCube {
    'use strict';

	export class ctrlTimelineContent {
		public streamBottomMargin: number;
		public tileMargin: number;
		public streamHeight: number;
		public streamWidth: number;
		public streamOffset: number;

		public stream: JourneyStreamModel;

		private maxRowCount = 0;
		private rows: JourneyTile[][];
		private rowOffsets: number[];
		private maxHeight: number;

		public config: IViewportConfig;
		public viewport: Viewport;

		constructor() {
			this.maxRowCount = 0;
			this.rows = new Array();
			this.rowOffsets = new Array();
			this.maxHeight = 0;
		}

		public CalculateMaxHeight() {
			for (var i = 0; i < this.stream.tiles.length; i++) {
				this.maxHeight = this.maxHeight > this.stream.tiles[i].meta.tileHeight ? this.maxHeight : this.stream.tiles[i].meta.tileHeight;
			}
		}

		private FindRowOpening(tile: JourneyTile, startRow: number, maxRows: number) {
            var startPx = tile.meta.posX1;
            for (var ro = startRow; ro <= maxRows; ro++) {
                if (this.rowOffsets[ro - 1] < startPx) {
                    return ro;
                }
            }
            return maxRows + 1;
        }

        private CalculateRow(tile: JourneyTile, width: number) {
	        var tileEndPx = tile.meta.posX1 + tile.getTileSize();
            tile.meta.row = this.FindRowOpening(tile, 1, this.maxRowCount);
            if (tile.meta.row > this.maxRowCount) {
                this.maxRowCount = tile.meta.row;
                this.rows[tile.meta.row - 1] = new Array();
                this.rowOffsets[tile.meta.row - 1] = 0;
            }
            this.rows[tile.meta.row - 1].push(tile);
            this.rowOffsets[tile.meta.row - 1] = tileEndPx;
        }

        private UpdateRowOffset(tile: JourneyTile) {
            if (tile.meta.row > this.maxRowCount) {
                this.rowOffsets[tile.meta.row - 1] = 0;
            }
			var tileOffset = tile.meta.posX1 + tile.getTileSize();
	        if (tileOffset > this.rowOffsets[tile.meta.row - 1]) {
		        this.rowOffsets[tile.meta.row - 1] = tileOffset;
	        }
        }

		private ResetRows(): void {
            for (var r = 0, rl = this.rows.length; r < rl; r++) {
                this.rows[r].length = 0;
                this.rowOffsets[r] = 0;
            }
        }

        public UpdateTileSizes() {
            var modelBegin = moment(new Date(this.viewport.dimensions.displayedYearRangeStart, 0, 1));
            for (var i = 0, j = this.stream.tiles.length; i < j; i++) {
                var currentTile = this.stream.tiles[i];
                currentTile.calculatePosX(modelBegin, this.viewport.scaling.yearWidth);
            }
        }

        public UpdateRows() {
			this.streamWidth = this.viewport.scaling.maxWidth;
			var Offset = 0;
			this.ResetRows();
			this.CalculateMaxHeight();

			var rowCount = 0;
			for (var i = 0, j = this.stream.tiles.length; i < j; i++) {
				var currentTile = this.stream.tiles[i];
                var oldRow = currentTile.meta.row;
				this.CalculateRow(currentTile, this.viewport.scaling.yearWidth);
				if (currentTile.meta.row > rowCount) {
					rowCount = currentTile.meta.row;
				}
				var zIndex = this.maxRowCount - (currentTile.meta.row - 1);

                currentTile.calculatePosY(this.maxHeight + this.streamBottomMargin);
				/*var top = CurrentTile.$Tile.css("top").replace('px', '');
				if (typeof (top) == 'string' && top != 'auto' && oldrow !== currentTile.meta.row) {
					//this.createRowTween(CurrentTile, this.getTopFromRow(CurrentTile.row, CurrentStream.maxHeight, this.streamBottomMargin));
				} else {
					currentTile.calculatePosY(this.maxHeight);
					//CurrentTile.$Tile.css("top", this.getTopFromRow(CurrentTile.row, CurrentStream.maxHeight, this.streamBottomMargin));
				}*/

				currentTile.meta.zIndex = zIndex;
				this.UpdateRowOffset(currentTile);
			}
			this.streamHeight = rowCount * (this.maxHeight + this.streamBottomMargin);
		}
	}

	export function dirTimelineContent(): ng.IDirective {

        return {
            restrict: 'EA',
			controller: ctrlTimelineContent,
			controllerAs: 'cStream',
            require: '^^dirTimeline',
			templateUrl: 'partials/timeline/stream.html',
			scope: true,
			bindToController: {
				streamBottomMargin: '=',
				tileMargin: '=',
				stream: '='
			},
            link: {
                pre: (scope: any, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
					scope.cStream.viewport = ctrl.viewport;
					scope.cStream.config = ctrl.config;
					
	                scope.$on(TimelineEvents.evtCJZoomChanged,
						(evt, offset) => {
                            scope.cStream.UpdateTileSizes();
						    scope.$broadcast(TimelineEvents.evtCJTileSizeChanged);
						});
                    scope.$on(TimelineEvents.evtCJTileSizeChanged,
                        (evt, offset) => {
                            scope.cStream.UpdateRows();
                        });
					scope.$on(TimelineEvents.evtCJPanChanged,
						(evt, offset) => {
                            scope.cStream.streamOffset = -(ctrl.viewport.position.posX1 / ctrl.viewport.scaling.factor);
						});
                    var onMouseMove = (evt: JQueryEventObject) => {
	                    if (ctrl.viewport != null) {
		                    if (evt.type === 'touchmove') {
			                    ctrl.onTouchMove(evt, ctrl.viewport.dimensions.streamWidth, -1);
		                    } else {
								ctrl.onPan(evt, ctrl.viewport.dimensions.streamWidth, -1);
		                    }
	                    }
                    }

                    var onMouseDown = (evt: JQueryEventObject) => {
                        ctrl.onMouseDown(evt);

                        $(document).on("mousemove touchmove gesturestart", onMouseMove);
                    }

                    element.on("mousedown touchstart", onMouseDown);
                },
                post: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
                    $(window).trigger('resize');
                }
            }
        };
    }

    dirTimelineContent.$inject = [];
    angular.module('SugarCube').directive('dirTimelineContent', dirTimelineContent);
}