module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirImgNotAvailable(): ng.IDirective {

        return {
            restrict: 'A',
            link: (scope: ng.IScope, element: JQuery) => {
                //element.error((evt) => {
                //    element.attr('src', 'images/img_not_available.png').attr('title', 'This image is not available yet, check back again later.');
                //});
            }
        };
    }

    dirImgNotAvailable.$inject = [];
    angular.module('SugarCube').directive('dirImgNotAvailable', dirImgNotAvailable);
}