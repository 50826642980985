module SugarCube {
    'use strict';

    export interface Resume {
        EnglishName: string;
        benefits: string;
        c1: Object;
        c2: Object;
        categories: Array<number>;
        cvcategory_id: number;
        id: number;
        name: string;
        order: number;
        price: number;
    }
}