module SugarCube {
    'use strict';

    export function infoSalary(
    ): ng.IDirective {
        return {
            restrict: 'EA',
            templateUrl: 'partials/infographics/salary.html',
            scope: {
                high: '=',
                low: '=',
                salary: '='
            },
            link: (scope: any, element: any, attrs: any) => {
                scope.getpercentage = () => {
                    return 100 - (scope.salary - scope.low) / (scope.high - scope.low) * 100;
                }
            }
        }
    }

    infoSalary.$inject = [];
    angular.module('SugarCube').directive('infoSalary', infoSalary);
}