module SugarCube {
    'use strict';

    declare var Stripe: any;

    export function dirPay(
        ngDialog: ng.dialog.IDialogService,
        srvAuth: srvAuth,
        srvMasterData: any,
        $resource: ng.resource.IResourceService,
        apiendpoints: any,
        srvProfile: srvProfile,
        srvAnalytics: any
    ): ng.IDirective {

        class ctrlPay {
            public md: any;
            public loading: boolean;

            public email: string;
            public errorstatus: number;

            public month: number;
            public year: number;
            public cardnumber: string;
            public cvc: string;

            public static $inject = ['productid', 'templateid', '$scope', 'displayprice'];

            constructor(private productid, private templateid, private $scope: ng.dialog.IDialogConfirmScope, public displayprice) {
                this.md = srvMasterData.md;
                this.email = srvAuth.session.email;
            }

            pay() {
                this.loading = true;

                Stripe.card.createToken({
                    number: this.cardnumber,
                    exp_month: this.month + 1,
                    exp_year: this.year,
                    cvc: this.cvc
                }, (status, response) => {
                    this.$scope.$apply(() => {
                        this.errorstatus = status;
                    });

                    if (status == 200) {
                        var token = response.id;

                        $resource(apiendpoints.payment + 'PayViaStripe?token=' + token + '&productId=' + this.productid + '&templateId=' + this.templateid).save((res) => {

                            srvAnalytics.buyevent(this.displayprice);

                            this.$scope.confirm(res);
                        }, (res) => {
                            this.$scope.closeThisDialog();
                        });
                    } else {
                        this.$scope.$apply(() => {
                            this.loading = false;
                        });
                    }
                });
            }
        }

        return {
            restrict: 'A',
            scope: {
                productid: '=',
                templateid: '=?',
                displayprice: '@?',
                onSuccess: '&'
            },
            link: (scope: any, element: JQuery, attrs: any) => {
                element.click(() => {
                    ngDialog.openConfirm({
                        template: 'partials/dialogs/pay.html',
                        className: 'ngdialog-theme-default ngdialog-pay',
                        controller: ctrlPay,
                        controllerAs: 'cPay',
                        resolve: {
                            productid: () => {
                                return scope.productid;
                            },
                            templateid: () => {
                                return scope.templateid;
                            },
                            displayprice: () => {
                                return scope.displayprice;
                            }
                        }
                    }).then((res) => {
                        srvProfile.basicinfo.refresh();

                        scope.onSuccess();
                    }, (res) => {
                    });
                });
            }
        };
    }

    dirPay.$inject = ['ngDialog', 'srvAuth', 'srvMasterData', '$resource', 'apiendpoints', 'srvProfile', 'srvAnalytics'];
    angular.module('SugarCube').directive('dirPay', dirPay);
}