module SugarCube {
    'use strict';

    export class srvMetaData {

        public static $inject = [
            '$resource', 'apiendpoints', '$rootScope'
        ];

        constructor(private $resource: ng.resource.IResourceService, private apiendpoints: any, private $rootScope: ng.IRootScopeService) {
        }

        get(pageId: number) {
            this.$resource(this.apiendpoints.common + 'GetMetaData').get({ pageId: pageId }, (res) => {
                this.$rootScope.$broadcast(Events.evtUpdateMetaData, res);
            });
        }
    }

    angular.module('gServices').service('srvMetaData', srvMetaData);
}