module SugarCube {
    'use strict';

    export function i18n(srvI18N: srvI18N) {
        return function (key) {
            return srvI18N.i18n(key);
        }
    }

    i18n.$inject = ['srvI18N'];
    angular.module('SugarCube').filter('i18n', i18n);
}