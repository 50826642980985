module SugarCube {
    'use strict';

    export function ordinal() {
        return function (input) {
            var lastnumber = input.toString().slice(-1);

            switch (lastnumber) {
                case '1':
                    return input + 'st';

                case '2':
                    return input + 'nd';

                case '3':
                    return input + 'rd';

                default:
                    return input + 'th';
            }
        }
    }
    
    angular.module('SugarCube').filter('ordinal', ordinal);
}