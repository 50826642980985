module SugarCube {
    'use strict';

    export class ctrlPersonalSettings {
        public basicinfo: IBasicInfo;
        public titles: Array<any>;

        public dzcfgUpload: DropzoneOptions;
        public dzevtUpload: DropzoneOptions;
        public dropzone: any;
        public newbackupemail: string;
        public newpassword: string;
        public confirmpassword: string;

        public static $inject = ['srvProfile', 'srvAuth', '$resource', 'ngDialog', '$state'];

        constructor(
            private srvProfile: srvProfile,
            private srvAuth: srvAuth,
            private $resource: ng.resource.IResourceService,
            private ngDialog: ng.dialog.IDialogService,
            private $state: ng.ui.IStateService
        ) {
            this.basicinfo = srvProfile.basicinfo.get();

            this.titles = [
                {
                    id: 1,
                    name: 'Mr'
                },
                {
                    id: 2,
                    name: 'Mrs'
                },
                {
                    id: 3,
                    name: 'Ms'
                }
            ];

            this.dzcfgUpload = {
                maxFilesize: 2,
                acceptedFiles: 'image/*',
                maxFiles: 1,
                url: Config.CCApiBase + '/AccountAPI/UpdateProfileImage',
                autoProcessQueue: true,
                headers: {
                    "Authorization": "Bearer " + srvAuth.getAccessToken()
                },
                addRemoveLinks: true
            }

            this.dzevtUpload = {
                removedfile: function (file) {
                },
                addedfile: function (file) {
                },
				success: function (file, res) {
					console.info(res);
                },
                error: function (file, res, xhr) {
					console.error(res);
				},
                complete: function () {
                }
            }
        }

        addBackupEmail() {
            if (!this.newbackupemail || this.newbackupemail == this.basicinfo.email) return;

            this.basicinfo.backupemails.push(this.newbackupemail);
            this.newbackupemail = null;
        }

        removeBackupEmail(index: number) {
            this.basicinfo.backupemails.splice(index, 1);
        }

        save() {
            this.basicinfo.$save();

            if (this.confirmpassword) {
                this.srvProfile.updatePassword(this.confirmpassword);
            }
        }

        deactivateAccount() {
            this.$resource(Config.CCApiBase + '/AccountAPI/DeactivateAccount').save((res) => {
                this.ngDialog.open({
                    className: 'ngdialog-theme-default dlg-msg',
                    plain: true,
                    template: '<p>Your account has been successfully deactivated. Please log out.</p>'
                }).closePromise.finally(() => {
                    this.$resource(Config.CCApiBase + '/AccountAPI/SignOutUser').get((v) => {
                        this.srvAuth.setSession(v);
                        this.$state.go('home');
                    });
                });
            });
        }
    }

    angular.module('gControllers').controller('ctrlPersonalSettings', ctrlPersonalSettings);
}
