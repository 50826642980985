module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditCoverimg(cfgApp: any, apiendpoints: any, srvAuth: srvAuth): ng.IDirective {

        class ctrlEditCoverImg {
            public coverimgs: Array<string>;
            public cdnurl: string = cfgApp.cdnbase;
            public slide: number = 0;
            public tab: number = 0;
            public coverimg: string;
            public uploading: boolean = true;

            public dzcfgUpload: any;
            public dzevtUpload: any;
            public dropzone: any;

            public static $inject = ['$scope'];

            constructor($scope: ng.IScope) {
                var _ctrl = this;

                this.coverimgs = ["Leaf", "Planet", "Jellyfish", "Scales", "Stigma", "Water", "Poinsettia", "Dew",
                    "Pattern", "Calligraphy", "Droplet", "Alps", "Dandelions", "Pages", "Smoke", "Bark",
                    "Submerged", "Azure", "Crimson", "Gold", "Mural", "Ink", "Shoots", "Polka", "Wave",
                    "Crystallize", "Shear", "Porcelain", "Watercolour", "Glamour", "Splash"];

                this.dzcfgUpload = {
                    maxFileSize: 2,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                    url: apiendpoints.common + 'UploadTempFile',
                    autoProcessQueue: true,
                    headers: {
                        "Authorization": "Bearer " + srvAuth.session.accesstoken
                    },
                    thumbnailWidth: null,
                    thumbnailHeight: null
                }

                this.dzevtUpload = {
                    removedfile: function (file) {
                    },
                    addedfile: function (file) {
                        _ctrl.uploading = true;
                    },
                    success: function (file, res) {
                        $scope.$apply(() => {
                            _ctrl.coverimg = res;
                            _ctrl.uploading = false;
                        });
                    },
                    error: function (file, res, xhr) {
                    },
                    complete: function () {
                    }
                }
            }

            prev() {
                if (this.slide > 0) this.slide--;
            }

            next() {
                if (this.slide < 3) this.slide++;
            }
        }

        return {
            restrict: 'EA',
            templateUrl: 'partials/dialogs/addcoverimg.html',
            controller: ctrlEditCoverImg,
            controllerAs: 'cEditCoverImg',
            bindToController: true,
            scope: {
                coverimg: '='
            }
        };
    }

    dirEditCoverimg.$inject = ['cfgApp', 'apiendpoints', 'srvAuth'];
    angular.module('SugarCube').directive('dirEditCoverimg', dirEditCoverimg);
}