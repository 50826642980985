module SugarCube {
    'use strict';

    export interface IProfileMiscDetails extends ng.resource.IResource<IProfileMiscDetails> {
        displayoption: number;
        displayoffer2: number;
        percentage: number;
        refereesectiondisplayoption: number;
        ProfileLifetimeInSeconds: number;
        chosencvid: number;
    }
}