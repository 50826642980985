/// <reference path="../classes/publicprofile.ts" />
/// <reference path="../components/timeline/models/JourneyModel.ts" />

module SugarCube {
    "use strict";

    export class ExperienceTile extends JourneyTile {
        constructor($q: ng.IQService, template: string, model: any) {
            super($q, template, model);

            this.getStartDate = this.getModelStartDate;
            this.getEndDate = this.getModelEndDate;
        }

        public getIcon(): string {
            var model = this.model as IExperience;
            var icon;
            switch (model.WorkTypeName) {
                case "Full Time":
                    {
                        icon = "/images/cj/icon-fulltime.png";
                        break;
                    }
                case "Contract":
                case "Part Time":
                case "Temporary":
                case "Casual":
                    {
                        icon = "/images/cj/icon-parttime.png";
                        break;
                    }
                case "Volunteer":
                    {
                        icon = "/images/cj/icon-casual.png";
                        break;
                    }
                default:
                    {
                        icon = "/images/cj/icon-casual.png";
                    }
            }
            return icon;
        }

        private getModelStartDate(): moment.Moment {
            var model = this.model as IExperience;
            return moment(model.StartDate);
        }

        private getModelEndDate(): moment.Moment {
            var model = this.model as IExperience;
            if (model.IsCurrentlyEmployed || model.EndDate == null) {
                return moment(new Date()).startOf('day');
            } else { // Some experiences have no end date, because they are ongoing, assume todays date for those.
                return moment(model.EndDate);
            }
        }
    }

    export class EducationTile extends JourneyTile {
        constructor($q: ng.IQService, template: string, model: any) {
            super($q, template, model);

            this.getStartDate = this.getModelStartDate;
            this.getEndDate = this.getModelEndDate;
        }

        private getModelStartDate(): moment.Moment {
            var model = this.model as IEducation;
            return moment(model.StartDate);
        }

        private getModelEndDate(): moment.Moment {
            var model = this.model as IEducation;
            if (model.IsCurrent || model.EndDate == null) {
                return moment(new Date()).startOf('day');
            } else { // Some experiences have no end date, because they are ongoing, assume todays date for those.
                return moment(model.EndDate);
            }
        }
    }

    export class CertificateTile extends JourneyTile {
        constructor($q: ng.IQService, template: string, model: any) {
            super($q, template, model);

            this.getStartDate = this.getModelStartDate;
            this.getEndDate = this.getModelEndDate;
        }

        private getModelStartDate(): moment.Moment {
            var model = this.model as ICertification;
            return moment(model.FromDate);
        }

        private getModelEndDate(): moment.Moment {
            var model = this.model as ICertification;
            if (model.ToDate == null) {
                return moment(new Date()).startOf('day');
            } else { // Some experiences have no end date, because they are ongoing, assume todays date for those.
                return moment(model.ToDate);
            }
        }
    }

    export class SkillModel {
        public skillName: string;
        public startDate: moment.Moment;
        public endDate: moment.Moment;
        public skillLevel: number;
    }

    export class SkillsTile extends JourneyTile {
        constructor($q: ng.IQService, template: string, model: any) {
            super($q, template, model);

            this.getStartDate = this.getModelStartDate;
            this.getEndDate = this.getModelEndDate;
            this.getTileSize = angular.bind(this, this.getSkillsTileSize);
        }

        private getModelStartDate(): moment.Moment {
            var model = this.model as SkillModel;
            return model.startDate;
        }

        private getModelEndDate(): moment.Moment {
            var model = this.model as SkillModel;
            return model.endDate;
        }

        private getSkillsTileSize(): number {
            var left = this.meta.contentWidth + 50 > this.meta.width
                ? this.meta.width
                : this.meta.width / 2 - this.meta.contentWidth / 2 - 10;
            var fullContentSize = left + this.meta.contentWidth;
            if (this.meta.width < fullContentSize) {
                return fullContentSize;
            }
            return this.meta.width;
        }
    }

    export class StoryboardTile extends JourneyTile {
        constructor($q: ng.IQService, template: string, model: any) {
            super($q, template, model);

            this.getStartDate = this.getModelStartDate;
            this.getEndDate = this.getModelEndDate;
        }

        private getModelStartDate(): moment.Moment {
            var model = this.model as IStoryBoard;
            return moment(model.StoryDate);
        }

        private getModelEndDate(): moment.Moment {
            var model = this.model as IStoryBoard;
            return moment(model.StoryDate);
        }

        public getMediaType(): String {
            //TODO This is not a reliable way of detecting the content type on the web, the AttachmentType functionality needs to be restored on the Storyboard model
            switch (this.model.Path.split('.').pop()) {
                case 'gif':
                case 'jpeg':
                case 'jpg':
                case 'png':
                    {
                        return 'image';
                    }
                case 'mp4':
                case 'webm':
                    {
                        return 'video';
                    }
                default:
                    {
                        if (this.model.path != null) {
                            return 'image';
                        } else {
                            return '';
                        }
                    }
            }
        }
    }

    export class ctrlCareerJourney extends PublicProfile {

        public static $inject = ['url', '$resource', 'apiendpoints', 'ngDialog', '$q'];
        private profilePromise: ng.IPromise<any>;
        public model: JourneyModel;
        public loading: boolean = true;

        constructor(url: string, $resource: ng.resource.IResourceService, apiendpoints: any, ngDialog: ng.dialog.IDialogService, $q: ng.IQService) {
            super(url, $resource, apiendpoints, ngDialog, $q);

            this.profileReady.promise.then(() => {
                this.profilePromise = $q.all([
                    this.experiences.$promise, this.educations.$promise, this.certifications.$promise, this.storyboard.$promise]);
                this.profilePromise.then(() => {
                    this.onDataLoaded($q);

                    this.loading = false;
                });
            });
        }

        private createStream(heading: string, tiles: JourneyTile[], $q: ng.IQService): JourneyStreamModel {
            var stream = new JourneyStreamModel(tiles, $q);
            stream.heading = heading;
            stream.id = "stream-" + heading.toLowerCase().replace(/[^-a-z0-9]+/g, "-").replace(/--+/g, '-');
            return stream;
        }

        private findExperienceTiles($q: ng.IQService): JourneyTile[] {
            var experienceTiles: ExperienceTile[] = [];
            for (var i = 0; i < this.experiences.length; i++) {
                var currentExperience = this.experiences[i];
                experienceTiles.push(new ExperienceTile($q, "experience", currentExperience));
            }
            return experienceTiles;
        }

        private findSkillTiles($q: ng.IQService): JourneyTile[] {
            var skillCache: SkillModel[] = [];
            var skillTiles: SkillsTile[] = [];
            for (var i = 0; i < this.experiences.length; i++) {
                var currentExperience = this.experiences[i];
                var startDate = moment(currentExperience.StartDate);
                var endDate;
                if (currentExperience.IsCurrentlyEmployed) {
                    endDate = moment(new Date()).startOf('day');
                } else { // Some experiences have no end date, because they are ongoing, assume todays date for those.
                    endDate = moment(currentExperience.EndDate);
                }
                //Parse experience skills
                for (var j = 0; j < currentExperience.Skills.length; j++) {
                    var currentSkill = currentExperience.Skills[j];
                    if (skillCache.hasOwnProperty(currentSkill.SkillName)) {
                        var tmpTileModel: SkillModel = skillCache[currentSkill.SkillName];
                        //Stretch existing tile to accomodate the new skill start
                        if (startDate < tmpTileModel.startDate) {
                            tmpTileModel.startDate = startDate;
                        }
                        //Stretch existing tile to accomodate the new skill end
                        if (endDate > tmpTileModel.endDate) {
                            tmpTileModel.endDate = endDate;
                        }
                        //Alter existing tile to show the max level achieved
                        if (tmpTileModel.skillLevel < currentSkill.SkillLevelId) {
                            tmpTileModel.skillLevel = currentSkill.SkillLevelId;
                        }
                    } else {
                        var newSkillModel = new SkillModel();
                        newSkillModel.startDate = startDate;
                        newSkillModel.endDate = endDate;
                        newSkillModel.skillName = currentSkill.SkillName;
                        newSkillModel.skillLevel = currentSkill.SkillLevelId;

                        skillTiles.push(new SkillsTile($q, "skills", newSkillModel));
                        skillCache[currentSkill.SkillName] = newSkillModel;
                    }
                }
            }
            return skillTiles;
        }

        private findEducationTiles($q: ng.IQService): JourneyTile[] {
            var tilesFound: JourneyTile[] = new Array();
            for (var i = 0; i < this.certifications.length; i++) {
                var currentCertification = this.certifications[i];
                if (currentCertification.FromDate != null) {
                    tilesFound.push(new CertificateTile($q, "certificate", currentCertification));
                }
            }
            for (var i = 0; i < this.educations.length; i++) {
                var currentEducation = this.educations[i];
                if (currentEducation.StartDate != null) {
                    tilesFound.push(new EducationTile($q, "education", currentEducation));
                }
            }
            return tilesFound;
        }

        private findStoryboardTiles($q: ng.IQService): JourneyTile[] {
            var tilesFound: StoryboardTile[] = new Array();
            for (var i = 0; i < this.storyboard.length; i++) {
                var currentStoryboard = this.storyboard[i];
                tilesFound.push(new StoryboardTile($q, "storyboard", currentStoryboard));
            }
            return tilesFound;
        }

        public onDataLoaded($q: ng.IQService) {
            var streams = [];
            var experienceStream = this.createStream("Experience", this.findExperienceTiles($q), $q);
            streams.push(experienceStream);
            streams.push(this.createStream("Education", this.findEducationTiles($q), $q));
            streams.push(this.createStream("Experience Skills", this.findSkillTiles($q), $q));
            streams.push(this.createStream("Storyboard", this.findStoryboardTiles($q), $q));
            this.model = new JourneyModel(streams, $q);
            this.model.initialTile = experienceStream.lastTile;
        }

    }

    angular.module('gControllers').controller('ctrlCareerJourney', ctrlCareerJourney);
}