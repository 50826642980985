module SugarCube {
    'use strict';

    export class Velocity {
        public static max: number = 280;
        public static friction: number = 0.95;

        public vx: number;
        public vy: number;

        private capVelocityAxis(velocity: number, max: number): number {
            var newVelocity = velocity;
            if (velocity > 0) {
                if (velocity > max) {
                    newVelocity = max;
                }
            } else {
                if (velocity < (0 - max)) {
                    newVelocity = (0 - max);
                }
            }
            return newVelocity;
        }

        public capVelocity(maxX: number, maxY: number): void {
            this.vx = this.capVelocityAxis(this.vx, maxX);
            this.vy = this.capVelocityAxis(this.vy, maxY);
        }

        public decelerateVelocityAxis(velocity, scaling): number {
            return Math.floor(Math.abs(velocity) * scaling) === 0
                ? 0 // is velocity less than 1?
                : velocity * Velocity.friction; // reduce by slowdown


        }

        public decelerateVelocity(scalingX, scalingY): void {
            if (Math.abs(this.vx) > 0) {
                this.vx = this.decelerateVelocityAxis(this.vx, scalingX);
            }
            if (Math.abs(this.vy) > 0) {
                this.vy = this.decelerateVelocityAxis(this.vy, scalingY);
            }
        }
    }
}