module SugarCube {
    export class TimelineEvents {
        // career journey events
		static evtCJViewportChanged: string = 'cj-viewportChanged';
        static evtCJZoomChanged: string = 'cj-zoomChanged';
        static evtCJStreamSizeChanged: string = 'cj-streamSizeChanged';
        static evtCJTileSizeChanged: string = 'cj-tileSizeChanged';
        static evtCJPanChanged: string = 'cj-panChanged';

        constructor() { }
    }
}