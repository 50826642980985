module SugarCube {
    'use strict';

    export function dirZoombar(): ng.IDirective {

        return {
            require: '^^dirTimeline',
            templateUrl: 'partials/timeline/zoombar.html',
            restrict: 'EA',
            link: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {

                var innerScrollbar = element.find(".fill_zoom");

                var onMouseMove = (evt: JQueryEventObject) => {
                    var adjustedscrollbar = element.width() - element.find('.zoom-left').width() - element.find('.zoom-right').width();

                    switch (ctrl.all_input.mode) {
                        case 'zoombar_pan':
                            ctrl.onPan(evt, adjustedscrollbar, 1);
                            break;

                        case 'left_caret':
                            ctrl.onDragZoom(evt, adjustedscrollbar, 1, 0);
                            break;

                        case 'right_caret':
                            ctrl.onDragZoom(evt, adjustedscrollbar, 0, 1);
                            break;
                    }

                    evt.stopPropagation();
                }

                var onMouseDown = (evt: JQueryEventObject) => {
                    ctrl.onMouseDown(evt);

                    ctrl.all_input.mode = 'zoombar_pan';
                    $(document).on("mousemove touchmove", onMouseMove);

                    evt.stopPropagation();
                }

                innerScrollbar.on("mousedown touchstart", onMouseDown);

                var onLeftCaretDown = (evt: JQueryEventObject) => {
                    ctrl.onMouseDown(evt);

                    ctrl.all_input.mode = 'left_caret';
                    $(document).on("mousemove touchmove", onMouseMove);

                    evt.stopPropagation();
                }

                innerScrollbar.children(".zoom-left").on("mousedown touchstart", onLeftCaretDown);

                var onRightCaretDown = (evt: JQueryEventObject) => {
                    ctrl.onMouseDown(evt);

                    ctrl.all_input.mode = 'right_caret';
                    $(document).on("mousemove touchmove", onMouseMove);

                    evt.stopPropagation();
                }

                innerScrollbar.children(".zoom-right").on("mousedown", onRightCaretDown);

                scope.$on(TimelineEvents.evtCJPanChanged, () => {
                    var buttonswidth = element.find('.zoom-left').width() + element.find('.zoom-right').width();
                    var zoombarmaxwidth = element.width() - buttonswidth;

                    var offset = ctrl.viewport.position.posX1 / ctrl.viewport.scaling.maxWidth * zoombarmaxwidth;
                    var width = (ctrl.viewport.position.posX2 - ctrl.viewport.position.posX1) / ctrl.viewport.scaling.maxWidth * zoombarmaxwidth;

                    innerScrollbar.width(Math.floor(width) + buttonswidth);
                    innerScrollbar.css("margin-left", Math.floor(offset));
                });

                //$(".zoom_in").on("click", $.proxy(this.timeline_zoomin, this));
                //$(".zoom_out").on("click", $.proxy(this.timeline_zoomout, this));
            }
        };
    }

    dirZoombar.$inject = [];
    angular.module('SugarCube').directive('dirZoombar', dirZoombar);
}