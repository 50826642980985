module SugarCube {
    "use strict";

    class UpdateToLog {
        userid: any;
        sourceid: number;
    }

    export class ctrlConfirmUpdate {
        //public profile: IBasicDetails;
        public md: any;

        public static $inject = ['srvProfile', 'srvMasterData', '$resource', '$scope', 'ngDialog','userid', '$state'];

        constructor(
            srvProfile: srvProfile,
            srvMasterData: any,
            private $resource: ng.resource.IResourceService,
            private $scope: ng.dialog.IDialogScope,
            ngDialog: ng.dialog.IDialogService,
            public userid: any,
            private $state: ng.ui.IStateService
        ) {
            //insert log

            var insertToLog = new UpdateToLog();
            insertToLog.userid = this.userid;
            insertToLog.sourceid = 1; // 1 means visit this page
            var updatehistorylog = this.$resource(Config.CCApiBase + '/ClassicProfileAPI/InsertRequestAutoEmialLog').save(insertToLog).$promise;
 
            $scope.userid = userid;

            this.$resource<IBasicDetails>(Config.CCApiBase + '/ClassicProfileAPI/BasicDetail', {
                userid: this.userid
            }).get((res) => {
                //this.profile = res;
                $scope.profile = res;

                if (res.isGuest
                    || res.GeoData == null
                    || typeof (res.GeoData) == undefined
                    || res.GeoData.GeoDataId <=0
                    || res.Phone == ''
                    || res.Phone == null
                    || typeof (res.Phone) == undefined) {

                    $scope.fromwhere = 'requestautoemail';

                    ngDialog.open({
                        template: 'partials/dialogs/confirmupdatedetails.html',
                        controller: ctrlConfirmUpdateDetailsDlg,
                        controllerAs: 'cConfirmUpdateDetailsDlg',
                        className: 'ngdialog-theme-default dlg-confirmdetails',
                        showClose: false,
                        closeByEscape: false,
                        scope: this.$scope
                    });
                }
                else
                {
                    this.$state.go('home');
                }
            });

        }
    }

    angular.module('gControllers').controller('ctrlConfirmUpdate', ctrlConfirmUpdate);
}