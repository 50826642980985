module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditCertifications(srvProfile: srvProfile, srvAuth: any, apiendpoints: any, ngDialog: ng.dialog.IDialogService): ng.IDirective {

        class ctrlEditCertifications extends EditDirBase {
            public certifications: ng.resource.IResourceArray<ICertification>;
            public dzcfgUpload: any;
            public dzevtUpload: any;
            public dropzone: any;

            public editingobj: ICertification;

            constructor() {
                super();

                var _self = this;

                this.certifications = srvProfile.certifications.query();

                this.dzcfgUpload = {
                    maxFileSize: 2,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                    url: apiendpoints.common + 'UploadTempFile',
                    autoProcessQueue: true,
                    headers: {
                        "Authorization": "Bearer " + srvAuth.session.accesstoken
                    },
                    thumbnailWidth: null,
                    thumbnailHeight: null
                }

                this.dzevtUpload = {
                    removedfile: function (file) {
                    },
                    addedfile: function (file) {
                    },
                    success: function (file, res) {
                        if (_self.editingobj) _self.editingobj.Path = res;
                    },
                    error: function (file, res, xhr) {
                        console.error(res);
                        alert(res);
                    },
                    complete: function () {
                    }
                }
            }

            editEntry(cert: ICertification) {

                this.editingobj = angular.copy(cert);

                this.editing = true;
                if (this.dropzone) this.dropzone.removeAll();
            }

            setStartMonth(newMonth) {
                if (arguments.length) {
                    this.editingobj.FromDate = moment(this.editingobj.FromDate).month(newMonth).format();
                } else {
                    return moment(this.editingobj.FromDate).month();
                }
            }

            setStartYear(newYear) {
                if (arguments.length) {
                    this.editingobj.FromDate = moment(this.editingobj.FromDate).year(newYear).format();
                } else {
                    return moment(this.editingobj.FromDate).year();
                }
            }

            setEndMonth(newMonth) {
                if (arguments.length) {
                    this.editingobj.ToDate = moment(this.editingobj.ToDate).month(newMonth).format();
                } else {
                    return moment(this.editingobj.ToDate).month();
                }
            }

            setEndYear(newYear) {
                if (arguments.length) {
                    this.editingobj.ToDate = moment(this.editingobj.ToDate).year(newYear).format();
                } else {
                    return moment(this.editingobj.ToDate).year();
                }
            }

            commitEdit() {
                this.saving = true;
                this.editing = false;

                if (this.editingobj.ProfileCertificationId > 0) {
                    (<any>srvProfile.certifications).update(this.editingobj, (res) => {
                        this.certifications.forEach((e, i, a) => {
                            if (e.ProfileCertificationId == this.editingobj.ProfileCertificationId) {
                                a[i] = res;
                                return false;
                            }
                        });

                        this.editingobj = null;
                        this.saving = false;
                    });
                } else {
                    srvProfile.certifications.save(this.editingobj, (res) => {
                        this.certifications.push(res);

                        this.editingobj = null;
                        this.saving = false;
                    });
                }
            }

            deleteEditing() {
                ngDialog.openConfirm({
                    plain: true,
                    template: '<p>Are you sure you wish to delete this entry?</p><p class="text-align-right"><button class="btn-small btn-cancel" ng-click="closeThisDialog()">No</button><button class="btn-small" ng-click="confirm()">Yes</button></p>'
                }).then((res) => {
                    this.saving = true;
                    this.editing = false;

                    srvProfile.certifications.delete({ id: this.editingobj.ProfileCertificationId }, (res) => {
                        this.certifications.forEach((e, i, a) => {
                            if (e.ProfileCertificationId == this.editingobj.ProfileCertificationId) {
                                a.splice(i, 1);
                                return false;
                            }
                        });

                        this.saving = false;
                        this.editingobj = null;
                    });
                });

                
            }

            newEntry() {
                super.newEntry();

                this.editingobj = new srvProfile.certifications();
                this.editingobj.FromDate = moment().format();
                this.editingobj.ToDate = moment().format();
                if (this.dropzone) this.dropzone.removeAll();
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/certifications.html',
            controller: ctrlEditCertifications,
            controllerAs: 'cEditCert'
        };
    }

    dirEditCertifications.$inject = ['srvProfile', 'srvAuth', 'apiendpoints', 'ngDialog'];
    angular.module('SugarCube').directive('dirEditCertifications', dirEditCertifications);
}