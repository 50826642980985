module SugarCube {
    'use strict';

    export class srvAuth {

        public session: any = null;

        public static $inject = [
            '$q', '$resource', 'apiendpoints', '$http', 'ngDialog', '$rootScope', '$timeout', 'localStorageService'
        ];

        constructor(private $q: ng.IQService,
            private $resource: ng.resource.IResourceService,
            private apiendpoints: any,
            private $http: ng.IHttpService,
            private ngDialog: ng.dialog.IDialogService,
            private $rootScope: ng.IRootScopeService,
            private $timeout: ng.ITimeoutService,
            private localStorageService: ng.local.storage.ILocalStorageService) {
        }

        getSession(culturecode: string): ng.IPromise<any> {
            var accesstoken = this.localStorageService.get('accesstoken');
            if (accesstoken) this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + accesstoken;
            else this.$http.defaults.headers.common['Authorization'] = null;
            return this.$resource(this.apiendpoints.auth + 'GetAccessToken').get({ rand: Date.now(), culturecode: culturecode }, (res) => {
                this.setSession(res);
            }, function (res) {
                console.error(res);
            }).$promise;
        }

        getAccessToken(): string {
            return this.session.accesstoken;
        }

        setAccessToken(accesstoken: string) {
            this.session = { accesstoken };
            this.localStorageService.set('accesstoken', accesstoken);
        }

        signin(register: boolean): ng.IPromise<ng.dialog.IDialogClosePromise> {
            return this.ngDialog.open({
                template: 'partials/dialogs/signin.html',
                className: 'ngdialog-theme-default ngdialog-signin',
                controller: 'ctrlSigninDialog as cSigninDialog',
                data: {
                    register: register
                }
            }).closePromise;
        }

        setSession(session: any) {
            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + session.accesstoken;
            this.session = session;
            this.localStorageService.set('accesstoken', session.accesstoken);
            this.$rootScope.$broadcast(Events.evtSetAccessToken);
        }

        clearTokensInLocalStorage() {
            this.localStorageService.clearAll();
        }

        isLoggedIn(): boolean {
            return this.session && !!this.session.profileid;
        }
    }

    angular.module('gServices').service('srvAuth', srvAuth);
}