module SugarCube {
	'use strict';

	export class ctrlJobAlert {

		public basicinfo: IBasicInfo;
		public jobfinders: Array<any>;
		public md: any;
		public matchresults: ITalentMatchResults;
		public tableParams: any;
		public registerdlg: any;
        public suggestedcsc: any;  
		public showedconfirmdetailspopup: boolean;
		public loadingjobs: boolean;

		public searchRole: IRole = <IRole>{};
		public searchSubcatids: Array<number> = [];
		public searchLocation: IGeoData = <IGeoData>{};
		public searchCurrency: any;
		public rate: number;
		public searchRadius: number;
		public hideCategory: boolean = true;

		public static $inject = ['$rootScope', 'srvAuth', '$resource', 'srvMasterData', 'srvProfile', 'ngDialog', 'talentmatchcriteria', '$scope', '$window', 'NgTableParams', 'searchLocationText', 'access', '$state', '$q', 'srvGooglePlaces', 'fromstaffing', 'srvAnalytics', 'apiendpoints', '$timeout', 'work_rights', 'country_of_origin_id', 'drivers_licence_and_own_vehicle', 'public_transport'];

		constructor(
			$rootScope: any,
			private srvAuth: srvAuth,
			private $resource: ng.resource.IResourceService,
			private srvMasterData: any,
			private srvProfile: srvProfile,
			private ngDialog: ng.dialog.IDialogService,
			private talentmatchcriteria: ITalentMatchCriteria,
			private $scope: ng.IScope,
			private $window: ng.IWindowService,
			private NgTableParams: any,
			searchLocationText: string,
			access: string,
			private $state: ng.ui.IStateService,
			private $q: ng.IQService,
			private srvGooglePlaces: any,
			fromstaffing: any,
			private srvAnalytics: any,
            apiendpoints: any,            
			$timeout: any,
			work_rights: any,
			country_of_origin_id: any,
			drivers_licence_and_own_vehicle: any,
			public_transport: any
		) {
			this.md = srvMasterData.md;

			if (access == null || access == '' || access == undefined) {
				if (!this.talentmatchcriteria || !this.talentmatchcriteria.placeId) {
					if (this.isLoggedIn()) {
						this.queryJobFinders();
					}
				} else {
					this.tableParams = new NgTableParams({}, {
						getData: (params) => {
							var pagination = params.url();

							var qbody = angular.extend({}, talentmatchcriteria, {
								page: pagination.page - 1,
								pageSize: pagination.count * 1
							});

							this.loadingjobs = true;

							// ajax request to api
							return $resource<ITalentMatchResults>(Config.SSSApiBase + "/jobsearch/criteria")
								.save(qbody)
								.$promise
								.then((res: ITalentMatchResults) => {

									params.total(res.totalCount); // recal. page nav controls
									this.loadingjobs = false;

									// track empty job searches
									if (res.totalCount == 0) this.srvAnalytics.emptyjobsearchevent();

									// map result
									// TODO: experiment in loading jobs details later, create concrete typescript model when this method is settled
									var resultjobs = res.results.map((v) => {
										return {
											$resolved: false,
											url: v.url.replace('/api/v1', ''),
											id: v.id,
											distance: v.distance,
											scoreCard: v.scoreCard,
											categoryId: v.categoryId,
											subcategoryId: v.subcategoryId,
											level: v.level,
											detail: {}
										};
									});

									// load each job
									resultjobs.forEach((v, i, a) => {
										a[i].detail = this.$resource<any>(Config.SSSApiBase + v.url).get();
									});

									return resultjobs;
								});
						}
					});
				}

				if (this.isLoggedIn()) {
					this.basicinfo = srvProfile.basicinfo.get();
				}

				if (!this.isLoggedIn() && fromstaffing) {
					this.showRegisterPopup();
				}
				// populate default search from parameters
				if (this.talentmatchcriteria) {
					this.searchRadius = this.talentmatchcriteria.distance;
					this.searchRole.RoleName = this.talentmatchcriteria.jobTitle;

					this.searchRole.RoleId = 0;

					//this.$resource(Config.CCApiBase + '/CommonAPI/AutoSuggestRoles?term=' + this.searchRole.RoleName).query((res) => {

					//    if (res.length > 0) {
					//        this.searchRole = res[0].rolevm;
					//    }
					//    else
					//        this.searchRole.RoleId = 0;
					//});

					this.searchSubcatids = this.talentmatchcriteria.subcategoryIds;

					this.searchLocation.description = searchLocationText;
					this.searchLocation.place_id = this.talentmatchcriteria.placeId;
					this.searchLocation.GeoDataId = 0;

					this.talentmatchcriteria.employmentTypeId = this.talentmatchcriteria.employmentTypeId == null || this.talentmatchcriteria.employmentTypeId == 0
						? 1
						: this.talentmatchcriteria.employmentTypeId;
					this.rate = 1;
					this.talentmatchcriteria.distance = this.talentmatchcriteria.distance / 1000;

					if (this.talentmatchcriteria.salary
						&& this.talentmatchcriteria.salary.currencySalary != ""
						&& this.talentmatchcriteria.salary.currencySalary != undefined) {
						// set default currency
						for (var currency in this.md.currencies) {
							if (this.md.currencies[currency].Code == this.talentmatchcriteria.salary.currencySalary) {
								this.searchCurrency = this.md.currencies[currency];
								break;
							}
						}
					}
					else {
						this.searchCurrency = this.md.currencies['1'];
					}

				}

				// handle drag and drop login
				$scope.$on('auth-signedin', () => {

					if (this.registerdlg) ngDialog.close(this.registerdlg);

					//this.showComfirmDetailsPopup();

					this.ngDialog.open({
						template: 'partials/dialogs/resentactivationemail.html',
						className: 'ngdialog-theme-default dlg-msg'
					});

				});

				$scope.$watch(() => {
					return this.suggestedcsc;
				}, () => {
					if (this.suggestedcsc && this.suggestedcsc.length > 0) {
						this.searchSubcatids = [];

						for (var i = 0; i < this.suggestedcsc.length; i++)
							this.searchSubcatids.push(this.suggestedcsc[0].SubClassificationId);
					}
				});

				// if user log in and also use our job finder to search jobs, we need to insert
				// this.insertjobsearchtojobfinder();
			} else {
				var decodedString = atob(access);
				var arr = decodedString.split("xzcvr");

				var objData = this.talentmatchcriteria;
				srvAuth.clearTokensInLocalStorage();

				srvAuth.getSession("en-US").then(() => {
					$resource(apiendpoints.account + 'SignInUser').save({
						username: arr[0],
						password: arr[1],
						rememberme: false
					}, function (res) {
						srvAuth.setSession(res);

						$state.go('culture.jobalert', {
							jobTitle: objData.jobTitle,
							placeId: objData.placeId,
							employmentTypeId: objData.employmentTypeId,
							maxSalary: objData.salary.maxSalary,
							minSalary: objData.salary.minSalary,
							currencySalary: objData.salary.currencySalary,
							subcatids: objData.subcategoryIds,
							location: searchLocationText,
							fromstaffing: false,
							distance: objData.distance * 1000,
							access: null,
							work_rights: objData.work_rights,
							country_of_origin_id: objData.country_of_origin_id,
							drivers_licence_and_own_vehicle: objData.drivers_licence_and_own_vehicle,
							public_transport: objData.public_transport
						});

					}, function (res) {
						$scope.loading = false;
						$scope.errormsg = res.data.Message;
					});
				}, function () {
					//$state.go(toState.name, toParams);
				});
			}

		}

		queryJobFinders() {
			this.srvProfile.jobfinders.query().$promise.then((res) => {
				this.jobfinders = res;

				var firstjobfinder = this.jobfinders[0];

				if (firstjobfinder == null) {
					//this.ngDialog.open({
					//    plain: true,
					//    template: "<p>We can't show you job alerts if you don't have any job finders! Go create one now.</p>",
					//    className: 'ngdialog-theme-default dlg-msg'
					//});

					//this.$state.go('culture.jobfinder.index');
					this.ngDialog.open({
						template: '<home-job-finder class="jobfinder-include"></home-job-finder>',
						className: 'ngdialog-theme-default p-home dlg-signupwithresume',
						//html code
						plain: true
					});

				} else {
					this.tableParams = new this.NgTableParams({}, {
						getData: (params) => {
							this.loadingjobs = true;

							var pagination = params.url();

							// ajax request to api
							return this.$resource<ITalentMatchResults>(Config.SSSApiBase + "/talentmatch", {
								jobPreferenceId: firstjobfinder.id,
								page: pagination.page - 1,
								pageSize: pagination.count * 1
							}).get().$promise.then((res: ITalentMatchResults) => {
								params.total(res.totalCount); // recal. page nav controls
								this.loadingjobs = false;

								// map result
								// TODO: experiment in loading jobs details later, create concrete typescript model when this method is settled
								var resultjobs = res.results.map((v) => {
									return {
										$resolved: false,
										url: v.url.replace('/api/v1', ''),
										id: v.id,
										scoreCard: v.scoreCard,
										categoryId: v.categoryId,
										subcategoryId: v.subcategoryId,
										level: v.level,
										detail: {}
									};
								});

								// load each job
								resultjobs.forEach((v, i, a) => {
									a[i].detail = this.$resource<any>(Config.SSSApiBase + v.url).get();
								});

								return resultjobs;
							});
						}
					});
				}

				return res;
			});
		}

		addKeywords(job: ITalentMatchJob) {
			this.$state.go('culture.profileedit', {
				skills: job.skills
			});
		}

        getJobStatistics(job: ITalentMatchJob) {

            this.ngDialog.open({
                template: 'partials/dialogs/signin.html',
                className: 'ngdialog-theme-default ngdialog-signin',
                controller: 'ctrlSigninDialog as ctrlSigninDialog',
                data: {
                    register: true
                }
            }).closePromise;

			job.compareme = {};

			job.compareme.skillsstats = this.$resource(Config.SSSApiBase + "/rank/skills").get({
				jobid: job.id
			});

			job.compareme.salarystats = this.$resource(Config.SSSApiBase + "/rank/salary").get({
				jobid: job.id
			});

			job.compareme.profilecompleteness = this.$resource(Config.SSSApiBase + "/rank/profile_complete").get({
				jobid: job.id
			});

			job.compareme.expyears = this.$resource(Config.SSSApiBase + "/rank/experience_years").get({
				jobid: job.id
            });          
		}       

		showComfirmDetailsPopup() {
			this.showedconfirmdetailspopup = true;

			this.ngDialog.open({
				template: 'partials/dialogs/confirmdetails.html',
				controller: ctrlConfirmDetailsDlg,
				controllerAs: 'cConfirmDetailsDlg',
				className: 'ngdialog-theme-default dlg-confirmdetails'
			}).closePromise.then((res) => {
				this.queryJobFinders();
			});
		}

        showRegisterPopup() {
            this.registerdlg = this.ngDialog.open({
                template: 'partials/dialogs/dndorrrb.html',
                className: 'ngdialog-theme-default dlg-dndorrrb'
			}).closePromise;
		}

		applyForJob(job: ITalentMatchJob) {
			if (!this.isLoggedIn()) {
				this.showRegisterPopup();

				return;
			}

			if (!this.checkActivation()) return;
			if (!this.checkProfileCompleteness()) return;

			if (this.basicinfo.endorsementcount < 2) this.showGetEndorsedPopup();

			if (job.external)
				this.$window.open(job.url, "_blank");

			this.ngDialog.open({
				plain: true,
				template: "<p>Congratulations! You have applied for the job, it will appear in your Jobs Management section. No apply for some more jobs!</p>",
				className: 'ngdialog-theme-default dlg-msg'
			});

			this.$resource(Config.CCApiBase + '/repo/ApplyForJob').save(job.id, (res) => {
				job.hasApplied = true;
			});
		}

		checkProfileCompleteness(): boolean {
			if (!this.basicinfo) return false;

			if (this.basicinfo.profilecompleteness < 85) {
				this.showResumePreviewPopup();

				return false;
			}

			return true;
		}

		checkActivation(): boolean {
			if (!this.basicinfo || !this.basicinfo.isactivated) {
				this.ngDialog.open({
					template: 'partials/dialogs/resentactivationemail.html',
					className: 'ngdialog-theme-default dlg-msg'
				});

				// resent activation email
				this.$resource(Config.CCApiBase + '/AccountAPI/ResentActivationEmail').get();

				return false;
			}

			return true;
		}

		showGetEndorsedPopup() {
			this.ngDialog.open({
				template: 'partials/dialogs/getendorsed.html',
				className: 'ngdialog-theme-default dlg-removewatermark dlg-getendorsed',
				controller: ['$scope', '$sce', ($scope, $sce) => {
					$scope.accesstoken = this.srvAuth.getAccessToken();
					$scope.apidomain = Config.CCApiBase.substr(0, Config.CCApiBase.indexOf('/api'));

					$scope.panel = this.$resource(Config.CCApiBase + '/DashboardAPI/GetDetails?id=1').get();

					$scope.session = this.srvAuth.session;

					$scope.trustAsResource = (url) => {
						return $sce.trustAsResourceUrl(url);
					}
				}]
			});
		}

        showResumePreviewPopup() {
			this.ngDialog.open({
				template: 'partials/dialogs/removewatermark.html',
				className: 'ngdialog-theme-default dlg-removewatermark',
				controller: ['$scope', '$sce', ($scope, $sce) => {
					$scope.accesstoken = this.srvAuth.getAccessToken();
					$scope.apidomain = Config.CCApiBase.substr(0, Config.CCApiBase.lastIndexOf('/api'));

					$scope.panel = this.$resource(Config.CCApiBase + '/DashboardAPI/GetDetails?id=1').get();

                    $scope.session = this.srvAuth.session;
                    $scope.resumeUrl = $sce.trustAsResourceUrl($scope.apidomain + '/en-US/ResumePreview/' + $scope.accesstoken + '/Parallel/?rand=' + $scope.rand)
				}]
			});
		}

		isLoggedIn(): boolean {
			return this.srvAuth.isLoggedIn();
		}

        
		saveJobSearch() {
			if (this.srvAuth.isLoggedIn()) {
				// create new job finder
				this.$resource(Config.CCApiBase + '/CommonAPI/AutoSuggestRoles?term=' + this.searchRole.RoleName).query((res) => {

					if (res.length > 0)
						this.searchRole = res[0].rolevm;
					else
						this.searchRole.RoleId = 0;
				}).$promise
					.then((res) => {
						var newjf = {
							id: 0,
							role: {
								Name: this.searchRole.RoleName,
								RoleId: this.searchRole.RoleId
							},
							salaryrange: {
								max: this.talentmatchcriteria.salary.maxSalary / 1000,
								min: this.talentmatchcriteria.salary.minSalary / 1000
							},
							salarytype: 'annual',
							subcategoryids: this.searchSubcatids,
							location: null,
							radius: this.searchRadius / 1000
						};

						var googleplacedetail = null;
						var promise1 = this.srvGooglePlaces.getPlaceDetails(this.searchLocation.place_id).then((res) => {
							newjf.location = res;
							return res;
						});

						this.$q.all([promise1]).then(() => {
							this.$resource(Config.CCApiBase + '/Repo/SaveJobFinderPreferences').save(newjf, (res) => {
							});
						})
					});


			} else {
				this.showRegisterPopup();
			}
		}

		search() {
			this.$state.go('culture.jobalert', {
				jobTitle: this.searchRole.RoleName,
				placeId: this.searchLocation.place_id,
				employmentTypeId: this.talentmatchcriteria.employmentTypeId,
				subcatids: this.searchSubcatids.join(),
				currencySalary: this.searchCurrency.Code,
				maxSalary: this.talentmatchcriteria.salary.maxSalary,
				minSalary: this.talentmatchcriteria.salary.minSalary,
				location: this.searchLocation.description,
				distance: this.talentmatchcriteria.distance * 1000
			});
		}

		getdisplayvalueofradiusslider(value) {
			return value + 'km';
		}

		insertjobsearchtojobfinder() {
			// insert to job Finder table
			if (this.srvAuth.isLoggedIn()
				&& this.talentmatchcriteria.jobTitle != null
				&& this.talentmatchcriteria.salary != null
				&& this.talentmatchcriteria.placeId != null) {
				this.saveJobSearch();
			}
		}

		deletecat($index) {
			if (this.searchSubcatids.length > 1) {
				this.searchSubcatids.splice($index, 1);
			} else {
				alert('At least one category and one subcategory are required');
			}
		}

		showCategoryDescription(level, $index, job) {
			if (level == 2 && this.hideCategory) {
				this.hideCategory = false;
				return true
			}
			return false;
		}
	}

	angular.module('gControllers').controller('ctrlJobAlert', ctrlJobAlert);
}