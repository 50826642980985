module SugarCube {
    'use strict';

    export class PublicProfile {
        public basicdetails: ng.resource.IResource<IBasicDetails>;
        public experiences: ng.resource.IResourceArray<IExperience>;
        public educations: ng.resource.IResourceArray<IEducation>;
        public skills: ng.resource.IResourceArray<ISkill>;
        public languages: ng.resource.IResourceArray<ILanguage>;
        public storyboard: ng.resource.IResourceArray<IStoryBoard>;
        public certifications: ng.resource.IResourceArray<ICertification>;
        public interests: ng.resource.IResourceArray<IInterest>;
        public referees: ng.resource.IResourceArray<IReferee>;
        public sociallinks: ng.resource.IResourceArray<ISocialMediaLink>;
		public profileReady: ng.IDeferred<any>;

        constructor(url: string, $resource: ng.resource.IResourceService, apiendpoints: any, private ngDialog: ng.dialog.IDialogService, $q: ng.IQService) {
	        this.profileReady = $q.defer();
            var checkCulture = $resource(apiendpoints.classicprofile + 'CheckCulture').get({ url: url }, () => {
                this.basicdetails = $resource<any>(apiendpoints.classicprofile + 'BasicDetail').get({ url: url });
                this.experiences = $resource<any>(apiendpoints.classicprofile + 'Experience').query({ url: url });
                this.educations = $resource<any>(apiendpoints.classicprofile + 'Education').query({ url: url });
                this.skills = $resource<any>(apiendpoints.classicprofile + 'GeneralSkill').query({ url: url });
                this.languages = $resource<any>(apiendpoints.classicprofile + 'Language').query({ url: url });
                this.storyboard = $resource<any>(apiendpoints.classicprofile + 'Storyboard').query({ url: url });
                this.certifications = $resource<any>(apiendpoints.classicprofile + 'Certification').query({ url: url });
                this.interests = $resource<any>(apiendpoints.classicprofile + 'Interest').query({ url: url });
                this.sociallinks = $resource<any>(apiendpoints.classicprofile + 'SocialMediaLink').query({ url: url });
                this.referees = $resource<any>(apiendpoints.classicprofile + 'Referee').query({ url: url });
	            this.profileReady.resolve();
            });
        }

        getDisplayDuration(startdate: string, enddate: string): string {
            var mstart = moment(startdate);
            var mend = moment(enddate);
            var dur = moment.duration(mend.diff(mstart)).add(1, 'month');

            return dur.years() + 'y' + ' ' + dur.months() + 'm';
        }

        getWorkTypeIcon(worktypeid: number): string {
            if (worktypeid == 1) return 'images/status-full.png';
            else return 'images/status-casual.png';
        }

        hasTeamMgmt(exp: IExperience): boolean {
            if (!exp.TeamManagement) return false;

            if (!exp.TeamManagement.hasExpenditureResponsibility &&
                !exp.TeamManagement.hasRevenueResponsibility &&
                exp.TeamManagement.ReportedToTypeId <= 0,
                !exp.TeamManagement.hasManagedTeam &&
                exp.TeamManagement.NumberOfStaffManaged <= 0 &&
                exp.TeamManagement.TeamTypeManaged <= 0 &&
                exp.TeamManagement.TeamLocationTypeManaged <= 0) return false;

            return true;
        }

        getEduStatusId(edu: IEducation): number {
            if (edu.IsCurrent && !edu.IsGraduate) return 1;
            if (!edu.IsCurrent && edu.IsGraduate) return 2;
            if (!edu.IsCurrent && !edu.IsGraduate) return 3;

            return 1;
        }

        getEduDisplayStatus(edu: IEducation): string {
            switch (this.getEduStatusId(edu)) {
                case 3: return 'Incomplete';
                case 2: return 'Completed';
                default: return 'Currently Studying';
            }
        }

        showVideo() {
            this.ngDialog.open({
                template: '<iframe ng-src="{{trustAsResource(videosrc)}}" frameborder="0"></iframe>',
                plain: true,
                className: 'ngdialog-theme-default ngdialog-iframe',
                controller: ['$scope', '$sce', ($scope, $sce) => {
                    $scope.videosrc = (<any>this.basicdetails).VideoIntroPath;

                    $scope.trustAsResource = function (url) {
                        return $sce.trustAsResourceUrl(url);
                    }
                }]
            });
        }
    }
}