module SugarCube {
    'use strict';

    export function nav(
        $rootScope: ng.IRootScopeService,
        srvAuth: srvAuth,
        apiendpoints,
        $timeout: ng.ITimeoutService,
        $state: ng.ui.IStateService,
        $window: ng.IWindowService,
        $document: ng.IDocumentService,
        srvI18N: srvI18N,
        $http: ng.IHttpService,
        $resource: ng.resource.IResourceService,
        ngDialog: ng.dialog.IDialogService,
        cfgApp,
        srvProfile: srvProfile
    ): ng.IDirective {

        interface INavScope extends ng.IScope {
            navloading: boolean;
            sharenav: boolean;
            languages: Array<string>;
            session: any;
            culture: string;
            miscdetails: IProfileMiscDetails;
            isloggedin: Function;
            signin: Function;
            signup: Function;
            closefixedmenu: Function;
            openfixedmenu: Function;
            opened: boolean;
            uploadresume: Function;
            changelang: Function;
            showprofileversions: boolean;
            subprofiles: Array<ISubProfile>;
            showfeedback: boolean;
            onSubmitFeedback: Function;
            feedbacksubmitted: boolean;
            feedbacktitle: string;
            feedbackcontent: string;
            showform: Function;

        }

        return {
            restrict: 'E',
            link: (scope: INavScope, element, attrs) => {
                var home = false;
                scope.navloading = true;

                scope.subprofiles = [];

                scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
                    home = toState.name == 'home';
                    scope.showprofileversions = toState.name == 'culture.profileedit';

                    if (home) {
                        element.addClass('transparent home');
                    } else {
                        element.removeClass('transparent home');
                    }

                    scope.sharenav = $state.current.data && $state.current.data.sharenav;

                    if (srvAuth.isLoggedIn() && toState.name != 'culture.logout')
                        scope.subprofiles = srvProfile.subprofiles.query((res: Array<ISubProfile>) => {
                            return res.filter((v) => {
                                return !v.isarchived;
                            });
                        });
                });

                scope.languages = ['...'];

                scope.session = srvAuth.session;
                scope.$watch(function () {
                    return srvAuth.session
                }, function (newvalue) {
                    scope.session = newvalue

                    if (newvalue && newvalue.accesstoken) {
                        srvI18N.loadtranslations(['nav']).then(function () { scope.navloading = false; });

                        scope.culture = srvAuth.session.displayculturecode;

                        $resource(apiendpoints.common + 'GetMasterData').save({
                            masterdatatypeid: "languages"
                        }, function (res) {
                            scope.languages = res.languages;
                        });
                    }

                    if (newvalue && newvalue.profileid) {
                        scope.miscdetails = srvProfile.miscdetails.get();
                    }
                });

                scope.isloggedin = () => {
                    return scope.session && !!scope.session.profileid;
                }

                scope.signin = () => {
                    srvAuth.signin(false);
                }

                scope.signup = function () {
                    srvAuth.signin(true);
                }

                scope.closefixedmenu = function () {
                    scope.opened = false;
                }

                scope.openfixedmenu = function () {
                    scope.opened = true;
                }

                var scroll = function () {
                    if ($window.pageYOffset > 0) {
                        element.removeClass('transparent');
                    } else if (home) {
                        element.addClass('transparent');
                    }
                }
                scroll();

                angular.element($window).bind('scroll', function () {
                    scroll();
                });

                $document.on('click', function (e) {
                    scope.closefixedmenu();

                    scope.$apply();
                });

                element.on('click', function (e) {
                    e.stopImmediatePropagation();
                });

                element.on('click', 'a[href]', function (e) {
                    window.location.href = $(this).attr('href');
                });

                scope.changelang = (lang) => {
                    var paths = window.location.pathname.split('/');
                    paths[1] = lang.CultureCode;

                    window.location.href = paths.join('/');
                }

                scope.uploadresume = () => {
                    var dlg = ngDialog.open({
                        template: 'partials/dialogs/uploadresume.html',
                        className: 'ngdialog-theme-default dlg-uploadresume',
                        controller: 'ctrlRegister'
                    }).id;

                    scope.$on('auth-signedin', () => {
                        ngDialog.close(dlg);
                    });
                }

                scope.showform = () => {
                    scope.showfeedback = !scope.showfeedback;
                    event.stopPropagation();
                }
                window.onclick = function () {
                    if (scope.showfeedback) {
                        scope.showfeedback = false;

                        // You should let angular know about the update that you have made, so that it can refresh the UI
                        scope.$apply();
                    }
                };

                scope.onSubmitFeedback = () => {
                    scope.feedbacksubmitted = true;

                    $resource(Config.CCApiBase + '/ProfileAPI/SubmitFeedback').save({
                        feedbacktitle: scope.feedbacktitle,
                        feedbackcontent: scope.feedbackcontent
                    }, () => {
                        scope.feedbacktitle = '';
                        scope.feedbackcontent = '';
                    });
                }

               
            }
        }
    }

    nav.$inject = ['$rootScope', 'srvAuth', 'apiendpoints', '$timeout', '$state', '$window', '$document', 'srvI18N', '$http', '$resource', 'ngDialog', 'cfgApp', 'srvProfile'];
    angular.module('SugarCube').directive('nav', nav);
}