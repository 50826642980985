module SugarCube {
    "use strict";

    export class ctrlConfirmDetailsDlg {
        public profile: IBasicDetails;
        public md: any;
        public googleAddresses: any = (new utilGoogleAddressPicker('US')).getTypeAheadDataSet();
        public newpassword: string;
        public confirmpassword: string;

        public static $inject = ['srvProfile', 'srvMasterData', '$resource', 'apiendpoints', 'srvGooglePlaces', '$scope', '$q'];

        constructor(
            srvProfile: srvProfile,
            srvMasterData: any,
            private $resource: ng.resource.IResourceService,
            private apiendpoints: any,
            private srvGooglePlaces: any,
            private $scope: ng.dialog.IDialogScope,
            private $q: ng.IQService
        ) {

            this.profile = srvProfile.basicdetails.get();
            this.md = srvMasterData.md;
        }

        submit() {

            if (this.profile.isGuest
                && (this.newpassword != this.confirmpassword
                || this.newpassword == undefined
                || this.confirmpassword == undefined)) return;

            var geodataid: number = 0;

            var updatedetailpromise = this.$resource(Config.CCApiBase + '/ProfileAPI/BasicDetail').save(this.profile).$promise;

            var updatepasswordpromise = null;


            if (this.profile.isGuest && this.newpassword) {
                updatepasswordpromise = this.$resource(Config.CCApiBase + '/ProfileAPI/UpdatePassword').save('"' + this.newpassword + '"').$promise;
            } else {
                updatepasswordpromise = this.$q.when('');
            }

            this.$q.all([updatedetailpromise, updatepasswordpromise]).then(() => {
                this.$scope.closeThisDialog();
            });
        }
    }

    angular.module('gControllers').controller('ctrlConfirmDetailsDlg', ctrlConfirmDetailsDlg);
}