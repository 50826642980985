module SugarCube {
    'use strict';

    export interface IMasterSocialMediaLink {
        MediaLinkTypeId: number;
        Name: string;
    }

    export interface ISocialMediaLink extends ng.resource.IResource<ISocialMediaLink> {
        ProfileSocialMediaLinkId: number;
        MediaLinkType: IMasterSocialMediaLink;
        SocialMediaLink: string;
    }
}