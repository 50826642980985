module SugarCube {
    'use strict';

	export class ctrlTimelineTile {
		public selectTile: JourneyTile;
		public template: string;

		private $compile: ng.ICompileService;
		private $http: ng.IHttpService;
		private $q: ng.IQService;
        private $scope: ng.IScope;

		static viewCache: ng.IDeferred<string>[] = [];

		static $inject = ['$compile', '$http', '$q', '$scope'];

		constructor($compile, $http, $q, $scope) {
			this.$compile = $compile;
			this.$http = $http;
            this.$q = $q;
            this.$scope = $scope;    
		}

		public setView(element, htmlview, scope) {
			element.html(htmlview).show();
			this.$compile(element.contents())(scope);
		}

        public getTileDimensions(element) {
            var $tileContent = element.find(".tile-content");
            this.selectTile.meta.contentLeft = $tileContent.position().left;
            this.selectTile.meta.contentWidth = $tileContent.width();
            this.selectTile.meta.contentHeight = $tileContent.height();
            this.selectTile.meta.tileHeight = element.children(".tile-container").outerHeight();
        }

		public setTemplate(): ng.IPromise<string> {
            if (this.selectTile.meta.template !== null) {
                if (!ctrlTimelineTile.viewCache.hasOwnProperty(this.selectTile.meta.template)) {
					var self = this;
                    ctrlTimelineTile.viewCache[this.selectTile.meta.template] = this.$q.defer<string>();
                    this.$http.get("/partials/journey/tiles/" + this.selectTile.meta.template + ".html")
						.then(function(response) {
                            ctrlTimelineTile.viewCache[self.selectTile.meta.template].resolve(response.data);
						});
				}
                return ctrlTimelineTile.viewCache[this.selectTile.meta.template].promise;
			}
			return null;
        }

        private getYearPercentage(currentDate: moment.Moment): number {
            var currentYear = moment(new Date(currentDate.year(), 0, 1)),
                nextYear = moment(new Date(currentDate.year() + 1, 0, 1));

            var daysSinceStartYear = Math.floor(moment.duration(moment(currentDate).diff(moment(currentYear))).asDays());
            var totalDaysInYear = moment.duration(moment(nextYear).diff(moment(currentYear))).asDays();

            return daysSinceStartYear / totalDaysInYear;
        }
	}

	export function dirTimelineTile(): ng.IDirective {
        return {
            restrict: 'EA',
            require: ['^^dirTimeline'],
			controller: ctrlTimelineTile,
			controllerAs: 'tileCtrl',
			scope: true,
			bindToController: {
                selectTile: '='
			},
            link: {
                pre: (scope: any, element: JQuery, attrs: any, ctrl: ctrlTimelineTile) => {
	                var self = this;
                    var promise = scope.tileCtrl.setTemplate(element, scope);
					if(promise !== null)
	                promise.then(function(htmlView) {
		                scope.tileCtrl.setView(element, htmlView, scope);
	                    scope.tileCtrl.getTileDimensions(element);
                        scope.tileCtrl.selectTile.meta.ready.resolve();
                        });
                },
                post: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimelineTile) => {
                    //
                }
            }
        };
    }

    dirTimelineTile.$inject = [];
    angular.module('SugarCube').directive('dirTimelineTile', dirTimelineTile);
}