module SugarCube {
    'use strict';

    export function infoRatings(
    ): ng.IDirective {
        return {
            restrict: 'EA',
            templateUrl: 'partials/infographics/ratings.html',
            link: (scope: ng.IScope, element: any, attrs: any) => {

            }
        }
    }

    infoRatings.$inject = [];
    angular.module('SugarCube').directive('infoRatings', infoRatings);
}