module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirLoginWithFacebook($window: ILoginWithFacebookWindowService,
        $resource: ng.resource.IResourceService,
        apiendpoints: any,
        $state: ng.ui.IStateService,
        ngDialog: ng.dialog.IDialogService,
        srvAuth: srvAuth,
        $rootScope: ng.IRootScopeService): ng.IDirective {

        interface ILoginWithFacebookScope extends ng.IScope {
            cvid: number;
        }

        return {
            restrict: 'A',
            scope: {
                cvid: '=?'
            },
            link: function (scope: ILoginWithFacebookScope, element: JQuery, attrs: any) {
                // This function is called when someone finishes with the Login Button. 
                $window.checkLoginState = function () {
                    $window.FB.getLoginStatus(function (response) {
                        statusChangeCallbackAfterLogin(response);
                    });
                }

                var statusChangeCallbackAfterLogin = function (response) {
                    // The response object is returned with a status field that lets the app know the current login status of the person.
                    if (response.status === 'connected') {
                        // Logged into your app and Facebook.
                        loginWithBasicDetails();
                    }
                }

                // This is called with the results from from FB.getLoginStatus().
                var statusChangeCallback = function (response) {
                    // The response object is returned with a status field that lets the app know the current login status of the person.
                    if (response.status === 'connected') {
                        loginWithBasicDetails();
                    }
                }

                var loginWithBasicDetails = function () {
                    // Logged into your app and Facebook.
                    $window.FB.api('/me/permissions', 'GET', function (response) {
                    });

                    $window.FB.api('/me/',
                        'GET',
                        {
                            "fields": "id,name,picture,email,age_range,cover,currency,devices,first_name,middle_name,last_name,gender,hometown,languages,link,locale,location,religion,relationship_status,security_settings,timezone,education,work,website,name_format"
                        },
                        function (fbresponse) {
                            // retrieved details
                            $resource(apiendpoints.account + 'SignInWithFacebook', { cvid: scope.cvid }, {
                                'save': {
                                    method: "POST",
                                    interceptor: {
                                        response: function (res: any) {
                                            if (res.status == 201) {
                                                srvAuth.setSession(res.data.access);

                                                $rootScope.$broadcast(Events.evtSignedIn, { to: 'rrb', payload: fbresponse, cvid: scope.cvid });
                                            } else return res;
                                        }
                                    }
                                }
                            }).save(fbresponse, (res) => {
                                if (res) {
                                    srvAuth.setSession(res.data.access);

                                    $rootScope.$broadcast(Events.evtSignedIn, { to: 'rrb', payload: fbresponse, cvid: scope.cvid });
                                }
                            }, (res) => {
                                switch (res.status) {
                                    case 409:
                                        ngDialog.open({
                                            template: '<p>Sorry! You cannot log in with Facebook because you already have an account in CareerController. Try signing in instead.</p>',
                                            plain: true,
                                            className: 'ngdialog-theme-default dlg-msg'
                                        });
                                        break;

                                    case 400:
                                        ngDialog.open({
                                            template: '<p>Sorry! You cannot log in with Facebook because there is no email address associated with it. Try another account.</p>',
                                            plain: true,
                                            className: 'ngdialog-theme-default dlg-msg'
                                        });
                                        break;
                                }
                            });
                        }
                    );
                }

                $window.fbAsyncInit = function () {
                    $window.FB.init({
                        appId: '540038882801656', // Careercontrollerstaging: 537067686465695 or Careercontroller: 540038882801656
                        cookie: true,  // enable cookies to allow the server to access the session
                        xfbml: true,  // parse social plugins on this page
                        version: 'v2.5' // use graph api version 2.5
                    });

                    $window.FB.getLoginStatus(function (response) {
                        statusChangeCallback(response);
                    });
                }

                element.click(() => {
                    $window.FB.login((response) => {
                        if (response.authResponse) {
							console.info('Welcome!  Fetching your information.... ');

                            loginWithBasicDetails();

                        }
                    }, { scope: 'public_profile,email,user_about_me,user_location,user_work_history,user_location,user_hometown' });
                });
            }
        };
    }

    interface ILoginWithFacebookWindowService extends ng.IWindowService {
        fbAsyncInit: any;
        FB: any;
        checkLoginState: any;
        fblogin: any;
    }

    dirLoginWithFacebook.$inject = ['$window', '$resource', 'apiendpoints', '$state', 'ngDialog', 'srvAuth', '$rootScope'];
    angular.module('SugarCube').directive('dirLoginWithFacebook', dirLoginWithFacebook);
}