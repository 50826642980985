module SugarCube {
    'use strict';

    export interface ISignUpWithResumeModel extends ng.resource.IResource<ISignUpWithResumeModel> {
        firstname: string;
        lastname: string;
        email: string; 
        password: string;
        comfirmpassword: string;
        addressunit: string;
        geodata: IGeoData;
        countryid: number;
        phone: string;
        personalbackupemail: string;
    }

    
}