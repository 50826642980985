module SugarCube {
    'use strict';

    export interface IScoreCardTagMatch {
        tagCodeName: string;
        description: string;
    }

    export interface ITalentMatchScoreCard {
        score: number;
        items: Array<IScoreCardTagMatch>;
    }

    export interface ITalentMatchJob {
        id: string;
        roleName: string;
        adTitle: string;
        organisationName: string;
        industry: string;
        createdDate: string;
        salary: ISalary;
        address: string;
        distance: number;
        score: number;
        employmentTypeId: number;
        hasApplied: boolean;
        hasSaved: boolean;
        url: string;
        external: boolean;
        skills: Array<string>;

        // compare me stats
        compareme: any;
    }

    export interface ITalentMatchResults extends ng.resource.IResource<ITalentMatchResults> {
        totalCount: number;
        jobs: Array<ITalentMatchJob>;
        results: Array<any>;
    }

    interface ISalary {
        minSalary: number;
        maxSalary: number;
        currencySalary: string; AutoSuggestRoles
    }

    export interface ITalentMatchCriteria {
        salary: ISalary;
        employmentTypeId: number;
        jobTitle: string;
        placeId: string;
        subcategoryIds: Array<number>;
		distance: number;
		work_rights: number;
		country_of_origin_id: number;
		drivers_licence_and_own_vehicle: number;
		public_transport: number;
    }
}