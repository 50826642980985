module SugarCube {
    'use strict';

    export interface ICertification extends ng.resource.IResource<ICertification> {
        ProfileCertificationId: number;
        IssuerName: string;
        CertificationName: string;
        IssuerURL: string;
        CertificationNumber: string;
        FromDate: string;
        ToDate: string;
        CertificateExpires: boolean;
        Path: string;
    }
}