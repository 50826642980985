/// <reference path="../../models/sharelinks.ts" />

module SugarCube {
    "use strict";

    export class ctrlNewJobGuaranteeDlg {
        public static $inject = ['ngDialog'];

        constructor(private ngDialog: ng.dialog.IDialogService) {
        }

        purchaseSuccess() {
            this.ngDialog.open({
                className: 'ngdialog-theme-default dlg-jobguarantee',
                template: 'partials/dialogs/jobguaranteepurchased.html'
            });
        }
    }

    angular.module('gControllers').controller('ctrlNewJobGuaranteeDlg', ctrlNewJobGuaranteeDlg);
}