/// <reference path="../../../controllers/careerjourney.ts" />

module SugarCube {
    'use strict';    
    export interface ILogInfo {
        type: string;
    }

    export interface IEventLogInfo extends ILogInfo {
        event: string;
    }

    export interface IHandlerLogInfo extends ILogInfo {
        handler: string;
    }

    export interface IPosCoordsLogInfo extends ILogInfo {
        x: number;
        y: number;
    }

    export interface IEventCoordsLogInfo extends ILogInfo {
        x: number;
        y: number;
    }

    export interface IDeltaCoordsLogInfo extends ILogInfo {
        dX: number;
        dY: number;
    }

    export interface IVelocityLogInfo extends ILogInfo {
        velocity: Velocity
    }

    class DebugModel {
        event: string;
        handler: string;
        x: number;
        y: number;
        eX: number;
        eY: number;
        dX: number;
        dY: number;
        velocityX: number;
        velocityY: number;

        constructor() {
            this.event = '-';
            this.handler = '-';
            this.x = 0;
            this.y = 0;
            this.eX = 0;
            this.eY = 0;
            this.dX = 0;
            this.dY = 0;
            this.velocityX = 0;
            this.velocityY = 0;
        }
    }

    class TileModel {
        yearWidth: number;
        width: number;
        start: string;
        end: string;

        constructor() {
            this.yearWidth = 0;
            this.yearWidth = 0;
            this.start = '-';
            this.end = '-';
        }
    }

    export class ctrlTimelineDebug {
        public debug: ILogInfo;
        public reportTile: JourneyTile;
        public yearWidth: number;

        public debugModel: DebugModel;
        public tileModel: TileModel;

        static $inject = [];

        constructor() {
            this.debugModel = new DebugModel();
		    this.tileModel = new TileModel();
		}
	}

	export function dirTimelineDebug(): ng.IDirective {
        return {
            restrict: 'EA',
			controller: ctrlTimelineDebug,
            controllerAs: 'timelineDebug',
            templateUrl: 'partials/timeline/debug.html',
            scope: true,
            bindToController: {
                debug: '=',
                reportTile: '=',
                yearWidth: '='
            },
            link: {
                pre: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimelineDebug) => {
                    scope.$watch('timelineDebug.debug',
                        function (newValue: ILogInfo) {
                            if (newValue != null) {
                                switch (newValue.type) {
                                case "event":
                                    var eventInfo: IEventLogInfo = <IEventLogInfo>newValue;
                                    ctrl.debugModel.event = eventInfo.event;
                                    break;
                                case "handler":
                                    var handlerInfo: IHandlerLogInfo = <IHandlerLogInfo>newValue;
                                    ctrl.debugModel.handler = handlerInfo.handler;
                                    break;
                                case "pos-coords":
                                    var posCoordsInfo: IPosCoordsLogInfo = <IPosCoordsLogInfo>newValue;
                                    ctrl.debugModel.x = posCoordsInfo.x;
                                    ctrl.debugModel.y = posCoordsInfo.y;
                                    break;
                                case "event-coords":
                                    var eventCoordsInfo: IEventCoordsLogInfo = <IEventCoordsLogInfo>newValue;
                                    ctrl.debugModel.eX = eventCoordsInfo.x;
                                    ctrl.debugModel.eY = eventCoordsInfo.y;
                                    break;
                                case "delta":
                                    var deltaCoordsInfo: IDeltaCoordsLogInfo = <IDeltaCoordsLogInfo>newValue;
                                    ctrl.debugModel.dX = deltaCoordsInfo.dX;
                                    ctrl.debugModel.dY = deltaCoordsInfo.dY;
                                    break;
                                case "velocity":
                                    var velocityInfo: IVelocityLogInfo = <IVelocityLogInfo>newValue;
                                    ctrl.debugModel.velocityX = Math.round(velocityInfo.velocity.vx);
                                    ctrl.debugModel.velocityY = Math.round(velocityInfo.velocity.vy);
                                    break;
                                }
                            }
                        });
                    scope.$watch('timelineDebug.reportTile',
                        function (newTile: JourneyTile) {
                            if (newTile != null) {
                                ctrl.tileModel.yearWidth = ctrl.yearWidth;
                                ctrl.tileModel.start = newTile.getStartDate().format("d MMM YYYY") + " " + newTile.meta.posX1;
                                ctrl.tileModel.end = newTile.getEndDate().format("d MMM YYYY") + " " + newTile.meta.posX2;
                                ctrl.tileModel.width = newTile.meta.width;
                            }
                        });
                },
                post: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
                    //
                }
            }
        };
    }

    dirTimelineDebug.$inject = [];
    angular.module('SugarCube').directive('dirTimelineDebug', dirTimelineDebug);
}