module SugarCube {
    'use strict';

    declare var Bloodhound: any;

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirAutoSuggestEducationCourses(apiendpoints: any, srvAuth: srvAuth): ng.IDirective {

        interface IASEduScope extends ng.IScope {
            _course: any;
            course: IEducationCourse;
            coursedata: any;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/templates/autosuggesteducationcourses.html',
            scope: {
                course: '='
            },
            link: {
                pre: (scope: IASEduScope) => {
                    var firsttrigger = true;

                    if (scope.course) scope._course = scope.course.CourseName;

                    var bh = new Bloodhound({
                        datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.num); },
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        local: [],
                        remote: {
                            url: apiendpoints.common + 'AutoSuggestEducationalCourses?term=%QUERY',
                            wildcard: '%QUERY',
                            ajax: {
                                beforeSend: function (jqXHR, settings) {
                                    jqXHR.setRequestHeader('Authorization', 'Bearer ' + srvAuth.session.accesstoken);
                                }
                            }
                        }
                    });
                    bh.initialize();

                    scope.coursedata = {
                        displayKey: 'CourseName',
                        source: bh.ttAdapter()
                    }

                    scope.$watch('_course', (newvalue: any) => {
                        if (firsttrigger) {
                            firsttrigger = false;
                            return;
                        }

                        if (!newvalue || !newvalue.CourseId) {
                            scope.course = {
                                CourseId: 0,
                                CourseName: <string>newvalue,
                                IsTempCourse: true
                            };
                        } else {
                            scope.course = <IEducationCourse>newvalue;
                        }
                    });

                    scope.$watch('course', (newvalue: any) => {
                        if (!newvalue || newvalue.CourseId == undefined) scope._course = null;
                    });
                }
            }
        };
    }

    dirAutoSuggestEducationCourses.$inject = ['apiendpoints', 'srvAuth'];
    angular.module('SugarCube').directive('dirAutoSuggestEducationCourses', dirAutoSuggestEducationCourses);
}