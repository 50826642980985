module SugarCube {
    'use strict';

    export interface IExperienceSubProfile {
        ExperienceSubProfileId: number;
        ProfileExperienceId: number;
        SubProfileId: number;
        DisplayOption: number;
        Achievements: string;
    }

    export interface ISubProfileDisplayMask {
        showedus: Object;
        showcertifications: boolean;
        showstoryboard: boolean;
        showgeneralskills: boolean;
        showlanguages: boolean;
        showinterests: boolean;
        showsocialmedia: boolean;
    }

    export interface ISubProfile extends ng.resource.IResource<ISubProfile> {
        id: number;
        name: string;
        created: string;
        modified: string;
        languageid: number;
        isarchived: boolean;
        displaymask: ISubProfileDisplayMask;
        expsubprofiles: Array<IExperienceSubProfile>;
        languagename: string;
        CareerSummaryHeading: string;
        CareerSummary: string;
    }
}