module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirOffersFanclub(): ng.IDirective {
        return {
            restrict: 'E',
            templateUrl: 'partials/offers/fanclub.html'
        };
    }

    dirOffersFanclub.$inject = [];
    angular.module('SugarCube').directive('dirOffersFanclub', dirOffersFanclub);
}