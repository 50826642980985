module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditExperiences(
        srvProfile: srvProfile,
        srvAuth: srvAuth,
        ngDialog: ng.dialog.IDialogService,
        $timeout: ng.ITimeoutService,
        apiendpoints: any,
        $resource: ng.resource.IResourceService,
        $cacheFactory: ng.ICacheFactoryService,
        srvMasterData: any): ng.IDirective {

        class ctrlEditExperience extends EditDirBase {
            public experiences: ng.resource.IResourceArray<IExperience>;
            public countryfilterid: number;
            public tmpcsc: Array<any> = [];
            public issalarycollapsed = true;
            public dzcfgUpload: any;
            public dzevtUpload: any;
            public newskill: ISkill;
            public isteammgmtcollapsed: boolean = true;
            public isnewcompanyformexpanded: boolean = false;
            public suggestedskills: ng.resource.IResourceArray<ISkill>;
            public suggestedcsc: Array<any> = [];
            public temporglocation: IGeoData;
            public dropzone: any;
            public suggestedacs: Array<any>;
            public shownsuggestions: number = 5;
            public suggestedskillslimit: number = 8;
            public subprofileid: number;
            public md: any;
            public errortext: string;
            public editbtnshow: boolean;          

            public editingobj: IExperience;

            public salaryrange: any = {
                ceil: 70,
                floor: 20
            };

            protected hSalarySliderChange: any;

            public static $inject = ['$scope'];

            constructor(private $scope: ng.IScope) {
                super();

                var _ctrl = this;

                this.md = srvMasterData.md;

                this.experiences = srvProfile.experiences.query({
                    subprofileid: this.subprofileid
                });

                this.dzcfgUpload = {
                    maxFileSize: 2,
                    acceptedFiles: 'image/*',
                    maxFiles: 1,
                    url: apiendpoints.common + 'UploadTempFile',
                    autoProcessQueue: true,
                    headers: {
                        "Authorization": "Bearer " + srvAuth.session.accesstoken
                    },
                    thumbnailWidth: null,
                    thumbnailHeight: null
                }

                this.dzevtUpload = {
                    removedfile: function (file) {
                    },
                    addedfile: function (file) {
                    },
                    success: function (file, res) {
                        if (_ctrl.editingobj) _ctrl.editingobj.Organisation.LogoURL = res;
                    },
                    error: function (file, res, xhr) {
                    },
                    complete: function () {
                    }
                }

                $scope.$watch(() => {
                    return this.suggestedcsc;
                }, () => {
                    if (this.editingobj && this.suggestedcsc && this.suggestedcsc.length > 0) {
                        this.editingobj.SubCategories.length = 0;

                        this.suggestedcsc.forEach((e, i) => {
                            this.editingobj.SubCategories.push(e.SubClassificationId);

                            this.tmpcsc[i] = {};
                            this.tmpcsc[i].c = srvMasterData.md.csc.sc[e.SubClassificationId].cid;
                        });
                    }
                });

                $scope.$watch(() => {
                    if (!this.editingobj) return null;
                    else return this.editingobj.ProfileExperienceSubProfileId;
                }, () => {
                    if (!this.editingobj || !this.editingobj.Organisation || !this.editingobj.Organisation.OrgLocationList) return;

                    if (this.editingobj.Organisation.OrgLocationList.length == 0) this.temporglocation = <IGeoData>{
                        description: null,
                        GeoDataId: 0
                    };
                    else this.temporglocation = this.editingobj.Organisation.OrgLocationList[0].GeoData;
                });

                $scope.$watch(() => {
                    return this.temporglocation ? this.temporglocation.GeoDataId : null;
                }, () => {
                    this.updateSelectedOrgLocation();
                });

                $scope.$watch(() => {
                    if (!this.editingobj) return null;
                    else return this.editingobj.Role;
                }, () => {
                    this.suggestAch();
                });

                $scope.$watch(() => {
                    return this.editingobj ? this.editingobj.Organisation : null;
                }, () => {
                    if (this.dropzone) this.dropzone.removeAllFiles();
                });

                $scope.$watch(() => {
                    return this.editingobj ? this.editingobj.SubCategories : null;
                }, () => {
                    if (this.editingobj && this.editingobj.SubCategories) {
                        this.editingobj.SubCategories.forEach((e, i) => {
                            if (e == 0) {
                                this.tmpcsc[i] = {
                                    c: 0,
                                    sc: 0
                                };
                            } else {
                                this.tmpcsc[i] = {
                                    c: srvMasterData.md.csc.sc[e].cid,
                                    sc: e
                                };
                            }
                        });
                    }
                });
            }

            getDisplayDuration(startdate: string, enddate: string): string {
                var mstart = moment(startdate);
                var mend = moment(enddate);
                var dur = moment.duration(mend.diff(mstart)).add(1, 'month');

                var res = dur.years() + 'y';
                if (dur.months() > 0) res += ' ' + dur.months() + 'm';

                return res;
            }

            onChangeSalaryType() {
                $timeout(() => {
                    this.$scope.$broadcast('rzSliderForceRender');
                });
            }

            showmoresuggestedskills() {
                this.suggestedskillslimit += 8;
            }

            newEntry() {
                super.newEntry();

                this.countryfilterid = srvAuth.session.countryid;

                this.editingobj = <IExperience>{};

                this.editingobj.StartDate = moment().format();
                this.editingobj.EndDate = moment().format();
                this.editingobj.SubCategories = [0];
                this.editingobj.Skills = [];
                this.editingobj.Organisation = <IOrganisation>{};
                this.editingobj.Organisation.OrgLocationList = [];
                this.editingobj.Organisation.CountryId = srvAuth.session.countryid;                                 
                this.initForm();
            }

            editEntry(exp: IExperience) {
                this.editingobj = angular.copy(exp);
                this.countryfilterid = exp.Organisation.CountryId || srvAuth.session.countryid;

                var loc = exp.Organisation.OrgLocationList.filter((e) => {
                    return e.IsSelected;
                });
                if (loc.length > 0) this.temporglocation = loc[0].GeoData;

                var geodataid = loc.length > 0 ? loc[0].GeoData.GeoDataId : 0;

                // get all organisation locations
                $resource(apiendpoints.common + 'AutoSuggestOrganisationLocations').query({ orgid: exp.Organisation.OrganisationId, avoidgeodataid: geodataid }, (res) => {
                    exp.Organisation.OrgLocationList = exp.Organisation.OrgLocationList.concat(res);
                });

                this.editing = true;
                this.editbtnshow = true;
                this.addbtnshow = false;
                this.initForm();
            }

            setStartMonth(newMonth) {
                if (arguments.length) {
                    this.editingobj.StartDate = moment(this.editingobj.StartDate).month(newMonth).format();
                } else {
                    return moment(this.editingobj.StartDate).month();
                }
            }

            setEndMonth(newMonth) {
                if (arguments.length) {
                    this.editingobj.EndDate = moment(this.editingobj.EndDate).month(newMonth).format();
                } else {
                    return moment(this.editingobj.EndDate).month();
                }
            }

            setStartYear(newYear) {
                if (arguments.length) {
                    this.editingobj.StartDate = moment(this.editingobj.StartDate).year(newYear).format();
                } else {
                    return moment(this.editingobj.StartDate).year();
                }
            }

            setEndYear(newYear) {
                if (arguments.length) {
                    this.editingobj.EndDate = moment(this.editingobj.EndDate).year(newYear).format();
                } else {
                    return moment(this.editingobj.EndDate).year();
                }
            }

            commitEdit() {
                if (!this.isEditingExpValid()) return;

                this.updateSelectedOrgLocation();

                this.$scope.$emit(Events.evtScrollToExp);

                this.saving = true;
                this.editing = false;

                if (this.editingobj.ProfileExperienceSubProfileId > 0) {
                    srvProfile.experiences.update(this.editingobj).$promise.then((res) => {
                        this.experiences.forEach((e, i, a) => {
                            if (e.ProfileExperienceSubProfileId == this.editingobj.ProfileExperienceSubProfileId) {
                                a[i] = res;
                                return false;
                            }
                        });

                        this.editingobj = null;
                        this.saving = false;
                    });
                } else {
                    srvProfile.experiences.save(this.editingobj).$promise.then((res) => {
                        this.experiences.push(res);

                        this.editingobj = null;
                        this.saving = false;
                    });
                }

                // invalidate cache
                $cacheFactory.get('$http').remove(apiendpoints.profile + 'Experience');
            }

            deleteEditing() {
                ngDialog.openConfirm({
                    plain: true,
                    template: '<p>Are you sure you wish to delete this entry</p><p class="text-align-right"><button class="btn-small btn-cancel" ng-click="closeThisDialog()">No</button><button class="btn-small" ng-click="confirm()">Yes</button></p>'
                }).then((res) => {
                    this.saving = true;
                    this.editing = false;

                    this.$scope.$emit(Events.evtScrollToExp);

                    srvProfile.experiences.delete({ id: this.editingobj.ProfileExperienceSubProfileId }).$promise.then((res) => {
                        this.experiences.forEach((e, i, a) => {
                            if (e.ProfileExperienceSubProfileId == this.editingobj.ProfileExperienceSubProfileId) {
                                a.splice(i, 1);
                                return false;
                            }
                        });

                        this.saving = false;
                    });

                    // invalidate cache
                    $cacheFactory.get('$http').remove(apiendpoints.profile + 'Experience');
                });
            }

            deleteExp(exp: IExperience) {
                this.editingobj = exp;

                this.deleteEditing();
            }

            addSubCat(exp: IExperience) {
                if (exp.SubCategories.length < 5) exp.SubCategories.push(0);
            }

            deleteSubCat(exp: IExperience, index: number) {
                if (exp.SubCategories.length > 1) exp.SubCategories.splice(index, 1);
            }

            hasTeamMgmt(exp: IExperience): boolean {
                if (!exp.TeamManagement) return false;

                if (!exp.TeamManagement.hasExpenditureResponsibility &&
                    !exp.TeamManagement.hasRevenueResponsibility &&
                    exp.TeamManagement.ReportedToTypeId <= 0,
                    !exp.TeamManagement.hasManagedTeam &&
                    exp.TeamManagement.NumberOfStaffManaged <= 0 &&
                    exp.TeamManagement.TeamTypeManaged <= 0 &&
                    exp.TeamManagement.TeamLocationTypeManaged <= 0) return false;
              
                return true;
            }

            initForm() {
                // adjust salary slider
                this.issalarycollapsed = !!!this.editingobj.SalaryTypeId;

                if (!this.editingobj.MaxSalary) this.editingobj.MaxSalary = 0;
                if (!this.editingobj.MinSalary) this.editingobj.MinSalary = 0;

                if (this.editingobj.MinSalary > 1000) this.editingobj.MinSalary /= 1000;
                if (this.editingobj.MaxSalary > 1000) this.editingobj.MaxSalary /= 1000;

                this.salaryrange.ceil = this.editingobj.MaxSalary + 5;
                this.salaryrange.floor = this.editingobj.MaxSalary - 50;

                if (this.salaryrange.floor < 0) {
                    this.salaryrange.floor = 0;
                    this.salaryrange.ceil = 50;
                }

                // init team management
                this.isteammgmtcollapsed = !this.hasTeamMgmt(this.editingobj);

                // init new company form
                this.isnewcompanyformexpanded = false;

                // scroll to top of form
                this.$scope.$emit(Events.evtScrollToExp);
            }

            getSalarySliderStep() {
                return Math.floor((this.salaryrange.ceil - this.salaryrange.floor) / 10);
            }

            getDisplayValueofSalarySlider(value) {
                // "this" here refers to the rz-salary slider
                if ((<any>this).attributes.salarytypeid == '3') return '$' + value;
                else return '$' + value + 'K';
            }

            onSalarySliderChange() {
                if (this.hSalarySliderChange) $timeout.cancel(this.hSalarySliderChange);
                this.hSalarySliderChange = $timeout(() => {
                    var step = 5;
                    var r = this.salaryrange;

                    if (this.editingobj.MaxSalary > r.ceil - step) {
                        this.editingobj.MinSalary += step;
                        r.floor += step;
                        r.ceil += step;
                    }

                    if (this.editingobj.MinSalary < r.floor + step && r.floor > 0) {
                        this.editingobj.MaxSalary -= step;
                        r.floor -= step;
                        r.ceil -= step;
                    }
                }, 100);
            }

            toggleSalarySliderCollapse() {
                if (this.issalarycollapsed) this.editingobj.SalaryTypeId = 1;
                else this.editingobj.SalaryTypeId = null;

                this.issalarycollapsed = !this.issalarycollapsed;
            }

            getWorkTypeIcon(worktypeid: number): string {
                if (worktypeid == 1) return 'images/status-full.png';
                else return 'images/status-casual.png';
            }

            isNewSkillValid(): boolean {
                if (!this.newskill) return false;

                if (this.newskill.SkillId > 0) {
                    if (this.editingobj.Skills.filter((e) => {
                        return e.SkillId == this.newskill.SkillId;
                    }).length > 0) return false;
                } else {
                    if (this.editingobj.Skills.filter((e) => {
                        return e.SkillName == this.newskill.SkillName;
                    }).length > 0) return false;
                }

                return true;
            }

            deleteSkill(index: number) {
                this.editingobj.Skills.splice(index, 1);
            }

            addSkill() {
                if (!this.isNewSkillValid()) return;

                this.newskill.SkillLevelId = 1;

                this.editingobj.Skills.push(this.newskill);
                this.newskill = null;
            }

            toggleTeamMgmtCollapse() {
                this.isteammgmtcollapsed = !this.isteammgmtcollapsed;
            }

            isEditingExpValid(): boolean {

                if (!this.editingobj) return false;

                // check for company
                if (!this.editingobj.Organisation
                    || this.editingobj.Organisation.Name == ''
                    || this.editingobj.Organisation.Name == undefined) {
                    this.errortext = 'Please select a company';
                    return false;
                }

                // check for role
                if (!this.editingobj.Role
                    || this.editingobj.Role.RoleName == ''
                    || this.editingobj.Role.RoleName == undefined) {
                    this.errortext = 'Please enter a role';
                    return false;
                }

                // check for cat/subcats
                if (this.editingobj.SubCategories.length == 0 || this.editingobj.SubCategories.filter((e) => {
                    return e > 0;
                }).length == 0) {
                    this.errortext = 'Please select at least one category/sub-category';
                    return false;
                }

                // check for company location
                if (this.editingobj.Organisation.OrgLocationList.length == 0 ||
                    this.editingobj.Organisation.OrgLocationList.filter((v) => {
                        return v.IsSelected && !!v.GeoData && v.GeoData.GeoDataId > 0;
                    }).length == 0) {
                    this.errortext = 'Please enter an location';
                    return false;
                }

                // check for country
                if (!this.editingobj.Organisation.CountryId
                    || this.editingobj.Organisation.CountryId <= 0
                    || this.editingobj.Organisation.CountryId == undefined) {
                    this.errortext = 'Please select a country';
                    return false;
                }

                this.errortext = null;
                return true;
            }

            addSuggestedSkill(skill: ISkill, index: number) {
                if (this.editingobj.Skills.filter((e) => {
                    return e.SkillId == skill.SkillId;
                }).length == 0) {
                    this.editingobj.Skills.push(skill);
                    
                    this.suggestedskills.splice(index, 1);
                }
            }

            updateSelectedOrgLocation() {
                var exp = this.editingobj;

                if (!exp || !exp.Organisation) return;

                if (!exp.Organisation.OrgLocationList || exp.Organisation.OrgLocationList.length == 0) {
                    exp.Organisation.OrgLocationList = [<IOrganisationLocation>{}];
                }

                var loc = exp.Organisation.OrgLocationList.filter((e) => {
                    return e.IsSelected;
                });

                if (loc.length <= 0) {
                    exp.Organisation.OrgLocationList[0].IsSelected = true;
                    loc = exp.Organisation.OrgLocationList;
                }

                loc[0].GeoData = this.temporglocation;
            }

            selectEditingOrgLoc(loc: IOrganisationLocation) {
                this.editingobj.Organisation.OrgLocationList.forEach((e) => {
                    e.IsSelected = false;
                });

                loc.IsSelected = true;
            }

            rateExpOrg(exp: IExperience) {
                ngDialog.open({
                    className: 'ngdialog-theme-default p-organisation',
                    template: '<div dir-rate-organisation exp="exp" on-save="save(exp)", on-cancel="closeThisDialog()"></div>',
                    plain: true,
                    controller: ['$scope', ($scope) => {
                        $scope.exp = exp;

                        $scope.save = (exp) => {
                            this.$scope.$emit(Events.evtScrollToExp);

                            this.saving = true;

                            $scope.closeThisDialog();

                            srvProfile.experiences.update(exp).$promise.then((res) => {
                                this.saving = false;
                            });
                        };
                    }]
                });
            }

            suggestAch() {

                if (!this.editingobj || !this.editingobj.Role || !this.editingobj.Role.RoleId || this.editingobj.SubCategories.length == 0) {
                    this.suggestedacs = [];
                    this.suggestedskills = <any>[];
                    return;
                }

                this.suggestedacs = $resource<Array<any>>(apiendpoints.profile + 'GetSuggestedResponsibilityTexts', {}, { save: { isArray: true, method: 'POST' } }).save(this.editingobj);
                this.suggestedskills = $resource<ISkill>(apiendpoints.common + 'AutoSuggestSkillsForExperience').query({
                    profileexperienceid: this.editingobj.ProfileExperienceSubProfileId,
                    roleid: this.editingobj.Role.RoleId,
                    catids: this.editingobj.SubCategories.join(','),
                    subcatids: this.editingobj.SubCategories.join(',')
                });
            }

            addSuggestedAch(suggestedtext, index) {
                this.editingobj.Achievements += '<ul><li>' + suggestedtext.ResponsibilityText + '</li></ul>';

                $resource(apiendpoints.profile + 'UpdateUsageCountForResponsibilityTexts').save([suggestedtext.id]);

                this.suggestedacs.splice(index, 1);
            }

            showMoreSuggestions() {
                this.shownsuggestions += 5;
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/exp.html',
            controller: ctrlEditExperience,
            controllerAs: 'cEditExp',
            scope: {
                subprofileid: '=?'
            },
            bindToController: true
        };
    }

    dirEditExperiences.$inject = ['srvProfile', 'srvAuth', 'ngDialog', '$timeout', 'apiendpoints', '$resource', '$cacheFactory', 'srvMasterData'];
    angular.module('SugarCube').directive('dirEditExperiences', dirEditExperiences);
}