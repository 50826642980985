module SugarCube {
    'use strict';

    export function infoProfilecompleteness(
    ): ng.IDirective {
        return {
            restrict: 'EA',
            templateUrl: 'partials/infographics/profilecompleteness.html',
            scope: {
                average: '=',
                current: '='
            },
            link: (scope: ng.IScope, element: any, attrs: any) => {
                
            }
        }
    }

    infoProfilecompleteness.$inject = [];
    angular.module('SugarCube').directive('infoProfilecompleteness', infoProfilecompleteness);
}