module SugarCube {
    'use strict';

    export function homeJobFinder(
        srvMasterData: any,
        apiendpoints: any,
        srvAuth: srvAuth,
        ngDialog: ng.dialog.IDialogService,
        $state: ng.ui.IStateService,
        $resource: ng.resource.IResourceService
    ): ng.IDirective {
        return {
            restrict: 'EA',
            templateUrl: 'partials/home/jobfinders.html',
            link: {
                pre: (scope: any, element: any, attrs: any) => {
                    scope.md = srvMasterData.md;
                    scope.jobtype = 1;
                    scope.rate = 1;

                    // set default currency
                    scope.currency = scope.md.currencies['1'];

                    scope.searchJobs = () => {
                        if (scope.rate == 2) {
                            scope.minSalary = Math.floor(scope.minSalary * 1.955);
                            scope.maxSalary = Math.floor(scope.maxSalary * 1.955);
                        }

                        $state.go('culture.jobalert', {
                            jobTitle: scope.jobTitle.RoleName,
                            placeId: scope.location.place_id,
                            employmentTypeId: scope.jobtype,
                            subcatids: scope.subcatids,
                            currencySalary: scope.currency.Code,
                            maxSalary: scope.maxSalary,
                            minSalary: scope.minSalary,
                            location: scope.location.description,                            
                            distance: scope.distance * 1000                            
                        });
                    };

                    scope.uploading = false;

                    scope.dzcfgRegister = {
                        maxFileSize: 2,
                        acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        maxFiles: 1,
                        url: apiendpoints.account + 'RegisterUserWithResume',
                        autoProcessQueue: false,
                        headers: {
                            "Authorization": "Bearer " + srvAuth.session.accesstoken
                        },
                        clickable: '.upload-container',
                        previewsContainer: '.file-preview'
                    }

                    scope.dzevtRegister = {
                        addedfile: function (file) {

                        },
                        success: function (file, res) {
                            //srvAuth.setSession(res);

                            scope.searchJobs();

                            //scope.$emit('auth-signedin', { to: 'draganddrop' });
                        },
                        error: function (file, res, xhr) {
                            switch (xhr.status) {
                                case 412:
                                    ngDialog.open({
                                        template: 'partials/dialogs/emailexists.html',
                                        className: 'ngdialog-theme-default dlg-msg',
                                        scope: scope
                                    });

                                    break;

                                case 400:
                                    ngDialog.open({
                                        template: '<p>Email was not found in the resume you provided. Please try a different resume.</p>',
                                        className: 'ngdialog-theme-default dlg-msg',
                                        plain: true
                                    });
                                    break;

                                case 503:
                                    ngDialog.open({
                                        template: 'partials/dialogs/resumeparsingunavailable.html',
                                        className: 'ngdialog-theme-default dlg-msg',
                                        scope: scope
                                    });

                                    $state.go('culture.rrb', { culturecode: srvAuth.session.displayculturecode });

                                    break;
                            }

                            this.removeFile(file);
                        },
                        complete: function () {
                            scope.uploading = false;
                            scope.$apply();

                            scope.loadingdlg.close();
                        }
                    }

                    scope.subcatids = [];

                    scope.addcategory = () => {
                        if (scope.subcatids.length < 5) scope.subcatids.push(0);
                    }

                    scope.deletecat = (index: number) => {                     

                        if (scope.subcatids.length > 1) {
                            scope.subcatids.splice(index, 1);
                        }                    
                    }

                    scope.$watch('suggestedcsc', () => {
                        if (scope.suggestedcsc && scope.suggestedcsc.length > 0) {
                            scope.subcatids = [];

                            for (var i = 0; i < scope.suggestedcsc.length; i++)
                                scope.subcatids.push(scope.suggestedcsc[i].SubClassificationId);
                        }
                    });

                    scope.stats = $resource(`${Config.SSSApiBase}/talentmatch/stats`).get(function (res) {
                        res.totalSalary = '$' + (res.totalSalary / 1000).toFixed(2) + 'B';
                        res.jobsCount = res.jobsCount.toLocaleString();
                        res.talentCount = res.talentCount.toLocaleString();
                        res.employersCount = res.employersCount.toLocaleString();
                    });

                    scope.getdisplayvalueofradiusslider = (value: number) => {
                        return value + 'km';
                    };
                },
                post: (scope: any, element: any, attrs: any) => {
                    scope.submitform = () => {

                        if (scope.dropzone.files.length > 0) {
                            scope.dropzone.processQueue();

                            scope.uploading = true;

                            scope.loadingdlg = ngDialog.open({
                                template: 'partials/dialogs/loadingdraganddrop.html',
                                className: 'ngdialog-theme-default ngdialog-loadingpagebreaks'
                            });
                        } else {

                            if (scope.rate == 2) {
                                scope.minSalary = Math.floor(scope.minSalary * 1.955);
                                scope.maxSalary = Math.floor(scope.maxSalary * 1.955);
                            }
                            $state.go('culture.jobalert', {
                                jobTitle: scope.jobTitle.RoleName,
                                placeId: scope.location.place_id,
                                employmentTypeId: scope.jobtype,
                                subcatids: scope.subcatids.join(),
                                currencySalary: scope.currency.Code,
                                maxSalary: scope.maxSalary,
                                minSalary: scope.minSalary,
                                location: scope.location.description,
                                distance: scope.distance * 1000
                            });
                        }
                    }
                }
            }
        }
    }

    

    homeJobFinder.$inject = ['srvMasterData', 'apiendpoints', 'srvAuth', 'ngDialog', '$state', '$resource'];
    angular.module('SugarCube').directive('homeJobFinder', homeJobFinder);
}