module SugarCube {
    'use strict';

    declare var Bloodhound: any;

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirAutoSuggestRoles(apiendpoints: any, srvAuth: srvAuth, $resource: ng.resource.IResourceService): ng.IDirective {

        interface IASRoleScope extends ng.IScope {
            _role: any;
            role: IRole;
            roledata: any;
            suggestedcsc: any;
            required: boolean;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/templates/autosuggestroles.html',
            scope: {
                role: '=',
                suggestedcsc: '=?'
            },
            link: {
                pre: (scope: IASRoleScope, element, attrs: any) => {
                    scope.required = attrs.required != null;

                    var firsttrigger = true;

                    if (scope.role) scope._role = scope.role.RoleName;

                    var bh = new Bloodhound({
                        datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.num); },
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        local: [],
                        remote: {
                            url: apiendpoints.common + 'AutoSuggestRoles?term=%QUERY',
                            wildcard: '%QUERY',
                            ajax: {
                                beforeSend: function (jqXHR, settings) {
                                    jqXHR.setRequestHeader('Authorization', 'Bearer ' + srvAuth.session.accesstoken);
                                }
                            }
                        }
                    });
                    bh.initialize();

                    scope.roledata = {
                        displayKey: 'Name',
                        source: bh.ttAdapter()
                    }

                    scope.$watch('_role', (newvalue: any) => {
                        if (firsttrigger) {
                            firsttrigger = false;
                            return;
                        }

                        if (!newvalue || !newvalue.rolevm) {
                            var roleres = $resource<IRole>(apiendpoints.profile + 'any');
                            scope.role = new roleres({
                                RoleId: 0,
                                RoleName: <string>newvalue,
                                IsTemprole: true,
                                roleLevelId: 0
                            });

                            scope.suggestedcsc = [];
                        } else {
                            scope.role = <IRole>newvalue.rolevm;
                            scope.suggestedcsc = <any>newvalue.CSC;
                        }
                    });

                    scope.$watch('role', (newvalue: any) => {
                        if (!newvalue || newvalue.RoleId == undefined) scope._role = null;
                        else if (newvalue.RoleId == 0) return;
                        else {
                            scope._role = {
                                rolvm: newvalue,
                                Name: newvalue.RoleName
                            };
                            firsttrigger = true;
                        }
                    });
                }
            }
        };
    }

    dirAutoSuggestRoles.$inject = ['apiendpoints', 'srvAuth', '$resource'];
    angular.module('SugarCube').directive('dirAutoSuggestRoles', dirAutoSuggestRoles);
}