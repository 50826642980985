module SugarCube {
    'use strict';

    export class ctrlEditSubprofileDetails {
        public static $inject = ['subprofile', 'srvProfile', '$resource', '$scope'];

        constructor(
            public subprofile: ISubProfile,
            private srvProfile: srvProfile,
            private $resource: ng.resource.IResourceService,
            private $scope: ng.dialog.IDialogScope
        ) {
        }

        save() {
            this.$resource(Config.CCApiBase + '/ProfileAPI/UpdateSubProfileDetails').save(this.subprofile, (res) => {
                this.$scope.closeThisDialog(res);
            });
        }
    }

    angular.module('gControllers').controller('ctrlEditSubprofileDetails', ctrlEditSubprofileDetails);
}