module SugarCube {
    'use strict';

    export function htmltoplaintext($sce: any) {
        return function (text) {
            var Output = String(text).replace(/<[^>]+>|&nbsp;/gm, '');
            return $sce.trustAs('html', Output);
        }
    }

    htmltoplaintext.$inject = ['$sce'];
    angular.module('SugarCube').filter('htmltoplaintext', htmltoplaintext);
}