module SugarCube {
    'use strict';

    declare var Bloodhound: any;

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirAutoSuggestEducationInstitutes(srvMasterData: any, apiendpoints: any, srvAuth: srvAuth): ng.IDirective {

        interface IASEduScope extends ng.IScope {
            _institute: any;
            institute: IEducationInstitute;
            countryid: number;
            instdata: any;
            inClass: any;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/templates/autosuggesteducationinstitutes.html',
            scope: {
                institute: '=',
                countryid: '=',
                inClass: '=?'
            },
            link: {
                pre: (scope: IASEduScope) => {
                    var firsttrigger = true;

                    if (scope.institute) scope._institute = scope.institute.Name;

                    var newInstData = function (cid) {
                        var bh = new Bloodhound({
                            datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.num); },
                            queryTokenizer: Bloodhound.tokenizers.whitespace,
                            local: [],
                            remote: {
                                url: apiendpoints.common + 'AutoSuggestEducationalInstitution?term=%QUERY' + '&countryid=' + cid,
                                wildcard: '%QUERY',
                                ajax: {
                                    beforeSend: function (jqXHR, settings) {
                                        jqXHR.setRequestHeader('Authorization', 'Bearer ' + srvAuth.session.accesstoken);
                                    }
                                }
                            }
                        });
                        bh.initialize();

                        scope.instdata = {
                            displayKey: 'Name',
                            source: bh.ttAdapter(),
                            templates: {
                                suggestion: function (context) {
                                    return '<div class="org-autosuggest float-container">\
                                <img src="' + (context.LogoURL || 'images/defaultcompany.png') + '" />\
                                <h3>' + context.Name + '</h3>\
                                <p>' + context.Website + '</p>\
                            </div>';
                                }
                            }
                        }
                    }
                    newInstData(scope.countryid);

                    scope.$watch('_institute', (newvalue: any) => {
                        if (firsttrigger) {
                            firsttrigger = false;
                            return;
                        }

                        if (!newvalue || !newvalue.InstituteId) {
                            scope.institute = {
                                CountryId: scope.countryid,
                                Email: null,
                                GeodataId: 0,
                                InstituteId: 0,
                                InstituteTypeId: 0,
                                LogoURL: null,
                                Name: <string>newvalue,
                                PhoneNumber: null,
                                Population: 0,
                                Website: null
                            };
                        } else {
                            scope.institute = <IEducationInstitute>newvalue;
                        }
                    });

                    scope.$watch('countryid', (newvalue, oldvalue) => {
                        newInstData(newvalue);
                    });

                    scope.$watch('institute', (newvalue: any) => {
                        if (!newvalue || newvalue.InstituteId == undefined) scope._institute = null;
                    });
                }
            }
        };
    }

    dirAutoSuggestEducationInstitutes.$inject = ['srvMasterData', 'apiendpoints', 'srvAuth'];
    angular.module('SugarCube').directive('dirAutoSuggestEducationInstitutes', dirAutoSuggestEducationInstitutes);
}