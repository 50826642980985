module SugarCube {
    'use strict';

    export function storyboardImgTile(): ng.IDirective {
        return {
            restrict: 'A',
            link: (scope: ng.IScope, element: JQuery, attrs: any) => {
                element.find('img').on('load', (evt) => {
                    
                    var width = element.find('img').width();
                    element.find('div').css('max-width', width);
                });
            }
        }
    }

    angular.module('SugarCube').directive('storyboardImgTile', storyboardImgTile);
}