module SugarCube {
	"use strict";

    export class JourneyTileMeta {
        public contentLeft: number;
		public contentWidth: number;
		public contentHeight: number;
		public tileHeight: number;

		public ready: ng.IDeferred<any>;

		public row: number;
		public width: number;

		public posX1: number;
		public posX2: number;
        public posY: number;
        public midX: number;
        
        public template: string;
		public zIndex: number;
	}

	export class JourneyTile {
		public getStartDate: Function;
		public getEndDate: Function;
        public getTileSize: Function;

		public meta: JourneyTileMeta;
		public model: any;

		constructor($q: ng.IQService, template: string, model: any) {
            this.meta = new JourneyTileMeta();
		    this.meta.template = template;
			this.meta.ready = $q.defer();

		    this.getTileSize = angular.bind(this, this.getDefaultTileSize);
			this.model = model;
		}

        private getDefaultTileSize(): number {
            var fullContentSize = this.meta.contentLeft + this.meta.contentWidth;
            if (this.meta.width < fullContentSize) {
                return fullContentSize;
            }
            return this.meta.width;
        }

		private getYearPercentage(currentDate: moment.Moment): number {
            var currentYear = moment(new Date(currentDate.year(), 0, 1)),
                nextYear = moment(new Date(currentDate.year() + 1, 0, 1));

			var daysSinceStartYear = Math.floor(moment.duration(moment(currentDate).diff(moment(currentYear))).asDays());
			var totalDaysInYear = moment.duration(moment(nextYear).diff(moment(currentYear))).asDays();

            return daysSinceStartYear / totalDaysInYear;
        }

		public getYear(currentDate: moment.Moment): number {
			var year = currentDate.year();
            var percentage = this.getYearPercentage(currentDate);
            return year + percentage;
		}

		public setRow(newRow: number): void {
			this.meta.row = newRow;
		}

		public calculatePosX(beginDate: moment.Moment, width: number): void {
			var startDateInYears = this.getYear(this.getStartDate());
			var endDateInYears = this.getYear(this.getEndDate());
			this.meta.posX1 = (startDateInYears - beginDate.year()) * width;
			this.meta.posX2 = (endDateInYears - beginDate.year()) * width;
            this.meta.width = this.meta.posX2 - this.meta.posX1;

            var tileMiddleInDays = moment.duration(this.getEndDate().diff(this.getStartDate())).asDays() / 2;
            var tileMidDate = this.getYear(this.getStartDate().add(tileMiddleInDays, 'days'));
            this.meta.midX = (tileMidDate - beginDate.year()) * width;
		}

		public calculatePosY(rowMaxHeight: number): void {
			this.meta.posY = (this.meta.row - 1) * rowMaxHeight;
        }
	}

	export class JourneyStreamModel {
		public heading: string;
		public id;

		public tiles: JourneyTile[];

		public startDate: moment.Moment;
		public endDate: moment.Moment;

		public firstTile: JourneyTile;
		public lastTile: JourneyTile;
		public lastDrawnTile: JourneyTile;

		public ready: ng.IPromise<any>;

        constructor(tiles: JourneyTile[], $q: ng.IQService) {
            this.tiles = tiles.sort(function (a, b) {
                return (a.getStartDate() - b.getStartDate());
            });
			var tilePromises: ng.IPromise<any>[] = [];
			for (var i = 0; i < this.tiles.length; i++) {
				if (this.startDate == null || this.startDate > this.tiles[i].getStartDate()) {
					this.startDate = this.tiles[i].getStartDate();
					this.firstTile = this.tiles[i];
				}
				if (this.endDate == null || this.endDate < this.tiles[i].getEndDate()) {
					this.endDate = this.tiles[i].getEndDate();
					this.lastTile = this.tiles[i];
				}
				tilePromises.push(this.tiles[i].meta.ready.promise);
			}
			this.lastDrawnTile = null;
			this.ready = $q.all(tilePromises);
		}

		public updateLastDrawn(beginDate: moment.Moment, width: number) {
			for (var i = 0; i < this.tiles.length; i++) {
				var currentTile = this.tiles[i];
				currentTile.calculatePosX(beginDate, width);
				if (this.lastDrawnTile == null ||
				(this.lastDrawnTile.meta.posX1 + this.lastDrawnTile.getTileSize()) <
				(currentTile.meta.posX1 + currentTile.getTileSize())) {
					this.lastDrawnTile = currentTile;
				}
			}
		}
	}

	export class JourneyModel {
		public streams: JourneyStreamModel[];

		public startDate: moment.Moment;
		public endDate: moment.Moment;
		public firstTile: JourneyTile;
		public lastTile: JourneyTile;
        public lastDrawnTile: JourneyTile;
        public initialTile: JourneyTile;

		public ready: ng.IPromise<any>;

		constructor(streams: JourneyStreamModel[], $q: ng.IQService) {
			this.streams = streams;
			var streamPromises: ng.IPromise<any>[] = [];
			for (var i = 0; i < this.streams.length; i++) {
				if (this.startDate == null || this.startDate > this.streams[i].startDate) {
					this.startDate = this.streams[i].startDate;
					this.firstTile = this.streams[i].firstTile;
				}
				if (this.endDate == null || this.endDate < this.streams[i].endDate) {
					this.endDate = this.streams[i].endDate;
					this.lastTile = this.streams[i].lastTile;
				}
				streamPromises.push(this.streams[i].ready);
			}
            this.lastDrawnTile = null;
		    this.initialTile = null;
            this.ready = $q.all(streamPromises);
		}

		public updateLastDrawn(beginDate: moment.Moment, width: number) {
			for (var i = 0; i < this.streams.length; i++) {
				var currentStream = this.streams[i];
				currentStream.updateLastDrawn(beginDate, width);
				if (this.lastDrawnTile == null ||
				(currentStream.lastDrawnTile != null &&
				(this.lastDrawnTile.meta.posX1 + this.lastDrawnTile.getTileSize()) <
				(currentStream.lastDrawnTile.meta.posX1 + currentStream.lastDrawnTile.getTileSize()))) {
					this.lastDrawnTile = currentStream.lastDrawnTile;
				}
			}
		}
	}
}