module SugarCube {
    export class Events {
        // http errors
        static evtHttpError: string = 'http-errorstatus';

        // auth events
        static evtSetAccessToken: string = 'auth-setaccesstoken';
        static evtSignedIn: string = 'auth-signedin';

        // profile edit events
        static evtScrollToExp: string = 'profileedit-scrolltoexp';
        static evtScrollToEdu: string = 'profileedit-scrolltoedu';

        // misc events
        static evtUpdateMetaData: string = 'misc-updatemetadata';
        static evtScrollToTop: string = 'misc-scrolltotop';

        constructor() { }
    }
}