module SugarCube {
    'use strict';

    export interface IBasicInfo extends ng.resource.IResource<IBasicInfo> {
        firstname: string;
        lastname: string;
        workphone: string;
        mobilephone: string;
        backupemails: Array<string>;
        email: string;
        titleid: number;
        gender: string;
        preferredname: string;
        jobtitle: string;
        avatarurl: string;
        preferredlanguageid: number;
        persontypeid: number;
        profileid: number;
        ispremium: boolean;
        registeredwithhirers: boolean;
        trialenddate: string;
        ccshareddate: string;
        profilecompleteness: number;
        isactivated: boolean;
        endorsementcount: number;
    }

    export interface IBasicDetails extends ng.resource.IResource<IBasicDetails> {
        ProfileId: number;
        UserId: string;
        ProfileImagePath: string;
        VideoIntroPath: string;
        FirstName: string;
        LastName: string;
        PreferredName: string;
        UsePreferredName: boolean;
        CountryId: number;
        CurrentSPLanguageId: number;
        AddressUnit: string;
        GeoData: IGeoData;
        Phone: string;
        DOB: string;
        isMale: boolean;
        Email: string;
        PostalCode: string;
        PositionTitle: string;
        CoverImage: string;
        CareerSummary: string;
        CareerSummaryHeading: string;
        isGuest: boolean;
    }
}