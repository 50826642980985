module SugarCube {
    'use strict';

    declare var Bloodhound: any;

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirAutoSuggestSkills(apiendpoints: any, srvAuth: srvAuth, $resource: ng.resource.IResourceService): ng.IDirective {

        interface IASskillScope extends ng.IScope {
            _skill: any;
            skill: ISkill;
            skilldata: any;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/templates/autosuggestskills.html',
            scope: {
                skill: '='
            },
            link: {
                pre: (scope: IASskillScope, element: JQuery) => {
                    var firsttrigger = true;

                    if (scope.skill) scope._skill = scope.skill.SkillName;

                    var bh = new Bloodhound({
                        datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.num); },
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        local: [],
                        remote: {
                            url: apiendpoints.common + 'AutoSuggestSkills?term=%QUERY',
                            wildcard: '%QUERY',
                            ajax: {
                                beforeSend: function (jqXHR, settings) {
                                    jqXHR.setRequestHeader('Authorization', 'Bearer ' + srvAuth.session.accesstoken);
                                }
                            }
                        }
                    });
                    bh.initialize();

                    scope.skilldata = {
                        displayKey: 'SkillName',
                        source: bh.ttAdapter()
                    }

                    scope.$watch('_skill', (newvalue: any) => {
                        if (firsttrigger) {
                            firsttrigger = false;
                            return;
                        }

                        var skillres = $resource<ISkill>(apiendpoints.profile + 'any');
                        if (!scope.skill) scope.skill = new skillres({});

                        if (typeof (newvalue) == "string") {
                            scope.skill.SkillId = 0;
                            scope.skill.SkillName = <string>newvalue;
                            scope.skill.IsTempSkill = true;
                        } else {
                            angular.extend(scope.skill, <ISkill>newvalue);
                        }
                    });

                    scope.$watch('skill', (newvalue: any) => {
                        if (!newvalue) scope._skill = null;
                        else if (typeof (newvalue) == 'object') {
                            scope._skill = newvalue.SkillName;
                            firsttrigger = false;
                        }

                        //if (!newvalue) scope._skill = null;
                        //else if (typeof (newvalue) == "string") scope.skill = <ISkill>{
                        //    SkillId: 0,
                        //    SkillName: <string>newvalue,
                        //    IsTempSkill: false,
                        //    SkillLevelId: 0
                        //};
                        //else {
                        //    scope._skill = newvalue;
                        //    firsttrigger = true;
                        //}
                    });
                }
            }
        };
    }

    dirAutoSuggestSkills.$inject = ['apiendpoints', 'srvAuth', '$resource'];
    angular.module('SugarCube').directive('dirAutoSuggestSkills', dirAutoSuggestSkills);
}