module SugarCube {
    'use strict';

    export interface IContact {
        id: number;
        ContactId: number;
        ContactGuid: string;
        IsNewContact: boolean;
        ContactAvatarImage: string;
        ContactFirstName: string;
        ContactLastName: string;
        IsConsultant: boolean;
        ContactRoleId: number
        ContactRoleName: string;
        isTempRole: boolean;
        PhoneCountryId: number;
        ContactPhone: string;
        IsWorkPhone: boolean;
        MobileCountryId: number;
        ContactMobile: string;
        ContactEmail: string;
        ContactTypeId: number;
        PersonTypeId: number;
        iscurrent: boolean;
        knownsince: number;

        ContactOrg: IOrganisation;
        ContactRelation: any;
        Endorsements: any;
        MyEndorsements: any;
        ContactWebsite: string;
    }
}