module SugarCube {
    'use strict';

    export function dirCscSelGroup(
        srvMasterData: any
    ): ng.IDirective {

        class ctrlCSCSelGroup {

            public md;
            public scid: number;
            public c: number;
            public sc: Object;
            public delete;

            public static $inject = ['$scope'];

            constructor($scope: any) {
                this.md = srvMasterData.md;

                var cat = srvMasterData.md.csc.sc[this.scid];

                if (cat) this.c = cat.cid;

                $scope.$watch(() => {
                    return this.c;
                }, () => {
                    this.sc = {};

                    for (var key in srvMasterData.md.csc.sc) {
                        if (srvMasterData.md.csc.sc[key].cid == this.c) this.sc[key] = srvMasterData.md.csc.sc[key];
                    }
                });

                $scope.$watch(() => {
                    return this.scid;
                }, () => {
                    if (srvMasterData.md.csc.sc[this.scid])
                        this.c = srvMasterData.md.csc.sc[this.scid].cid;
                    });   

                this.delete = function (index) {

                    $scope.$emit("deletecat", index);
                };
            }

        
        }

        return {
            restrict: 'A',
            scope: {
                scid: '=',
                delete: '&onDelete'
            },
            templateUrl: 'partials/templates/cscselgroup.html',
            controller: ctrlCSCSelGroup,
            controllerAs: 'cCSCSelGroup',
            bindToController: true,
            link: (scope, element, attrs) => {
            }
        }
    }

    dirCscSelGroup.$inject = ['srvMasterData'];
    angular.module('SugarCube').directive('dirCscSelGroup', dirCscSelGroup);
}