module SugarCube {
    'use strict';

    export function cdnurl(cfgApp: any) {
        return function (url) {
            return Config.cdnbase + url;
        }
    }

    cdnurl.$inject = ['cfgApp'];
    angular.module('SugarCube').filter('cdnurl', cdnurl);
}