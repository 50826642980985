module SugarCube {
    'use strict';

    export class ctrlResumes {
        public cdnbase: string;
        public resumes: Array<any> = [];
        public subprofiles: ng.resource.IResource<ISubProfile>;
        public jobfinderprefs: ng.resource.IResourceArray<any>;

        public static $inject = ['cfgApp', 'srvMasterData', '$resource', 'apiendpoints', 'srvProfile', '$q'];

        constructor(
            cfgApp: any,
            srvMasterData: any,
            private $resource: ng.resource.IResourceService,
            private apiendpoints: any,
            srvProfile: srvProfile,
            $q: ng.IQService
        ) {
            this.cdnbase = cfgApp.cdnbase;

            this.resumes.$resolved = false;

            $q.all([
                $resource(apiendpoints.common + 'GetResumestatus').get((res) => {
                    angular.forEach(srvMasterData.md.resumes, (v, k) => {
                        if (res[k] && res[k].haspurchased) {
                            var comb = {};
                            angular.extend(comb, v, res[k]);

                            this.resumes.push(comb);
                        }

                        this.resumes.$resolved = true;
                    });
                }).$promise,
                srvProfile.subprofiles.query((res) => {
                    this.subprofiles = res;
                }).$promise
            ]).then(() => {
                this.updateUsedInDisplay();
            });

            this.jobfinderprefs = $resource(apiendpoints.jobs + 'GetJobFinderPreferences').query();
        }

        getresumebreakpoint(resume) {
            var bp = 73;

            if (resume.hover && resume.usedin) {
                bp += resume.usedin.length * 31;
            }

            return bp + 'px';
        }

        iscvlinkedtoprofile(cv, value) {
            if (!cv) return false;
            return cv.id == value.SelectedResumeId;
        }

        linkcvtoprofile(cv, value) {
            if (!cv) return false;
            if (value.SelectedResumeId == cv.id) value.SelectedResumeId = null;
            else value.SelectedResumeId = cv.id;
        }

        isjobfinderslinkedtoprofile(cv, value) {
            if (!cv) return false;
            return cv.id == value.cv_id;
        }

        linkjobfinderstoprofile(cv, value) {
            if (!cv) return false;
            if (value.cv_id == cv.id) value.cv_id = null;
            else value.cv_id = cv.id;
        }

        updateUsedInDisplay() {
            this.resumes.forEach((v) => {
                v.usedin = [];
            });

            angular.forEach(this.subprofiles, (v, k) => {
                var resume = this.resumes.find((r) => {
                    return r.id == v.SelectedResumeId;
                });

                if (resume) {
                    resume.usedin.push(v);
                }
            });
        }

        savedefaultcvlinkages() {
            var sps = [];
            Object.keys(this.subprofiles).forEach((e) => {
                if (e.indexOf('$') == 0) return true;

                sps.push(this.subprofiles[e]);
            });

            this.$resource(this.apiendpoints.common + 'UpdateSelectedResume').save({
                subprofiles: sps.map((obj) => {
                    return { SubProfileId: obj.SubProfileId, SelectedResumeId: obj.SelectedResumeId };
                }),
                jobfinderprefs: this.jobfinderprefs.map((obj) => {
                    return { id: obj.id, cv_id: obj.cv_id };
                })
            }, () => {
                this.updateUsedInDisplay();
            });
        }
    }

    angular.module('SugarCube').controller('ctrlResumes', ctrlResumes);
}