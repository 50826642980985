module SugarCube {
    'use strict';

    export class ctrlPrivacySettings {

        public settings: ng.resource.IResourceArray<IPrivacySetting>;
		public locked: boolean;
		private globalPrivacySettingId: number;

        public static $inject = ['srvProfile'];

        constructor(
            private srvProfile: srvProfile
        ) {
            this.settings = srvProfile.privacysettings.query();
        }

        GetSettingsValue(masterid: number): boolean {
            var setting = this.settings.find((v) => {
                return v.mastersettingid == masterid;
            });

            if (setting) return setting.ischecked;
            else return false;
        }

        SetSettingsValue(masterid: number, checked: boolean) {
            var setting = this.settings.find((v) => {
                return v.mastersettingid == masterid;
            });

            if (setting) setting.ischecked = checked;
            else if (checked) {
                var newsetting = {
                    id: 0,
                    ischecked: checked,
                    mastersettingid: masterid
                };

                this.settings.push(<IPrivacySetting>newsetting);
            }
        }

        SetPrivacy(templatename: string) {
            // remove all settings
            this.settings = <any>[];

            var newmastersettingids = [2, 3, 4, 5, 6, 8, 12];
            newmastersettingids.forEach((v) => {
                this.settings.push(<IPrivacySetting>{
                    mastersettingid: v,
                    ischecked: false
                });
            });

            if (templatename == 'public') {
                this.settings.forEach((v) => {
                    v.ischecked = true;
                });
            } else if (templatename == 'private') {
                this.settings.forEach((v) => {
					if (v.mastersettingid == 2 || v.mastersettingid == 8 || v.mastersettingid == 3 || v.mastersettingid == 4 || v.mastersettingid == 12)
						v.ischecked = false;
					else
						v.ischecked = true;
                });
            } else if (templatename == 'locked') {
                this.settings.forEach((v) => {
                    v.ischecked = false;
                });
                this.locked = templatename == 'locked';
            } 
            else if (templatename == 'privateplus') {
                this.settings.forEach((v) => {
					if (v.mastersettingid == 2 || v.mastersettingid == 8 || v.mastersettingid == 3 || v.mastersettingid == 4 || v.mastersettingid == 5 || v.mastersettingid == 12)
						v.ischecked = false;
					else
						v.ischecked = true;
                });
            } 
        }

        GetPrivacyTemplateName(): string {
			if (this.settings.length == 7 && this.settings.every((v) => {
				return v.ischecked;
			})) {
				this.globalPrivacySettingId = GlobalPrivacySettings.Public;
				return 'public';
			}

			if (this.settings.every((v) => {
				if (v.mastersettingid == 2 || v.mastersettingid == 8 || v.mastersettingid == 3 || v.mastersettingid == 4 || v.mastersettingid == 12)
					return !v.ischecked;
				else
					return v.ischecked;
			})) {
				this.globalPrivacySettingId = GlobalPrivacySettings.Private;
				return 'private';
			}

			if (this.settings.every((v) => {
				if (v.mastersettingid == 2 || v.mastersettingid == 8 || v.mastersettingid == 3 || v.mastersettingid == 4 || v.mastersettingid == 5 || v.mastersettingid == 12)
					return !v.ischecked;
				else
					return v.ischecked;
			})) {
				this.globalPrivacySettingId = GlobalPrivacySettings.PrivatePlus;
				return 'privateplus';
			}

			if (this.settings.every((v) => {
				return !v.ischecked;
			})) {
				this.globalPrivacySettingId = GlobalPrivacySettings.Locked;
				return 'locked';
			}

			this.globalPrivacySettingId = GlobalPrivacySettings.Custom;
            return 'custom';
        }

        Save() {
			this.srvProfile.privacysettings.save({ id: this.globalPrivacySettingId, settings: this.settings });
        }
	}

	export enum GlobalPrivacySettings {
		Public = 1,
		Locked = 2,
		Private = 3,
		PrivatePlus = 4,
		Custom = 5,
		RSAgencyLocked = 6,
		RSAgencyUnlocked = 7,
		RSMatchedLocked = 8,
		RSMatchedUnlocked = 9
	}

    angular.module('gControllers').controller('ctrlPrivacySettings', ctrlPrivacySettings);
}