module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirOffersLookinggood(srvProfile: srvProfile, srvAuth: any): ng.IDirective {
        return {
            restrict: 'E',
            templateUrl: 'partials/offers/lookinggood.html',
            link: function (scope: IOfferScope, element: JQuery, attrs: any) {
                scope.show = !srvAuth.session.profileid;

                if (srvAuth.isLoggedIn()) {
                    srvProfile.miscdetails.get().$promise.then((res) => {
                        scope.show = !srvAuth.session.profileid || res.percentage < 85;
                        scope.expires = moment.duration(86400 - res.ProfileLifetimeInSeconds, 'seconds');
                    });
                }

                scope.$watch('show', () => {
                    element.toggleClass('ng-hide', !scope.show);
                });
            }
        };
    }

    dirOffersLookinggood.$inject = ['srvProfile', 'srvAuth'];
    angular.module('SugarCube').directive('dirOffersLookinggood', dirOffersLookinggood);
}