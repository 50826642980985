module SugarCube {
    'use strict';

    export function infoSkills(
    ): ng.IDirective {
        return {
            restrict: 'EA',
            templateUrl: 'partials/infographics/skills.html',
            scope: {
                averagenumberofskills: '=',
                percentage: '='
            },
            link: (scope: ng.IScope, element: any, attrs: any) => {

            }
        }
    }

    infoSkills.$inject = [];
    angular.module('SugarCube').directive('infoSkills', infoSkills);
}