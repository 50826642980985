module SugarCube {
    'use strict';

    export function dirRateOrganisation(srvMasterData: any): ng.IDirective {

        class ctrlRateOrganisation {
            public step: number = 1;
            public exp: IExperience;
            public md: any;

            public static $inject = [];

            constructor() {
                srvMasterData.get(['ratingtype']).then((res) => {
                    this.md = res;
                });
            }

            changeOverallRating() {
                this.step = 2;

                for (var id in this.md.ratingtype) this.exp.Review.RatingDetail[id] = this.exp.Review.OverallRating;
            }
        }

        return {
            restrict: 'EA',
            scope: {
                exp: '=',
                onSave: '&',
                onCancel: '&'
            },
            controller: ctrlRateOrganisation,
            controllerAs: 'cRateOrg',
            bindToController: true,
            templateUrl: 'partials/templates/rateorganisation.html',
            link: (scope: ng.IScope, element: JQuery, attrs: any) => {
            }
        };
    }

    dirRateOrganisation.$inject = ['srvMasterData'];
    angular.module('SugarCube').directive('dirRateOrganisation', dirRateOrganisation);
}