module SugarCube {
    "use strict";

    export class ctrlResetPassword {
        public email: string;
        public submitted: boolean;

        public static $inject = ['$resource', 'srvAuth', '$rootScope', '$stateParams'];

        constructor(
            private $resource: ng.resource.IResourceService,
            private srvAuth: srvAuth,
            private $rootScope: ng.IRootScopeService,
            private $stateParams: any
        ) {
        }

        resetpassword() {
			this.$resource(Config.CCApiBase + '/AccountAPI/CCTriggerResetPassword').save('"' + this.email + '"', (res) => {
                this.submitted = true;
            });
        }
    }

    angular.module('gControllers').controller('ctrlResetPassword', ctrlResetPassword);
}