/// <reference path="../classes/publicprofile.ts" />

module SugarCube {
    "use strict";

    export class ctrlClassicProfile extends PublicProfile {
        public static $inject = ['url', '$resource', 'apiendpoints', 'ngDialog', 'srvMasterData', '$q'];
        public md: any;

        constructor(url: string, $resource: ng.resource.IResourceService, apiendpoints: any, ngDialog: ng.dialog.IDialogService, srvMasterData, $q: ng.IQService) {
            super(url, $resource, apiendpoints, ngDialog, $q);
            
            this.md = srvMasterData.md;
        }
    }

    angular.module('gControllers').controller('ctrlClassicProfile', ctrlClassicProfile);
}