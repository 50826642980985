module SugarCube {
    "use strict";

    export class ctrlStaffingRegister {

        public static $inject = ['$scope', '$state', 'srvAuth', 'ngDialog'];

        constructor($scope: ng.IScope, $state: ng.ui.IStateService, srvAuth: srvAuth, ngDialog: ng.dialog.IDialogService) {
            $scope.$on(Events.evtSignedIn, (evt) => {

                $('#staffing-helper-form').submit();

                //ngDialog.open({
                //    template: 'partials/dialogs/confirmdetails.html',
                //    controller: ctrlConfirmDetailsDlg,
                //    controllerAs: 'cConfirmDetailsDlg',
                //    className: 'ngdialog-theme-default dlg-confirmdetails'
                //}).closePromise.then(() => {
                //    $state.go('culture.cvfirstview', {
                //        culturecode: srvAuth.session.displayculturecode,
                //        cvid: 16
                //    });
                //});

                evt.stopPropagation();
            });
        }
    }

    angular.module('gControllers').controller('ctrlStaffingRegister', ctrlStaffingRegister);
}