module SugarCube {
    "use strict";

    export class ctrlCreatePassword {
        public password: string;

        public static $inject = ['$resource', 'apiendpoints', 'id', 'srvAuth', '$rootScope', '$stateParams'];

        constructor(
            private $resource: ng.resource.IResourceService,
            private apiendpoints: any,
            private id: string,
            private srvAuth: srvAuth,
            private $rootScope: ng.IRootScopeService,
            private $stateParams: any
        ) {
        }

        create() {
            this.$resource(this.apiendpoints.account + 'CreatePassword').save({
                id: this.id,
                password: this.password
            }, (res) => {
                this.srvAuth.clearTokensInLocalStorage();
                //this.srvAuth.signin(false);
                window.location.href = 'https://www.careercontroller.com';
            });
        }
    }

    angular.module('gControllers').controller('ctrlCreatePassword', ctrlCreatePassword);
}