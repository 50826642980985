module SugarCube {
    'use strict';

    const throttleFPS = 60;

    export enum AnimationType {
        Pan,
        FocusOnTile,
        Tween
    }

    declare var TWEEN: any;

    export class IAnimationManager {
        private queued: Array<any>;
        private animinprogress: string;
        private callinprogress: boolean;
        private throttleTimeout: number;
        private callback: Function;

        constructor(callback: Function) {
            this.queued = new Array();
            this.animinprogress = null;
            this.callinprogress = false;
            this.throttleTimeout = 1000 / throttleFPS;
            this.callback = callback;

            if (!window.requestAnimationFrame) {
                window.requestAnimationFrame = ($.proxy(function () {
                    return (<any>window).webkitRequestAnimationFrame ||
                        (<any>window).mozRequestAnimationFrame ||
                        (<any>window).oRequestAnimationFrame ||
                        (<any>window).msRequestAnimationFrame ||
                        $.proxy(function (/* function FrameRequestCallback */ callback, /* DOMElement Element */ element) {
                            var timeout = 1000 / throttleFPS;
                            window.setTimeout(callback, timeout);
                        }, this);
                }, this)());
            }
        }

        callAnimation() {
            if (!this.callinprogress) {
                this.callinprogress = true;
                window.requestAnimationFrame($.proxy(() => {
                    this.callinprogress = false;
                    this.updateAnimation();
                }, this));
            }
        }

        updateAnimation() {
            var currenttime = $.now();
            for (var i = 0, al = this.queued.length; i < al; i++) {
                var QueueItem = this.queued[i];
                this.animinprogress = QueueItem;
                QueueItem.func.call(this, i, currenttime - QueueItem.start, QueueItem.data);
            }
            var tweensinprogress = TWEEN.update();
            this.animinprogress = null;
            if (this.queued.length > 0 || tweensinprogress) {
                this.callAnimation();
            }
            this.callback();
        }

        public QueueAnimation(animtype: AnimationType, func, data, cancel?) {
            var QueueItem;
            for (var i = 0, al = this.queued.length; i < al; i++) {
                var TestItem = this.queued[i];
                if (TestItem.type == animtype) {
                    if (typeof (TestItem.cancel) != "undefined") {
                        TestItem.cancel.call(this, i, TestItem.data);
                    }
                    TestItem.start = $.now();
                    TestItem.data = data;
                    TestItem.cancel = cancel;
                    TestItem.func = func;
                }
            }
            if (TestItem === undefined) {
                QueueItem = {};
                QueueItem.start = $.now();
                QueueItem.type = animtype;
                QueueItem.func = func;
                QueueItem.cancel = cancel;
                QueueItem.data = data;
                this.queued.push(QueueItem);
            }
            this.callAnimation();
        }

        public RemoveAnimation(index) {
            if (index > -1) {
                this.queued.splice(index, 1);
            }
        }
    }
}