module SugarCube {
    'use strict';

    export enum SidebarToggleReason {
        None,
        Computer,
        User
    }

    export enum SidebarTab {
        Profile = 1,
        Skills = 2,
        Other = 3,
        Experience = 4
    }

    export class ctrlTimeline {
        public all_input: IInput;
        private decelerate: boolean; //Used for post pan animation

        public viewport: Viewport = null;
        public config: IViewportConfig = null;
        public animation: IAnimationManager = null;

        public startDate: moment.Moment;
        public endDate: moment.Moment;

        public firstTile: JourneyTile;
        public lastTile: JourneyTile;

        public model: JourneyModel;

        public selectedTile: JourneyTile;
        public highlightTiles: JourneyTile[];

        public debug: ILogInfo;
        public showDebug = true;

        public verticalOffset: number;
        public height: number;
        public openSidebar: boolean;
        private sidebarReason: SidebarToggleReason;
        public tab: SidebarTab;

        public animate: boolean;

        public static $inject = ['$scope', '$timeout'];

        constructor(private $scope: ng.IScope, private $timeout: ng.ITimeoutService) {
            var self = this;

            $scope.$watch<JourneyModel>(() => {
                return this.model;
            }, (newVal) => {
                if (self.model != null) {
                    self.model.ready.then(() => {
                        //self.viewport.setModel(self.model);
                        self.viewport.zoomDefaultViewport();
                        self.viewport.position.posY = self.viewport.dimensions.clampMaxY;
                        $timeout(() => {
                            if (self.model.initialTile != null) {
                                self.panToTile(self.model.initialTile);
                            } else {
                                // TODO: pan does not exist in ctrlTimeline
                                //this.pan(this.scaling.maxWidth - this.scaling.dimensions.width / 2, self.viewport.position.posY);
                            }
                            $scope.$apply();
                        }, 0);
                        $scope.$broadcast(TimelineEvents.evtCJZoomChanged);
                        $scope.$broadcast(TimelineEvents.evtCJStreamSizeChanged);
                        $scope.$broadcast(TimelineEvents.evtCJPanChanged);
                    });
                }
            });
            this.config = {
                tileMargin: 2,
                minTileSize: 217,
                minYearGranularity: 1,
                defaultYearGranularity: 12,
                granularityIncrement: 1,
                enforceMaximum: true
            };

            this.all_input = {
                dragmode: false,
                inputs: [],
                blocked: false,
                lastmove: null,
                mode: ''
            };
            for (var i = 0; i < 2; i++) {
                this.all_input.inputs.push({
                    lastX: 0,
                    lastY: 0,
                    posX: 0,
                    posY: 0
                });
            }

            this.animation = new IAnimationManager(angular.bind(this, this.onAnimateCallback));

            this.viewport = new Viewport(this.config);
            this.viewport.dimensions.clampMaxY = 151;

            $scope.$on(TimelineEvents.evtCJViewportChanged, (evt, newWidth) => {
                this.viewport.setWidth(newWidth);
            });

            this.selectedTile = null;
            this.openSidebar = false;
            this.animate = true;
            this.height = 0;
            this.tab = 1;
        }

        public toggleSidebar() {
            this.openSidebar = !this.openSidebar;
            if (this.openSidebar)
                this.sidebarReason = SidebarToggleReason.User;
            else
                this.sidebarReason = SidebarToggleReason.None;
        }

        public toggleTab(newTab: number) {
            this.tab = newTab;
        }

        public onAnimateCallback() {
            this.all_input.blocked = false;
            this.$scope.$apply();
        }

        public calculateClamping(element: JQuery) {
            var streamsHeight = element.find('.stream-container').height();
            var bodyHeight = element.find('.lp-body').height();
            this.viewport.dimensions.clampMinY = -(streamsHeight - bodyHeight + 40);
        }

        public panToTile(tile: JourneyTile) {
            this.viewport.pan((tile.meta.midX - (this.viewport.dimensions.width / 2)) * this.viewport.scaling.factor, this.viewport.position.posY);
            this.$scope.$broadcast(TimelineEvents.evtCJPanChanged);
        }

        public selectTile($event: ng.IAngularEvent, tile: JourneyTile) {
            if (tile instanceof ExperienceTile) {
                if (!this.openSidebar) {
                    this.openSidebar = true;
                    this.sidebarReason = SidebarToggleReason.Computer;
                }

                this.selectedTile = tile;
                this.tab = SidebarTab.Experience;
                this.panToTile(tile);
                $event.preventDefault();
                $event.stopPropagation();
            }
        }

        public unselectTile($event: ng.IAngularEvent) {
            if (this.sidebarReason === SidebarToggleReason.Computer && this.openSidebar)
                this.toggleSidebar();
            this.selectedTile = null;
            $event.preventDefault();
            $event.stopPropagation();
        }

        public onPan(evt: JQueryEventObject, width: number, axis: number) {
            if (this.all_input.dragmode) {
                if (this.all_input.blocked === false) {
                    this.all_input.blocked = true;
                    this.all_input.lastmove = new Date();
                    if (evt.type == 'mousemove') {
                        this.all_input.inputs[0].posX = evt.pageX;
                        this.all_input.inputs[0].posY = evt.pageY;
                    }
                    if (evt.type == 'touchmove') {
                        var touch = (<any>evt.originalEvent).touches[0];
                        this.all_input.inputs[0].posX = touch.pageX;
                        this.all_input.inputs[0].posY = touch.pageY;
                    }
                    var scaling = this.viewport.scaling.maxWidth / width;
                    var velocities = this.calculateVelocitiesScaled(this.all_input.inputs[0], axis, scaling);
                    this.all_input.inputs[0].lastX = this.all_input.inputs[0].posX;
                    this.all_input.inputs[0].lastY = this.all_input.inputs[0].posY;
                    this.log(<IEventLogInfo>{ type: 'event', event: evt.type });
                    this.log(<IHandlerLogInfo>{ type: 'handler', handler: 'common_mousepan' });
                    this.log(<IEventCoordsLogInfo>{ type: 'event-coords', x: this.all_input.inputs[0].posX, y: this.all_input.inputs[0].posY });
                    this.log(<IVelocityLogInfo>{ type: 'velocity', velocity: velocities });
                    this.animation.QueueAnimation(AnimationType.Pan, angular.bind(this, this.animatePan), { velocity: velocities });
                    this.animate = false;
                }
                evt.preventDefault();
                evt.stopPropagation();
            }
        }

        public onMouseUp(evt: JQueryEventObject) {
            if (this.viewport) {
                this.log(<IEventLogInfo>{ type: 'event', event: evt.type });

                this.all_input.dragmode = false;
                $(document).off("mousemove touchmove");
                $(document).off("mouseup touchend");

                evt.preventDefault();

                this.decelerate = true;
                this.$scope.$apply();
            }
        }

        public onMouseDown(evt: JQueryEventObject) {
            this.log(<IEventLogInfo>{ type: 'event', event: evt.type });

            this.all_input.dragmode = true;

            if (evt.type == 'mousedown') {
                this.all_input.inputs[0].lastX = evt.pageX;
                this.all_input.inputs[0].lastY = evt.pageY;
            }

            if (evt.type == 'touchstart') {
                var touch = (<any>evt.originalEvent).touches[0];
                this.all_input.inputs[0].lastX = touch.pageX;
                this.all_input.inputs[0].lastY = touch.pageY;
            }

            if (evt.type == 'gesturestart') {
                //
            }

            $(document).on("mouseup touchend", $.proxy(this.onMouseUp, this));
            evt.preventDefault();
            this.$scope.$apply();
        }

        public onDragZoom(evt: JQueryEventObject, width: number, startaxis: number, endaxis: number) {
            if (this.all_input.dragmode) {
                if (this.all_input.blocked === false) {
                    //this.all_input.blocked = true;
                    this.all_input.lastmove = new Date();
                    if (evt.type === 'mousemove') {
                        this.all_input.inputs[0].posX = evt.pageX;
                        this.all_input.inputs[0].posY = evt.pageY;
                    }
                    /*if (evt.type == 'touchmove') {
                        var touch = evt.originalEvent.touches[0];
                        this.all_input.inputs[0].posX = touch.pageX;
                        this.all_input.inputs[0].posY = touch.pageY;
                    }*/
                    var saved1 = this.viewport.position.posX1;
                    var saved2 = this.viewport.position.posX2;
                    //Calculate differences
                    var deltax = this.all_input.inputs[0].posX - this.all_input.inputs[0].lastX;
                    var deltay = this.all_input.inputs[0].posY - this.all_input.inputs[0].lastY;

                    this.log(<IEventLogInfo>{ type: 'event', event: evt.type });
                    this.log(<IHandlerLogInfo>{ type: 'handler', handler: 'common_mousezoom' });
                    this.log(<IEventCoordsLogInfo>{ type: 'event-coords', x: this.all_input.inputs[0].posX, y: this.all_input.inputs[0].posY });

                    var targetx1 = this.viewport.position.posX1;
                    var targetx2 = this.viewport.position.posX2;
                    var scaling = 1 / width * this.viewport.scaling.maxWidth;
                    var deltax1per = deltax * scaling * startaxis;
                    var deltax2per = deltax * scaling * endaxis;
                    targetx1 += deltax1per;
                    targetx2 += deltax2per;
                    if (targetx1 < 0)
                        targetx1 = 0;
                    if (targetx1 > (this.viewport.scaling.maxWidth - this.viewport.dimensions.width))
                        targetx1 = (this.viewport.scaling.maxWidth - this.viewport.dimensions.width);
                    if (targetx2 < this.viewport.dimensions.width)
                        targetx2 = this.viewport.dimensions.width;
                    if (targetx2 > this.viewport.scaling.maxWidth)
                        targetx2 = this.viewport.scaling.maxWidth;

                    if (this.viewport.zoom(targetx2 - targetx1)) {
                        this.$scope.$broadcast(TimelineEvents.evtCJZoomChanged);
                        this.$scope.$broadcast(TimelineEvents.evtCJStreamSizeChanged);

                        this.all_input.inputs[0].lastX = this.all_input.inputs[0].posX;
                        this.all_input.inputs[0].lastY = this.all_input.inputs[0].posY;
                        this.viewport.pan(targetx1, this.viewport.position.posY);
                        this.$scope.$broadcast(TimelineEvents.evtCJPanChanged);
                    }
                    this.animate = false;
                    this.log(<IDeltaCoordsLogInfo>{ type: 'delta', dX: this.viewport.position.posX1 - saved1, dY: this.viewport.position.posX2 - saved2 });
				} else {
					console.warn("Blocked by Interval");
                }
                evt.preventDefault();
                this.$scope.$apply();
            }
        }

        calculateVelocitiesScaled(inputs: IInputCoord, axis: number, scaling: number): Velocity {
            var deltaX: number = inputs.posX - inputs.lastX;
            var deltaY: number = inputs.posY - inputs.lastY;

            var maxX: number = Velocity.max * scaling;
            var initialX: number = deltaX !== 0 ? deltaX * scaling * axis : 0;
            var velocity = new Velocity();
            velocity.vx = initialX;
            velocity.vy = deltaY;
            velocity.capVelocity(maxX, Velocity.max);
            return velocity;
        }

        public animatePan(index, time, data) {
            this.viewport.pan(Math.round(this.viewport.position.posX1 + data.velocity.vx), Math.round(this.viewport.position.posY + data.velocity.vy));
            if (this.decelerate) {
                data.velocity.decelerateVelocity(1, 1);
            }
            if (!(this.decelerate && Math.abs(data.velocity.vx) > 0 && Math.abs(data.velocity.vy) > 0)) {
                this.animation.RemoveAnimation(index);
                this.animate = true;
            }
            this.log(<IVelocityLogInfo>{ type: 'velocity', velocity: data.velocity });
            this.$scope.$broadcast(TimelineEvents.evtCJPanChanged);
        }

        public onTouchMove(evt: JQueryEventObject, width, axis) {
            if (evt.type === 'touchmove') {
                if ((<any>evt.originalEvent).touches.length === 1) {
                    this.onPan(evt, width, axis);
                }
                if ((<any>evt.originalEvent).touches.length === 2) {
                    this.onPan(evt, width, axis);
                    //this.common_touchzoom(e, width, axis);
                }
            }
        }

        public log(log_info: ILogInfo) {
            this.debug = log_info;
        }
    }

    export class dirTimeline implements ng.IDirective {
        public restrict = 'EA';
        public controller = ctrlTimeline;
        public controllerAs = 'cTimeline';
        public scope = true;
        public bindToController = {
            model: '='
        };
        public link = {
            pre: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {
                if (ctrl.viewport !== null) {
                    ctrl.viewport.setWidth(element.width());
                }
                scope.$on(TimelineEvents.evtCJPanChanged,
                    (evt, offset) => {
                        ctrl.calculateClamping(element);
                        ctrl.verticalOffset = ctrl.viewport.position.posY <= 0 ? ctrl.viewport.position.posY : 0;
                        var timeout = this.$timeout(function () {
                            ctrl.height = element.find('.lp-timeline').height();
                        });
                    });
                scope.$on(TimelineEvents.evtCJZoomChanged,
                    (evt, offset) => {
                        //
                    });
            },
            post: (scope: ng.IScope, element: JQuery, attrs: any, ctrl: ctrlTimeline) => {

            }
        };

        static $inject = ['$timeout'];

        constructor(private $timeout: ng.ITimeoutService) {

        }
    }

    angular.module('SugarCube').directive('dirTimeline', ['$timeout', function ($timeout) { return new dirTimeline($timeout) }]);
}