module SugarCube {
    'use strict';

    declare var AddressPicker: any;

    export class utilGoogleAddressPicker {
        protected autosuggestGooglePlaces: any;

        constructor(countrycode: string) {
            this.autosuggestGooglePlaces = new AddressPicker({
                autocompleteService: {
                    componentRestrictions: { country: countrycode }
                }
            });
            this.autosuggestGooglePlaces.initialize();
        }

        getTypeAheadDataSet(): any {
            return {
                displayKey: 'description',
                source: this.autosuggestGooglePlaces.ttAdapter()
            };
        }
    }
}