module SugarCube {
	'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
	export function dirEditReferees(
		srvProfile: srvProfile,
		srvAuth: any,
		ngDialog: ng.dialog.IDialogService,
		$resource: ng.resource.IResourceService,
		apiendpoints: any,
		srvMasterData: any
	): ng.IDirective {

		class ctrlEditReferees extends EditDirBase {
			public referees: ng.resource.IResourceArray<IReferee>;
			public potentialreferees: ng.resource.IResourceArray<IReferee>;
			public newref: IReferee;
			public cachedrefs: ng.resource.IResourceArray<IReferee>;
			public experiences: ng.resource.IResourceArray<IExperience>;
			public educations: ng.resource.IResourceArray<IEducation>;
			public md: any;

			public showviewoptions: boolean = false;
			public showpotentialrefs: boolean = false;

			constructor() {
				super();

				this.referees = srvProfile.referees.query();
				this.experiences = srvProfile.experiences.query();
				this.educations = srvProfile.education.query();

				this.md = srvMasterData.md;

				this.potentialreferees = $resource<IReferee>(apiendpoints.profile + 'GetPotentialReferees').query();
			}

			addPotentialRef(index: number) {
				this.newref = this.potentialreferees[index];
				this.newref.ProfileRefereeId = null;
				this.showpotentialrefs = false;
				this.potentialreferees.splice(index, 1);
			}

			newEntry() {
				this.editing = true;

				this.newref = new srvProfile.referees();
				this.newref.IsSendEmailRequest = true;
				this.cachedrefs = angular.copy(this.referees);
			}

			editEntry(ref: IReferee) {
				this.editing = true;

				this.newref = ref;
				this.cachedrefs = angular.copy(this.referees);
			}

			deleteEntry(index: number) {
				ngDialog.openConfirm({
					plain: true,
					template: '<p>Are you sure you wish to delete this entry</p><p class="text-align-right"><button class="btn-small btn-cancel" ng-click="closeThisDialog()">No</button><button class="btn-small" ng-click="confirm()">Yes</button></p>'
				}).then((res) => {
					this.cachedrefs.splice(index, 1);
				});
			}

			addOrUpdate() {
				if (this.isNewRefValid()) {
					var email = this.newref.Email;
					if (this.referees.filter(function (e) {
						return e.Email == email;
					}).length == 0) {
						this.cachedrefs.push(this.newref);
						this.referees.push(this.newref);
					} else {
						this.cachedrefs.map(x => {
							if (x.ProfileRefereeId === this.newref.ProfileRefereeId) {
								x.FirstName = this.newref.FirstName;
								x.LastName = this.newref.LastName;
								x.AvatarImagePath = this.newref.AvatarImagePath;
								x.Email = this.newref.Email;
								x.Phone = this.newref.Phone;
								x.RefereeTypeId = this.newref.RefereeTypeId;
								x.RoleId = this.newref.RoleId;
								x.RoleTitle = this.newref.RoleTitle;
								x.IsTempRole = this.newref.IsTempRole;
								x.OrganisationId = this.newref.OrganisationId;
								x.OrgCountryId = this.newref.OrgCountryId;
								x.OrganisationName = this.newref.OrganisationName;
								x.KnownSince = this.newref.KnownSince;
								x.ProfileExperienceId = this.newref.ProfileExperienceId;
								x.ProfileEducationId = this.newref.ProfileEducationId;
								x.ProfileId = this.newref.ProfileId;
								x.DisplayOption = this.newref.DisplayOption;
								x.OverallRating = this.newref.OverallRating;
								x.TestimonialText = this.newref.TestimonialText;
								x.IsSendEmailRequest = this.newref.IsSendEmailRequest;
								return;
							}
						});
					}
					this.newref = new srvProfile.referees();
				}
			}

			isNewRefValid(): boolean {
				if (!this.newref || !this.newref.FirstName || !this.newref.LastName || !this.newref.Email || !this.newref.Phone || !this.newref.RefereeTypeId)
					return false;
				return true;
			}

			commitRefs() {
				this.saving = true;
				this.editing = false;

				srvProfile.referees.save(this.cachedrefs, (res) => {
					this.referees = res;
					this.saving = false;
				});
			}

			saveRefDisplayOption(displayoption: number) {
				if (this.cachedrefs.length > 0) {
					this.cachedrefs.forEach((v) => {
						v.DisplayOption = displayoption;
					});
				}
			}

			cancelEdit() {
				this.editing = false;
			}

			toggleProfileDisplay(ref: IReferee) {
				ref.DisplayOption ^= 1 << 0;
			}

			toggleResumeDisplay(ref: IReferee) {
				ref.DisplayOption ^= 1 << 1;
			}

			isRefDisplayInProfile(ref: IReferee) {
				return ((ref.DisplayOption >> 0) & 1) > 0;
			}

			isRefDisplayInResume(ref: IReferee) {
				return ((ref.DisplayOption >> 1) & 1) > 0;
			}

			getRefereeDetailsDesc(ref: IReferee) {
				if (!ref.RefereeTypeId)
					return "";

				// Managing Director, Cooper Digital
				if (this.experiences.length > 0 && (ref.RefereeTypeId == 1 || ref.RefereeTypeId == 2 || ref.RefereeTypeId == 9)) {
					var exp = this.experiences.find(x => x.ProfileExperienceId === ref.ProfileExperienceId);
					return (exp.Role.RoleName + ', ' + exp.Organisation.Name);
				} else if (this.educations.length > 0 && (ref.RefereeTypeId == 5)){
					var edu = this.educations.find(x => x.ProfileEducationId === ref.ProfileEducationId);
					return edu.Institute.Name;
				}

				return "";
			}
		}

		return {
			restrict: 'E',
			templateUrl: 'partials/profileedit/referees.html',
			controller: ctrlEditReferees,
			controllerAs: 'cEditRef'
		};
	}

	dirEditReferees.$inject = ['srvProfile', 'srvAuth', 'ngDialog', '$resource', 'apiendpoints', 'srvMasterData'];
	angular.module('SugarCube').directive('dirEditReferees', dirEditReferees);
}