module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditEducation(
        srvProfile: srvProfile,
        srvAuth: srvAuth,
        ngDialog: ng.dialog.IDialogService,
        apiendpoints: any,
        $cacheFactory: ng.ICacheFactoryService,
        srvMasterData: any): ng.IDirective {

        class ctrlEditEducation extends EditDirBase {
            public education: ng.resource.IResourceArray<IEducation>;
            public countryfilterid: number;
            public subprofileid: number;
            public md: any;

            public editingedu: IEducation;

            public static $inject = ['$scope'];

            constructor(private $scope: ng.IScope) {
                super();

                this.md = srvMasterData.md;

                this.education = srvProfile.education.query({
                    subprofileid: this.subprofileid
                });
            }

            newEntry() {
                super.newEntry();

                this.editingedu = new srvProfile.education();
                this.countryfilterid = srvAuth.session.countryid;
                this.editingedu.StartDate = moment().format();
                this.editingedu.EndDate = moment().format();

                this.$scope.$emit(Events.evtScrollToEdu);
            }

            editEntry(edu: IEducation) {
                this.editingedu = angular.copy(edu);
                this.countryfilterid = edu.Institute.CountryId || srvAuth.session.countryid;

                this.editing = true;

                this.$scope.$emit(Events.evtScrollToEdu);
            }

            getDisplayStatus(edu: IEducation): string {
                switch (this.getStatusId(edu)) {
                    case 3: return 'Incomplete';
                    case 2: return 'Completed';
                    default: return 'Currently Studying';
                }
            }

            getStatusId(edu: IEducation): number {
                if (edu.IsCurrent && !edu.IsGraduate) return 1;
                if (!edu.IsCurrent && edu.IsGraduate) return 2;
                if (!edu.IsCurrent && !edu.IsGraduate) return 3;

                return 1;
            }

            setStatusId(edu: IEducation, id) {
                switch (id) {
                    case 3:
                        edu.IsCurrent = false;
                        edu.IsGraduate = false;
                        break;

                    case 2:
                        edu.IsCurrent = false;
                        edu.IsGraduate = true;
                        break;

                    default:
                        edu.IsCurrent = true;
                        edu.IsGraduate = false;
                        break;
                }
            }

            setStartMonth(newMonth) {
                if (arguments.length) {
                    this.editingedu.StartDate = moment(this.editingedu.StartDate).month(newMonth).format();
                } else {
                    return moment(this.editingedu.StartDate).month();
                }
            }

            setEndMonth(newMonth) {
                if (arguments.length) {
                    this.editingedu.EndDate = moment(this.editingedu.EndDate).month(newMonth).format();
                } else {
                    return moment(this.editingedu.EndDate).month();
                }
            }

            setStartYear(newYear) {
                if (arguments.length) {
                    this.editingedu.StartDate = moment(this.editingedu.StartDate).year(newYear).format();
                } else {
                    return moment(this.editingedu.StartDate).year();
                }
            }

            setEndYear(newYear) {
                if (arguments.length) {
                    this.editingedu.EndDate = moment(this.editingedu.EndDate).year(newYear).format();
                } else {
                    return moment(this.editingedu.EndDate).year();
                }
            }

            isEditingEduValid(): boolean {
                if (this.editingedu == null) return false;

                if (this.editingedu.Institute == null) return false;
                if (this.editingedu.Course == null) return false;

                if (this.editingedu.Institute.InstituteId <= 0 && this.editingedu.Institute.Name == null) return false;
                if (this.editingedu.Course.CourseId <= 0 && this.editingedu.Course.CourseName == null) return false;

                return true;
            }

            commitEdit() {
                if (!this.isEditingEduValid()) return;

                this.saving = true;
                this.editing = false;

                this.$scope.$emit(Events.evtScrollToEdu);

                if (this.editingedu.ProfileEducationSubProfileId > 0) {
                    (<any>srvProfile.education).update(this.editingedu, (res) => {
                        this.education.forEach((e, i, a) => {
                            if (e.ProfileEducationSubProfileId == this.editingedu.ProfileEducationSubProfileId) {
                                a[i] = res;
                                return false;
                            }
                        });

                        this.editingedu = null;
                        this.saving = false;
                    });
                } else {
                    srvProfile.education.save(this.editingedu, (res) => {
                        this.education.push(res);

                        this.editingedu = null;
                        this.saving = false;
                    });
                }

                // invalidate cache
                $cacheFactory.get('$http').remove(apiendpoints.profile + 'Education');
            }

            deleteEditingEdu() {
                ngDialog.openConfirm({
                    plain: true,
                    template: '<p>Are you sure you wish to delete this entry</p><p class="text-align-right"><button class="btn-small btn-cancel" ng-click="closeThisDialog()">No</button><button class="btn-small" ng-click="confirm()">Yes</button></p>'
                }).then((res) => {
                    this.saving = true;
                    this.editing = false;

                    srvProfile.education.delete({ id: this.editingedu.ProfileEducationSubProfileId }, (res) => {
                        this.education.forEach((e, i, a) => {
                            if (e.ProfileEducationSubProfileId == this.editingedu.ProfileEducationSubProfileId) {
                                a.splice(i, 1);
                                return false;
                            }
                        });

                        this.saving = false;
                    });

                    // invalidate cache
                    $cacheFactory.get('$http').remove(apiendpoints.profile + 'Education');
                });
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/edu.html',
            controller: ctrlEditEducation,
            controllerAs: 'cEditEdu',
            scope: {
                subprofileid: '=?'
            },
            bindToController: true
        };
    }

    dirEditEducation.$inject = ['srvProfile', 'srvAuth', 'ngDialog', 'apiendpoints', '$cacheFactory', 'srvMasterData'];
    angular.module('SugarCube').directive('dirEditEducation', dirEditEducation);
}