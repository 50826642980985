module SugarCube {
	"use strict";

	export class ctrlDashboard {
		public metadata: any = {};

		public static $inject = ['$rootScope', '$scope', 'srvDashboard', '$resource', 'apiendpoints', '$timeout', 'srvI18N', 'ngDialog', '$state'];

		constructor($rootScope: any,
			$scope: any,
			srvDashboard: any,
			$resource: ng.resource.IResourceService,
			apiendpoints: any,
			$timeout: ng.ITimeoutService,
			srvI18N: any,
			ngDialog: ng.dialog.IDialogService,
			private $state: ng.ui.IStateService) {

			$rootScope.loading = true;

			$scope.i18n = srvI18N.i18n;

			srvDashboard.dashboard.get({}, (res) => {
				$rootScope.loading = false;

				$scope.panels = res.panels;
				$scope.welcometext = res.welcometext;
				$scope.translations = res.translations;
				$scope.premiumcost = res.premiumcost;

				$scope.firstname = res.firstname;
				$scope.profilecompleteness = res.profilecompleteness;

				$timeout(() => {
					$scope.panels.forEach((e, i, a) => {
						e.feedbackData = {
							title: '[' + e.title + '] ',
							content: '',
							submitted: false
						};

						$resource(apiendpoints.dashboard + e.url).get({}, (res) => {
							a[i] = res;
						});
					});
				}, 0);

				//if (res.showprofiledetailspopup) {

				//    ngDialog.open({
				//        template: 'partials/dialogs/confirmdetails.html',
				//        controller: ctrlConfirmDetailsDlg,
				//        controllerAs: 'cConfirmDetailsDlg',
				//        className: 'ngdialog-theme-default dlg-confirmdetails',
				//        showClose: false,
				//        closeByEscape: false
				//    }).closePromise.then(() => {
				//        this.$state.go('culture.profileedit');
				//    });

				//}



			});

			srvDashboard.stats.query({}, (res) => {
				$scope.stats = res;
			});
		}
	}

	angular.module('gControllers').controller('ctrlDashboard', ctrlDashboard);
}