module SugarCube {
    'use strict';

    export class ctrlManageProfileVersions {

        public subprofiles: ng.resource.IResourceArray<ISubProfile>;
        public experiences: ng.resource.IResourceArray<IExperience>;
        public educations: ng.resource.IResourceArray<IEducation>;

        protected updatesectiondisplaytimeouthandle: ng.IPromise<any>;

        public static $inject = ['srvProfile', '$timeout', 'ngDialog', '$resource'];

        constructor(
            private srvProfile: srvProfile,
            private $timeout: ng.ITimeoutService,
            private ngDialog: ng.dialog.IDialogService,
            private $resource: ng.resource.IResourceService
        ) {
            this.subprofiles = srvProfile.subprofiles.query();
            this.experiences = srvProfile.experiences.query();
            this.educations = srvProfile.education.query();
        }

        updateSectionDisplay() {
            if (this.updatesectiondisplaytimeouthandle) this.$timeout.cancel(this.updatesectiondisplaytimeouthandle);
            this.updatesectiondisplaytimeouthandle = this.$timeout(() => {
                this.srvProfile.subprofiles.save(this.subprofiles, (res) => {
                });
            }, 1000);
        }

        getExperienceSubProfile(subprofile: ISubProfile, profileexperienceid: number): IExperienceSubProfile {
            return subprofile.expsubprofiles.find((v) => {
                return v.ProfileExperienceId == profileexperienceid;
            });
        }

        translateExperience(exp: IExperience, subprofile: ISubProfile) {
            var sourcesubprofileexp: IExperienceSubProfile;

            this.subprofiles.forEach((v) => {
                if (!sourcesubprofileexp)
                    sourcesubprofileexp = v.expsubprofiles.find((t) => {
                        return t.ProfileExperienceId == exp.ProfileExperienceId && !!t.Achievements;
                    });
            });

            var targetsubprofileexp = subprofile.expsubprofiles.find((v) => {
                return v.ProfileExperienceId == exp.ProfileExperienceId;
            });

            this.$resource(Config.CCApiBase + '/ProfileAPI/UpdateTranslatedExperience', {
                sourceexperiencesubprofileid: sourcesubprofileexp.ExperienceSubProfileId,
                targetexperiencesubprofileid: targetsubprofileexp.ExperienceSubProfileId
            }).save((res: IExperienceSubProfile) => {

                // update experience subprofile
                targetsubprofileexp.Achievements = res.Achievements;
                targetsubprofileexp.DisplayOption = res.DisplayOption;
                targetsubprofileexp.ExperienceSubProfileId = res.ExperienceSubProfileId;
                targetsubprofileexp.ProfileExperienceId = res.ProfileExperienceId;
                targetsubprofileexp.SubProfileId = res.SubProfileId;

                this.ngDialog.open({
                    template: 'partials/dialogs/translateexperience.html',
                    className: 'ngdialog-theme-default dlg-translateexperience',
                    controller: ctrlTranslateExpDlg,
                    controllerAs: 'cTranslateExpDlg',
                    resolve: {
                        exp: () => {
                            return exp;
                        },
                        subprofile: () => {
                            return subprofile;
                        }
                    }
                });
            });

        }
    }

    angular.module('gControllers').controller('ctrlManageProfileVersions', ctrlManageProfileVersions);
}