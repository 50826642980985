module SugarCube {
    'use strict';
    
    export interface ISkill extends ng.resource.IResource<ISkill> {
        SkillId: number;
        SkillName: string;
        IsTempSkill: boolean;
        SkillLevelId: number;
        endorsementcount: number;
    }

    export interface IAdditionalSkill extends ISkill {
        AdditionalSkillId: number;
        Description: string;
        Order: number;
    }
}