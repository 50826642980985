module SugarCube {
    'use strict';

    declare var Bloodhound: any;

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirAutoSuggestOrganisations(srvMasterData: any, apiendpoints: any, srvAuth: srvAuth, $resource: ng.resource.IResourceService): ng.IDirective {

        interface IASOrgScope extends ng.IScope {
            _org: any;
            org: IOrganisation;
            countryid: number;
            orgdata: any;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/templates/autosuggestorganisations.html',
            scope: {
                org: '=',
                countryid: '=',
                inClass: '=?'
            },
            link: {
                pre: (scope: IASOrgScope) => {
                    var firsttrigger = true;

                    if (scope.org) scope._org = scope.org.Name;

                    var newOrgData = function (cid) {
                        var bh = new Bloodhound({
                            datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.num); },
                            queryTokenizer: Bloodhound.tokenizers.whitespace,
                            local: [],
                            remote: {
                                url: apiendpoints.common + 'AutoSuggestOrganisations?term=%QUERY' + '&countryid=' + cid,
                                wildcard: '%QUERY',
                                ajax: {
                                    beforeSend: function (jqXHR, settings) {
                                        jqXHR.setRequestHeader('Authorization', 'Bearer ' + srvAuth.session.accesstoken);
                                    }
                                }
                            }
                        });
                        bh.initialize();

                        scope.orgdata = {
                            displayKey: 'Name',
                            source: bh.ttAdapter(),
                            templates: {
                                suggestion: function (context) {
                                    return '<div class="org-autosuggest float-container">\
                                <img src="' + (context.LogoURL || 'images/defaultcompany.png') + '" />\
                                <h3>' + context.Name + '</h3>\
                                <p>' + context.WebURL + '</p>\
                            </div>';
                                }
                            }
                        }
                    }
                    newOrgData(scope.countryid);

                    scope.$watch('_org', (newvalue: any) => {
                        if (firsttrigger) {
                            firsttrigger = false;
                            return;

                        }

                        if (typeof(newvalue) == "string") {
                            scope.org = <IOrganisation>{
                                Name: <string>newvalue,
                                OrganisationId: 0,
                                OrgLocationList: []
                            };
                        } else {
                            scope.org = <IOrganisation>newvalue;
                        }
                    });

                    scope.$watch('countryid', (newvalue, oldvalue) => {
                        newOrgData(newvalue);
                    });

                    scope.$watch('org', (newvalue: any) => {
                        if (!newvalue || newvalue.OrganisationId == undefined) scope._org = newvalue;
                        else if (newvalue.OrganisationId == 0) return;
                        else {
                            scope._org = newvalue;
                            firsttrigger = true;
                        }
                    });
                }
            }
        };
    }

    dirAutoSuggestOrganisations.$inject = ['srvMasterData', 'apiendpoints', 'srvAuth', '$resource'];
    angular.module('SugarCube').directive('dirAutoSuggestOrganisations', dirAutoSuggestOrganisations);
}