module SugarCube {
    "use strict";

    export class ctrlApp {
        public metadata: any = {};
        public culturecode: string = 'en-US';
        public currencysymbol: string = '$';
        public apidomain: string;
        public static $inject = ['$rootScope', '$scope', 'srvI18N', 'srvAuth', '$state', '$urlRouter', 'ngDialog', '$sce', 'cfgApp', '$window'];

        constructor(private $rootScope: IAppRootScope,
            $scope: IAppScope,
            private srvI18N: srvI18N,
            srvAuth: srvAuth,
            $state: ng.ui.IStateService,
            $urlRouter: ng.ui.IUrlRouterService,
            ngDialog: ng.dialog.IDialogService,
            private $sce: ng.ISCEService,
            cfgApp: any) {

            $rootScope.bodystyle = {};

            $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
                var continueExecution = function () {
                    $state.go(toState.name, toParams);
                }

                if (srvAuth.session === null) {
                    event.preventDefault();
                    if (!!toParams && !!toParams.accesstoken) {
                        srvAuth.setAccessToken(toParams.accesstoken);
                    }

                    srvAuth.getSession(toParams.culturecode).then(() => {
                        this.culturecode = srvAuth.session.displayculturecode;
                        if (srvAuth.isLoggedIn()) {
                            if (toState.data && toState.data.authenticate === false) {
                                $state.go('culture.profileedit', { culturecode: srvAuth.session.profileculturecode });
                            } else {
                                continueExecution();
                            }
                        } else {
                            if (toState.data && toState.data.authenticate === true) {
                                $state.go('home', { culturecode: srvAuth.session.displayculturecode });
                            } else {
                                continueExecution();
                            }
                        }

                    }, function () {
                        //$state.go(toState.name, toParams);
                    });
                }
            });

            $rootScope.$on('$locationChangeSuccess', (event) => {
                $rootScope.bodystyle = {};                                  // reset styles of body
                $("html, body").animate({ scrollTop: 0 }, "slow");          // scroll page to top
                ngDialog.closeAll();
            });

            $rootScope.$on(Events.evtScrollToTop, () => {
                $("html, body").animate({ scrollTop: 0 }, "slow");          // scroll page to top
            });

            $scope.$on(Events.evtUpdateMetaData, (evt, md) => {
                this.metadata.title = md.title;
                this.metadata.keywords = md.keywords;
                this.metadata.description = md.description;
            });

            $rootScope.$on(Events.evtHttpError, (evt, data) => {
                switch (data.status) {
                    case 401:
                        if (!ngDialog.isOpen('dlg-sessionexpired'))
                            ngDialog.open({
                                plain: true,
                                template: '<p>Your session has expired. Please login again.</p>',
                                className: 'ngdialog-theme-default dlg-msg',
                                name: 'dlg-sessionexpired'
                            });

                        srvAuth.clearTokensInLocalStorage();

                        $state.go('home');
                        break;

                    case 421:
                        window.location.href = data.data;
                        break;

                    case 500:
                        $state.go('error.500');
                        break;

                    default:
                        // do nothing, and hope the controllers can handle this gracefully
                        break;
                }
            });

            $scope.$on(Events.evtSignedIn, (evt, data) => {
                if (data) {
                    switch (data.to) {
                        case 'draganddrop':
                            if ($state.current.name != 'culture.jobalert')
                                $state.go('culture.cvfirstview', {
                                    culturecode: srvAuth.session.displayculturecode,
                                    cvid: 16
                                });
                            break;
                        case 'rrb':
                            $state.go('culture.rrb', {
                                culturecode: srvAuth.session.displayculturecode,
                                fbobj: data.payload,
                                cvid: data.cvid
                            });
                            break;
                    }
                } else {
                    // $state.go('culture.dashboard', {
                    //     culturecode: srvAuth.session.displayculturecode
                    // });
                    $state.go('culture.profileedit');
                }
            });

            $rootScope.$on(Events.evtSetAccessToken, (evt) => {
                this.currencysymbol = srvAuth.session.currencySymbol;
            });

            this.apidomain = cfgApp.apidomain;
        }

        getbodystyle = function () {
            return this.$rootScope.bodystyle;
        }

        onkeydown = function ($event) {
            if ($event.keyCode == 90 && $event.ctrlKey) this.$rootScope.$broadcast('undo');
            if ($event.keyCode == 27) this.$rootScope.$broadcast('esc');
        }

        i18n(key: string): string {
            return this.srvI18N.i18n(key);
        }

        trustAsResource(url: string): string {
            return this.$sce.trustAsResourceUrl(url);
        }
    }

    interface IAppRootScope extends ng.IRootScopeService {
        bodystyle: any;
        loading: boolean;
    }

    interface IAppScope extends ng.IScope {
        i18n: MethodDecorator;
    }

    angular.module('gControllers').controller('ctrlApp', ctrlApp);
}