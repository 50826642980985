/// <reference path="../models/profile/language.ts" />

module SugarCube {
    'use strict';

    declare var Bloodhound: any;

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirAutoSuggestLanguages(apiendpoints: any, srvAuth: srvAuth, $resource: ng.resource.IResourceService): ng.IDirective {

        interface IASLangScope extends ng.IScope {
            _lang: any;
            lang: ILanguage;
            langdata: any;
            langdatanotfound: boolean;
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/templates/autosuggestlanguages.html',
            scope: {
                lang: '='
            },
            link: {
                pre: (scope: IASLangScope) => {
                    var firsttrigger = true;

                    if (scope.lang) scope._lang = scope.lang.Name;

                    var bh = new Bloodhound({
                        datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.num); },
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        local: [],
                        remote: {
                            url: apiendpoints.common + 'AutoSuggestLanguageSkills?term=%QUERY',
                            wildcard: '%QUERY',
                            ajax: {
                                beforeSend: function (jqXHR, settings) {
                                    jqXHR.setRequestHeader('Authorization', 'Bearer ' + srvAuth.session.accesstoken);
                                }
                            }
                        }
                    });
                    bh.initialize();

                    scope.langdata = {
                        displayKey: 'Name',
                        source: bh.ttAdapter()
                    }

                    scope.$watch('_lang', (newvalue: any) => {
                        if (firsttrigger) {
                            firsttrigger = false;
                            return;
                        }

                        if (!newvalue || !newvalue.LanguageId) {                           
                            scope.$emit("notfound");
                            var langres = $resource<ILanguage>(apiendpoints.profile + 'Language');
                            scope.lang = new langres({
                                LanguageId: 0,
                                Name: <string>newvalue,
                                LanguageLevelId: 0,
                                ProfileLanguageId: 0
                                
                            });
                        } else {
                            scope.$emit("langfound");
                            scope.lang = <ILanguage>newvalue;
                        }
                    });

                    scope.$watch('lang', (newvalue: any) => {
                        if (!newvalue || newvalue.LanguageId == undefined) scope._lang = null;
                    });
                }
            }
        };
    }

    dirAutoSuggestLanguages.$inject = ['apiendpoints', 'srvAuth', '$resource'];
    angular.module('SugarCube').directive('dirAutoSuggestLanguages', dirAutoSuggestLanguages);
}