module SugarCube {
    'use strict';

    export function empowerPanel(
        $resource: ng.resource.IResourceService,
        srvProfile: srvProfile,
        $q: ng.IQService,
        ngDialog: ng.dialog.IDialogService
    ): ng.IDirective {

        class ctrlEmpowerPanel {

            public endorsers: ng.resource.IResourceArray<any>;
            public basicinfo: IBasicInfo;
            public rankings: Array<any>;
            public total: number;
            public myrank: number;
            public endorsedsomeonebefore: boolean;

            public rankingobj: any;

            constructor() {
                this.endorsers = $resource(Config.CCApiBase + '/ProfileAPI/GetActiveEndorsersInTrial').query();

                this.rankingobj = $resource(Config.CCApiBase + '/ProfileAPI/GetEmpowerSuccessRanking').get((res) => {
                    this.rankings = res.endorsers;
                    this.myrank = res.myrank;
                    this.total = res.total;

                    return res;
                });

                $q.all([
                    this.endorsers.$promise.then((res) => {
                        if (res.length > 0) this.endorsedsomeonebefore = true;

                        return res;
                    }),
                    srvProfile.basicinfo.get().$promise.then((res) => {
                        this.basicinfo = res;
                    })
                ]).then(() => {
                    if (this.basicinfo.ccshareddate != null) {
                        this.endorsers.unshift({
                            avatarurl: 'images/lightbluefb.png',
                            firstname: 'Shared!',
                            endorsementtime: this.basicinfo.ccshareddate,
                            isactive: true
                        });
                    }
                });
            }

            public showEmpowerNoEndorsementsPopup() {
                ngDialog.open({
                    className: 'ngdialog-theme-default dlg-empowerpopup',
                    template: 'partials/dialogs/empowernoendorsements.html'
                });
            }

            public getDaysLeftInTrial() {
                if (!this.basicinfo) return -1;

                var now = moment.utc();
                var trialenddate = moment.utc(this.basicinfo.trialenddate);

                var days = trialenddate.diff(now, 'day');
                return days > 0 ? days : 0;
            }
        }

        return {
            restrict: 'EA',
            templateUrl: 'partials/profileedit/empowerpanel.html',
            controller: ctrlEmpowerPanel,
            controllerAs: 'cEmpowerPanel',
            link: (scope: ng.IScope, element: JQuery, attrs: any) => {

            }
        }
    }

    empowerPanel.$inject = ['$resource', 'srvProfile', '$q', 'ngDialog'];
    angular.module('SugarCube').directive('empowerPanel', empowerPanel);
}