module SugarCube {
    "use strict";

    export class ctrlRRB {
        public step: number = 1;
        public view: string = 'myresume';
        public googleAddresses: utilGoogleAddressPicker;
        public md: any;
        public countryid: any;
        public address: any;
        public exps: Array<any> = [];
        public edus: Array<any> = [];
        public orgsdata: any;
        public rolesdata: any;
        public instdata: any;
        public isloggedin: boolean;
        public firstname: string;
        public lastname: string;
        public email: string;
        public password: string;
        public phone: string;
        public totalyears: number = 1;
        public culture: string;
        public addressunit: string;
        public postalcode: string;
        public cvname: string;

        protected loadingdlg: ng.dialog.IDialogOpenResult;

        public static $inject = ['srvMasterData', 'srvAuth', '$resource', 'apiendpoints', '$rootScope', 'srvGooglePlaces', 'srvAnalytics', 'ngDialog', '$q', 'fbobj', 'cvid', 'srvProfile', '$state'];

        constructor(srvMasterData: any,
            private srvAuth: srvAuth,
            private $resource: ng.resource.IResourceService,
            private apiendpoints: any,
            private $rootScope: ng.IRootScopeService,
            private srvGooglePlaces: any,
            private srvAnalytics: any,
            private ngDialog: ng.dialog.IDialogService,
            private $q: ng.IQService,
            fbobj: any,
            public cvid: number,
            private srvProfile: srvProfile,
            private $state: ng.ui.IStateService) {

            this.culture = srvAuth.session.displayculturecode;
            this.countryid = srvAuth.session.countryid;
            this.md = srvMasterData.md;
            this.googleAddresses = (new utilGoogleAddressPicker(this.md.country[this.countryid].abbr)).getTypeAheadDataSet();
            this.orgsdata = srvMasterData.getorgdata();
            this.rolesdata = {
                displayKey: 'Name',
                source: srvMasterData.getrolesautosuggest().ttAdapter()
            };
            this.cvname = this.md.resumes[cvid].EnglishName;

            this.instdata = srvMasterData.getinstitutedata();

            this.isloggedin = !!srvAuth.session.profileid;

            if (this.isloggedin) {
                this.firstname = srvAuth.session.firstname;
                this.lastname = srvAuth.session.lastname;
            }

            // pre populate with fbobj
            if (fbobj && fbobj.work) {
                fbobj.work.forEach((e) => {
                    var exp: any = {};

                    // suggest organisation if found
                    if (e.employer && e.employer.name) {
                        $resource(apiendpoints.common + 'AutoSuggestOrganisations?countryid=&term=' + e.employer.name).query((res) => {
                            if (res.length > 0) {
                                exp.Organisation = res[0];
                            } else {
                                exp.Organisation = e.employer.name;
                            }
                        });
                    }

                    // suggest roles if found
                    if (e.position && e.position.name) {
                        $resource(apiendpoints.common + 'AutoSuggestRoles?term=' + e.position.name).query((res) => {
                            if (res.length > 0) {
                                exp.role = res[0];

                                exp.csc = [];
                                res[0].CSC.forEach((cscres) => {
                                    exp.csc.push({
                                        c: cscres.ClassificationId,
                                        sc: cscres.SubClassificationId
                                    });
                                });
                            } else {
                                exp.role = e.position.name;
                                exp.csc = [
                                    { c: 0, sc: 0 }
                                ];
                            }
                        });
                    }

                    this.exps.push(exp);
                });
            }

            if (this.exps.length == 0) this.addExp();
            this.addEdu();
        }

        changeCountry() {
            this.googleAddresses = (new utilGoogleAddressPicker(this.md.country[this.countryid].abbr)).getTypeAheadDataSet();
        }

        addExp() {
            this.exps.push({});
        }

        deleteExp(index) {
            if (this.exps.length>1) {
                this.exps.splice(index, 1);
            }
           
        }

        addEdu() {
            this.edus.push({});
        }

        deleteEdu(index) {
            if (this.edus.length>1) {
                this.edus.splice(index, 1);
            }
        }

        createResume() {
            this.loadingdlg = this.ngDialog.open({
                template: 'partials/dialogs/loadingrrb.html',
                className: 'ngdialog-theme-default ngdialog-loadingpagebreaks'
            });

            if (!this.isloggedin) {
                this.$resource(this.apiendpoints.account + 'RegisterUser').save({
                    FirstName: this.firstname,
                    LastName: this.lastname,
                    Email: this.email,
                    Password: this.password,
                    cvid: this.cvid
                }, (res) => {
                    this.srvAuth.setSession(res);
                    this.srvAnalytics.registerevent();

                    this.updateData();
                }, (res) => {

                    switch (res.status) {
                        case 409:
                            this.ngDialog.open({
                                template: 'partials/dialogs/emailexists.html',
                                className: 'ngdialog-theme-default dlg-msg'
                            });

                            this.$rootScope.$broadcast(Events.evtScrollToTop);
                            break;
                    }

                    this.loadingdlg.close();
                });
            } else {
                this.updateData();
            }
        }

        updateData() {
            // transform exps to match IExperience
            var enddate = moment();

            var nexp = this.exps.map((e, i, a) => {
                return <IExperience>{
                    Achievements: '',
                    IsCurrentlyEmployed: i == 0,
                    MaxSalary: 0,
                    MinSalary: 0,
                    Organisation: e.Organisation,
                    Role: e.role.rolevm || {
                        RoleId: 0,
                        RoleName: e.role,
                        IsTempRole: true
                    },
                    SubCategories: e.csc.map((e1) => {
                        return e1.sc;
                    }),
                    EndDate: enddate.clone().subtract((this.totalyears / this.exps.length) * i, 'year').format(),
                    StartDate: enddate.clone().subtract((this.totalyears / this.exps.length) * (i + 1), 'year').format()
                };
            });

            // transform edus to match IEducation
            var nedu = this.edus.map((e, i, a) => {
                return <IEducation>{
                    Description: '',
                    EducationalLevelId: e.EducationalLevelId,
                    EndDate: enddate.format(),
                    StartDate: enddate.clone().subtract(1, 'years').format(),
                    Institute: e.institute
                };
            });

            this.srvGooglePlaces.getPlaceDetails(this.address.place_id).then((res) => {
                this.$resource(this.apiendpoints.common + 'UpdateMasterGeoData').save({ geodatastring: JSON.stringify(res) }, (geodata) => {
                    this.$resource(this.apiendpoints.common + 'UpdateProfileBasicInfo').save({
                        firstname: this.firstname,
                        lastname: this.lastname,
                        phonenumber: this.phone,
                        countryid: this.countryid,
                        geodataid: geodata.geodataid,
                        address: this.address.description,
                        addressunit: this.addressunit,
                        postalcode: this.postalcode
                    }, () => {
                        this.$q.all(
                            [this.$resource(Config.CCApiBase + '/ProfileAPI/CreateRRBExperiences').save(nexp).$promise]

                                .concat(nedu.map((e) => {
                                    return <ng.IPromise<any>>this.srvProfile.education.save(e).$promise;
                                }))
                        ).then(() => {
                            this.$resource(this.apiendpoints.common + 'UpdateCareerSummary').get(() => {
                                this.loadingdlg.close();

                                // we go to cv first
                                this.$state.go('culture.cvfirstview', {
                                    cvid: this.cvid
                                });

                                this.ngDialog.open({
                                    template: 'partials/dialogs/rrbcomplete.html',
                                    className: 'ngdialog-theme-default ngdialog-rrbcomplete'
                                });

                                

                                //this.step = 2;

                                this.$rootScope.$broadcast(Events.evtScrollToTop);
                            });
                        });
                    });
                });
            }, (res) => {
            });
        }

        changeRole(exp) {
            exp.csc = [];

            if (exp.role && exp.role.CSC) {
                exp.role.CSC.forEach((e) => {
                    exp.csc.push({
                        c: e.ClassificationId,
                        sc: e.SubClassificationId
                    });
                });
            } else {
                exp.csc.push({ c: 0, sc: 0 });
            }
        }

        deleteCSC(exp, index) {
            exp.csc.splice(index, 1);
        }

        addCSC(exp) {
            exp.csc.push({});
        }
    }

    angular.module('gControllers').controller('ctrlRRB', ctrlRRB);
}