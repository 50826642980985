module SugarCube {
    'use strict';

    /**
     * Directive that forces banner to be full height of window
     */
    export function dirEditInterests(srvProfile: srvProfile, srvAuth: any, $resource: ng.resource.IResourceService, apiendpoints: any): ng.IDirective {

        class ctrlEditInterests extends EditDirBase {
            public interests: ng.resource.IResourceArray<IInterest>;
            public newinterest: IInterest;
            public cachedinterests: ng.resource.IResourceArray<IInterest>;

            constructor() {
                super();

                this.interests = srvProfile.interests.query();
            }

            newEntry() {
                this.editing = true;

                this.cachedinterests = angular.copy(this.interests);
            }

            deleteEntry(index: number) {
                this.cachedinterests.splice(index, 1);
            }

            addNew() {
                if (this.isNewInterestValid()) {
                    this.cachedinterests.push(this.newinterest);

                    this.newinterest = new srvProfile.interests();
                }
            }

            isNewInterestValid(): boolean {
                if (!this.newinterest) return false;

                if (this.newinterest.InterestName == '') return false;

                if (this.cachedinterests.filter((e) => {
                    return e.InterestName == this.newinterest.InterestName
                }).length > 0) return false;

                return true;
            }

            commitInterests() {
                this.saving = true;
                this.editing = false;

                srvProfile.interests.save(this.cachedinterests, (res) => {
                    this.interests = res;

                    this.saving = false;
                });
            }
        }

        return {
            restrict: 'E',
            templateUrl: 'partials/profileedit/interests.html',
            controller: ctrlEditInterests,
            controllerAs: 'cEditInterests'
        };
    }

    dirEditInterests.$inject = ['srvProfile', 'srvAuth', '$resource', 'apiendpoints'];
    angular.module('SugarCube').directive('dirEditInterests', dirEditInterests);
}